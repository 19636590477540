const initialState = {
    items: [],
    loading: false,
    count: 0,
    error: null,
};

export const PartyReply = (state = initialState, action) => {
    switch (action.type) {
        case "PartyReplyBegin":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "PartyReplySuccess":
            return {
                ...state,
                items: action.data,
                count: action.count,
                loading: false,
            };
        case "PartyReplyFailure":
            return {
                ...state,
                items: [],
                loading: false,
                error: action.data,
            };
        default:
            return state;
    }
};

// export default Users;
