import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { withRouter, Link, useHistory, Redirect } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect, useDispatch } from "react-redux"
import axios from "axios"
import { Store } from "react-notifications-component"
import { logIn } from "store/actions"
import { ApiRoute } from "common/ApiRoute"
import { ShowNotification } from "common/ShowNotification"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowSwal } from "common/ShowSwal"

const Verify = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  // handleValidSubmit

  const handleValidSubmit = (event, values) => {
    const requestPlayload = {
      ...props?.registerData,
      otp: values.emailOTP,
    }
    // console.log(requestPlayload)
    ApiCall(requestPlayload, "userRegister", false, result => {
      if (result?.data?.response === "success") {
        ShowSwal(
          "Register Successfully",
          "You are successfully register. Your account is under review you will receive an email shortly.",
          "info",
          "Ok",
          result => {
            history.push("/login")
          }
        )
        // history.push("/login")
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  const handleResendOtp = () => {
    // dispatch(registerAction(values))
    const requestPlayload = {
      ...props?.registerData,
    }
    // console.log(requestPlayload)
    ApiCall(requestPlayload, "sendOtp", false, result => {
      if (result?.data?.response === "success") {
        ShowNotification(result?.data?.message, "success")
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  return (
    <React.Fragment>
      {/* {parseInt(props.data.role) === 0 ? (
        <Redirect to="/user/dashboard" />
      ) : parseInt(props.data.role) === 1 ? (
        <Redirect to="/admin/dashboard" />
      ) : ( */}
      <>
        <MetaTags>
          <title>Verify Account | Incase360</title>
        </MetaTags>
        <div className="account-pages my-2 pt-sm-4">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                    {/* <h3 className="text-center mt-4 mb-3">
                      <Link to="/" className="d-block auth-logo">
                        <img
                          src="https://api.incase360.com/assets/images/Incase360-logo-1-white.png"
                          alt=""
                          height="30"
                          className="auth-logo-dark"
                        />
                        <img
                          src="https://api.incase360.com/assets/images/Incase360-logo-1-white.png"
                          alt=""
                          height="30"
                          className="auth-logo-light"
                        />
                      </Link>
                    </h3> */}
                    {props?.registerData?.length === 0 ? (
                      <div className="p-3">
                        <h4 className="text-muted font-size-24 text-center">
                          No Direct Access First Register Your Account
                        </h4>
                      </div>
                    ) : (
                      <div className="p-3">
                        <h4 className="text-muted font-size-24 text-center">
                          Please enter OTP received in email.
                        </h4>

                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                          }}
                        >
                          {/* {props.error && typeof props.error === "string" ? (
                                                  <Alert color="danger">{props.error}</Alert>
                                              ) : null} */}

                          <div className="mb-3">
                            <AvField
                              name="emailOTP"
                              label="OTP"
                              className="form-control"
                              placeholder="Enter Your OTP"
                              type="text"
                              required
                            />
                          </div>

                          <div className="mb-3 row mt-2">
                            <div className="col-6">
                              <div>
                                <input
                                  type="button"
                                  className="btn btn-link"
                                  id="customControlInline"
                                  value="Resend OTP"
                                  onClick={handleResendOtp}
                                />
                              </div>
                            </div>
                            <div className="col-6 text-end">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                          <div className="form-group mb-0 row">
                            <div className="col-12 text-center mt-2">
                              <p style={{ color: "red" }}>
                                <b>
                                  <u>Note</u>:- Please Don't Refresh This Page
                                </b>
                              </p>
                            </div>
                          </div>
                        </AvForm>
                      </div>
                    )}
                    <div className="form-group mb-0 row">
                      <div className="col-12 text-center">
                        <p>
                          Already have an account ?{" "}
                          <Link to="/login" className="text-primary">
                            {" "}
                            Login{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
      {/* )} */}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.Login,
  registerData: state.Register.items,
})

export default withRouter(connect(mapStateToProps)(Verify))

// Login.propTypes = {
// 	error: PropTypes.any,
// 	history: PropTypes.object,
// 	loginUser: PropTypes.func,
// };
