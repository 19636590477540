import 'rsuite/dist/rsuite.min.css';

import { ApiRoute } from 'common/ApiRoute';
import { ShowNotification } from 'common/ShowNotification';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Pagination, Table } from 'rsuite';

const { Column, HeaderCell, Cell } = Table

const BlockedUsers = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [total, setTotal] = useState(0)

  const fetchBlockedUsers = async () => {
    setLoading(true)
    try {
      const response = await fetch(
        `${ApiRoute}blocked-users?page=${page}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      if (!response.ok) throw new Error("Network response was not ok")
      const data = await response.json()
      setUsers(data.data)
      setTotal(data.total)
    } catch (error) {
      ShowNotification("Failed to fetch data: " + error.message, "danger")
    }
    setLoading(false)
  }

  const unblockUser = async userId => {
    try {
      const response = await fetch(ApiRoute + `unblock-user/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ token: localStorage.getItem("USER_TOKEN") }),
      })
      const result = await response.json()
      if (result.status === "success") {
        ShowNotification("User successfully unblocked", "success")
        fetchBlockedUsers()
      } else {
        throw new Error(result.message)
      }
    } catch (error) {
      ShowNotification("Failed to unblock user: " + error.message, "danger")
    }
  }

  useEffect(() => {
    fetchBlockedUsers()
  }, [page, limit])

  return (
    <div className="table-responsive">
      <Table height={400} data={users} loading={loading}>
        <Column width={80} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          {/* <Cell dataKey="id" /> */}
          <Cell>{(rowdata, key) => <>{key + 1}</>}</Cell>
        </Column>
        <Column flexGrow={2}>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="username" />
        </Column>
        <Column flexGrow={2}>
          <HeaderCell>Organization</HeaderCell>
          <Cell dataKey="organization_name" />
        </Column>
        <Column flexGrow={3}>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="email" />
        </Column>
        <Column flexGrow={3}>
          <HeaderCell>Contact</HeaderCell>
          <Cell dataKey="contact" />
        </Column>
        <Column flexGrow={2}>
          <HeaderCell>Created At</HeaderCell>
          <Cell>
            {rowdata => <>{moment(rowdata.created_at).format("DD-MM-YYYY")}</>}
          </Cell>
        </Column>
        <Column width={120} fixed="right">
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {rowData => (
              <Button
                color="green"
                size="xs"
                onClick={() => unblockUser(rowData.id)}
              >
                Unblock
              </Button>
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        prev
        next
        first
        last
        boundaryLinks
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={total}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={setLimit}
      />
    </div>
  )
}

export default BlockedUsers
