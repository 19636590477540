import React, { useEffect, useState } from "react"
import CanvasJSReact from "@canvasjs/react-charts"

const CanvasJSChart = CanvasJSReact.CanvasJSChart

const Chart = () => {
  const [chart1Options, setChart1Options] = useState([])
  const [chart2Options, setChart2Options] = useState([])

  const date = new Date()

  // Get the day, month, and year components
  const day = String(date.getDate()).padStart(2, "0")
  const month = String(date.getMonth() + 1).padStart(2, "0") // Note: Month is zero-based, so we add 1
  const year = date.getFullYear()

  const currentFormattedDate = `${day}-${month}-${year}`;

  const organizationName = "Recordent"
  const batchName = "Batch_98"
  const totalNoOfCases = 22
  const successfulDelivery = 22
  const electronicDelivery = 100
  const physicalDelivery = 0
  const processedOn = ""
  const whatsappAvailable = 22
  const whatsappDelivered = 19
  const emailAvailable = 11
  const emailDelivered = 6
  const smsAvailable = 22
  const smsDelivered = 21

  // Chart 1 Data
  const orangePercentage1 = totalNoOfCases - electronicDelivery
  const dataset1 = [orangePercentage1, electronicDelivery]
  const colors1 = ["#f1dbc8", "#8fd1b9"]
  const radius1 = 100
  const innerRadius1 = 70

  // Chart 2 Data
  const orangePercentage2 = totalNoOfCases - physicalDelivery
  const dataset2 = [orangePercentage2, physicalDelivery]
  const colors2 = ["#f1dbc8", "#ee8269"]
  const radius2 = 100
  const innerRadius2 = 70

  // Chart 3 Data
  const chart3Options = {
    theme: "dark2",
    backgroundColor: "#1f2431",
    labelFontColor: "red",
    // color: "white",
    titleFontColor: "white",
    animationEnabled: false,
    title: {
      text: "",
    },
    axisY: {
      title: "",
      interval: 25,
      includeZero: true,
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries,
    },
    toolTip: {
      shared: true,
      content: toolTipFormatter,
    },
    data: [
      {
        type: "bar",
        showInLegend: true,
        name: "Available",
        color: "#ebb075",
        dataPoints: [
          { y: totalNoOfCases, label: "Post" },
          { y: smsAvailable, label: "SMS" },
          { y: emailAvailable, label: "Email" },
          { y: whatsappAvailable, label: "WhatsApp" },
        ],
      },
      {
        type: "bar",
        showInLegend: true,
        name: "Delivered",
        color: "#8fd1b9",
        dataPoints: [
          { y: physicalDelivery, label: "Post" },
          { y: smsDelivered, label: "SMS" },
          { y: emailDelivered, label: "Email" },
          { y: whatsappDelivered, label: "WhatsApp" },
        ],
      },
      {
        type: "bar",
        showInLegend: true,
        name: "Read*",
        color: "#ee8269",
        dataPoints: [
          { y: 0, label: "Post" },
          { y: 0, label: "SMS" },
          { y: 0, label: "Email" },
          { y: 0, label: "WhatsApp" },
        ],
      },
    ],
  }

  useEffect(() => {
    // Chart 1
    const chart1Options = {
      theme: "dark2",
      backgroundColor: "#1f2431",
      titleFontColor: "white",
      labelFontColor: "red",
      animationEnabled: false,
      data: [
        {
          type: "doughnut",
          radius: radius1,
          innerRadius: innerRadius1,
          cornerRadius: 10,
          dataPoints: dataset1.map((value, index) => ({
            y: value,
            color: colors1[index],
          })),
        },
      ],
      centerText: `${dataset1[1]}%`,
      centerTextColor: "white",
    }

    setChart1Options(chart1Options)
    // Create Chart 1
    // new CanvasJSChart("chartContainer1", chart1Options)

    // Chart 2
    const chart2Options = {
      theme: "dark2",

      backgroundColor: "#1f2431",
      animationEnabled: false,
      data: [
        {
          type: "doughnut",
          radius: radius2,
          innerRadius: innerRadius2,
          cornerRadius: 10,
          dataPoints: dataset2.map((value, index) => ({
            y: value,
            color: colors2[index],
          })),
        },
      ],
      centerText: `${dataset2[1]}%`,
    }

    // Create Chart 2
    setChart2Options(chart2Options)
    // new CanvasJSChart("chartContainer2", chart2Options)

    // Create Chart 3
    // new CanvasJSChart('chartContainer3', chart3Options);
  }, [])

  function toggleDataSeries(e) {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false
    } else {
      e.dataSeries.visible = true
    }
    e.chart.render()
  }

  function toolTipFormatter(e) {
    let str = ""
    let total = 0
    let str3
    let str2
    for (let i = 0; i < e.entries.length; i++) {
      const str1 = `<span style="color:${e.entries[i].dataSeries.color}">${e.entries[i].dataSeries.name}</span>: <strong>${e.entries[i].dataPoint.y}</strong> <br/>`
      total = e.entries[i].dataPoint.y + total
      str = str.concat(str1)
    }
    str2 = `<strong>${e.entries[0].dataPoint.label}</strong> <br/>`
    str3 = `<span style="color:Tomato">Total: </span><strong>${total}</strong><br/>`
    return str2.concat(str).concat(str3)
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <center>
            <h1 className="mt-4">
              {organizationName} {batchName} Insights
            </h1>
            <h5 className="mt-4"> Notice | Processed on {processedOn}</h5>
          </center>
        </div>
      </div>

      <div className="row">
        {/* Chart 1 */}
        <div className="col-md-6" style={{ borderRight: "1px solid orange" }}>
          <div className="row">
            <div className="col-md-12">
              <center>
                <h1 className="mt-4">{successfulDelivery}%</h1>
                <h4 className="mt-4">successful delivery achieved</h4>
              </center>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div
                className="panel panel-default mt-4"
                style={{ borderColor: "white" }}
              >
                <div className="panel-body">
                  <div className="container-fluid">
                    {/* Chart 1 */}
                    <div id="chartContainer1">
                      <CanvasJSChart options={chart1Options} />
                    </div>
                  </div>
                </div>
              </div>
              <center>
                <span>Electronic delivery</span>
              </center>
            </div>
            <div className="col-md-6">
              <div
                className="panel panel-default mt-4"
                style={{ borderColor: "white" }}
              >
                <div className="panel-body">
                  <div className="container-fluid">
                    {/* Chart 2 */}
                    <div id="chartContainer2">
                      <CanvasJSChart options={chart2Options} />
                    </div>
                  </div>
                </div>
              </div>
              <center>
                <span>Physical delivery</span>
              </center>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div id="chartContainer3">
            <CanvasJSChart options={chart3Options} />
          </div>
          <br />
          <br />
          <br />
          <center>
            <span>
              *Read receipts are not available for SMS and postal delivery
            </span>
          </center>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="row mb-5">
        <div className="col-md-6">
          <div style={{ textAlign: "left" }}>
            {/* <span>
              <img
                src="assets/images/canva_logo.jpg"
                style={{ width: "156px" }}
                alt="Canva Logo"
              />
            </span> */}
          </div>
        </div>
        <div className="col-md-6">
          <div style={{ textAlign: "right" }}>
            <span>{currentFormattedDate}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chart
