import axios from "axios"
import { ApiRoute } from "common/ApiRoute"
import { da } from "date-fns/locale"

export const getBatchByUserId = (data) => {
   return axios.post(`${ApiRoute}report/batchNameByUserIdOrNoticeId`,data)
}

export const saveInoviceAnnexure = (data) => {
     console.log(data)
  let payload = new FormData()
   
     payload.append('userName',data.userName)
     payload.append('userId',data.userId)
     payload.append('batchNames',data.batchNames)
     payload.append('reportOn',data.reportOn)
     payload.append('token',data.token)

     return axios.post(`${ApiRoute}report/saveAnnexure`,payload)
} 
export const listInoviceAnnexure = (token,len=50) => {
      let payload = new FormData()
      payload.append('token',token)
      payload.append('iDisplayStart',0)
      payload.append('iDisplayLength',len)
      payload.append('batchName','')
     return axios.post(`${ApiRoute}report/listAnnexure`,payload)
}