import React, { useEffect, useState } from "react"
import Editor from "common/Editor"
import { Button } from "rsuite"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { useParams, useHistory } from "react-router-dom"
import { connect, useSelector } from "react-redux"
import { ShowNotification } from "common/ShowNotification"
import axios from "axios"
import { ExpressUrl } from "common/ApiRoute"

function ContentEditor(props) {
  const params = useParams()
  const history = useHistory()

  const [request, setRequest] = useState({
    draft_notice_html: "",
    token: props?.token,
    mnid: params?.id,
    caseid: params?.noticeId,
  })
  const pdfStrategy = useSelector((state=> state?.DraftNotice?.items?.pdf_generation_strategy))
  const handleUpdate = () => {
    const payloadObj = {
      templateId:params?.id,
        noticeId:params?.noticeId,
      template:request.draft_notice_html
    }
      if(pdfStrategy === "EXPRESS") {
        axios.post(`${ExpressUrl}notices/pdf`,payloadObj) 
        .then((res)=>{
          if (res?.data?.code === "200") {
            ShowNotification(res?.data?.response, "success")
            history.push("/user/draft-notice/" + params.id)
          } else {
            ShowNotification(res?.data?.response, "danger")
          }

         })
       
      } else {
        ApiCall(request, "user/update-user-notice", false, result => {
          if (result?.data?.response === "success") {
            ShowNotification(result?.data?.message, "success")
            history.push("/user/draft-notice/" + params.id)
          } else {
            ShowNotification(result?.data?.message, "danger")
          }
        })
      }
 
  }

  useEffect(() => {
    const requestPayload = {
      token: props?.token,
      mnid: params?.id,
      caseid: params?.noticeId,
    }
      const payloadObj = {
        templateId:params?.id,
        noticeId:params?.noticeId
      }
    if(pdfStrategy === "EXPRESS") {
      axios.post(`${ExpressUrl}notices/pdf/draft`,payloadObj) 
      .then((res)=>{
        if (res?.data?.code === "200") {
          setRequest({...request,draft_notice_html:res.data?.message?.template})
        } else {
          setRequest({...request,draft_notice_html:'Failed to load Data'})
        }
      })
      .catch((err)=>{
        setRequest({...request,draft_notice_html:'Failed to load Data'})
          ShowNotification("Something went wrong!","danger")
      })
   }
   else {
    ApiCall(requestPayload, "user/edit-user-notice", false, result => {
      setRequest({
        ...request,
        // draft_notice_html: result?.data?.message?.html_content,
        draft_notice_html: result?.data?.message?.html_content,
      })
    })
   }
  
  }, [])

  return (
    // <div>ContentEditor</div>
    <>
      <h6 style={{ color: "red" }}>
        * While editing scroll to bottom, right click on the stamp and put width
        90 than click OK to save.
      </h6>{" "}
      {request?.draft_notice_html && (
        <Editor
          request={request}
          setRequest={setRequest}
          defaultValue={request?.draft_notice_html || null}
          // editType={true}
        />
      )}
      <Button className="my-4" onClick={handleUpdate} appearance="primary">
        Submit
      </Button>
    </>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps)(ContentEditor)
