import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import { CustomProvider, Divider } from "rsuite"

import { setBreadcrumbItems } from "../../../store/actions"
import Retrigger from "./Retrigger"
import WhatsAppIDForm from "./WhatsAppIDForm"
import SMSRetrigger from "./SMSRetrigger"
import FetchEmailStatus from "./FetchEmailStatus"

const Settings = props => {
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Settings", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Settings", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Settings - Admin | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        <WhatsAppIDForm />
        <Divider />
        <Retrigger />
        <Divider />
        <SMSRetrigger />
        <Divider />
        <FetchEmailStatus />
        <Divider />
      </CustomProvider>
      {/* <ExistingNoticeTable /> */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Settings)
