import FileDownloadIcon from "@rsuite/icons/FileDownload"
import EyeIcon from "@rsuite/icons/legacy/Eye"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import { RouteWithoutApi } from "common/ApiRoute"
import { ShowNotification } from "common/ShowNotification"
import useWindowDimensions from "common/useWindowDimensions"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import SearchIcon from '@rsuite/icons/Search';
import { IconButton, Pagination, Table ,Input, InputGroup, Grid, Row, Col } from "rsuite"
import { getAutoReport } from "store/actions"
import { listInoviceAnnexure } from "./InvoiceService"

const { Column, HeaderCell, Cell } = Table
const styles = {
  width: 300,
  marginLeft:'auto'
};
const AutoReportTable = props => {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const [tableData,settableData] = useState([])
  const { height, width } = useWindowDimensions()
const [searchQuery,setsearchQuery] = useState("")
  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const downloadClickFile = (file) => {
      const request = {
        token: props?.token,
        folder: "invoice_report",
        filename: file,
      }
      DownloadApiCall(request, "download/single-download", "_blank")
  }

   const SearchAutoreportList =() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      batchName:searchQuery
    }
    dispatch(getAutoReport(requestPayload))
   }


  useEffect(() => {
    const displayStart = limit * (page - 1)
    const requestPayload = {
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      batchName:searchQuery
    }
    dispatch(getAutoReport(requestPayload))
    listInoviceAnnexure(props.token,limit)
    .then((res)=>{
         if(res.data.code === 200 ){
          settableData(res.data.responseData)
         }
    })
    .catch((err)=>{
      ShowNotification("Something Went Wrong ,while fetching List", "danger")
    })


  }, [page, limit, props?.apiAction?.action])

  return (
    <div className="mt-4">
       <InputGroup onChange={(e)=>{
         setsearchQuery(e.target.value)
       }} onKeyDown={(e)=>{
        if(e.key === "Enter"){
          SearchAutoreportList()
        }

       }} style={styles}>
      <Input/>
      <InputGroup.Addon>
        <SearchIcon onClick={()=>{
               SearchAutoreportList()
        }} />
      </InputGroup.Addon>
    </InputGroup>
      <h4>View Invoice Annexure</h4>
     
      <div className="table-responsive mt-4">
        <Table
          height={height - 350}
          data={tableData}
          wordWrap="break-word"
          headerHeight={46}
          loading={props?.tableLoading}
        >
            
          <Column width={80} align="center">
            <HeaderCell>Sr.No.</HeaderCell>
            <Cell>
              {(rowdata, key) => (
                <>
                  <span>
                    {page === 1 ? key + 1 : limit * (page - 1) + key + 1}
                  </span>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={2}>
            <HeaderCell>User Name</HeaderCell>
            <Cell dataKey="user_name" />
          </Column>
          <Column flexGrow={2}>
            <HeaderCell>Batch</HeaderCell>
            <Cell dataKey="batch_names">
            {(rowdata, key) => (
                <>
                  <span>
                    {
                       rowdata.batch_names.replaceAll('\\', ' ').replaceAll('"',' ')

                    }
                 
                  </span>
                </>
              )}
            </Cell>
            {/* <Cell dataKey="batch_names" /> */}
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Report On</HeaderCell>
            <Cell dataKey="report_on" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Download File</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        disabled = {rowdata.status === "SUCCESS" ? false : true  }
                        onClick={() => {
                          downloadClickFile(rowdata.file_name)
                        }}
                      />
                    </WhisperTooltip>
                  </>
                </>
              )}
            </Cell>
          </Column>
          {/* <Column flexGrow={1} align="center">
            <HeaderCell>Preliminary</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        disabled={
                          rowdata?.preliminary_report === null &&
                          rowdata?.nature_of_notice === "USER"
                        }
                        onClick={() =>
                          downloadClickFile(
                            rowdata?.nature_of_notice === "PROXY"
                              ? "preliminary_report"
                              : rowdata?.preliminary_report,
                            rowdata?.nature_of_notice,
                            rowdata?.id
                          )
                        }
                      />
                    </WhisperTooltip>
                  </>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Interim</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        disabled={
                          rowdata?.interim_report === null &&
                          rowdata?.nature_of_notice === "USER"
                        }
                        onClick={() =>
                          downloadClickFile(
                            rowdata?.nature_of_notice === "PROXY"
                              ? "interim_report"
                              : rowdata?.interim_report,
                            rowdata?.nature_of_notice,
                            rowdata?.id
                          )
                        }
                      />
                    </WhisperTooltip>
                  </>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Final</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="Download File"
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="green"
                      icon={<FileDownloadIcon />}
                      disabled={
                        rowdata?.final_report === null &&
                        rowdata?.nature_of_notice === "USER"
                      }
                      onClick={() =>
                        downloadClickFile(
                          rowdata?.nature_of_notice === "PROXY"
                            ? "final_report"
                            : rowdata?.final_report,
                          rowdata?.nature_of_notice,
                          rowdata?.id
                        )
                      }
                    />
                  </WhisperTooltip>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Canva</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="View Canva Report"
                  >
                    <Link
                      to={{
                        pathname:
                          RouteWithoutApi + "autoCanvaReport/" + rowdata?.id,
                      }}
                      target="_blank"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="blue"
                        disabled={rowdata?.final_report === null}
                        icon={<EyeIcon />}
                      />
                    </Link>
                  </WhisperTooltip>
                </>
              )}
            </Cell>
          </Column> */}
        </Table>
        <Pagination
          className="my-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={props.tableCount}
          limitOptions={[50, 100, 200]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.AutoReport.items,
  tableLoading: state.AutoReport.loading,
  tableCount: state.AutoReport.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(AutoReportTable)
