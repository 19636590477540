import React, { useState, useEffect } from "react"
import {
  Button,
  Checkbox,
  CustomProvider,
  IconButton,
  Pagination,
  Table,
} from "rsuite"
import { Link, useParams } from "react-router-dom"
import { getPartyReply } from "store/actions"
import { connect, useDispatch } from "react-redux"
import useWindowDimensions from "common/useWindowDimensions"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import VisibleIcon from "@rsuite/icons/Visible"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"

const { Column, HeaderCell, Cell } = Table

function ReplyTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const { height, width } = useWindowDimensions()
  let checked = false
  let indeterminate = false
  const dispatch = useDispatch()
  const params = useParams()
  const data = props?.data?.your_reply
    ? JSON.parse(props?.data?.your_reply)
    : ""

  // const handleChangeLimit = dataKey => {
  //   setPage(1)
  //   setLimit(dataKey)
  // }

  const downloadClick = value => {
    const request = {
      token: props?.token,
      folder: "replay",
      filename: value,
    }
    DownloadApiCall(request, "download/single-download", "_blank")
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      replyID: params.id,
      token: props.token,
      // page: page,
      // iDisplayStart: displayStart,
      // iDisplayLength: limit,
    }
    dispatch(getPartyReply(requestPayload))
  }, [page, limit])

  return (
    <div className="table-responsive" style={{ fontSize: "0.9em" }}>
      <Table
        height={height - 300}
        data={data}
        wordWrap="break-word"
        headerHeight={50}
        loading={props?.tableLoading}
      >
        <Column width={95} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell>
            {(rowdata, dataKey) => (
              <>
                <span>
                  {page === 1 ? dataKey + 1 : limit * (page - 1) + dataKey + 1}
                </span>
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Reply</HeaderCell>
          <Cell dataKey="reply" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Party Name</HeaderCell>
          <Cell dataKey="party_name" />
        </Column>
        <Column flexGrow={1} align="center">
          <HeaderCell>Attachment</HeaderCell>
          <Cell>
            {rowdata =>
              rowdata?.attachment && (
                <>
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="View Attachment File"
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="violet"
                      icon={<VisibleIcon />}
                      onClick={() => downloadClick(rowdata?.attachment)}
                    />
                  </WhisperTooltip>
                </>
              )
            }
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Date</HeaderCell>
          <Cell dataKey="reply_on" />
        </Column>
      </Table>
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.PartyReply.items,
  tableLoading: state.PartyReply.loading,
  count: state.PartyReply.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(ReplyTable)
