import { ApiCall } from "common/ActionApiCall/ApiCall"
import Editor from "common/Editor"
import { ShowNotification } from "common/ShowNotification"
import React, { useEffect, useState } from "react"
import { FileUploader } from "react-drag-drop-files"
import { DatePicker, SelectPicker } from "rsuite"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { Button, Form, FormFeedback, Input, Label,FormGroup } from "reactstrap"
import { CheckPicker, CustomProvider } from "rsuite"
import { Toggle } from 'rsuite';

import PdfViewer from "../PDFViewer/PdfViewer"
import axios from "axios"

import { ApiRoute } from "common/ApiRoute"
import LetterHeadSetting from "./LetterHeadSetting"
import {SanitizeHtml} from "common/Editor/sanitizeHtml"
function NoticeForm(props) {
  const location = useLocation()
  const [Express,setExpress] = useState(false)
  const [open,setOpen]= useState(false)
  const [letterHead,setletterHead]= useState([{}])
  const requestData = {
    noticeType: null,
    noticeDescription: null,
    draft_notice_html: location?.state?.draft_notice_html || null,
    willLinkExpire: 0,
    transmission_type:"FILE",
    send_as: 2,
    autoGeneratePdf: 1,
    token: props?.token,
    bulk_csv: null,
    userId: null,
    canvaSubtitle: null,
    enterpriseAbbr: null,
    emailEnabled: 1,
    smsEnabled: 1,
    whatsappEnabled: 1,
    rpadEnabled: 1,
    sent_whatsapp_text:0,
    sent_whatsapp_pdf:0,
    use_organization_name:0,
    whatsappTemplatePrefix:"",
    pdfGenerationStrategy:"STANDARD",
    whatsappTextVariable:"",
    whatsappPdfVariable:"",
    fontUsed:"freesans",
    co_borrower_font:"freesans",
    co_borrower_font_size:"12pt"
  }
  function closeModal (value) {
    setOpen(value)
  }
  const getAllConfig = (data) =>{
    setconfig(data)
    console.log(data)
  }
  const [request, setRequest] = useState(requestData)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const history = useHistory()

  const userdata = props?.userdata?.map(item => ({
    label: item?.organization_name,
    value: item?.id,
  }))

  const handleFileChange = file => {
    setRequest({ ...request, bulk_csv: file })
  }

  const handleLinkExpireChange = value => {
    setRequest({ ...request, willLinkExpire: value })
  }

  const handleSendAsChange = value => {
    setRequest({ ...request, send_as: value })
  }

  const handleAutoGeneratePdfChange = value => {
    setRequest({ ...request, autoGeneratePdf: value })
  }

   const updaterequest =(data)=> {
    setRequest({ ...request, letterheadConfiguration:JSON.stringify(data)})
   }
   const getLetterHeadById = id => {
     const formdata = new FormData();
      id.map((userId)=>{
          formdata.append("userId[]",userId);
          return 
      })
      axios.post(`${ApiRoute}letterheadByUserId`,formdata)
      .then((res)=>{
        setletterHead(res.data.response)
      })
   }
  const handleCommunicationModeChange = (mode, isChecked) => {
    let updatedRequest = { ...request }
    switch (mode) {
      case 1: // Email
        updatedRequest.emailEnabled = isChecked ? 1 : 0
        break
      case 2: // SMS
        updatedRequest.smsEnabled = isChecked ? 1 : 0
        break
      case 3: // WhatsApp
        updatedRequest.whatsappEnabled = isChecked ? 1 : 0
        break
      case 4: // RPAD
        updatedRequest.rpadEnabled = isChecked ? 1 : 0
        break
      default:
        break
    }

    setRequest(updatedRequest)
  }

  const handleSeparateWhatsappModeChange = (mode, isChecked) => {
    let updatedRequest = { ...request }
    switch (mode) {
      case 1: // sent whatsapp text
        updatedRequest.sent_whatsapp_text = isChecked ? 1 : 0
        break
      case 2: // sent whatsapp pdf
        updatedRequest.sent_whatsapp_pdf = isChecked ? 1 : 0
        break
      case 3: // use organisation name
        updatedRequest.use_organization_name = isChecked ? 1 : 0
        break
      default:
        break
    }

    setRequest(updatedRequest)
  }

   const validationCheck = () => {
    if(request?.transmission_type === "API" || request?.transmission_type === "BOTH"){
        if(!request?.letterheadConfiguration){
          ShowNotification('Letter Head is Mandatory for Transmission Type API','danger')
             return false
        }
    }
    if(request.noticeType.length >= 55){
      ShowNotification('Maximum 55 characters allowed','danger')
      return false
    }
    else {
       return true
    }

   }

  const handleSubmit = e => {
    e.preventDefault()
 
    const newRequest =  {...request,draft_notice_html:SanitizeHtml(request.draft_notice_html)}
    request?.bulk_csv &&
      request?.noticeType &&
      request?.noticeDescription &&
      request?.draft_notice_html && validationCheck() &&
      ApiCall(newRequest, "admin/save-notice", false, result => {
        if (result?.data?.response === "success") {
          ShowNotification(result?.data?.message, "success")
          history.push("/admin/existingnotice")
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
  }

  const handlePreview = () => {
    const passData = {
      token: props?.token,
      noticeContent: request?.draft_notice_html,
    }
    ApiCall(passData, "previewNoticeContent", false, result => {
      if (result?.data?.response === "success") {
        ShowNotification(result?.data?.message, "success")
        const link = document.createElement("a")
        // link.target = "_blank"
        link.target = "_blank"
        link.href = result?.data?.url
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  useEffect(() => {
    request?.bulk_csv &&
    request?.noticeType &&
    request?.noticeDescription &&
    request?.draft_notice_html
      ? setButtonDisabled(false)
      : setButtonDisabled(true)
  }, [request])

  return (
    <div>
    <Toggle style={{marginLeft:"85%"}} checkedChildren="Express" onChange={(isChecked)=>{
        setExpress(isChecked)
        isChecked ?  setRequest({ ...request,   pdfGenerationStrategy:"EXPRESS", fontUsed:"Times New Roman", co_borrower_font:"Times New Roman", co_borrower_font_size:"12pt" }) :  setRequest({ ...request,  pdfGenerationStrategy:"STANDARD",fontUsed:"freesans" , co_borrower_font:"freesans", co_borrower_font_size:"12pt"})     
    }}  unCheckedChildren="Standard"/>
      <Form method="post" onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="noticeType">Notice Type (Maximum 55 characters allowed) :</Label>
          <Input
            type="text"
            name="noticeType"
            id="noticeType"
            placeholder="Enter the name of the notice"
            onChange={e =>
              setRequest({ ...request, noticeType: e.target.value })
            }
          />
          <FormFeedback>Notice Type Filed is Required</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="mt-3" for="noticeDescription">
            Notice Description(max: 100 words) :
          </Label>
          <Input
            type="textarea"
            name="noticeDescription"
            id="noticeDescription"
            placeholder="Enter Notice Description"
            onChange={e =>
              setRequest({ ...request, noticeDescription: e.target.value })
            }
          />
          <FormFeedback>Notice Description Filed is Required</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="mt-3" for="noticeDescription">
            Assign User :
          </Label>
          <br />
          <CustomProvider theme="dark">
            <CheckPicker
              size="sm"
              data={userdata}
              placeholder="Select User"
              onChange={e =>  {setRequest({ ...request, userId: e })
                getLetterHeadById(e)
            }}
              block
            />
          </CustomProvider>
        </FormGroup>
        <FormGroup>
          <Label className="mt-3" for="draftNotice">
            Create Draft Notice :
          </Label>
          <Editor request={request} setRequest={setRequest} />

          {
             Express ? <PdfViewer html={request.draft_notice_html} /> :<> <Button
             type="button"
             className="my-4"
             color="warning"
             size="md"
             onClick={() => handlePreview()}
           >
             Preview
           </Button>
       
           </>
          }
          
           {
             request.userId &&   <Button
             type="button"
             className="my-4 mx-3"
             color="primary"
             size="md"
             onClick={() => 
              {
                setOpen(true)
                  getLetterHeadById(request.userId)
              }
              }
           >
             Select LetterHead
           </Button>
           }
           <LetterHeadSetting open={open} setOpen={closeModal} allLetterHead= {letterHead} setRequest={updaterequest} />
        </FormGroup>
        <FormGroup>
          <Label className="mt-3" for="bulkUpload">
            Upload Format for Bulk Upload :
          </Label>
          <FileUploader
            name="file"
            types={["csv"]}
            handleChange={handleFileChange}
            onTypeError={e =>
              e &&
              setRequest({
                ...request,
                bulk_csv: null,
              })
            }
          />
          <p style={{ color: "green" }}>
            {request?.bulk_csv && `${request?.bulk_csv?.name}`}
          </p>
          
        </FormGroup>
        <FormGroup className="mt-3">
          <Label for="linkExpire">Will Reply Link Expire ? :</Label>
          <samp className="px-3">
            <Input
              style={{ marginRight: "0.5em" }}
              name="linkExpire"
              type="radio"
              checked={request.willLinkExpire === 1}
              onChange={() => handleLinkExpireChange(1)}
            />
            <Label check>Yes</Label>
          </samp>
          <span>
            <Input
              style={{ marginRight: "0.5em" }}
              name="linkExpire"
              type="radio"
              checked={request.willLinkExpire === 0}
              onChange={() => handleLinkExpireChange(0)}
            />
            <Label check>No</Label>
          </span>
        </FormGroup>
        <FormGroup className="mt-3">
          <Label for="sendAs">Send As :</Label>
          <samp className="px-3">
            <Input
              style={{ marginRight: "0.5em", cursor: "pointer" }}
              name="sendAs"
              type="radio"
              checked={request.send_as === 1}
              onChange={() => handleSendAsChange(1)}
            />
            <Label check>Legal Notice</Label>
          </samp>
          <span>
            <Input
              style={{ marginRight: "0.5em", cursor: "pointer" }}
              name="sendAs"
              type="radio"
              checked={request.send_as === 2}
              onChange={() => handleSendAsChange(2)}
            />
            <Label check>Communication</Label>
          </span>
        </FormGroup>{" "}
        <FormGroup className="mt-3" inline>
          <Label for="communicationModes">Communication Modes:</Label>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "20px" }}>
              <Input
                type="checkbox"
                id="email"
                checked={request.emailEnabled === 1}
                onChange={e =>
                  handleCommunicationModeChange(1, e.target.checked)
                }
                style={{ marginRight: "5px" }}
              />
              <Label for="email">Email</Label>
            </div>
            <div style={{ marginRight: "20px" }}>
              <Input
                type="checkbox"
                id="sms"
                checked={request.smsEnabled === 1}
                onChange={e =>
                  handleCommunicationModeChange(2, e.target.checked)
                }
                style={{ marginRight: "5px" }}
              />
              <Label for="sms">SMS</Label>
            </div>
            <div style={{ marginRight: "20px" }}>
              <Input
                type="checkbox"
                id="whatsapp"
                checked={request.whatsappEnabled === 1}
                onChange={e =>
                  handleCommunicationModeChange(3, e.target.checked)
                }
                style={{ marginRight: "5px" }}
              />
              <Label for="whatsapp">WhatsApp</Label>
            </div>
            <div>
              <Input
                type="checkbox"
                id="rpad"
                checked={request.rpadEnabled === 1}
                onChange={e =>
                  handleCommunicationModeChange(4, e.target.checked)
                }
                style={{ marginRight: "5px" }}
              />
              <Label for="rpad">RPAD</Label>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="mt-3" inline>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "20px" }}>
            <Label for="font name">Select Font :</Label>
            <SelectPicker
            value={request?.fontUsed}
            defaultValue={ Express ?  "Times New Roman" : "freesans"}
            block
            className="selectMenu"
            placeholder="Select Font"
            data={[
                {
                  label:"Freesans",
                  value:"freesans"
                },{
                  label:"Navi Body Font",
                  value:"navibodyfont"
                },{
                  label:"Kruti Dev",
                  value:"krutidev"
                },{
                  label:"Poppins",
                  value:"Poppins"
                }
                ,{
                  label:"Times New Roman",
                  value:"Times New Roman"
                }
            ]}
            searchable={false}
            onChange={e => {
              setRequest({ ...request,fontUsed: e })
            }}
         />
         </div>
            <div style={{ marginRight: "20px" }}>
            <Label for="font name for coborrower">Select Font For Coborrower :</Label>
            <SelectPicker
            value={request?.co_borrower_font}
            defaultValue={ Express ?  "Times New Roman" : "freesans"}
            block
            className="selectMenu"
            placeholder="Select Font For Coborrower"
            data={[
                {
                  label:"Freesans",
                  value:"freesans"
                },{
                  label:"Navi Body Font",
                  value:"navibodyfont"
                },{
                  label:"Kruti Dev",
                  value:"krutidev"
                },{
                  label:"Poppins",
                  value:"Poppins"
                },{
                  label:"Times New Roman",
                  value:"Times New Roman"
                }
            ]}
            searchable={false}
            onChange={e => {
              setRequest({ ...request,co_borrower_font: e })
            }}
         />
         </div>
         <div style={{ marginRight: "20px" }}>
            <Label for="font size">Select Font Size For Coborrower :</Label>
            <SelectPicker
            value={request?.co_borrower_font_size}
            defaultValue={"12pt"}
            block
            className="selectMenu"
            placeholder="Select Font Size"
            data={[
                {
                  label:"8",
                  value:"8pt"
                },{
                  label:"9",
                  value:"9pt"
                },{
                  label:"10",
                  value:"10pt"
                },{
                  label:"11",
                  value:"11pt"
                },{
                  label:"12",
                  value:"12pt"
                }
                ,{
                  label:"13",
                  value:"13pt"
                },{
                  label:"14",
                  value:"14pt"
                },{
                  label:"15",
                  value:"15pt"
                },{
                  label:"16",
                  value:"16pt"
                },{
                  label:"17",
                  value:"17pt"
                }
                ,{
                  label:"18",
                  value:"18pt"
                }
            ]}
            searchable={false}
            onChange={e => {
              setRequest({ ...request,co_borrower_font_size: e })
            }}
         />
         </div>
         </div>
         </FormGroup>
        <FormGroup className="mt-3" inline>
          <Label for="transmission_type">Transmisson Type:</Label>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "20px" }}>
              <Input
                type="checkbox"
                id="FILE"
                checked={request.transmission_type === "FILE"}
                onChange={e =>
                  setRequest({...request,transmission_type:"FILE"})
             }
                style={{ marginRight: "5px" }}
              />
              <Label for="FILE">FILE</Label>
            </div>
            <div style={{ marginRight: "20px" }}>
              <Input
                type="checkbox"
                id="API"
                checked={request.transmission_type === "API"}
                onChange={e =>
                  setRequest({...request,transmission_type:"API"})
             }
                style={{ marginRight: "5px" }}
              />
              <Label for="API">API</Label>
            </div>
            <div style={{ marginRight: "20px" }}>
              <Input
                type="checkbox"
                id="BOTH"
                checked={request.transmission_type === "BOTH"}
                onChange={e =>
                     setRequest({...request,transmission_type:"BOTH"})
                }
                style={{ marginRight: "5px" }}
              />
              <Label for="BOTH">BOTH</Label>
            </div>
      
          </div>
        </FormGroup>

        <FormGroup className="mt-3">
          <Label for="autoGeneratePdf">System generated pdf required? :</Label>
          <samp className="px-3">
            <Input
              style={{ marginRight: "0.5em", cursor: "pointer" }}
              name="autoGeneratePdf"
              type="radio"
              checked={request.autoGeneratePdf === 1}
              onChange={() => handleAutoGeneratePdfChange(1)}
            />
            <Label check>Yes</Label>
          </samp>
          <span>
            <Input
              style={{ marginRight: "0.5em", cursor: "pointer" }}
              name="autoGeneratePdf"
              type="radio"
              checked={request.autoGeneratePdf === 0}
              onChange={() => handleAutoGeneratePdfChange(0)}
            />
            <Label check>No</Label>
          </span>
        </FormGroup>
        <FormGroup>
          <Label for="canvaSubtitle">Subtitle for Canva :</Label>
          <Input
            type="text"
            name="canvaSubtitle"
            id="canvaSubtitle"
            placeholder="Enter the Subtitle for Canva"
            onChange={e =>
              setRequest({ ...request, canvaSubtitle: e.target.value })
            }
          />
          <FormFeedback>Canva Subtitle is Required</FormFeedback>
        </FormGroup>
        
        <FormGroup className="mt-3">
          <Label for="enterpriseAbbr">Enterprise Abbr :</Label>
          <Input
            type="text"
            name="enterpriseAbbr"
            id="enterpriseAbbr"
            placeholder="Enter Enterprise Abbr"
            onChange={e =>
              setRequest({ ...request, enterpriseAbbr: e.target.value })
            }
          />
          <FormFeedback>Enterprise Abbr is Required</FormFeedback>
        </FormGroup>

        <FormGroup className="mt-3">
          <Label>Fill below section only if separate whatsapp template required for this notice.</Label>
        </FormGroup>

        <FormGroup>
          <Label>Template Prefix :</Label>
          <Input
            type="text"
            name="whatsappTemplatePrefix"
            id="whatsappTemplatePrefix"
            placeholder="Enter Whatsapp Template Prefix"
            onChange={e =>
              setRequest({ ...request, whatsappTemplatePrefix: e.target.value })
            }
          />
          <FormFeedback>Template Prefix is Required</FormFeedback>
        </FormGroup>

        <FormGroup className="mt-3" inline>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "20px" }}>
              <Input
                type="checkbox"
                id="sent_whatsapp_text"
                checked={request.sent_whatsapp_text === 1}
                onChange={e =>
                  handleSeparateWhatsappModeChange(1, e.target.checked)
                }
                style={{ marginRight: "5px" }}
              />
              <Label for="send_whatsapp_text">Send Whatsapp Text</Label>
            </div>
            <div style={{ marginRight: "20px" }}>
              <Input
                type="checkbox"
                id="sent_whatsapp_pdf"
                checked={request.sent_whatsapp_pdf === 1}
                onChange={e =>
                  handleSeparateWhatsappModeChange(2, e.target.checked)
                }
                style={{ marginRight: "5px" }}
              />
              <Label for="send_whatsapp_pdf">Send Whatsapp Pdf</Label>
            </div>
           
            <div style={{ marginRight: "20px" }}>
              <Input
                type="checkbox"
                id="use_organization_name"
                checked={request.use_organization_name === 1}
                onChange={e =>
                  handleSeparateWhatsappModeChange(3, e.target.checked)
                }
                style={{ marginRight: "5px" }}
              />
              <Label for="use_organization_name">Use Organization Name</Label>
            </div>
          </div>
        </FormGroup>

        <FormGroup className="mt-3">
          <Label>Whatsapp text variable (each variable comma separated without space and for notice variable use _ if variable have space in between):</Label>
          <Input
            type="text"
            name="whatsappTextVariable"
            id="whatsappTextVariable"
            placeholder="nid,rlink,footernote,Date_of_notice"
            onChange={e =>
              setRequest({ ...request, whatsappTextVariable: e.target.value })
            }
          />
        </FormGroup>

        <FormGroup>
          <Label>Whatsapp pdf variable (each variable comma separated without space and for notice variable use _ if variable have space in between):</Label>
          <Input
            type="text"
            name="whatsappPdfVariable"
            id="whatsappPdfVariable"
            placeholder="nid"
            onChange={e =>
              setRequest({ ...request, whatsappPdfVariable: e.target.value })
            }
          />
        </FormGroup>
        <p style={{margin:'0',padding:'1em 0'}}>Fill below section only if separate email template required for this notice.</p>
        <FormGroup>
          <Label className="mt-3" for="noticeDescription">
          Email template config ( for separate emails only ) :
          </Label>
          <Input
            type="textarea"
            name="email_template_config"
            id="email_template_config"
            placeholder="Enter Email Template Config"
            onChange={e =>
              setRequest({ ...request, email_template_config: e.target.value })
            }
          />
        </FormGroup>

        <Button
          type="submit"
          className="my-4"
          color="primary"
          size="md"
          disabled={buttonDisabled}
        >
          Submit
        </Button>{" "}
      </Form>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps)(NoticeForm)
