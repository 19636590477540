const initialState = {
  items: [],
  loading: false,
  count: 0,
  error: null,
};

const AutoReport = (state = initialState, action) => {
  switch (action.type) {
    case "AutoReportListBegin":
      return {
        ...state,
        loading: true,
        error: null,
      }
    case "AutoReportListSuccess":
      return {
        ...state,
        items: action.data,
        count: action.count,
        loading: false,
      }
    case "AutoReportListFailure":
      return {
        ...state,
        items: [],
        loading: false,
        error: action.data,
      }
    default:
      return state
  }
}

export default AutoReport
