import React from "react"
import { Container, Content, Footer, CustomProvider } from "rsuite"
import "./index.scss"
import HomeHeader from "./HomeHeader"
import HomeFooter from "./HomeFooter"
import useWindowDimensions from "common/useWindowDimensions"
import MobileHomeHeader from "./MobileHomeHeader"

function HomePageLayout(props) {
  const { height, width } = useWindowDimensions()

  return (
    <CustomProvider theme="dark">
      <div className="show-fake-browser navbar-page">
        <Container>
          {width < 900 ? <MobileHomeHeader /> : <HomeHeader />}
          <Content>{props.children}</Content>
          <Footer>
            <HomeFooter />
          </Footer>
        </Container>
      </div>
    </CustomProvider>
  )
}

export default HomePageLayout
