import axios from "axios"
import { ApiRoute } from "common/ApiRoute"

const api_url = ApiRoute

export const logIn = result => {
  return dispatch => {
    var obj = {
      token: result?.data?.token,
      role: result?.data?.responseData?.user_type,
      id: result?.data?.responseData?.id,
    }
    localStorage.setItem("data", JSON.stringify(obj))

    dispatch({
      type: "LOGIN",
      token: result.data.token,
      data: result.data.responseData,
    })
  }
}

export const refreshToken = requestPayload => {
  return dispatch => {
    axios
      .post(api_url + "refreshToken", requestPayload)
      .then(result => {
        var obj = {
          token: result?.data?.token,
          role: result?.data?.responseData?.user_type,
          id: result?.data?.responseData?.id,
        }
        localStorage.setItem("data", JSON.stringify(obj))
        dispatch({
          type: "REFRESHTOKEN",
          token: result?.data?.token,
          data: result?.data?.responseData,
        })
      })
      .catch(error => {
        dispatch({
          type: "LOGOUT",
        })
      })
  }
}

export const logOut = () => {
  return dispatch => {
    localStorage.removeItem("data")

    dispatch({
      type: "LOGOUT",
      // token: result.data.token,
      // data: result.data.responseData,
    })
  }
}
