import { ShowNotification } from "common/ShowNotification"

const { default: axios } = require("axios")
const { ApiRoute, RouteWithoutApi } = require("common/ApiRoute")

 export const getCommunication = (id) => {
       const payload = new FormData ()
        payload.append('mnid',id)
          if(id === null) {
            ShowNotification(
                 "Please Select Notice Type First",
                "danger"
              )   
                return
          }
       axios.post(`${RouteWithoutApi}getCommunicationMode`,payload)
       .then((res)=>{
        ShowNotification(
            res?.data?.message ? res?.data?.message : "File Not Found!",
            "success",15000
          )
       })
       .catch((e)=>{
        ShowNotification(
            res?.data?.message ? res?.data?.message : "File Not Found!",
            "danger"
          )
    
       })
}