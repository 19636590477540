import React, { useEffect, useState } from "react"
import { CustomProvider, IconButton, Pagination, Table, Input } from "rsuite"
import FileDownloadIcon from "@rsuite/icons/FileDownload"
import EyeIcon from "@rsuite/icons/legacy/Eye"
import { connect, useDispatch } from "react-redux"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import { getAutoDownload } from "store/actions"
import { Link, useHistory } from "react-router-dom"
import useWindowDimensions from "common/useWindowDimensions"
import { RouteForShow, RouteWithoutApi } from "common/ApiRoute"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"

const { Column, HeaderCell, Cell } = Table

const AutoDownloadTable = props => {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const { height, width } = useWindowDimensions()

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const downloadClickFile = file => {
    const request = {
      token: props?.token,
      folder: "auto_download",
      filename: file,
    }
    DownloadApiCall(request, "download/local-download", "_blank")
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      //   searchValue: search?.value,
    }
    dispatch(getAutoDownload(requestPayload))
  }, [page, limit, props?.apiAction?.action])

  return (
    <div className="mt-4">
      <h4>View Auto Download</h4>
      <div className="table-responsive mt-4">
        <Table
          height={height - 350}
          data={props?.data}
          wordWrap="break-word"
          headerHeight={46}
          loading={props?.tableLoading}
        >
          <Column width={80} align="center">
            <HeaderCell>Sr.No.</HeaderCell>
            <Cell>
              {(rowdata, key) => (
                <>
                  <span>
                    {page === 1 ? key + 1 : limit * (page - 1) + key + 1}
                  </span>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={2}>
            <HeaderCell>Notice</HeaderCell>
            <Cell dataKey="notice_type" />
          </Column>
          <Column flexGrow={2}>
            <HeaderCell>Batch</HeaderCell>
            <Cell dataKey="batch_name" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Scheduled On</HeaderCell>
            <Cell dataKey="scheduled_on" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>File</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        disabled={rowdata?.file_name === null}
                        onClick={() =>
                          downloadClickFile(rowdata?.file_name)
                        }
                      />
                    </WhisperTooltip>
                  </>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Notice Name As</HeaderCell>
            <Cell dataKey="notice_name_as" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>With Track Data</HeaderCell>
            <Cell dataKey="track_data" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status_label" />
          </Column>
        </Table>
        <Pagination
          className="my-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={props.tableCount}
          limitOptions={[50, 100, 200]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.AutoDownload.items,
  tableLoading: state.AutoDownload.loading,
  tableCount: state.AutoDownload.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(AutoDownloadTable)
