import FormGroup from "components/Common/FormGroup"
import React, { useEffect, useState } from "react"
import { Button } from "reactstrap";
import { Modal, Stack} from 'rsuite';
import { useSelector } from "react-redux";
import { ShowNotification } from "common/ShowNotification";
import { ApiCall } from "common/ActionApiCall/ApiCall";
 export default function LetterHeadSetting({open,setOpen,allLetterHead,setRequest}){
    const [letterHead,setLetterHead] = useState();
    const [letterheadData,setletterHeadData] = useState({})
    const [src,setsrc]=useState("")
      const token = useSelector(state => state.Login.token)
    const handlePreview = value => {
       setsrc("")
      const requestData = {
        token: token,
        letterheadId: value,
      }
      ApiCall(requestData, "previewLetterhead", false, result => {
        if (result?.data?.response === "success") {
           setsrc(result?.data?.url)
        } else {
          ShowNotification("Failed to Fetch the Letter Head", "danger")
        }
      })
    }
     const changeLetterHeadData = (id) => {
        handlePreview(id)
       const filterData = allLetterHead.filter((lh)=>{
           if(id === lh.id){
               return lh
           }
       })
        setletterHeadData(filterData[0])
     }
    

    const handleSubmit = () =>{
      setOpen(false)
    }
    return (
      <div>

      
          <Modal open={open} onClose={handleSubmit} dialogStyle={{width:"max-width"}}>
          {/* <span style={{fontSize:"1em",cursor:"pointer",marginLeft:"93%",backgroundColor:"#fff",padding:".5em .7em",color:"#000",borderRadius:"5px"}} onClick={handleSubmit}>X</span> */}
            <Modal.Body style={{height:"120vh"}}>
              <Stack   direction="row" spacing={5}>
              <FormGroup
                select={true}
                datalabel="Letter Head:"
                selectData={allLetterHead?.map(item => ({
                    label: item?.head_name,
                    value: item?.id,
                  }))}
                datavalue={letterHead}
                onChange={e => {setLetterHead(e)
                      changeLetterHeadData(e)
                }}
              />
                   <Button
                    style={{marginTop:"15px"}}
             type="button"
             color="primary"
             size="md"
             onClick={() => {setOpen(!open)

                setRequest(letterheadData)

             }
                
            }
           >
             Set LetterHead
           </Button>
              </Stack>
            
               
               
              {
                 letterHead && <>
                   
                  {   
                    <iframe src={src} width="100%" height="100%">

                    </iframe>
                  }
                 </>
              }

    
       
            </Modal.Body>
          </Modal>
          </div>
       
      );
   }