import React, { useState, useEffect } from "react"
import {
  Button,
  Checkbox,
  CustomProvider,
  IconButton,
  Pagination,
  Table,
} from "rsuite"
import { Link, useParams } from "react-router-dom"
import { getPartyReply, getSubUser } from "store/actions"
import { connect, useDispatch } from "react-redux"
import useWindowDimensions from "common/useWindowDimensions"
import VisibleIcon from "@rsuite/icons/Visible"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"

const { Column, HeaderCell, Cell } = Table

function UserListTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const { height, width } = useWindowDimensions()
  let checked = false
  let indeterminate = false
  const dispatch = useDispatch()
  const params = useParams()
  const data = props?.data

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
    }
    dispatch(getSubUser(requestPayload))
  }, [page, limit])

  return (
    <div className="table-responsive" style={{ fontSize: "0.9em" }}>
      <Table
        height={height - 300}
        data={data}
        wordWrap="break-word"
        headerHeight={50}
        loading={props?.tableLoading}
      >
        <Column width={50} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell>
            {(rowdata, dataKey) => (
              <span>
                {page === 1 ? dataKey + 1 : limit * (page - 1) + dataKey + 1}
              </span>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="username" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="email" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Organization</HeaderCell>
          <Cell dataKey="organization_name" />
        </Column>
        <Column flexGrow={1} align="center">
          <HeaderCell>Draft/Active</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                Draft: {rowdata.noOfDraft}
                <br />
                Active: {rowdata.noOfActive}
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1} align="center">
          <HeaderCell>Overdue</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                Resolved: {rowdata.noOfRes}
                <br />
                Escalated: {rowdata.noOfEsc}
                <br />
                Overdue: {rowdata.noOfOverdue}
              </>
            )}
          </Cell>
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Notice</HeaderCell>
          <Cell>
            {rowdata => (
              <WhisperTooltip
                placement="top"
                trigger="hover"
                tooltipMsg="View Notice Details"
              >
                <Link
                  to={{
                    pathname: "/user/subuser/noticelist/" + btoa(rowdata.id),
                    state: { prevPath: location.pathname },
                  }}
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="orange"
                    icon={<VisibleIcon />}
                    // onClick={() =>
                    //   downloadClickNoticePdf(rowdata?.user_notice_path)
                    // }
                  />
                </Link>
              </WhisperTooltip>
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.count}
        limitOptions={[50, 100, 500, 1000, 5000]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.SubUser.items,
  tableLoading: state.SubUser.loading,
  count: state.SubUser.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(UserListTable)
