import axios from "axios"
import { ApiRoute } from "common/ApiRoute"

const api_url = ApiRoute

export const getRefReport = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "RefReportBegin" })
    axios
      .post(api_url + "refIdReport", requestPayload)
      .then(result => {
        dispatch({
          type: "RefReportSuccess",
          data: result.data.responseData,
        })
      })
      .catch(error => {
        dispatch({
          type: "RefReportFailure",
          data: error,
        })
      })
  }
}
