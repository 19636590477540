import React from 'react'
import { Tooltip, Whisper } from 'rsuite'

function WhisperTooltip(props) {
    return (
        <Whisper
            placement={props.placement}
            controlId="control-id-hover"
            trigger={props.trigger}
            speaker={
                <Tooltip>
                    {props.tooltipMsg}
                </Tooltip>
            }
        >
            {props.children}
        </Whisper>
    )
}

export default WhisperTooltip