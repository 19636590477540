import React, { useState, useEffect } from "react"
import { CustomProvider, IconButton, Pagination } from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import CheckRoundIcon from "@rsuite/icons/CheckRound"
import DetailIcon from "@rsuite/icons/Detail"
import { getDataNotice, getEmailLog } from "store/actions"
import { connect, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import useWindowDimensions from "common/useWindowDimensions"
import Loader from "common/Loader"
import { Table } from "reactstrap"

function EmailLogTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const params = useParams()
  const data = props?.data
  const { height, width } = useWindowDimensions()

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  var event = ""
  var euser = ""

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      case_id: atob(params.id),
      token: props.token,
      page: page,
      //   iDisplayStart: displayStart,
      //   iDisplayLength: limit,
    }
    dispatch(getEmailLog(requestPayload))
  }, [page, limit])

  return (
    <div className="table-responsive">
      <div style={{ height: height - 300, overflow: "scroll" }}>
        <Table size="sm">
          <thead>
            <tr>
              <th>Sr.No.</th>
              <th>Event</th>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Status Date</th>
            </tr>
          </thead>
          <tbody>
            {props?.tableLoading ? (
              <tr>
                <td colSpan={6} className="text-center">
                  <div className="mt-4">
                    <Loader />
                  </div>
                </td>
              </tr>
            ) : data?.length !== 0 ? (
              data?.map((value, index) => {
                var skip = false
                value.event == event && value.email == euser
                  ? (skip = true)
                  : ""
                event = value.event
                euser = value.email
                return (
                  <>
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {!skip
                          ? "Email sent with notice to " + value.usertype
                          : ""}
                      </td>
                      <td>{!skip ? value.usertype : ""}</td>
                      <td>{value.email}</td>
                      <td>
                        {value.edevent}{" "}
                        {value.edevent == "click" ? (
                          <>
                            <br />
                            <span
                              title={value.url}
                              style={{ cursor: "pointer", color: "green" }}
                            >
                              <b>Link</b>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{value.created_at}</td>
                    </tr>
                  </>
                )
              })
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  <div className="mt-4">No Data Found</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {/* <Table
        height={height - 300}
        data={data}
        wordWrap="break-word"
        headerHeight={50}
        loading={props.tableLoading}
      >
        <Column width={95} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell>{(rowdata, datakey) => <span>{datakey + 1}</span>}</Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Event</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Name</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Email</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Status</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Date</HeaderCell>
          <Cell></Cell>
        </Column>
      </Table> */}
      {/* <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.count}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      /> */}
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.EmailLog.items,
  tableLoading: state.EmailLog.loading,
  count: state.EmailLog.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(EmailLogTable)
