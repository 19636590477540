import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import { setBreadcrumbItems } from "store/actions"
import Details from "pages/Admin/AdminPartyDetails/Details"

const ApiNoticePartyDetails = props => {
  const location = useLocation()

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Notice List", link: "/admin/noticelist" },
    { title: "Notice Details", link: location?.state?.prevPath },
    { title: "Party Details", link: "#" },
  ]

  const params = useParams()

  const result = props?.data?.resultData?.filter(
    value => parseInt(value.Id) === parseInt(atob(params.id))
  )
  useEffect(() => {
    props.setBreadcrumbItems("Party Details", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Party Details - Admin | Incase360</title>
      </MetaTags>

      {result ? (
        <Details data={result[0]} />
      ) : (
        <h6
          className="mt-5"
          style={{ display: "flex", justifyContent: "center" }}
        >
          No Direct Access
        </h6>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(
  ApiNoticePartyDetails
)
