import React, { useState, useEffect } from "react"
import { FileUploader } from "react-drag-drop-files"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CustomInput,
} from "reactstrap"
import { Col, Grid, Row } from "rsuite"
import "./index.scss"
import { useParams, useHistory } from "react-router-dom"
import { ShowNotification } from "common/ShowNotification"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import LetterheadPreview from "common/LetterheadPreview"

function LetterheadForm(props) {
  const params = useParams()
  const history = useHistory()
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [signUrl, setSignUrl] = useState()
  const [letterPicUrl, setLetterPicUrl] = useState()
  const [request, setRequest] = useState({
    token: props?.token,
    userprofileId: props?.userId,
    head_name: null,
    head_margin: null,
    foot_margin: null,
    letterhead: null,
    signature: null,
    signature_width: null,
    signature_height: null,
  })

  useEffect(() => {
    request?.head_name &&
    request?.head_margin &&
    request?.foot_margin &&
    request?.letterhead
      ? setButtonDisabled(false)
      : setButtonDisabled(true)
  }, [request])

  const handleSubmit = e => {
    e.preventDefault()
    request?.head_name &&
      request?.head_margin &&
      request?.foot_margin &&
      request?.letterhead &&
      ApiCall(request, "user/add-letterhead", false, result => {
        if (result?.data?.response === "success") {
          ShowNotification(result?.data?.message, "success")
          history.push("/user/letterhead")
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
  }

  return (
    <div>
      <Grid fluid>
        <Row>
          <Col xs={24} sm={24} md={11}>
            <Form method="post" onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="head_name">Name :</Label>
                <Input
                  type="text"
                  name="head_name"
                  id="head_name"
                  placeholder="Enter the name of the Letterhead"
                  onChange={e =>
                    setRequest({ ...request, head_name: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="headerMargin" className="mt-2">
                  Letterhead Header Margin (in mm) :
                </Label>
                <Input
                  type="number"
                  name="headerMargin"
                  id="headerMargin"
                  placeholder="Enter the Letterhead Header Margin"
                  onChange={e =>
                    setRequest({ ...request, head_margin: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="footerMargin" className="mt-2">
                  Letterhead Footer Margin (in mm) :
                </Label>
                <Input
                  type="number"
                  name="footerMargin"
                  id="footerMargin"
                  placeholder="Enter the Letterhead Footer Margin"
                  onChange={e =>
                    setRequest({ ...request, foot_margin: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label className="mt-2" for="letterhead">
                  Letterhead :
                </Label>
                <FileUploader
                  name="letterhead"
                  types={["jpeg", "jpg", "png"]}
                  onTypeError={e =>
                    e && [
                      setLetterPicUrl(null),
                      setRequest({
                        ...request,
                        letterhead: null,
                      }),
                    ]
                  }
                  // handleChange={e => setRequest({ ...request, letterhead: e })}
                  handleChange={e => [
                    setLetterPicUrl(URL.createObjectURL(e)),
                    setRequest({ ...request, letterhead: e }),
                  ]}
                />
              </FormGroup>
              <FormGroup>
                <Label className="mt-2" for="signature">
                  Specimen Signature :
                </Label>
                <FileUploader
                  name="signature"
                  types={["jpeg", "jpg", "png"]}
                  onTypeError={e =>
                    e && [
                      setSignUrl(null),
                      setRequest({
                        ...request,
                        signature: null,
                      }),
                    ]
                  }
                  // handleChange={e => setRequest({ ...request, signature: e })}
                  handleChange={e => [
                    setSignUrl(URL.createObjectURL(e)),
                    setRequest({ ...request, signature: e }),
                  ]}
                />
              </FormGroup>
              <FormGroup>
                <Label for="footerMargin" className="mt-2">
                  Letterhead Signature Width (in px) :
                </Label>
                <Input
                  type="number"
                  name="signatureWidth"
                  id="signatureWidth"
                  placeholder="Enter the Letterhead Signature Width"
                  onChange={e =>
                    setRequest({ ...request, signature_width: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="footerMargin" className="mt-2">
                  Letterhead Signature Height (in px) :
                </Label>
                <Input
                  type="number"
                  name="signatureHeight"
                  id="signatureHeight"
                  placeholder="Enter the Letterhead Signature Height"
                  onChange={e =>
                    setRequest({ ...request, signature_height: e.target.value })
                  }
                />
              </FormGroup>
              <Button
                className="my-4"
                color="primary"
                size="sm"
                disabled={buttonDisabled}
              >
                Submit
              </Button>{" "}
            </Form>
          </Col>
          <Col xs={24} sm={24} md={13}>
            <h4 className="text-center mb-3">Letterhead Preview</h4>
            <LetterheadPreview
              headMargin={request?.head_margin}
              footMargin={request?.foot_margin}
              signature_height={request?.signature_height}
              signature_width={request?.signature_width}
              signUrl={signUrl}
              letterPicUrl={letterPicUrl}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default LetterheadForm
