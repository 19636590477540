import React, { useState } from "react"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import { IconButton } from "rsuite"
import AddOutlineIcon from "@rsuite/icons/AddOutline"
import DrwerBar from "components/Common/DrwerBar"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"

function AddNotes(props) {
  const [openAddNotesDrawer, setOpenAddNotesDrawer] = useState(false)
  const [request, setRequest] = useState({
    token: props?.token,
    user_notes: props?.userNotes,
    userNoticeId: props?.id,
    adminNoticeId: props?.notice,
    notesfile: null,
    attach_date: null,
    totalFiles: null,
  })

  const handleAddNotesDrawer = () => {
    setOpenAddNotesDrawer(true)
    setRequest({
      ...request,
      user_notes: props?.userNotes,
      notesfile: null,
      attach_date: null,
      totalFiles: null,
    })
  }

  const handleAddNotes = () => {
    request?.notesfile &&
      request?.attach_date &&
      ApiCall(request, "user/addnotes", true, result => {
        if (result?.data?.response === "success") {
          setOpenAddNotesDrawer(false)
          props?.setAction(!props?.action)
          ShowNotification(result?.data?.message, "success")
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
  }

  const handleClose = () => {
    openAddNotesDrawer && [
      setOpenAddNotesDrawer(false),
      setRequest({
        ...request,
        notesfile: null,
        attach_date: null,
        totalFiles: null,
      }),
    ]
  }

  return (
    <>
      {openAddNotesDrawer && (
        <DrwerBar
          open={openAddNotesDrawer}
          setOpen={setOpenAddNotesDrawer}
          title="Add Notes"
          body="AddNotes"
          size="xs"
          onClick={() => handleAddNotes()}
          state={request}
          setState={setRequest}
          onCancel={handleClose}
        />
      )}
      <WhisperTooltip placement="top" trigger="hover" tooltipMsg="Add Notes">
        <IconButton
          size="xs"
          appearance="primary"
          color="cyan"
          icon={<AddOutlineIcon />}
          onClick={() => handleAddNotesDrawer()}
        />
      </WhisperTooltip>
    </>
  )
}

export default AddNotes
