import axios from "axios"
import { ApiRoute } from "common/ApiRoute"

const api_url = ApiRoute

export const getUsers = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "UsersListBegin" })
    axios
      .post(api_url + "allUser", requestPayload)
      .then(result => {
        dispatch({
          type: "UsersListSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "UsersListFailure",
          data: error,
        })
      })
  }
}

export const getUserLetterhead = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "UserLetterheadBegin" })
    axios
      .post(api_url + "userLetterhead", requestPayload)
      .then(result => {
        dispatch({
          type: "UserLetterheadSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "UserLetterheadFailure",
          data: error,
        })
      })
  }
}

export const getUserDetails = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "UserDetailsBegin" })
    axios
      .post(api_url + "user-details", requestPayload)
      .then(result => {
        dispatch({
          type: "UserDetailsSuccess",
          data: result.data.responseData,
        })
      })
      .catch(error => {
        dispatch({
          type: "UserDetailsFailure",
          data: error,
        })
      })
  }
}

export const getAllLetterhead = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "AllLetterheadBegin" })
    axios
      .post(api_url + "all-Letterhead", requestPayload)
      .then(result => {
        dispatch({
          type: "AllLetterheadSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "AllLetterheadFailure",
          data: error,
        })
      })
  }
}

export const getAllApproveUser = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "AllApproveUserBegin" })
    axios
      .post(api_url + "getallapproveuser", requestPayload)
      .then(result => {
        dispatch({
          type: "AllApproveUserSuccess",
          data: result.data.responseData,
        })
      })
      .catch(error => {
        dispatch({
          type: "AllApproveUserFailure",
          data: error,
        })
      })
  }
}

export const getChangeUserSelect = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "AllChangeUserSelectBegin" })
    axios
      .post(api_url + "onChangeUserSelect", requestPayload)
      .then(result => {
        dispatch({
          type: "AllChangeUserSelectSuccess",
          data: result.data.responseData,
        })
      })
      .catch(error => {
        dispatch({
          type: "AllChangeUserSelectFailure",
          data: error,
        })
      })
  }
}