import React, { useState } from "react"
import { List } from "rsuite"

function FaqList() {
  const [active, setActive] = useState({
    id1: false,
    id2: false,
    id3: false,
    id4: false,
    id5: false,
    id6: false,
    id7: false,
    id8: false,
  })
  return (
    <div>
      <h1>FAQs</h1>
      <br />
      <List bordered>
        <List.Item
          onClick={() =>
            setActive({
              id1: !active?.id1,
            })
          }
        >
          <span className="manage-faq">
            <h5>
              1. Why have I received communication from Incase360 on email /
              WhatsApp / SMS?
            </h5>
            <i className={`fa fa-${active?.id1 ? "minus" : "plus"}`}></i>
          </span>
          <div
            className="dataView my-3"
            style={{ display: active?.id1 ? "block" : "none" }}
          >
            Incase360 is a platform for sending legal communications digitally.
            Think of it as a courier partner but for digital mediums like email
            / WhatsApp / SMS.
            <br />
            <br />
            <span style={{ color: "red" }}>Note: </span>
            Incase360 does not represent the sender or provide any legal advice.
            It is not responsible for any contents of the communication.
          </div>
        </List.Item>
        <List.Item
          onClick={() =>
            setActive({
              id2: !active?.id2,
            })
          }
        >
          <span className="manage-faq">
            <h5>2. What do I do if I have received a legal communication?</h5>
            <i className={`fa fa-${active?.id2 ? "minus" : "plus"}`}></i>
          </span>
          <div
            className="dataView my-3"
            style={{ display: active?.id2 ? "block" : "none" }}
          >
            You can reply to it and add attachments using the link provided in
            the email body / WhatsApp message / SMS. For any other information,
            you can contact the sender.
          </div>
        </List.Item>
        <List.Item
          onClick={() =>
            setActive({
              id3: !active?.id3,
            })
          }
        >
          <span className="manage-faq">
            <h5>3. Do you need to make an account to access notices?</h5>
            <i className={`fa fa-${active?.id3 ? "minus" : "plus"}`}></i>
          </span>
          <div
            className="dataView my-3"
            style={{ display: active?.id3 ? "block" : "none" }}
          >
            No, you don’t need to create an account on Incase360 to access your
            notices. Notices can be accessed via the link shared with you on
            WhatsApp/SMS/email.
          </div>
        </List.Item>
        <List.Item
          onClick={() =>
            setActive({
              id4: !active?.id4,
            })
          }
        >
          <span className="manage-faq">
            <h5>4. How do you reply to a notice?</h5>
            <i className={`fa fa-${active?.id4 ? "minus" : "plus"}`}></i>
          </span>
          <div
            className="dataView my-3"
            style={{ display: active?.id4 ? "block" : "none" }}
          >
            Generally, you can include your response in the reply section of the
            link provided to you. Once you click on the link, you will be
            redirected to the response page. There, you can type/record your
            response after mentioning your name. The same response will be
            updated with our team as well.
          </div>
        </List.Item>
        <List.Item
          onClick={() =>
            setActive({
              id5: !active?.id5,
            })
          }
        >
          <span className="manage-faq">
            <h5>
              5. What is the Schedule A Demo form on the Incase360 website?
            </h5>
            <i className={`fa fa-${active?.id5 ? "minus" : "plus"}`}></i>
          </span>
          <div
            className="dataView my-3"
            style={{ display: active?.id5 ? "block" : "none" }}
          >
            Incase360 is an intuitive legal notice management platform, that
            makes notice creation, intimation, and management effective and
            efficient. If you would like to see a short demonstration of how the
            platform works, fill out this form and a representative will reach
            out to you.
          </div>
        </List.Item>
        <List.Item
          onClick={() =>
            setActive({
              id6: !active?.id6,
            })
          }
        >
          <span className="manage-faq">
            <h5>6. What should you do when you receive legal notice?</h5>
            <i className={`fa fa-${active?.id6 ? "minus" : "plus"}`}></i>
          </span>
          <div
            className="dataView my-3"
            style={{ display: active?.id6 ? "block" : "none" }}
          >
            Contact the concerned person/authority who sent you the legal
            notice.
          </div>
        </List.Item>
        <List.Item
          onClick={() =>
            setActive({
              id7: !active?.id7,
            })
          }
        >
          <span className="manage-faq">
            <h5>
              7. Can Incase360 provide me with legal advice and how to proceed
              with responding to the notice?
            </h5>
            <i className={`fa fa-${active?.id7 ? "minus" : "plus"}`}></i>
          </span>
          <div
            className="dataView my-3"
            style={{ display: active?.id7 ? "block" : "none" }}
          >
            No. Incase360 is a SaaS platform and not a law firm and does not
            provide legal advice. Please see the Disclaimer on the Incase360
            website for more details.
          </div>
        </List.Item>
        <List.Item
          onClick={() =>
            setActive({
              id8: !active?.id8,
            })
          }
        >
          <span className="manage-faq">
            <h5>8. Why should I use Incase360?</h5>
            <i className={`fa fa-${active?.id8 ? "minus" : "plus"}`}></i>
          </span>
          <div
            className="dataView my-3"
            style={{ display: active?.id8 ? "block" : "none" }}
          >
            You can use Incase360 to send out legal notices through a
            multi-communication channel, powered by automation. Incase360 can
            help you serve and track e-notices in real time, analyse responses,
            and achieve resolutions.
          </div>
        </List.Item>
      </List>
    </div>
  )
}

export default FaqList
