import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"

import { getUserDetails, setBreadcrumbItems } from "../../../store/actions"
import Details from "./Details"

const UserDetails = props => {
  const dispatch = useDispatch()

  const location = useLocation()

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    {
      title: "Manage Users",
      link: location?.state?.prevPath
        ? location?.state?.prevPath
        : "/admin/users/newrequest",
    },
    { title: "User Personal Details", link: "#" },
  ]

  const params = useParams()

  const result = props?.data

  useEffect(() => {
    props.setBreadcrumbItems("User Personal Details", breadcrumbItems)
  })

  useEffect(() => {
    const requestPayload = {
      token: props.token,
      userId: params.id,
    }
    dispatch(getUserDetails(requestPayload))
  }, [params.id])

  // console.log(goToPreviousPath)

  return (
    <React.Fragment>
      <MetaTags>
        <title>User Personal Details - Admin | Incase360</title>
      </MetaTags>

      {result ? (
        <Details result={result} />
      ) : (
        <h6
          className="mt-5"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Data Not Found
        </h6>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
  data: state.UserDetails.items,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(UserDetails)
