import { useCallback, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { refreshToken } from "store/actions"

const useActivityBasedTokenRefresh = () => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.Login.token)
  const clickCountRef = useRef(0)

  const handleUserActivity = useCallback(() => {
    clickCountRef.current += 1
    if (clickCountRef.current >= 2) {
      if (token) {
        dispatch(refreshToken({ token }))
      }
      clickCountRef.current = 0
    }
  }, [dispatch, token])

  useEffect(() => {
    document.addEventListener("click", handleUserActivity)
    return () => {
      document.removeEventListener("click", handleUserActivity)
    }
  }, [handleUserActivity])
}

export default useActivityBasedTokenRefresh
