import React, { useState, useEffect } from "react"
import { CustomProvider, IconButton, Pagination, Table, Input } from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import TagAuthorizeIcon from "@rsuite/icons/TagAuthorize"
import PageIcon from "@rsuite/icons/Page"
import { getUsers, getAllApproveUser, getOffers } from "store/actions"
import { useDispatch, connect } from "react-redux"
import { Link } from "react-router-dom"
import moment from "moment"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import DrwerBar from "components/Common/DrwerBar"
import { getExistingNotice } from "store/Admin/ExistingNotice/actions"
import useWindowDimensions from "common/useWindowDimensions"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"
import EditIcon from "@rsuite/icons/Edit"
import PeopleBranchIcon from "@rsuite/icons/PeopleBranch"
import SearchInput from "common/SearchInput"

const { Column, HeaderCell, Cell } = Table

function ApprovedUsers(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState({
    submit: false,
    value: "",
  })
  const [openAssignNoticeDrawer, setOpenAssignNoticeDrawer] = useState(false)
  const [drawerItemId, setDrawerItemId] = useState()
  const [accessItemId, setAccessItemId] = useState()
  const [updateAccessItemId, setUpdateAccessItemId] = useState()
  const { height, width } = useWindowDimensions()
  const [action, setAction] = useState(false)
  const [openEditProfileDrawer, setOpenEditProfileDrawer] = useState(false)
  const [openSubUserAddDrawer, setOpenSubUserAddDrawer] = useState(false)
  const [openAssignOffersDrawer, setOpenAssignOffersDrawer] = useState(false)
  const [drawerLoad, setDrawerLoad] = useState(false)
  const [addSubUserRequest, setAddSubUserRequest] = useState({
    token: props?.token,
    userprofileId: "",
    subuser: "",
    relation_name: "",
  })
  const [drawItemId, setDrawItemId] = useState()
  const [updateRequest, setUpdateRequest] = useState([])
  const [requestAddOffers, setRequestAddOffers] = useState({
    token: props?.token,
    user_id: "",
    offer_ids: "",
  })
  const [CheckUser, setCheckUser] = useState([])

  const dispatch = useDispatch()
  const data = props?.data

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  var filterData = data?.filter(value => {
    return parseInt(value.id) === parseInt(drawItemId)
  })

  const handleopenAssignNoticeDrawer = (itemId, access) => {
    setAccessItemId(access)
    setDrawerItemId(itemId)
    setOpenAssignNoticeDrawer(true)
  }

  const handleopenAssignOffersDrawer = itemId => {
    // setAccessItemId(access)
    // setDrawItemId(itemId)
    setRequestAddOffers({ ...requestAddOffers, user_id: itemId })
    setOpenAssignOffersDrawer(true)
  }

  const handleAssignOffers = () => {
    setDrawerLoad(true)
    // console.log(requestAddOffers)
    ApiCall(requestAddOffers, "admin/assign-offers", false, result => {
      if (result?.data?.response === "success") {
        setAction(!action)
        setOpenAssignOffersDrawer(false)
        ShowNotification(result?.data?.message, "success")
        // history.push("/admin/existingnotice")
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
      setDrawerLoad(false)
    })
  }

  const handleopenAddSubUserDrawer = (itemId, access, relation) => {
    // setAccessItemId(access)
    // setDrawerItemId(itemId)
    setAddSubUserRequest({
      ...addSubUserRequest,
      userprofileId: itemId,
      subuser: access ? access?.split(",") : "",
      relation_name: relation,
    })
    const filterUser = props?.AllApproveUser?.filter(value => {
      return parseInt(value.id) !== parseInt(itemId)
    })
    setCheckUser(filterUser)
    setOpenSubUserAddDrawer(true)
  }

  const handleActionSubUserAdd = () => {
    // console.log(addSubUserRequest)
    ApiCall(addSubUserRequest, "admin/add-subuser", false, result => {
      // console.log(result)
      if (result?.data?.response === "success") {
        setAction(!action)
        setOpenSubUserAddDrawer(false)
        ShowNotification(result?.data?.message, "success")
        // history.push("/admin/existingnotice")
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  const handleAssignNotice = () => {
    const request = {
      token: props?.token,
      userId: drawerItemId,
      noticemenu: updateAccessItemId,
    }
    // console.log(request);
    ApiCall(request, "admin/assign-usernotice", false, result => {
      if (result?.data?.response === "success") {
        setAction(!action)
        setOpenAssignNoticeDrawer(false)
        ShowNotification(result?.data?.message, "success")
        // history.push("/admin/existingnotice")
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  const handleopenEditProfileDrawer = value => {
    setDrawItemId(value)
    setOpenEditProfileDrawer(true)
    // setUpdateRequest(filterData[0])
    setTimeout(() => {
      setUpdateRequest(prev => ({ ...prev, token: props?.token }))
    }, 2000)
  }

  const handleUpdateProfile = () => {
    ApiCall(updateRequest, "user/update-profile", false, result => {
      if (result?.data?.response === "success") {
        setOpenEditProfileDrawer(false)
        setAction(!action)
        ShowNotification(result?.data?.message, "success")
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  useEffect(() => {
    setUpdateRequest(filterData[0])
  }, [drawItemId])
  
  const displayStart = limit * (page - 1)

  useEffect(() => {

    const requestPayload = {
      token: props.token,
      status: 1,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      searchValue: search?.value,
    }
    dispatch(getUsers(requestPayload))
  }, [page, limit, action, search?.submit])

  const handleClose = () => {
    openAssignNoticeDrawer && setOpenAssignNoticeDrawer(false)
    openSubUserAddDrawer && setOpenSubUserAddDrawer(false)
    openEditProfileDrawer && setOpenEditProfileDrawer(false)
    openAssignOffersDrawer && setOpenAssignOffersDrawer(false)
  }

  useEffect(() => {
    const requestPayload = {
      token: props?.token,
    }
    const requestForNotice = {
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
    }
    dispatch(getAllApproveUser(requestPayload))
    dispatch(getOffers(requestPayload))
    dispatch(getExistingNotice(requestPayload))
  }, [])

  return (
    <div className="table-responsive">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SearchInput
          placeholder="Search"
          search={search}
          setSearch={setSearch}
        />
      </div>
      {openAssignNoticeDrawer && (
        <DrwerBar
          open={openAssignNoticeDrawer}
          setOpen={setOpenAssignNoticeDrawer}
          itemId={drawerItemId}
          title="Assign Notice"
          body="assignNotice"
          labelData="Notice"
          subData={props.existingNotice}
          size="xs"
          defaultValue={accessItemId}
          setState={setUpdateAccessItemId}
          onClick={handleAssignNotice}
          onCancel={handleClose}
        />
      )}
      {openAssignOffersDrawer && (
        <DrwerBar
          open={openAssignOffersDrawer}
          setOpen={setOpenAssignOffersDrawer}
          title="Assign Offers"
          body="assignOffers"
          labelData="Offers"
          subData={props?.offerList}
          size="xs"
          loading={drawerLoad}
          // defaultValue={accessItemId}
          state={requestAddOffers}
          setState={setRequestAddOffers}
          onClick={handleAssignOffers}
          onCancel={handleClose}
        />
      )}
      {openSubUserAddDrawer && (
        <DrwerBar
          open={openSubUserAddDrawer}
          setOpen={setOpenSubUserAddDrawer}
          title="Add Sub User"
          body="SubUserAdd"
          subData={CheckUser}
          size="xs"
          state={addSubUserRequest}
          setState={setAddSubUserRequest}
          onClick={handleActionSubUserAdd}
          onCancel={handleClose}
        />
      )}
      {openEditProfileDrawer && (
        <DrwerBar
          open={openEditProfileDrawer}
          setOpen={setOpenEditProfileDrawer}
          title="Edit Profile"
          body="editProfile"
          forEditData={filterData[0]}
          size="sm"
          state={updateRequest}
          setState={setUpdateRequest}
          onClick={() => handleUpdateProfile()}
          onCancel={handleClose}
        />
      )}
      <Table
        height={height - 350}
        data={data}
        wordWrap="break-word"
        rowHeight={46}
        loading={props.tableLoading}
      >
        <Column width={90} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell>{(rowdata, key) => <>{key + 1}</>}</Cell>
        </Column>
        <Column flexGrow={3}>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="username" />
        </Column>
        <Column flexGrow={3}>
          <HeaderCell>Organization</HeaderCell>
          <Cell dataKey="organization_name" />
        </Column>
        <Column flexGrow={3}>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="email" />
        </Column>
        <Column flexGrow={3}>
          <HeaderCell>Contact</HeaderCell>
          <Cell dataKey="contact" />
        </Column>
        <Column flexGrow={3}>
          <HeaderCell>Date</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                {moment(rowdata.created_at).format("DD-MM-YYYY")}
                <br />
                <span style={{ color: "green" }}>
                  {rowdata?.acc_activated_at?.split("<br>")[0]}
                </span>
              </>
            )}
          </Cell>
        </Column>
        {/* <Column width={100}>
          <HeaderCell>Approved On</HeaderCell>
          <Cell>{rowdata => <></>}</Cell>
        </Column> */}
        <Column flexGrow={3} align="center">
          <HeaderCell>Draft/Active</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                Draft: {rowdata.noOfDraft}
                <br />
                Active: {rowdata.noOfActive}
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={3} align="center">
          <HeaderCell>Overdue</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                Resolved: {rowdata.noOfRes}
                <br />
                Escalated: {rowdata.noOfEsc}
                <br />
                Overdue: {rowdata.noOfOverdue}
              </>
            )}
          </Cell>
        </Column>
        <Column width={180} align="center">
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="Edit User"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="blue"
                    icon={<EditIcon />}
                    onClick={() => handleopenEditProfileDrawer(rowdata?.id)}
                    // onClick={() =>
                    //   handleChangeUserStatus(rowdata?.id, 2)
                    // }
                  />
                </WhisperTooltip>
                &nbsp;
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View User Personal Details"
                >
                  <Link
                    to={{
                      pathname: "/admin/user-details/" + rowdata.id,
                      state: { prevPath: location.pathname },
                    }}
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="orange"
                      icon={<VisibleIcon />}
                    />
                  </Link>
                </WhisperTooltip>
                &nbsp;
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View User Letterhead"
                >
                  <Link
                    to={{
                      pathname: "/admin/user-letterhead/" + btoa(rowdata.id),
                      state: { prevPath: location.pathname },
                    }}
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="violet"
                      icon={<PageIcon />}
                    />
                  </Link>
                </WhisperTooltip>
                &nbsp;
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="Assign Notice"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="blue"
                    onClick={() =>
                      handleopenAssignNoticeDrawer(
                        rowdata.id,
                        rowdata.menu_access
                      )
                    }
                    icon={<TagAuthorizeIcon />}
                  />
                </WhisperTooltip>
                &nbsp;
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="Add Sub User"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="violet"
                    onClick={() =>
                      handleopenAddSubUserDrawer(
                        rowdata?.id,
                        rowdata?.hierarchy_id,
                        rowdata?.relation_name
                      )
                    }
                    icon={<PeopleBranchIcon />}
                  />
                </WhisperTooltip>
                &nbsp;
{/*                 <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="Assign Offers"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="blue"
                    onClick={() => handleopenAssignOffersDrawer(rowdata.id)}
                    icon={<TagAuthorizeIcon />}
                  />
                </WhisperTooltip> */}
              </>
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.tableCount}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.Users.items,
  tableLoading: state.Users.loading,
  tableCount: state.Users.count,
  token: state.Login.token,
  existingNotice: state.ExistingNotice.items,
  AllApproveUser: state.AllApproveUser.items,
  offerList: state.OfferList.items,
})

export default connect(mapStateToProps)(ApprovedUsers)
