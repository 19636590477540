import React, { useState, useEffect } from "react"
import { CustomProvider, IconButton, Pagination } from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import CheckRoundIcon from "@rsuite/icons/CheckRound"
import DetailIcon from "@rsuite/icons/Detail"
import { getCourierLog } from "store/actions"
import { connect, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import useWindowDimensions from "common/useWindowDimensions"
import { Table } from "reactstrap"
import Loader from "common/Loader"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import FileDownloadIcon from "@rsuite/icons/FileDownload"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"

function CourierLogTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const params = useParams()
  const data = props?.data
  const { height, width } = useWindowDimensions()

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const downloadClickNoticePdf = (file, id) => {
    const request = {
      token: props?.token,
      folder: "CourierPdf",
      filename: file,
      id: id,
    }
    DownloadApiCall(request, "download/single-download", "_blank")
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      case_id: atob(params.id),
      token: props.token,
      page: page,
      //   iDisplayStart: displayStart,
      //   iDisplayLength: limit,
    }
    dispatch(getCourierLog(requestPayload))
  }, [page, limit])

  return (
    <div className="table-responsive">
      <div style={{ height: height - 300, overflow: "scroll" }}>
        <Table size="sm">
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Case Id</th>
              <th>AWB No</th>
              <th>Type</th>
              <th>Status</th>
              <th>Status as on Date</th>
              <th>Status at</th>
              <th>Last Activity</th>
              <th>Reason</th>
              <th>Final Status</th>
              <th>Pdf</th>
            </tr>
          </thead>
          <tbody>
            {props?.tableLoading ? (
              <tr>
                <td colSpan={11} className="text-center">
                  <div className="mt-4">
                    <Loader />
                  </div>
                </td>
              </tr>
            ) : data?.length !== 0 ? (
              data?.map((value, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>IN{value.notice_unq_id}</td>
                      <td>{value.awb_no}</td>
                      <td>{value.courier_type}</td>
                      <td>{value.status}</td>
                      <td>{value.status_as_on_date}</td>
                      <td>{value.status_at}</td>
                      <td>{value.last_activity}</td>
                      <td>{value.reason}</td>
                      <td>{value.final_status}</td>
                      <td>
                        {value.uploaded_file && (
                          <>
                            <WhisperTooltip
                              placement="top"
                              trigger="hover"
                              tooltipMsg="Download Courier PDF"
                            >
                              <IconButton
                                size="sm"
                                appearance="primary"
                                color="green"
                                icon={<FileDownloadIcon />}
                                onClick={() =>
                                  downloadClickNoticePdf(
                                    value.uploaded_file,
                                    value.adminNotice_id
                                  )
                                }
                              />
                            </WhisperTooltip>
                          </>
                        )}
                      </td>
                    </tr>
                  </>
                )
              })
            ) : (
              <tr>
                <td colSpan={11} className="text-center">
                  <div className="mt-4">No Data Found</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {/* <Table
        height={height - 300}
        data={data}
        wordWrap="break-word"
        headerHeight={50}
        loading={props.tableLoading}
      >
        <Column width={95} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Case ID</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>AWB No</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Type</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Status</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Status on Date</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Status at</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Last Activity</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Reason</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Final Status</HeaderCell>
          <Cell></Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.count}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      /> */}
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.CourierLog.items,
  tableLoading: state.CourierLog.loading,
  count: state.CourierLog.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(CourierLogTable)
