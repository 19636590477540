import useWindowDimensions from "common/useWindowDimensions"
import React from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, CardHeader, Container, Table } from "reactstrap"
import { Col, Grid, Row } from "rsuite"
import "./cookie.scss"

function Cookie() {
  const { height, width } = useWindowDimensions()

  return (
    <React.Fragment>
      <MetaTags>
        <title>Cookie Policy | Incase360</title>
      </MetaTags>
      <div className="banner">
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none container"
          aria-hidden="true"
        >
          <Grid fluid>
            <Row className="show-grid text-center">
              <Col xs={24} sm={24} md={24}>
                <h2>
                  <b>Cookie Policy</b>
                </h2>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
      <Container className="mt-3 cookie">
        <Row className="justify-content-center">
          <Col md={24} lg={24} xl={24}>
            <Card className="overflow-hidden">
              <CardBody className="p-4 body">
                <p>
                  Cookie Policy The Cookies Notice explains how Incase360 uses
                  cookies and similar technologies in the course of our
                  business, through our website{" "}
                  <a href="https://incase360.com/">Incase360.</a> It explains
                  what these technologies are, and why we use them, as well as
                  your rights to control our use of them.
                </p>
              </CardBody>
              <CardHeader>
                <h5>
                  <b>1. WHAT IS A COOKIE?</b>
                </h5>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  Cookies are small text files that a website asks the user’s
                  browser to save on the user’s system/device, when a user
                  visits the website, in order to remember the user’s device
                  upon revisiting the website or during the session. Cookies set
                  up by the domain{" "}
                  <a href="https://incase360.com/">Incase360</a>, are called
                  first-party cookies and cookies set by other domain(s) than
                  the website(s) you are visiting are termed as third-party
                  cookies. Third-party cookies are generally used for marketing
                  and advertising purposes.
                </p>
              </CardBody>
              <CardHeader>
                <h5>
                  <b>2. PURPOSE OF USING COOKIES?</b>
                </h5>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  Our website <a href="https://incase360.com/">Incase360</a>{" "}
                  uses cookies and other tracking technologies for the following
                  purposes:
                </p>
                <p>
                  • To analyze your use of our website
                  <br />• For promotional and marketing purposes
                  <br />• To understand your preferences such as preferred
                  language
                  <br />• To deliver information that matches your interests
                  <br />• To help us understand our audience and traffic
                  patterns
                  <br />
                  • To let you automatically log into programs and parts of our
                  site that require membership
                  <br />• To manage and present the information displayed on our
                  website
                </p>
                <p>TYPES OF COOKIES USED BY Incase360:</p>
                <p>
                  • Strictly Necessary Cookies
                  <br />• Targeting Cookies
                  <br />• Functionality Cookies
                  <br />• Performance Cookies
                </p>
              </CardBody>
              <CardHeader>
                <h5>
                  <b>3. COOKIE LIST</b>
                </h5>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  The table below provides additional details on the specific
                  cookies used on <a href="https://incase360.com/">Incase360</a>
                  :
                </p>
                <p>
                  <u>Strictly Necessary Cookies</u> - Some cookies are required
                  for technical reasons in order for our websites to support
                  functions such as logging in, and payment transactions.
                </p>
                <table
                  className="table table-responsive table-bordered"
                  style={{ overflow: "scroll" }}
                >
                  <thead style={{ zIndex: "0" }}>
                    <tr>
                      <th>Cookie Provider</th>
                      <th>Name of Cookie</th>
                      <th>Type of Cookie</th>
                      <th>Purpose</th>
                      <th>Duration (days)</th>
                    </tr>
                  </thead>{" "}
                  <tbody>
                    <tr>
                      <td>www.Incase360</td>
                      <td>_incase_visit</td>
                      <td>First-party</td>
                      <td>
                        Cookie associated with sites for used to identify user
                        cookie policy accepted or not
                      </td>
                      <td>1 Day</td>
                    </tr>
                    <tr>
                      <td>www.Incase360</td>
                      <td>ci_session</td>
                      <td>First-party</td>
                      <td>
                        This cookie name is associated with website used by PHP.
                        This cookie use to provide functions across pages.
                      </td>
                      <td>Session</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  <u>Targeted Cookies</u> - These cookies may be set through our
                  site by our advertising partners. They may be used by those
                  companies to build a profile of your interests and show you
                  relevant adverts on other sites. They do not store direct
                  personal information but are based on uniquely identifying
                  your browser and internet device.
                </p>
                <p>
                  <u>Functionality Cookies</u> - Some cookies are required for
                  technical reasons in order for our websites to support
                  functions such as logging in, and payment transactions.
                </p>
                <table
                  className="table table-responsive table-bordered"
                  style={{ overflow: "scroll" }}
                >
                  <thead style={{ zIndex: "0" }}>
                    <tr>
                      <th>Cookie Provider</th>
                      <th>Name of Cookie</th>
                      <th>Type of Cookie</th>
                      <th>Purpose</th>
                      <th>Duration (days)</th>
                    </tr>
                  </thead>{" "}
                  <tbody>
                    <tr>
                      <td>www.Incase360</td>
                      <td>name</td>
                      <td>First-party</td>
                      <td>
                        This cookie name is associated with website used to
                        Split cookie string and get all individual name=value
                        pairs in an array.
                      </td>
                      <td>Session</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  <u>Performance Cookies</u> - Performance cookies are cookies
                  used specifically for gathering data on how visitors use a
                  website, which pages of a website are visited most often, or
                  if they get error messages on web pages. These cookies monitor
                  only the performance of the site as the user interacts with
                  it. These cookies don’t collect identifiable information on
                  visitors, which means all the data collected is anonymous and
                  only used to improve the functionality of a website.
                </p>
                <p>
                  Any cookie, whose purpose has not been identified by us or is
                  unknown to our current understanding, is classified together
                  as “Unclassified Cookies”. Such cookies may or may not adhere
                  to our policy and we cannot take ownership of said cookies,
                  however, we would periodically and actively try to investigate
                  them and categorize them in their respective cookie categories
                  for the benefit of our users, partners and customers.
                </p>
              </CardBody>
              <CardHeader>
                <h5>
                  <b>4. Other tracking technologies like web beacons</b>
                </h5>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  Cookies are not the only way to recognize or track visitors on
                  a website. We may use other similar technologies from time to
                  time, like web beacons (sometimes called “tracking pixels” or
                  “clear gifs”). These are tiny graphics files that contain a
                  unique identifier that enables us to recognize when someone
                  has visited our websites or opened an e-mail including these.
                  In many instances, these technologies are reliant on cookies
                  to function properly, and hence, declining cookies will impair
                  their functioning
                </p>
              </CardBody>
              <CardHeader>
                <h5>
                  <b>5. How can I control the cookie preferences?</b>
                </h5>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  Most browsers are set to accept cookies by default. However,
                  you may remove or reject cookies in your browser’s settings.
                  Removing or rejecting the cookies may affect the performance
                  of the website. You may opt-out of the DoubleClick cookie by
                  visiting the Google advertising opt-out page:{" "}
                  <a href="http://www.google.com/policies/privacy/ads/">
                    http://www.google.com/policies/privacy/ads/
                  </a>
                  . Or you may opt-out of Google Analytics by visiting the
                  Google Analytics Opt-out page:{" "}
                  <a href="http://tools.google.com/dlpage/gaoptout">
                    http://tools.google.com/dlpage/gaoptout
                  </a>
                  .
                </p>
              </CardBody>
              <CardHeader>
                <h5>
                  <b>6. Update and Review</b>
                </h5>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  We may update this Cookie Policy from time to time, therefore,
                  we encourage you to periodically review this page for the
                  latest information on the Policy.
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Cookie
