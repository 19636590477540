import { TabBar } from "common/TabBar/TabBar"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { CustomProvider } from "rsuite"
import { setBreadcrumbItems } from "../../../store/actions"
import ActiveTable from "./ActiveTable"
import DraftTable from "./DraftTable"
import EscalatedTable from "./EscalatedTable"
import OverdueTable from "./OverdueTable"
import ResolvedTable from "./ResolvedTable"

const AdminNoticeDetails = props => {
  const [active, setActive] = useState("draft")

  const tabbardata = [
    {
      eventkey: "draft",
      lable: "Draft",
    },
    { eventkey: "active", lable: "Active" },
    { eventkey: "overdue", lable: "Overdue" },
    { eventkey: "resolved", lable: "Resolved" },
    { eventkey: "escalated", lable: "Escalated" },
  ]

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Notice List", link: "/admin/noticelist" },
    { title: "Notice Details", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems(`Notice Details - ${props?.data?.noticeName?.replace(
      "notice_",
      ""
    )}`, breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Notice Details - Admin | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        <TabBar
          style={{ textAlign: "center" }}
          appearance="tabs"
          active={active}
          onSelect={setActive}
          panel={tabbardata}
        />

        {active === "draft" && (
          <>
            <DraftTable />
          </>
        )}
        {active === "active" && (
          <>
            <ActiveTable />
          </>
        )}
        {active === "overdue" && (
          <>
            <OverdueTable />
          </>
        )}
        {active === "resolved" && (
          <>
            <ResolvedTable />
          </>
        )}
        {active === "escalated" && (
          <>
            <EscalatedTable />
          </>
        )}
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(AdminNoticeDetails)
