const initialState = {
    items: [],
    loading: true,
    error: null,
};

const DraftNotice = (state = initialState, action) => {
    switch (action.type) {
        case "DraftNoticeBegin":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "DraftNoticeSuccess":
            return {
                ...state,
                items: action?.data,
                loading: false,
            };
        case "DraftNoticeFailure":
            return {
                ...state,
                items: [],
                loading: false,
                error: action?.data,
            };
        default:
            return state;
    }
};

export default DraftNotice;
