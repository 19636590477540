import { AvField, AvForm } from 'availity-reactstrap-validation';
import { ApiCall } from 'common/ActionApiCall/ApiCall';
import { ShowNotification } from 'common/ShowNotification';
import React, { useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { registerAction } from 'store/actions';

// availity-reactstrap-validation
// action

// Redux
// import { connect } from "react-redux";
// import images
// import logoLightPng from "../../assets/images/logo-light.png";
// import logoDark from "../../assets/images/logo-dark.png";
const Register = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleValidSubmit = (event, values) => {
    dispatch(registerAction(values))
    // console.log(requestPlayload)
    ApiCall(values, "sendOtp", false, result => {
      if (result?.data?.response === "success") {
        ShowNotification(result?.data?.message, "success")
        history.push("/register-verify")
        // ShowSwal(
        //   "Register Successfully",
        //   "You are successfully register. Your account is under review you will receive an email shortly.",
        //   "info",
        //   "Ok",
        //   result => {
        //     history.push("/login")
        //   }
        // )
        // history.push("/login")
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  // useEffect(() => {
  // 	props.apiError("");
  // }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Incase360</title>
      </MetaTags>

      <div className="account-pages my-2 pt-sm-4">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={8} xl={8}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-4 mb-3">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src="https://api.incase360.com/assets/images/Incase360-logo-1-white.png"
                        alt=""
                        height="30"
                        className="auth-logo-dark"
                      />
                      <img
                        src="https://api.incase360.com/assets/images/Incase360-logo-1-white.png"
                        alt=""
                        height="30"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Register
                    </h4>
                    <p className="text-muted text-center">Create New Account</p>
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {/* {props.user && props.user ? (
												<Alert color="success">
													Register User Successfully
												</Alert>
											) : null} */}

                      {/* {props.registrationError && props.registrationError ? (
												<Alert color="danger">{props.registrationError}</Alert>
											) : null} */}
                      <Row>
                        <Col md={4} lg={4} xl={4} sm={12}>
                          <div className="mb-3">
                            <AvField
                              id="orgName"
                              name="orgName"
                              label="Organization Name"
                              className="form-control"
                              placeholder="Organization Name"
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={4} lg={4} xl={4} sm={12}>
                          <div className="mb-3">
                            <AvField
                              id="authorisedName"
                              name="authorisedName"
                              label="Authorized Representative"
                              className="form-control"
                              placeholder="Authorized Representative"
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={4} lg={4} xl={4} sm={12}>
                          <div className="mb-3">
                            <AvField
                              id="designation"
                              name="designation"
                              label="Enter Designation"
                              className="form-control"
                              placeholder="Enter Designation"
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={6} lg={6} xl={6} sm={12}>
                          <div className="mb-3">
                            <AvField
                              id="addressLine1"
                              name="addressLine1"
                              label="Address Line 1"
                              className="form-control"
                              placeholder="Address Line 1"
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={6} lg={6} xl={6} sm={12}>
                          <div className="mb-3">
                            <AvField
                              id="addressLine2"
                              name="addressLine2"
                              label="Address Line 2"
                              className="form-control"
                              placeholder="Address Line 2"
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={3} lg={3} xl={3} sm={12}>
                          <div className="mb-3">
                            <AvField
                              id="city"
                              name="city"
                              label="Enter City"
                              className="form-control"
                              placeholder="Enter City"
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={3} lg={3} xl={3} sm={12}>
                          <div className="mb-3">
                            <AvField
                              id="state"
                              name="state"
                              label="Enter State"
                              className="form-control"
                              placeholder="Enter State"
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={3} lg={3} xl={3} sm={12}>
                          <div className="mb-3">
                            <AvField
                              id="pinCode"
                              name="pinCode"
                              label="Enter Pin Code"
                              className="form-control"
                              placeholder="Enter Pin Code"
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={3} lg={3} xl={3} sm={12}>
                          <div className="mb-3">
                            <AvField
                              id="country"
                              name="country"
                              label="Enter Country"
                              className="form-control"
                              placeholder="Enter Country"
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={6} lg={6} xl={6} sm={12}>
                          <div className="mb-3">
                            <AvField
                              id="contact"
                              name="contact"
                              label="Enter Mobile Number"
                              className="form-control"
                              placeholder="Enter Mobile Number"
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={6} lg={6} xl={6} sm={12}>
                          <div className="mb-3">
                            <AvField
                              id="email"
                              name="email"
                              label="Email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={6} lg={6} xl={6} sm={12}>
                          <div className="mb-3">
                            <AvField
                              name="password"
                              label="Password"
                              type="password"
                              required
                              placeholder="Enter Password"
                              validate={{
                                pattern: {
                                  value:
                                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$",
                                  errorMessage:
                                    "Password must be at least 8 characters long, with an uppercase letter, a lowercase letter, a number, and a special character.",
                                },
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={6} lg={6} xl={6} sm={12}>
                          <div className="mb-3">
                            <AvField
                              name="confirmPassword"
                              label="Confirm Password"
                              type="password"
                              required
                              placeholder="Confirm Password"
                              validate={{
                                match: {
                                  value: "password",
                                  errorMessage:
                                    "Confirm Password does not match",
                                },
                              }}
                            />
                          </div>
                        </Col>
                        {/* <Col md={6} lg={6} xl={6} sm={12}> */}
                        <div className="mb-3 mt-2">
                          <div className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Register
                            </button>
                          </div>
                        </div>
                        {/* </Col> */}
                      </Row>

                      <div className="mb-0 row text-center">
                        <div className="col-12 mt-2">
                          <p>
                            Already have an account ?{" "}
                            <Link to="/login" className="text-primary">
                              {" "}
                              Login
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
								<p>
									Already have an account ?{" "}
									<Link to="/" className="text-primary">
										{" "}
										Login
									</Link>{" "}
								</p>
								<p>
									© {new Date().getFullYear()} Lexa{" "}
									<span className="d-none d-sm-inline-block">
										{" "}
										- Crafted with <i className="mdi mdi-heart text-danger"></i>{" "}
										by Themesbrand.
									</span>
								</p>
							</div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// Register.propTypes = {
// 	registerUser: PropTypes.func,
// 	registerUserFailed: PropTypes.func,
// 	registrationError: PropTypes.any,
// 	user: PropTypes.any,
// };

// const mapStatetoProps = (state) => {
// 	const { user, registrationError, loading } = state.Account;
// 	return { user, registrationError, loading };
// };

export default Register
