import React, { useState, useEffect } from "react"
import { CustomProvider, IconButton, Pagination, Table, Input } from "rsuite"
import ReviewIcon from "@rsuite/icons/Review"
import CloseIcon from "@rsuite/icons/Close"
import VisibleIcon from "@rsuite/icons/Visible"
import { getAllLetterhead } from "store/actions"
import { connect, useDispatch } from "react-redux"
import moment from "moment"
import CheckIcon from "@rsuite/icons/Check"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import { RouteForLetterHead } from "common/ApiRoute"
import useWindowDimensions from "common/useWindowDimensions"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"
import { ShowSwal } from "common/ShowSwal"
import SearchInput from "common/SearchInput"

const { Column, HeaderCell, Cell } = Table

function LetterheadTable(props) {
  const { height, width } = useWindowDimensions()

  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const [action, setAction] = useState(false)
  const [search, setSearch] = useState({
    submit: false,
    value: "",
  })

  const dispatch = useDispatch()
  const data = props?.data

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const handlePreview = value => {
    const passData = {
      token: props?.token,
      letterheadId: value,
    }
    ApiCall(passData, "previewLetterhead", false, result => {
      if (result?.data?.response === "success") {
        ShowNotification(result?.data?.message, "success")
        const link = document.createElement("a")
        // link.target = "_blank"
        link.target = "_blank"
        link.href = result?.data?.url
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  const handleChangeLetterheadStatus = (dataId, status) => {
    const request = {
      token: props?.token,
      letterHeadid: dataId,
      newstatus: status,
    }
    const buttonText =
      status == 0
        ? "Yes, Under Review it!"
        : status == 1
        ? "Yes, Approve it!"
        : "Yes, Reject it!"
    ShowSwal("Are you sure?", "", "warning", buttonText, result => {
      if (result.isConfirmed) {
        ApiCall(request, "admin/letterhead-statuschange", false, result => {
          if (result?.data?.response === "success") {
            setAction(!action)
            ShowNotification(result?.data?.message, "success")
            // history.push("/admin/existingnotice")
          } else {
            ShowNotification(result?.data?.message, "danger")
          }
        })
      }
    })
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      searchValue: search?.value,
    }
    dispatch(getAllLetterhead(requestPayload))
  }, [page, limit, action, search?.submit])

  return (
    <div className="table-responsive">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SearchInput
          placeholder="Search"
          search={search}
          setSearch={setSearch}
        />
      </div>
      <Table
        height={height - 350}
        data={data}
        wordWrap="break-word"
        headerHeight={50}
        loading={props.tableLoading}
      >
        <Column width={50} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          {/* <Cell dataKey="id" /> */}
          <Cell>
            {(rowdata, dataKey) => (
              <>
                <span>
                  {page === 1 ? dataKey + 1 : limit * (page - 1) + dataKey + 1}
                </span>
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={2}>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="head_name" />
        </Column>
        <Column flexGrow={3}>
          <HeaderCell>User</HeaderCell>
          <Cell dataKey="email" />
        </Column>
        <Column flexGrow={1} align="center">
          <HeaderCell>Letterhead</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                {rowdata.letterhead && (
                  <a
                    target="_blank"
                    href={
                      RouteForLetterHead +
                      rowdata.user_id +
                      "/" +
                      rowdata.letterhead
                    }
                  >
                    <img
                      width={40}
                      src={
                        RouteForLetterHead +
                        rowdata.user_id +
                        "/" +
                        rowdata.letterhead
                      }
                    />
                  </a>
                )}
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1} align="center">
          <HeaderCell>Signature</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                {rowdata.signature && (
                  <a
                    target="_blank"
                    href={
                      RouteForLetterHead +
                      rowdata.user_id +
                      "/" +
                      rowdata.signature
                    }
                  >
                    <img
                      width={40}
                      src={
                        RouteForLetterHead +
                        rowdata.user_id +
                        "/" +
                        rowdata.signature
                      }
                    />
                  </a>
                )}
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1} align="center">
          <HeaderCell>Header Margin</HeaderCell>
          <Cell dataKey="head_margin" />
        </Column>
        <Column flexGrow={1} align="center">
          <HeaderCell>Footer Margin</HeaderCell>
          <Cell dataKey="foot_margin" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Created On</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: moment(rowdata.created_date).format("DD-MM-YYYY"),
                  }}
                />
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1} align="center">
          <HeaderCell>Status</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                {parseInt(rowdata.status) === 0 && (
                  <span style={{ color: "orange" }}>Under Review</span>
                )}
                {parseInt(rowdata.status) === 1 && (
                  <span style={{ color: "green" }}>Approved</span>
                )}
                {parseInt(rowdata.status) === 2 && (
                  <span style={{ color: "red" }}>Rejected</span>
                )}
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1} align="center">
          <HeaderCell>Preview</HeaderCell>
          <Cell>
            {rowdata => (
              <WhisperTooltip
                placement="top"
                trigger="hover"
                tooltipMsg="Letterhead Preview"
              >
                <IconButton
                  size="xs"
                  appearance="primary"
                  color="violet"
                  icon={<VisibleIcon />}
                  onClick={() => handlePreview(rowdata?.id)}
                />
              </WhisperTooltip>
            )}
          </Cell>
        </Column>
        <Column width={100} align="center" fixed="right">
          <HeaderCell>Actoin</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                {parseInt(rowdata.status) === 0 && (
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Set Approve"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<CheckIcon />}
                        onClick={() =>
                          handleChangeLetterheadStatus(rowdata?.id, 1)
                        }
                      />
                    </WhisperTooltip>
                    &nbsp;
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Set Reject"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="red"
                        icon={<CloseIcon />}
                        onClick={() =>
                          handleChangeLetterheadStatus(rowdata?.id, 2)
                        }
                      />
                    </WhisperTooltip>
                    &nbsp;
                  </>
                )}
                {parseInt(rowdata.status) !== 0 && (
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Set Under Review"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="orange"
                        icon={<ReviewIcon />}
                        onClick={() =>
                          handleChangeLetterheadStatus(rowdata?.id, 0)
                        }
                      />
                    </WhisperTooltip>
                    &nbsp;
                  </>
                )}
              </>
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props.tableCount}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.AllLetterhead.items,
  tableLoading: state.AllLetterhead.loading,
  tableCount: state.AllLetterhead.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(LetterheadTable)
