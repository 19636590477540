import React, { useEffect, useState } from "react"
import { CustomProvider, IconButton, Pagination, Table } from "rsuite"
import FileDownloadIcon from "@rsuite/icons/FileDownload"
import { getFileSharing } from "store/actions"
import { connect, useDispatch } from "react-redux"
import useWindowDimensions from "common/useWindowDimensions"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import { ShowSwal } from "common/ShowSwal"

const { Column, HeaderCell, Cell } = Table

function UserShareFileTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const data = props?.data
  const { height, width } = useWindowDimensions()

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const downloadClickNoticePdf = (file, fileId) => {
    const request = {
      token: props?.token,
      folder: "fileToShare",
      filename: file,
      fileId: fileId,
    }
    ShowSwal(
      "",
      "File you are attempting to download could be achieved in future by admin. So make sure you download it completely.",
      "info",
      "Agree",
      result => {
        if (result?.isConfirmed) {
          DownloadApiCall(request, "download/single-download", "_blank")
        }
      }
    )
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props?.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      user_type: props?.user_type,
    }
    dispatch(getFileSharing(requestPayload))
  }, [page, limit])

  return (
    <div className="table-responsive">
      <Table
        height={height - 300}
        data={data}
        loading={props?.dataloading}
        wordWrap="break-word"
        headerHeight={width < 500 ? 50 : 40}
      >
        <Column width={80} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell>
            {(rowdata, dataKey) => (
              <>{page === 1 ? dataKey + 1 : limit * (page - 1) + dataKey + 1}</>
            )}
          </Cell>
        </Column>
        <Column width={100}>
          <HeaderCell>File</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <>
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="Download File"
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="green"
                      icon={<FileDownloadIcon />}
                      onClick={() =>
                        downloadClickNoticePdf(rowdata?.file_name, rowdata?.id)
                      }
                    />
                  </WhisperTooltip>
                </>
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Date of Creation</HeaderCell>
          <Cell dataKey="created_on" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Shared With</HeaderCell>
          <Cell dataKey="sharedWith" />
        </Column>
        <Column width={80} align="center">
          <HeaderCell>Status</HeaderCell>
          <Cell>
            {rowdata => (
              <p
                style={{
                  color: rowdata?.status === "Pending" ? "red" : "green",
                }}
              >
                {rowdata?.status}
              </p>
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.tableCount}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.FileSharing.items,
  dataloading: state.FileSharing.loading,
  tableCount: state.FileSharing.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(UserShareFileTable)
