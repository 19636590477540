import React, { useEffect, useState } from 'react'
import { CardBody,} from 'react-bootstrap';
import { Button, Col, Grid, Input, Row } from "rsuite"
import { Card ,Label,Badge,Table } from 'reactstrap';
import FormGroup from "components/Common/FormGroup"
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import axios from 'axios';
import { ApiRoute } from 'common/ApiRoute';
import { UserActionService } from 'Service/userActionService';
import { useSelector } from 'react-redux'
import { UserServerice } from './UserService';
import Loader from 'common/Loader';
import { ShowNotification } from 'common/ShowNotification';
export default function FilterDataChart(props) {
      const [graphData,setdata] = useState()
      const [loading,setLoading] = useState(false)
      const id =  useSelector((state)=> state.Login.id)
      const [request, setRequest] = useState({
        token: props?.token,
        mnid: "",
        dateFrom: "",
        dateTo: "",
        userId:id
      })
      
      const [noticeData, setNoticeData] = useState()
      const date = new Date
      const year = date.getFullYear()
      const month = date.getMonth()  
       function validationCheck () {
         if(!request.fromDate || !request.noticeId || !request.toDate){
              ShowNotification('All Fields are manadatory!','danger')
              setLoading(false)
                return false
         }
         else {
          return true
         }
       } 
        function handleSubmit () {
          setLoading(true)
          validationCheck() && UserServerice.getNotificationBetweenRange(request)
            .then((res)=>{
               console.log(res.data?.response)
                if (res.data?.response == 'error'){
                   setdata(undefined)
                   setLoading(false)
                   return
                }
               setdata(res.data?.response)
               setLoading(false)
            })
        } 
        useEffect(() => {
          setLoading(true)
          UserActionService.getNoticeByUserId(id)
          .then(res => {
            const data = res?.data?.response?.map(item => ({
              label: item?.notice_name,
              value: item?.notice_id,
            }))
            UserServerice.getNotificationBetweenRange({
              noticeId: res?.data?.response[0].notice_id,
              fromDate:`01-${month}-${year -1}`,
              toDate:`${date.getDate()}-${month+1}-${year}`,
              userId:id
            })
            .then((e)=>{
              if(e.data.response === "error" || e.data.response.length == 0 ){
                setdata(undefined)
                setLoading(false)
                return
              }
              setdata(e.data?.response)
              setLoading(false)
            })
            setNoticeData(data)
          })

        }, [])
  return (
    <React.Fragment>
    <div className="card">
      <div className="card-header">
        <h5>Notice Delivery Statistics</h5>
      </div>
      <div className="card-Body m-2">
        <Grid fluid>
          <Row className="show-grid">
            <Col lg={6} md={6} sm={24} xs={24} className="mt-3">
              <FormGroup
                select={true}
                datalabel="Notice"
                selectData={noticeData}
                datavalue={request?.noticeId}
                onChange={e => setRequest({ ...request, noticeId: e })}
              />
            </Col>
            <Col lg={6} md={6} sm={24} xs={24} className="mt-3">
              <span>From</span>
              <Input
                type="date"
                name="dateFrom"
                id="dateFrom"
                onChange={e => setRequest({ ...request, fromDate: e })}
                block
              />
            </Col>
            <Col lg={6} md={6} sm={24} xs={24} className="mt-3">
              <span>To</span>
              <Input
                type="date"
                name="dateTo"
                id="dateTo"
                min={request?.fromDate}
                onChange={e => setRequest({ ...request, toDate: e })}
                block
              />
            </Col>
            <Col lg={6} md={6} sm={24} xs={24} className="mt-3">
              <Button
                appearance="primary"
                color="blue"
                style={{ marginTop: "1.2em" }}
                onClick={() => handleSubmit()}
                block
              >
                Submit
              </Button>

            </Col>
          </Row>
        </Grid>
      </div>

      {
        loading  ? <div style={{display:"grid",placeItems:"center"}}><Loader/></div> :  graphData &&  <div className='d-flex align-items-center'>
        <BarChart
  height={300}
  width={800}
  data={graphData}
>
  {/* <CartesianGrid strokeDasharray="3 3" /> */}
  <XAxis dataKey="name"  tick={{stroke: '#FFF',strokeWidth:'.5'}} />
  <YAxis  tick={{stroke: '#FFF',strokeWidth:'.5'}} />
  {/* <Tooltip /> */}
  <Legend />
  <Bar dataKey="Transmitted" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
  <Bar dataKey="Delivered" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
  <Bar dataKey="Read" fill="#87cf30" activeBar={<Rectangle fill="gold" stroke="purple" />} />
</BarChart>
<Table bordered style={{width:"500px"}}>
<thead>
<tr>
   <th></th>
   <th>Processed</th>
  <th>Delivered
  </th>
 <th>Read</th>
</tr>
</thead>
<tbody>
<tr>
  <th scope="row">
  Email
  </th>
  <td>{graphData[0].Transmitted}</td>
  <td>{graphData[0].Delivered}</td>
  <td>{graphData[0].Read}</td>
</tr>
<tr>
  <th scope="row">
     WhatsApp
  </th>
  <td>{graphData[2].Transmitted}</td>
  <td>{graphData[2].Delivered}</td>
  <td>{graphData[2].Read}</td>
</tr>
<tr>
  <th scope="row">
  SMS
  </th>
  <td>{graphData[1].Transmitted}</td>
  <td>{graphData[1].Delivered}</td>
  <td>{graphData[1].Read}</td>
</tr>

</tbody>
</Table>
        </div>
      }
    
    </div>
      
          

   
    </React.Fragment>
  )
}




