import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import CheckAccess from "components/Common/CheckAccess"

const Pagemiddleware = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    exact
    render={props => {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Pagemiddleware.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Pagemiddleware
