import React, { useEffect, useState } from "react"
import { Row, FormGroup, Col, Label, Form } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect, useDispatch } from "react-redux"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowSwal } from "common/ShowSwal"
import { ShowNotification } from "common/ShowNotification"
import { getAllExistingNoticeIdName, getBatchByNoticeId } from "store/actions"
import { SelectPicker } from "rsuite"

function FetchEmailStatus(props) {
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [request, setRequest] = useState({
    batchName: null,
    noticeId: null,
    token: props?.token,
  })
  const dispatch = useDispatch()

  const selectdatanotice = props?.noticeData?.map(item => ({
    value: item?.notice_id,
    label: item?.notice_type,
  }))

  const selectdatabatch = props?.batchData?.map(item => ({
    value: item?.batchName,
    label: item?.batchName,
  }))

  const handleSubmit = e => {
    e.preventDefault()

    // console.log(values)
    // ShowSwal(
    //   "",
    //   `Do You Want to Resend From ${values?.from_case} To ${values?.to_case}?`,
    //   "warning",
    //   "Ok",
    //   result => {
    //     if (result.isConfirmed) {
    request?.batchName &&
      request?.noticeId && [
        setDisabledBtn(true),
        ApiCall(request, "fetchEmailStatusSendgrid", false, result => {
          if (result?.data?.response === "success") {
            ShowNotification(result?.data?.message, "success")
          } else {
            ShowNotification(result?.data?.message, "danger")
          }
          setDisabledBtn(false)
        }),
      ]

    //     } else {
    //     }
    //   }
    // )
  }

  useEffect(() => {
    setRequest({ ...request, noticeId: null })

    let formData = new FormData()
    formData.append("token", props?.token)

    dispatch(getAllExistingNoticeIdName(formData))
  }, [])

  useEffect(() => {
    setRequest({ ...request, batchName: null })

    let formData = new FormData()
    formData.append("token", props?.token)
    formData.append("notice_id", request?.noticeId)

    dispatch(getBatchByNoticeId(formData))
  }, [request?.noticeId])

  return (
    <div>
      <div
        style={{
          backgroundColor: "#131826",
          padding: "1em 2em",
          borderRadius: "0.5em",
        }}
      >
        <h5>Fetch Email Status</h5>
      </div>
      <Form className="mt-4" method="post" onSubmit={handleSubmit}>
        <Row>
          <Col lg="5" md="5">
            <Label>Select Notice:</Label>
            <SelectPicker
              placement="topStart"
              value={request?.noticeId}
              block
              className="mb-3"
              data={selectdatanotice}
              onChange={e => setRequest({ ...request, noticeId: e })}
            />
          </Col>
          <Col lg="5" md="5">
            <Label>Select Batch:</Label>
            <SelectPicker
              placement="topStart"
              value={request?.batchName}
              className="mb-3"
              block
              data={selectdatabatch}
              onChange={e => setRequest({ ...request, batchName: e })}
            />
          </Col>

          <Col lg="2" md="2">
            <button
              disabled={disabledBtn}
              className="btn btn-primary w-md waves-effect waves-light mt-4"
              type="submit"
            >
              {disabledBtn ? "Processing..." : "Fetch Email Status"}
            </button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
  noticeData: state.ExistingNoticeIdName.items,
  batchData: state.BatchByNoticeId.items,
})

export default connect(mapStateToProps)(FetchEmailStatus)
