import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect, useDispatch, useSelector } from "react-redux"
import { Row, Col } from "reactstrap"

import { Link } from "react-router-dom"

// Reactstrap
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

// Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import { getUserMe, logOut } from "store/actions"

// import megamenuImg from "../../assets/images/megamenu-img.png"
// import logo from "../../assets/images/logo-sm.png";
// import logoLightPng from "../../assets/images/logo-light.png";
// import logoDark from "../../assets/images/logo-dark.png";

//i18n
// import { withTranslation } from "react-i18next";

// Redux Store
// import {
// 	showRightSidebarAction,
// 	toggleLeftmenu,
// 	changeSidebarType,
// } from "../../store/actions";

const Header = props => {
  const [search, setsearch] = useState(false)
  const [createmenu, setCreateMenu] = useState(false)
  const state = useSelector(state => state.Login.token)

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const dispatch = useDispatch()

  useEffect(() => {
    const requestPayload = {
      token: state,
    }
    dispatch(
      getUserMe(requestPayload, result => {
        result?.data?.code !== 200 && dispatch(logOut())
      })
    )
  }, [])

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img
                    src="https://api.incase360.com/assets/images/logo-sm.png"
                    alt=""
                    height="25"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src="https://api.incase360.com/assets/images/Incase360-logo-1-white.png"
                    alt=""
                    height="28"
                  />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="mdi mdi-menu"></i>
            </button>
          </div>
          <div className="d-flex">
            {/* <div className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Search" + "..."}
                />
                <span className="fa fa-search"></span>
              </div>
            </div>
            <div className="dropdown d-inline-block d-lg-none ml-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
            {/* <LanguageDropdown /> */}
            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen font-size-24"></i>
              </button>
            </div>
            <NotificationDropdown />
            <ProfileMenu />
            {/* <div
							// onClick={() => {
							// 	props.showRightSidebarAction(!props.showRightSidebar);
							// }}
							className="dropdown d-inline-block"
						>
							<button
								type="button"
								className="btn header-item noti-icon right-bar-toggle waves-effect"
							>
								<i className="mdi mdi-spin mdi-cog"></i>
							</button>
						</div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

// const mapStatetoProps = (state) => {
// 	const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
// 		state.Layout;
// 	return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
// };

export default Header
