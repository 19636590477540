import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"
import { Row, Col } from "reactstrap"

// Pages Components
import Miniwidget from "./Miniwidget"
import ActiveCount from "./ActiveCount"
// import MonthlyEarnings from "./montly-earnings";
// import EmailSent from "./email-sent";
// import MonthlyEarnings2 from "./montly-earnings2";
// import Inbox from "./inbox";
// import RecentActivity from "./recent-activity";
// import WidgetUser from "./widget-user";
// import YearlySales from "./yearly-sales";
// import LatestTransactions from "./latest-transactions";
// import LatestOrders from "./latest-orders";
import { CustomProvider } from "rsuite"

//Import Action to copy breadcrumb items from local state to redux state
import {
  getDashboardCountData,
  getUserMe,
  logOut,
  setBreadcrumbItems,
} from "../../../store/actions"
import UploadCSV from "./UploadCSV"

const AdminDashboard = props => {
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Dashboard", link: "#" },
  ]
  const dispatch = useDispatch()

  useEffect(() => {
    props.setBreadcrumbItems("Dashboard", breadcrumbItems)
  })

  useEffect(() => {
    const requestPayload = {
      token: props?.token,
    }
    dispatch(
      getUserMe(requestPayload, result => {
        result?.data?.code !== 200 && dispatch(logOut())
      })
    )
  }, [])

  useEffect(() => {
    let interval = setInterval(() => {
      if (props?.token) {
        dispatch(getDashboardCountData({ token: props?.token }))
      }
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const reports = [
    {
      title: "Pending WhatsApp",
      iconClass: "whatsapp",
      total: props?.data?.wa_count ? props?.data?.wa_count : 0,
      // average: "+11%",
      badgecolor: "primary",
    },
    // {
    //   title: "Failed WhatsApp",
    //   iconClass: "whatsapp",
    //   total: "2000",
    //   // average: "-29%",
    //   badgecolor: "danger",
    // },
    {
      title: "Pending Email",
      iconClass: "email",
      total: props?.data?.email_count ? props?.data?.email_count : 0,
      // average: "0%",
      badgecolor: "warning",
    },
    {
      title: "Pending sms",
      iconClass: "message-processing",
      total: props?.data?.sms_count ? props?.data?.sms_count : 0,
      // average: "+89%",
      badgecolor: "info",
    },
  ]

  return (
    <React.Fragment>
      <MetaTags>
        <title>Dashboard - Admin | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        {/*mimi widgets */}
        <Miniwidget reports={reports} />
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <ActiveCount />
          </div>
          <div className="col-lg-6 col-sm-12">
            {/* <UploadCSV /> */}
          </div>
        </div>
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.DashboardCountData.items,
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(AdminDashboard)
