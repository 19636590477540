import React, { useState } from "react"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import { IconButton } from "rsuite"
import DrwerBar from "components/Common/DrwerBar"
// import { ApiCall } from "common/ActionApiCall/ApiCall"
// import { ShowNotification } from "common/ShowNotification"
import VisibleIcon from "@rsuite/icons/Visible"

function NotesAttachment(props) {
  const [openViewNotesDrawer, setOpenViewNotesDrawer] = useState(false)
  const [request, setRequest] = useState({
    token: props?.token,
    id: props?.id,
    folder: "notesfile",
  })
  const handleViewNotesDrawer = value => {
    setOpenViewNotesDrawer(true)
  }

  const handleClose = () => {
    openViewNotesDrawer && setOpenViewNotesDrawer(false)
  }

  const files = JSON.parse(props?.attachment)

  return (
    <>
      {openViewNotesDrawer && (
        <DrwerBar
          open={openViewNotesDrawer}
          setOpen={setOpenViewNotesDrawer}
          title="View Notes Attachment"
          body="ViewAttachment"
          subDataFromHtml={files}
          size="xs"
          view={true}
          state={request}
          setState={setRequest}
          onCancel={handleClose}
        />
      )}
      &nbsp;
      <WhisperTooltip
        placement="top"
        trigger="hover"
        tooltipMsg="View Notes Attachment"
      >
        <IconButton
          size="xs"
          appearance="primary"
          color="green"
          icon={<VisibleIcon />}
          onClick={() => handleViewNotesDrawer(props?.id)}
        />
      </WhisperTooltip>
    </>
  )
}

export default NotesAttachment
