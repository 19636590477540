import React from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, CardHeader, Container } from "reactstrap"
import { Col, Grid, Row } from "rsuite"
import "./terms.scss"

function Terms() {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Terms of Use | Incase360</title>
      </MetaTags>
      <div className="banner">
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none container"
          aria-hidden="true"
        >
          <Grid fluid>
            <Row className="show-grid text-center">
              <Col xs={24} sm={24} md={24}>
                <h2>
                  <b>Terms of Use</b>
                </h2>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
      <Container className="mt-3 terms">
        <Row className="justify-content-center">
          <Col md={24} lg={24} xl={24}>
            <Card className="overflow-hidden">
              {/* <CardHeader className="text-center">
                <h3>
                  <b>Introduction</b>
                </h3>
              </CardHeader> */}
              <CardBody className="p-4 body">
                <p>
                  Incase360 is a software platform that enables creation of
                  customized templates, automation of communication workflow,
                  serving and tracking of communications digitally and
                  physically, recording and analysing responses and negotiating
                  terms for resolution (collectively referred to as “Services”).
                  This website/ online portal and access to any of the services
                  offered by Incase360 is subject to these terms and conditions.
                  These Terms of Use (“Terms” or “Agreement”) is an
                  electronic record in the form of an electronic contract formed
                  under the Information Technology Act, 2000, and the rules made
                  thereunder. The terms “we” / “us” / “our” / “platform” /
                  “Incase360”, individually and collectively, refer to{" "}
                  <a href="https://www.incase360.com">
                    https://www.incase360.com/
                  </a>
                  ; and the terms “you” / “your” / “yourself” / “user” refer to
                  any legal person or entity accessing the platform who is
                  competent to enter into binding contracts, as per the
                  provisions of the Indian Contract Act, 1872. You are solely
                  responsible for understanding and complying with all
                  applicable rules, regulations and laws that may be applicable
                  in your use of the platform / Services.
                </p>
                <p>
                  Please read the Terms carefully. By using the platform, you
                  indicate that you understand, agree and consent to the Terms.
                  If you do not agree with the Terms, please do not use the
                  platform. The user expressly agrees that we retain the sole
                  and exclusive right to amend or modify the Terms and the{" "}
                  <a href="https://www.incase360.com/privacy-policy">
                    Privacy Policy
                  </a>{" "}
                  (“Policy”) without any prior permission or intimation to the
                  user, and the user expressly agrees that any such amendments
                  or modifications shall come into effect immediately.
                </p>
                <p>
                  Your use of the platform is governed by the Terms and the
                  Policy. Each time you use the platform, the current version of
                  the Terms will govern your use. Accordingly, each time you use
                  our platform, you should check the date at the bottom of this
                  Agreement’s page to be sure that you’ve read the most recent
                  version.
                </p>
                <p>
                  You unequivocally agree that the Terms and the Policy
                  constitute a legally binding agreement between you and us, and
                  that you shall be subject to the terms applicable to any
                  service that is provided by the platform, and that the same
                  shall be deemed to be incorporated into the terms and shall be
                  treated as part and parcel of the same. You acknowledge and
                  agree that no physical or electronic signature or express act
                  is required to make the Terms and the Policy binding on you,
                  and that your act of visiting any part of the platform
                  constitutes your full and final acceptance of the Terms and
                  the Policy.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Disclaimer</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  The Company is not a law firm and does not provide legal
                  advice. Services provided or use of any material provided by
                  the Company is not a substitute for legal advice. Only a legal
                  practitioner can provide legal advice. A legal practitioner
                  should be consulted for any legal advice or matter. No
                  attorney-client relationship is created by receiving services
                  or use of materials provided by us.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Eligibility</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  The Services provided through the platform are available only
                  to persons who are competent to enter into a legally binding
                  contract under the Indian Contract Act, 1872. The platform
                  does not cater to individuals less than 18 years of age. By
                  accessing or using the platform, you represent that you are 18
                  years or older. In the case of a minor (under 18 years of
                  age), a parent or guardian must accept the Terms and guide all
                  consultations under the Services offered.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Registration of User for Availing Services</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  In order to avail our Services, you need to register on
                  Incase360 by creating an account by providing your details,
                  including but not limited to first name, last name, office or
                  residential address, nationality, e-mail address and mobile
                  number (individually and collectively referred as “Personal
                  Information”), on the ‘Login / Create Account’ page of the
                  platform. You are required to provide information about
                  yourself that is true, accurate, current and complete in all
                  respects. Should any of your Personal Information change, do
                  notify us via e-mail at{" "}
                  <a href="mailto:info@incase360.com">info@incase360.com</a>
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>User Account, Password and Security</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  Once you complete the registration process, you are
                  responsible for maintaining the confidentiality of your
                  account’s username and password and are fully responsible for
                  all activities that occur under your account. You shall
                  immediately notify Incase360 via email at{" "}
                  <a href="mailto:info@incase360.com">info@incase360.com</a>{" "}
                  with full details if: (a) you discover or suspect unauthorized
                  access to your account; (b) you notice discrepancies that
                  might be attributable to unauthorized access; (c) you forget
                  your username and/or password; or (d) you discover a security
                  flaw on the platform.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Communication</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  By providing your Personal Information to us, you agree that
                  we may send you informational / promotional text (SMS)
                  messages and e-mail notifications. You may opt-out of
                  receiving informational / promotional text (SMS) messages or
                  e-mail notification from us at any time by intimating{" "}
                  <a href="mailto:info@incase360.com">info@incase360.com</a>{" "}
                  indicating that you no longer wish to receive such messages,
                  along with your e-mail address and the phone number of the
                  mobile device receiving the messages.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Payment Terms</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  1. We will not assume liability for any loss caused to users
                  due to any payment issues arising out of online transactions.
                </p>
                <p>
                  2. The payment for all Services shall be made mandatorily in
                  Indian National Rupees which are acceptable in the Republic of
                  India.
                </p>
                <p>
                  3. You warrant that in case you are making the payment by
                  credit card, (i) any credit information you supply is true and
                  complete; (ii) charges incurred by you will be honoured by
                  your credit card company. In a credit card transaction, you
                  must use your own credit card. We will not be liable for any
                  credit card fraud. The liability of usage of a fraudulent card
                  will be on person who indulges in such activity and the onus
                  to ‘prove otherwise’ shall be exclusively on such person.
                </p>
                {/* <p>
                  <dl>
                    <dt>
                      4. You represent and warrant that if you are purchasing
                      something from us, that you will have the following
                      options of payment:
                    </dt>
                    <dd>i. Credit Card</dd>
                    <dd>ii. Debit Card </dd>
                    <dd>iii. Net Banking</dd>
                  </dl>
                </p> */}
                <p>
                  4. We reserve the right to refuse to process transactions by
                  buyers with a prior history of questionable charges including
                  without limitation breach of any agreements by buyer with us
                  or breach / violation of any law or any charges imposed by
                  issuing bank or breach of any policy.
                </p>
                {/* <p>
                  6. We reserve the right to refuse to process transactions by
                  buyers with a prior history of questionable charges including
                  without limitation breach of any agreements by buyer with us
                  or breach / violation of any law or any charges imposed by
                  issuing bank or breach of any policy.
                </p>
                <p>
                  7. All valid credit/debit card and other payment instruments
                  are processed using a trusted payment gatewayor appropriate
                  payment system infrastructure, and the same will also be
                  governed by the terms and conditions agreed to between the
                  buyer and the respective issuing bank and payment instrument
                  issuing company.
                </p>
                <p>
                  8. All online bank transfers from valid bank accounts are
                  processed using the payment gateway provided by the respective
                  issuing bank which supports payment facility to provide these
                  services to the users. All such online bank transfers on
                  payment facility are also governed by the terms and conditions
                  agreed to between buyer and the respective issuing bank.
                </p> */}
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Refund and Cancellation Policy</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  We strive to ensure that the Services you avail through the
                  platform are to your full satisfaction and are the best in the
                  industry. However, after placing an order, if you wish to
                  cancel the order, you shall send an email to{" "}
                  <a href="mailto:info@incase360.com">info@incase360.com</a>{" "}
                  along with the details of the order that needs to be cancelled
                  and mention a valid reason for such cancellation. The order
                  can be cancelled at any time, however, the fees paid for the
                  Servicesare non-refundable. Refunds cannot be issued under any
                  circumstances, unless the order is not processed after
                  purchase or any of the Servicesare rejected outright by us.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Privacy Policy</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  Use of the platform is governed by our{" "}
                  <a href="https://www.incase360.com/privacy-policy">Policy</a>.
                  Please note that the Policy contains important terms,
                  conditions, and declarations relating to any information,
                  including sensitive personal data or information as defined in
                  the Information Technology (Reasonable security practices and
                  procedures and sensitive personal data or information) Rules,
                  2011, that constitute your consent to the manner in which such
                  information and sensitive personal data and information may be
                  accessed, used, exploited, and stored by the Firm, and your
                  use of the platform constitutes an acceptance of such terms,
                  conditions, and declarations.We encourage you to read and
                  review the terms of the Policy closely.
                </p>
              </CardBody>
              {/* <CardHeader className="text-center">
                <h3>
                  <b>Limited Use</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  You agree and undertake not to reverse engineer, modify, copy,
                  distribute, transmit, display, perform, reproduce, publish,
                  license, create derivative works from, transfer, or sell any
                  information obtained from the platform. Limited reproduction
                  and copying of the content of the platform is permitted
                  provided that our name is stated as the source and our prior
                  written permission is sought. For the removal of doubt, it is
                  clarified that unlimited or wholesale reproduction, copying of
                  the content for commercial or non-commercial purposes and
                  unwarranted modification of data and information within the
                  content of the platform is not permitted.
                </p>
              </CardBody> */}
              <CardHeader className="text-center">
                <h3>
                  <b>User Conduct and Rules</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  You agree and undertake to use the platform and the services
                  only to share messages and material that is proper. By way of
                  example, and not as a limitation, you agree and undertake that
                  when using any Services or the platform, you will not:
                </p>
                <p>
                  1. defame, abuse, harass, stalk, threaten or otherwise violate
                  the legal rights of others;
                </p>
                <p>
                  2. publish, post, upload, distribute or disseminate any
                  inappropriate, profane, defamatory, pornographic, paedophilic,
                  libelous, hateful, ethnically objectionable, infringing,
                  obscene, indecent or unlawful topic, name, material or
                  information or use the Services to transmit or store libelous,
                  infringing or unlawful or tortious material or store/transmit
                  any material in violation of third party rights including
                  privacy rights or threatens the unity, integrity, defence,
                  security or sovereignty of India or friendly relations with
                  foreign states or public order or causes incitement to the
                  commission of any cognizable offence or prevents investigation
                  of any offence or is insulting other nation;
                </p>
                <p>
                  3. violate any applicable laws or regulations for the time
                  being in force in or outside India or any code of conduct or
                  other rules and guidelines;
                </p>
                <p>
                  4. modify, copy or create any derivative works based on the
                  Services, features, integrations, user interfaces and/or
                  graphics;
                </p>
                <p>
                  5. license, sublicense, sell, lease, transfer, assign,
                  distribute, time share, offer in service bureau or otherwise
                  make available or grant any rights to the Services to any
                  third party, other than as explicitly permitted hereunder;
                </p>
                <p>
                  6. use or access the Services to build competitive products or
                  services;
                </p>
                <p>
                  7. introduce any code that contains software viruses or any
                  other computer codes, files or programs designed to interrupt,
                  destroy or limit the functionality of any computer resource or
                  introduce any kind of malicious code into the Platform,
                  hosting server or network of Incase360 or upload any materials
                  which tamper with the security features of the Services;
                </p>
                <p>
                  8. upload, display, modify, publish, transmit, update, share
                  any information that infringes any patent, trademark,
                  copyright or other proprietary rights whatsoever or violates
                  any applicable laws. You agree to use the Services strictly in
                  accordance with the terms herein and applicable laws and also
                  agree that any breach of these terms shall constitute a
                  material breach.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Warranty and Representation</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  You hereby warrant that you are capable of agreeing to the
                  Terms and that this Agreement is not in contravention to any
                  rights of any party with whom you have any agreements, at any
                  time prior to your acceptance of the Terms.{" "}
                </p>
                <p>
                  You agree to provide and continue to provide all details about
                  yourself as may be required by Incase360, including but not
                  restricted to Personal Information, and state that all
                  details, information and facts represented to us are true,
                  complete and accurate. You also agree to procure all consents
                  and approvals of third parties (including as per applicable
                  laws) as necessary, before you submit, upload, provide any
                  information/ materials to Incase360 for the purposes of
                  availing the Services and accessing the portal and shall fully
                  indemnify Incase360 against any third-party claims in this
                  regard.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>No Agency or Partnership</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  No agency, partnership, joint venture, or employment is
                  created as a result of this Agreement or your use of any part
                  of the platform.
                </p>
              </CardBody>
              {/* <CardHeader className="text-center">
                <h3>
                  <b>Prohibited Activity</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  You expressly agree that you are prohibited from hosting,
                  displaying, uploading, modifying, publishing, transmitting,
                  updating or sharing any information that —
                </p>
                <p>
                  a) belongs to another person and to which the user does not
                  have any right to;
                </p>
                <p>
                  b) is grossly harmful, harassing, blasphemous defamatory,
                  obscene, pornographic, paedophilic, libellous, invasive of
                  another's privacy, hateful, or racially, ethnically
                  objectionable, disparaging, relating or encouraging money
                  laundering or gambling, or otherwise unlawful in any manner
                  whatever;
                </p>
                <p>c) harm minors in any way;</p>
                <p>
                  d) infringes any patent, trademark, copyright or other
                  proprietary rights;
                </p>
                <p>e) violates any law for the time being in force;</p>
                <p>
                  {" "}
                  f) deceives or misleads the addressee about the origin of such
                  messages or communicates any information which is grossly
                  offensive or menacing in nature;
                </p>
                <p> g) impersonate another person;</p>
                <p>
                  {" "}
                  h) contains software viruses or any other computer code, files
                  or programs designed to interrupt, destroy or limit the
                  functionality of any computer resource;{" "}
                </p>
                <p>
                  i) threatens the unity, integrity, defence, security or
                  sovereignty of India, friendly relations with foreign states,
                  or public order or causes incitement to the commission of any
                  cognizable offence or prevents investigation of any offence or
                  is insulting any other nation.
                </p>
              </CardBody> */}

              <CardHeader className="text-center">
                <h3>
                  <b>Limitation of Liability</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  You acknowledge and agree that all Services (including portal/
                  website) are provided on an as-is and as-available basis and
                  that the use of the services shall be at the sole risk of the
                  user. To the fullest extent permitted by applicable law,
                  Incase360, our affiliates and respective officers and
                  licensors disclaim all warranties whether express or implied,
                  in connection with the Services. To the fullest extent
                  permitted by applicable law, Incase360 make no warranties or
                  representations that the Services have been and will be
                  provided with due skill, care and diligence or about the
                  accuracy or completeness of the Services’ content and assumes
                  no responsibility for any (i) errors, mistakes, or
                  inaccuracies of content, (ii) personal injury or property
                  damage, of any nature whatsoever, resulting from your access
                  to and use of the services, (iii) any unauthorized access to
                  or use of our servers and/or any and all personal information
                  stored therein, (iv) any interruption or cessation of
                  transmission to or from the services, (v) any bugs, viruses,
                  trojan horses, or the like which may be transmitted to or
                  through the services through the actions of any third party,
                  (vi) any loss of your data or content from the services and/or
                  (vii) any errors or omissions in any content or for any loss
                  or damage of any kind incurred as a result of the use of any
                  content posted, emailed, transmitted, or otherwise made
                  available via the Services. In no event shall the aggregate
                  liability of Incase360 arising out of or related to these
                  terms/ our services, exceed the total amount paid by the user
                  hereunder for the Services giving rise to the liability in the
                  twelve months preceding the first incident out of which the
                  liability arose. The foregoing limitation will apply whether
                  an action is in contract or tort and regardless of the theory
                  of liability.
                </p>
                <p>
                  We do not warrant that your access to the platform will be
                  uninterrupted or error-free, that defects will be corrected,
                  or that the platform or the server on which the platform is
                  hosted is free of viruses or other harmful components. Access
                  to and use of the platform and the information provided herein
                  is at your own risk and we do not undertake any accountability
                  for any irregularities, viruses or damage to any computer or
                  device that results from accessing, availing or downloading of
                  any information from the platform. We do not warrant or make
                  any representations regarding the use of or any consequences
                  resulting from the use of any product and / or service
                  purchased in terms of its compatibility, correctness,
                  accuracy, and reliability or otherwise. You assume total
                  responsibility and risk for your use of the platform and all
                  platform-related services.
                </p>
                <p>
                  In no event will either party have any liability arising out
                  of or related to the terms herein for any lost profits,
                  revenues, goodwill, or indirect, special, incidental,
                  consequential, business interruption or punitive damages,
                  whether an action is in contract or tort and regardless of the
                  theory of liability, even if a party has been advised of the
                  possibility of such damages or if a party’s remedy otherwise
                  fails of its essential purpose. The foregoing disclaimer will
                  not apply to the extent prohibited by law. The possibility
                  exists that the platform could include inaccuracies or errors.
                  Additionally, the possibility exists that unauthorized
                  additions, deletions or alterations could be made by third
                  parties to the platform. Although we attempt to ensure the
                  highest level of integrity, correctness and authenticity of
                  the platform, we make no guarantees whatsoever as to its
                  completeness, correctness or accuracy. In the event that any
                  inaccuracy arises, please inform us at{" "}
                  <a href="mailto:info@incase360.com">info@incase360.com</a> so
                  that it can be corrected.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Usage Terms</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  The information, materials, Services available on the platform
                  may inadvertently include inaccuracies, typographical errors,
                  or outdated information. We are not responsible for and shall
                  not be bound to honor typographical or pricing errors on the
                  platform. We reserve the right to refuse or cancel orders at
                  any time, including but not limited to the orders that contain
                  incorrect prices or product descriptions, orders in which we
                  believe the buyer has violated applicable laws or the terms of
                  this Agreement, orders that we believe are harmful to us or
                  orders that we believe are fraudulent or based on illegal,
                  fraudulent or deceitful use / furnishing of information or
                  based on false information.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Intellectual Property Rights</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  Subject to the limited rights expressly granted hereunder,
                  Incase360, our affiliates, licensors, agents and
                  representatives reserve all right, title and interest in and
                  to the Services and/or Portal (including tools / application
                  programming interface (API) access of which is provided to you
                  pursuant to the Services herein), including all related
                  intellectual property rights. Incase360 shall remain the sole
                  and exclusive owner of the Services and Portal (associated
                  tools / API) and all intellectual property rights associated
                  therewith. No rights are granted to User hereunder other than
                  as expressly set forth herein.
                </p>
                <p>
                  You have the limited right to access the Services as per the
                  terms herein. You shall ensure that you have all necessary
                  rights and applicable consents as required (including those
                  required under applicable laws), to upload or submit any
                  information (including but not limited to information or
                  materials with respect to third parties) on the Portal for
                  availing/ accessing the Services. You grant Incase360 a
                  royalty-free, worldwide, transferable, sub-licensable,
                  irrevocable and perpetual license to incorporate into the
                  Services, any suggestions, enhancement requests,
                  recommendations or other feedback received from you. Further,
                  you also acknowledge and agree that Incase360 engages with
                  third parties for providing its Services and to this extent,
                  you agree to all applicable terms of service of such third
                  parties.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Copyrighted Material for Limited Use</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  This platform contains graphics, photographs, images, document
                  layouts, artwork, text, fonts, software tools, and other
                  information (referred to herein as “Content”). This platform
                  and all Content are the copyrighted property of theFirm,
                  and/or its subsidiaries, or the copyrighted property of
                  parties from whom we have licensed such property. All rights
                  in this platform and its Content are reserved worldwide. It is
                  strictly prohibited to retain, copy, distribute, publish, or
                  use any portion of the Content except as expressly allowed in
                  herein. We reserve the right to add to, delete from, or modify
                  any part of Content at any time without prior notice.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Links to Third Party Sites</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  Third party content may appear on the platform or may be
                  accessible via links. We are not responsible for and assume no
                  liability for any mistakes, misstatements of law, defamation,
                  slander, libel, omissions, falsehood, obscenity or profanity
                  in the statements, opinions, representations or any other form
                  of information contained in any third-party content appearing
                  on the platform. You understand that the information and
                  opinions in the third-party content is neither endorsed by nor
                  does it reflect our belief.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Lawyer Ethics Notice</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  If you are an attorney/lawyer participating in any aspect of
                  the Portal, including but not limited to message(s), chats
                  (text or voice), submissions or e-mails, you acknowledge that
                  the Bar Council Rules of Professional Conduct in India
                  ("Rules") apply to all aspects of your participation and that
                  you will abide by these Rules. These Rules include, but are
                  not limited to, the rules relating to advertising,
                  solicitation of clients, unauthorized practice of law, and
                  misrepresentations of fact. Incase360 disclaims all
                  responsibility for your compliance with these Rules.{" "}
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>No Guarantee</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  You acknowledge and agree that nothing mentioned on the
                  platform shall be construed as a guarantee or promise of the
                  results or effectiveness of the Services rendered. We assure
                  you that we will provide the Services in a professional
                  manner, in good faith and in accordance with all the relevant
                  national, state and local laws. Nonetheless, we do not promise
                  or guarantee any results.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Indemnity</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  You agree to indemnify, defend and hold harmless us, our
                  contractors, representatives and affiliates, from and against
                  any and all losses, liabilities, claims, damages, costs and
                  expenses (including legal fees and disbursements in connection
                  therewith and interest chargeable thereon) that arise from or
                  result out of, or may be payable by virtue of, (i) your use of
                  the portal and/or the Services; (ii) your violation of the
                  Terms of Use or any applicable law; (iii) violation of third
                  party rights.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Notices</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  By using the platform, you consent to electronically receive
                  from us any communications including but not limited to
                  notice, instructions, communication, notification, order,
                  award, agreements, legally required disclosures or other
                  information.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Breach of Terms</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  We reserve the right to investigate complaints or reported
                  violations of this Agreement and to take any action we deem
                  appropriate including but not limited to reporting any
                  suspected unlawful activity to law enforcement officials,
                  regulators, or other third parties and disclosing any
                  information necessary or appropriate to such persons or
                  entities, subject to confidentiality obligations of the Firm,
                  relating to e-mail addresses, usage history, posted materials,
                  IP addresses and traffic information. We reserve our right to
                  seek all remedies available at law and in equity for
                  violations of the terms of this Agreement, including but not
                  limited to the right to block access from a particular
                  internet address to the platform and Services.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Confidentiality</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  We make every effort to maintain the confidentiality of
                  Personal Information, Content or any other information
                  submitted by you to our system and not disclose it to any
                  third party. We have implemented appropriate managerial,
                  technical, operational and physical procedures to safeguard
                  your Personal Information, Content and any other information
                  against loss, misuse or theft, as well as against destruction,
                  alteration, disclosure and unauthorized access.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Termination of Access</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  The terms of this Agreement are effective until terminated.
                  You agree that we may terminate your access to the platform at
                  any time for any reason without prior notice or liability.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Entire Agreement</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  The Terms, the Policy and any other relevant terms and
                  conditions, policies and notices which may be applicable to a
                  specific section or module of this platform will together
                  constitute the entire and whole agreement between the you and
                  us, and together are intended as a complete and exclusive
                  statement of the terms of this Agreement. This Agreement shall
                  supersede all other communications between you and us with
                  respect to the subject matter hereof and supersedes and
                  replaces all prior or contemporaneous understandings or
                  agreements, written or oral, regarding such subject matter. If
                  at any time you find the terms of this Agreement unacceptable
                  or if you do not agree with them, kindly refrain from using
                  the platform.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Severability</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  If at any time any provision of this Agreement is or becomes,
                  or is adjudicated by any court of competent jurisdiction or
                  public authority to be illegal, invalid or unenforceable in
                  any respect under the law of any jurisdiction, this shall not
                  affect or impair the legality, validity or enforceability of
                  any other provision of this Agreement.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Waiver</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  We will not be considered to have waived any of the rights or
                  remedies described in this Agreement unless the waiver is in
                  writing and signed by us. No delay or omission by us in
                  exercising our rights or remedies will impair or be construed
                  as a waiver. Any single or partial exercise of a right or
                  remedy will not preclude further exercise of any other right
                  or remedy. Our failure to enforce the strict performance of
                  any provision of this Agreement will not constitute a waiver
                  of our right to subsequently enforce such provision or any
                  other provisions of this Agreement.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Force Majeure</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  Incase360 shall not be liable to you, to the extent, that the
                  performance or delay in performance of any of its obligations
                  under these terms is caused by a force majeure event including
                  applicable laws and regulations, government rules and
                  regulations, fires, floods, explosions, epidemics, accidents,
                  acts of God, wars, riots, strikes, lockouts, or other
                  concerted acts of workmen, acts of Government, hacking,
                  unanticipated technological or natural interference or loss or
                  damage to satellites or loss of satellite linkage or any other
                  data communications linkage or loss of connectivity or any
                  other irresistible force or compulsion.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Dispute Resolution</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  In the event that any disputes, differences, claims and
                  questions whatsoever arising out of or in connection with or
                  incidental to or touching this Agreement or the construction
                  or application thereof or any clauses or thing herein
                  contained or in respect of any account and the duties,
                  responsibilities and obligations of either party hereunder or
                  as to any act or omission of any party or as to any other
                  matter in anywise relating to these presents or the rights,
                  duties and liabilities of either party under these presents
                  shall be resolved and settled by arbitration, and administered
                  electronically, in accordance with the Indian Arbitration and
                  Conciliation Act, 1996 or any statutory modification or
                  re-enactment thereof for the time being in force. The Parties
                  agree that the aforesaid proceedings shall be carried out by a
                  sole arbitrator. The juridical seat of the arbitration shall
                  be at Mumbai, India. The language of the arbitration shall be
                  English. The law governing the arbitration proceedings shall
                  be Indian law. Each Party shall bear its own cost of
                  arbitration. In the event the arbitration proceedings cannot
                  be administered electronically, the proceedings shall be
                  conducted physically, and the venue of the proceedings shall
                  be Mumbai, India.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Governing Law and Jurisdiction</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  This Agreement shall be governed by and construed in
                  accordance with law prevalent in India. You hereby agree that
                  the courts having jurisdiction in Mumbai shall have exclusive
                  jurisdiction to hear and decide any suit, action or
                  proceedings and to resolve any disputes, arising in connection
                  with this Agreement.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Assignment</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  You may not assign your rights under these Terms to any other
                  party without Incase360’s express written consent. Incase360
                  may assign its rights under these Terms without condition.
                  These Terms will be binding upon and will inure to the benefit
                  of the parties and their permitted assigns.{" "}
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Section Headings</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  The section headings in this Agreement are for convenience
                  only and have no legal or contractual effect.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Contact Information</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  If you wish to contact us for any reason regarding the terms
                  of this Agreement, kindly e-mail it to us at the following
                  address:{" "}
                  <a href="mailto:info@incase360.com">info@incase360.com</a>{" "}
                </p>
                <br />
                <br />
                <p>Last updated on 1st June 2023.</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Terms
