import PropTypes from "prop-types"
import React, { Component } from "react"

import { connect } from "react-redux"
import { Container } from "reactstrap"
import { Redirect, withRouter } from "react-router-dom"
// import {
// 	changeLayout,
// 	changeSidebarTheme,
// 	changeSidebarType,
// 	changeTopbarTheme,
// 	changeLayoutWidth,
// } from "../../store/actions";

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
// import Rightbar from "../CommonForBoth/Rightbar";
//Import Breadcrumb
import Breadcrumb from "../Common/Breadcrumb"

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    }
    // this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  render() {
    return (
      <React.Fragment>
        {parseInt(this.props.role) === 0 ? (
          <div id="layout-wrapper">
            <Header />
            <Sidebar theme="light" type="condensed" isMobile="false" />
            <div className="main-content">
              <div className="page-content">
                <Container fluid>
                  <Breadcrumb />
                  {this.props.children}
                  {/* render Footer */}
                  {/* <Footer /> */}
                </Container>
              </div>
            </div>
            <Footer />
          </div>
        ) : (
          <Redirect to="/login" />
        )}
      </React.Fragment>
    )
  }
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
}

// const mapStatetoProps = (state) => {
// 	return {
// 		...state.Layout,
// 	};
// };
const mapStateToProps = state => ({
  role: state.Login.role,
})
export default withRouter(connect(mapStateToProps)(Layout))
