const data = JSON.parse(localStorage.getItem("data"))

const initialState = {
  items: [],
  loading: false,
  error: null,
};

export const Profile = (state = initialState, action) => {
  switch (action.type) {
    case "getUserDataSuccess":
      return {
        ...initialState,
        items: action.data,
        loading: true,
      }
    case "getUserDataFailure":
      return {
        ...initialState,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}
