import React, { useEffect, useState } from "react"
import { Row, FormGroup, Col } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { connect } from "react-redux"
import { ShowNotification } from "common/ShowNotification"
import { Divider } from "rsuite"

function WhatsAppIDForm(props) {
  const [action, setAction] = useState(false)
  const [defaultValues, setDefaultValues] = useState({ token: props?.token })
  useEffect(() => {
    ApiCall(
      { token: props?.token },
      "updateNotificationTemplate",
      false,
      result => {
        result?.data?.responseData?.map((value, index) => {
          setDefaultValues(prev => ({
            ...prev,
            [value?.name]: value?.fieldValue,
          }))
        })
      }
    )
  }, [action])

  const handleUpdateTemp = (event, values) => {
    const request = {
      ...values,
      token: props?.token,
      updateButton: true,
    }
    // console.log(request)
    ApiCall(request, "updateNotificationTemplate", false, result => {
      if (result?.data?.response === "success") {
        ShowNotification(result?.data?.message, "success")
        setAction(!action)
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  return (
    <div>
      <div
        style={{
          backgroundColor: "#131826",
          padding: "1em 2em",
          borderRadius: "0.5em",
        }}
      >
        <h5>Update WhatsApp Template</h5>
      </div>
         <Divider style={{marginTop:"40px"}}>Panel 1</Divider>
      <AvForm
        className="mt-3 mb-3"
        onValidSubmit={(e, v) => {
          handleUpdateTemp(e, v)
        }}
      >
        <Row>
          <Col lg="6" md="6">
            <FormGroup className="mt-2">
              <AvField
                name="waLegalTempId_1"
                label="WhatsApp Template ID Legal (Text)"
                className="form-control"
                type="text"
                value={defaultValues?.waLegalTempId_1}
                required
              />
            </FormGroup>
          </Col>
          <Col lg="6" md="6">
            <FormGroup className="mt-2">
              <AvField
                name="waAttachLegalTempId_1"
                label="WhatsApp Template ID Legal (PDF)"
                className="form-control"
                type="text"
                value={defaultValues?.waAttachLegalTempId_1}
                required
              />
            </FormGroup>
          </Col>
          <Col lg="6" md="6">
            <FormGroup className="mt-2">
              <AvField
                name="waComTempId_1"
                label="WhatsApp Template ID Communication (Text)"
                className="form-control"
                type="text"
                value={defaultValues?.waComTempId_1}
                required
              />
            </FormGroup>
          </Col>
          <Col lg="6" md="6">
            <FormGroup className="mt-2">
              <AvField
                name="waAttachComTempId_1"
                label="WhatsApp Template ID Communication (PDF)"
                className="form-control"
                type="text"
                value={defaultValues?.waAttachComTempId_1}
                required
              />
            </FormGroup>
          </Col>
          <Divider style={{marginTop:"40px"}}>Panel 2</Divider>

          <Col lg="6" md="6">
            <FormGroup className="mt-2">
              <AvField
                name="waLegalTempId_2"
                label="WhatsApp Template ID Legal (Text)"
                className="form-control"
                type="text"
                value={defaultValues?.waLegalTempId_2}
                required
              />
            </FormGroup>
          </Col>
          <Col lg="6" md="6">
            <FormGroup className="mt-2">
              <AvField
                name="waAttachLegalTempId_2"
                label="WhatsApp Template ID Legal (PDF)"
                className="form-control"
                type="text"
                value={defaultValues?.waAttachLegalTempId_2}
                required
              />
            </FormGroup>
          </Col>
          <Col lg="6" md="6">
            <FormGroup className="mt-2">
              <AvField
                name="waComTempId_2"
                label="WhatsApp Template ID Communication (Text)"
                className="form-control"
                type="text"
                value={defaultValues?.waComTempId_2}
                required
              />
            </FormGroup>
          </Col>
          <Col lg="6" md="6">
            <FormGroup className="mt-2">
              <AvField
                name="waAttachComTempId_2"
                label="WhatsApp Template ID Communication (PDF)"
                className="form-control"
                type="text"
                value={defaultValues?.waAttachComTempId_2}
                required
              />
            </FormGroup>
          </Col>

          <Divider style={{marginTop:"40px"}}>Panel 3</Divider>
             
          <Col lg="6" md="6">
            <FormGroup className="mt-2">
              <AvField
                name="waLegalTempId_3"
                label="WhatsApp Template ID Legal (Text)"
                className="form-control"
                type="text"
                value={defaultValues?.waLegalTempId_3}
                required
              />
            </FormGroup>
          </Col>
          <Col lg="6" md="6">
            <FormGroup className="mt-2">
              <AvField
                name="waAttachLegalTempId_3"
                label="WhatsApp Template ID Legal (PDF)"
                className="form-control"
                type="text"
                value={defaultValues?.waAttachLegalTempId_3}
                required
              />
            </FormGroup>
          </Col>
          <Col lg="6" md="6">
            <FormGroup className="mt-2">
              <AvField
                name="waComTempId_3"
                label="WhatsApp Template ID Communication (Text)"
                className="form-control"
                type="text"
                value={defaultValues?.waComTempId_3}
                required
              />
            </FormGroup>
          </Col>
          <Col lg="6" md="6">
            <FormGroup className="mt-2">
              <AvField
                name="waAttachComTempId_3"
                label="WhatsApp Template ID Communication (PDF)"
                className="form-control"
                type="text"
                value={defaultValues?.waAttachComTempId_3}
                required
              />
            </FormGroup>
          </Col>
          <Divider style={{marginTop:"40px"}}>Action</Divider>
          <Col lg="8" md="8">
            <FormGroup className="mt-2">
              <AvField
                name="waAutoResponse"
                label="WhatsApp Template Auto Response"
                className="form-control"
                type="text"
                value={defaultValues?.waAutoResponse}
                required
              />
            </FormGroup>
          </Col>
          <Col lg="4" md="4" className="mt-3">
            <button
              className="btn btn-primary w-md waves-effect waves-light mt-3"
              type="submit"
            >
              Update
            </button>
          </Col>
        </Row>
      </AvForm>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps)(WhatsAppIDForm)
