import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { CustomProvider, Checkbox } from "rsuite"
import { setBreadcrumbItems } from "../../../store/actions"
import OverdueTable from "./OverdueTable"
import BulkMenu from "../BulkMenu"
import { useParams } from "react-router-dom"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"
import DrwerBar from "components/Common/DrwerBar"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import FormGroup from "components/Common/FormGroup"
import FilterMenu from "../FilterMenu"
import NewOverdueTable from "./NewOverdueTable"

const UserOverdue = props => {
  const [checkedKeys, setCheckedKeys] = useState([])
  const [openSubMenu, setOpenSubMenu] = useState(false)
  const [openFilterMenu, setOpenFilterMenu] = useState(false)
  const [stateCsv, setStateCsv] = useState()
  const [openUploadCourierCSVDrawer, setOpenUploadCourierCSVDrawer] =
    useState(false)
  const [openUploadCourierPDFDrawer, setOpenUploadCourierPDFDrawer] =
    useState(false)
  const [modalShow, setModalShow] = useState({ show: false, msg: "" })

  const [downloadAs, setDownloadAs] = useState("notice_id")
  const [deliveryTracking, setDeliveryTracking] = useState("0")
  const [fromTo, setFromTo] = useState({
    rangeFrom: "",
    rangeTo: "",
    rangeExcept: "",
  })
  const params = useParams()
  const [filter, setFilter] = useState({
    batch: "",
    notesAdded: "",
    replyAdded: "",
    undeliveredWhatsapp: 0,
    undeliveredEmail: 0,
    undeliveredSms: 0,
    case_ref_id: "",
    submit: false,
    filterFrom: "",
    filterTo: "",
  })
  const [courierCsvRequest, setCourierCsvRequest] = useState({
    token: props?.token,
    noticeId: params?.id,
    courier_type: null,
    user_bulk_data: null,
  })

  const [courierPdfRequest, setCourierPdfRequest] = useState({
    token: props?.token,
    noticeId: params?.id,
    courier_type: null,
    user_bulk_data: null,
  })

  const breadcrumbItems = params?.userId
    ? [
        { title: "Incase360", link: "#" },
        { title: "Sub User", link: "/user/subuser" },
        {
          title: "Sub User Notice List",
          link: "/user/subuser/noticelist/" + params?.userId,
        },
        { title: "Sub User Overdue Notice Details", link: "#" },
      ]
    : [
        { title: "Incase360", link: "#" },
        { title: "Notice List", link: "/user/noticelist" },
        { title: "Overdue Notice Details", link: "#" },
      ]

  const handleUploadCourierCSVOpenDrawer = () => {
    setOpenUploadCourierCSVDrawer(true)
  }

  const handleUploadCourierPDFOpenDrawer = () => {
    setOpenUploadCourierPDFDrawer(true)
  }

  const handleCoourierCSVUpload = () => {
    // console.log(courierCsvRequest)
    courierCsvRequest?.courier_type &&
      courierCsvRequest?.user_bulk_data && [
        setModalShow({ show: true, msg: "Work in Progress." }),
        setOpenUploadCourierCSVDrawer(false),
        ApiCall(courierCsvRequest, "user/courier-upload", false, result => {
          if (result?.data?.response === "success") {
            setModalShow({ show: true, msg: result?.data?.message })
            ShowNotification(result?.data?.message, "success")
          } else {
            setModalShow({ show: true, msg: result?.data?.message })
            ShowNotification(result?.data?.message, "danger")
          }
          setOpenUploadCourierCSVDrawer(false)
        }),
      ]
  }

  const handleCoourierPDFUpload = () => {
    courierPdfRequest?.courier_type &&
      courierPdfRequest?.user_bulk_data && [
        setModalShow({ show: true, msg: "Work in Progress." }),
        setOpenUploadCourierPDFDrawer(false),
        ApiCall(courierPdfRequest, "user/courier-pdf-upload", false, result => {
          if (result?.data?.response === "success") {
            setModalShow({ show: true, msg: result?.data?.message })
            ShowNotification(result?.data?.message, "success")
          } else {
            ShowNotification(result?.data?.message, "danger")
            setModalShow({ show: true, msg: result?.data?.message })
          }
          setOpenUploadCourierPDFDrawer(false)
        }),
      ]
  }

  const handleBulkDownloadNotices = () => {
    // if()
    const request = {
      token: props?.token,
      all_ids: checkedKeys,
      mnid: params?.id,
      download_as: downloadAs,
      deliveryTracking: deliveryTracking,
      refrenceCsv: stateCsv,
      ...fromTo,
    }
    DownloadApiCall(request, "download/download-notice", "_self")
  }

  const handleBulkDownloadReport = () => {
    // if()
    const request = {
      token: props?.token,
      all_ids: checkedKeys,
      mnid: params?.id,
      refrenceCsv: stateCsv,
      ...fromTo,
    }
    DownloadApiCall(request, "download/download-track", "_self")
  }

  const handleBulkDownloadReportTesting = () => {
    // if()
    const request = {
      token: props?.token,
      all_ids: checkedKeys,
      mnid: params?.id,
      refrenceCsv: stateCsv,
      ...fromTo,
    }
    DownloadApiCall(request, "download/download-track-testing", "_self")
  }

  const handleBulkDownloadReplyAttchment = () => {
    // if()
    const request = {
      token: props?.token,
      all_ids: checkedKeys,
      mnid: params?.id,
      refrenceCsv: stateCsv,
      download_as: downloadAs,
      ...fromTo,
    }
    DownloadApiCall(request, "download/reply-attachment", "_self")
  }

  const handleBulkDownloadCourierReceipts = () => {
    // if()
    const request = {
      token: props?.token,
      all_ids: checkedKeys,
      mnid: params?.id,
      refrenceCsv: stateCsv,
      ...fromTo,
    }
    DownloadApiCall(request, "download/courier-receipts", "_self")
  }

  const handleBulkDownloadTrackingReports = () => {
    // if()
    const request = {
      token: props?.token,
      all_ids: checkedKeys,
      mnid: params?.id,
      refrenceCsv: stateCsv,
      ...fromTo,
    }
    DownloadApiCall(request, "download/delivery-tracking-report", "_self")
  }

  const bulkButton = [
    {
      id: 1,
      size: "xs",
      color: "blue",
      label: "Bulk Upload Courier Excel",
      onClick: handleUploadCourierCSVOpenDrawer,
    },
    {
      id: 2,
      size: "xs",
      color: "cyan",
      label: "Bulk Upload Courier PDFs",
      // display: checkedKeys.length === 0 && "d-none",
      onClick: handleUploadCourierPDFOpenDrawer,
    },
    {
      id: 3,
      size: "xs",
      color: "orange",
      label: "Bulk Download Notices",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "") ||
        stateCsv
          ? "d-block"
          : "d-none",
      onClick: handleBulkDownloadNotices,
    },
    // {
    //   id: 4,
    //   size: "xs",
    //   color: "violet",
    //   label: "Bulk Download Report",
    //   display:
    //     checkedKeys.length > 0 ||
    //     (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "") ||
    //     stateCsv
    //       ? "d-block"
    //       : "d-none",
    //   onClick: handleBulkDownloadReport,
    // },
    {
      id: 5,
      size: "xs",
      color: "violet",
      label: "Bulk Download Report",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "") ||
        stateCsv
          ? "d-block"
          : "d-none",
      // onClick: handleApproveOpenDrawer,
      onClick: handleBulkDownloadReportTesting,
    },
    {
      id: 6,
      size: "xs",
      color: "cyan",
      label: "Bulk Download Reply Attchment",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "") ||
        stateCsv
          ? "d-block"
          : "d-none",
      onClick: handleBulkDownloadReplyAttchment,
    },
    {
      id: 7,
      size: "xs",
      color: "green",
      label: "Bulk Download Courier Receipts",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "") ||
        stateCsv
          ? "d-block"
          : "d-none",
      onClick: handleBulkDownloadCourierReceipts,
    },
    {
      id: 8,
      size: "xs",
      color: "red",
      label: "Bulk Delivery Tracking Reports",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "") ||
        stateCsv
          ? "d-block"
          : "d-none",
      onClick: handleBulkDownloadTrackingReports,
    },
  ]

  const fromToInput = [
    {
      id: 1,
      name: "rangeFrom",
      placeholder: "From ex. IN12-1234",
      onChange: e => setFromTo({ ...fromTo, rangeFrom: e }),
    },
    {
      id: 2,
      name: "rangeTo",
      placeholder: "To ex. IN12-2345",
      onChange: e => setFromTo({ ...fromTo, rangeTo: e }),
    },
    {
      id: 3,
      name: "rangeExcept",
      placeholder: "Except ex. IN12-23,IN12-34,IN12-45",
      onChange: e => setFromTo({ ...fromTo, rangeExcept: e }),
    },
  ]

  const fromToDate = [
    {
      id: 1,
      name: "rangeDateFrom",
      placeholder: "From",
      onChange: e => setFilter({ ...filter, filterFrom: e }),
    },
    {
      id: 2,
      name: "rangeDateTo",
      placeholder: "To",
      min: filter?.filterFrom,
      onChange: e => setFilter({ ...filter, filterTo: e }),
    },
  ]

  const handleClose = () => {
    openUploadCourierCSVDrawer && [
      setOpenUploadCourierCSVDrawer(false),
      setCourierCsvRequest({
        ...courierCsvRequest,
        courier_type: null,
        user_bulk_data: null,
      }),
    ]
    openUploadCourierPDFDrawer && [
      setOpenUploadCourierPDFDrawer(false),
      setCourierPdfRequest({
        ...courierPdfRequest,
        courier_type: null,
        user_bulk_data: null,
      }),
    ]
  }

  useEffect(() => {
    props.setBreadcrumbItems(
      `${
        params?.userId ? "Sub User" : ""
      } Overdue Notice Details - ${props?.data?.noticeName?.replace(
        "notice_",
        ""
      )}`,
      breadcrumbItems
    )
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Notice Details - User | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        {modalShow && (
          <DrwerBar
            open={modalShow?.show}
            body="viewMsg"
            view={true}
            size="xs"
            subDataFromHtml={modalShow?.msg}
            onCancel={() => setModalShow({ show: false, msg: "" })}
          />
        )}
        {openUploadCourierCSVDrawer && (
          <DrwerBar
            open={openUploadCourierCSVDrawer}
            setOpen={setOpenUploadCourierCSVDrawer}
            title="Upload Courier Tracking Details"
            body="CourierCsv"
            size="xs"
            onClick={() => handleCoourierCSVUpload()}
            state={courierCsvRequest}
            setState={setCourierCsvRequest}
            onCancel={handleClose}
          />
        )}
        {openUploadCourierPDFDrawer && (
          <DrwerBar
            open={openUploadCourierPDFDrawer}
            setOpen={setOpenUploadCourierPDFDrawer}
            title="Upload Courier Tracking PDFs"
            body="CourierPdf"
            size="xs"
            onClick={() => handleCoourierPDFUpload()}
            state={courierPdfRequest}
            setState={setCourierPdfRequest}
            onCancel={handleClose}
          />
        )}
          <FilterMenu
            openFilterMenu={openFilterMenu}
            setOpenFilterMenu={setOpenFilterMenu}
            state={filter}
            setState={setFilter}
            mnid={params?.id}
            tab="overdue"
            fromToDate={fromToDate}
          />
        <NewOverdueTable
          checkedKeys={checkedKeys}
          setCheckedKeys={setCheckedKeys}
          setOpenSubMenu={setOpenSubMenu}
          filter={filter}
        />
        <BulkMenu
          openSubMenu={openSubMenu}
          setOpenSubMenu={setOpenSubMenu}
          bulkButton={bulkButton}
          state={downloadAs}
          setState={setDownloadAs}
          fromToInput={fromToInput}
          stateDelivery={deliveryTracking}
          setStateDelivery={setDeliveryTracking}
          setStateCsv={setStateCsv}
        />
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(UserOverdue)
