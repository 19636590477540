import React, { useEffect, useState } from "react"
import { Button, Col, Grid, Input, Row } from "rsuite"
import { connect } from "react-redux"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { Table } from "reactstrap"

function ActiveCount(props) {
  const [activeCountData, setActiveCountData] = useState({})
  const [request, setRequest] = useState({
    token: props?.token,
    fromDate: "",
    toDate: "",
  })

  const handleSearch = () => {
    request?.fromDate &&
      request?.toDate &&
      ApiCall(request, "activeCountFilter", false, result => {
        if (result?.data?.response === "success") {
          setActiveCountData(result?.data?.responseData)
        } else {
          setActiveCountData({})
        }
      })
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5>Active Count</h5>
      </div>
      <div className="card-Body m-2">
        <Grid fluid>
          <Row className="show-grid">
            <Col lg={9} md={9} sm={24} xs={24} className="mt-3">
              <span>From Date</span>
              <Input
                type="date"
                name="fromDate"
                id="fromDate"
                onChange={e => setRequest({ ...request, fromDate: e })}
                block
              />
            </Col>
            <Col lg={9} md={9} sm={24} xs={24} className="mt-3">
              <span>To Date</span>
              <Input
                type="date"
                name="toDate"
                id="toDate"
                min={request?.dateFrom}
                onChange={e => setRequest({ ...request, toDate: e })}
                block
              />
            </Col>
            <Col lg={6} md={6} sm={24} xs={24} className="mt-3">
              <Button
                appearance="primary"
                color="blue"
                style={{ marginTop: "1.2em" }}
                onClick={() => handleSearch()}
                block
              >
                Search
              </Button>
            </Col>
          </Row>
        </Grid>
        {activeCountData?.length > 0 && (
          <div
            className="table-responsive"
            style={{ fontSize: "1.2em", margin: "2em 1em" }}
          >
            <div style={{ maxHeight: 300, overflow: "scroll" }}>
              <Table size="sm" bordered>
                {activeCountData?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th style={{ padding: "0.3em 1.5em" }}>
                        {item?.notice_type}
                      </th>
                      <td style={{ padding: "0.3em" }}>{item?.activeCount}</td>
                    </tr>
                  )
                })}
              </Table>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps)(ActiveCount)
