import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"

import { getAllApproveUser, setBreadcrumbItems } from "../../../store/actions"
import AutoDownloadComponents from "./AutoDownloadComponents"
import { CustomProvider } from "rsuite"
import AutoDownloadTable from "./AutoDownloadTable"
//import UploadCSV from "./UploadCSV"

const AutoDownload = props => {
  const [apiAction, setApiAction] = useState({ loading: false, action: false })

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Auto Download", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Auto Download", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Auto Download - Admin | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        <AutoDownloadComponents
          apiAction={apiAction}
          setApiAction={setApiAction}
        />
        {/* <UploadCSV /> */}
        <AutoDownloadTable apiAction={apiAction} />
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.AllApproveUser.items,
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(AutoDownload)
