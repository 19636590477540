import CopyIcon from "@rsuite/icons/Copy"
import EditIcon from "@rsuite/icons/Edit"
import TagAuthorizeIcon from "@rsuite/icons/TagAuthorize"
import TrashIcon from "@rsuite/icons/Trash"
import VisibleIcon from "@rsuite/icons/Visible"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import SearchInput from "common/SearchInput"
import { ShowNotification } from "common/ShowNotification"
import { ShowSwal } from "common/ShowSwal"
import useWindowDimensions from "common/useWindowDimensions"
import DrwerBar from "components/Common/DrwerBar"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { CustomProvider, IconButton, Pagination, Table } from "rsuite"
import { getExistingNotice, getOffers } from "store/actions"

// import '@sweetalert2/themes-dark/dark.scss';
const { Column, HeaderCell, Cell } = Table

function ExistingNoticeTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const history = useHistory()
  const { height, width } = useWindowDimensions()
  const [openNoticeDraftDrawer, setOpenNoticeDraftDrawer] = useState(false)
  const [openNoticeBulkFormatDrawer, setOpenNoticeBulkFormatDrawer] =
    useState(false)
  const [openAssignOffersDrawer, setOpenAssignOffersDrawer] = useState(false)
  const [drawerLoad, setDrawerLoad] = useState(false)
  const [drawerSubData, setDrawerSubData] = useState(false)
  const [bulkFormatSubData, setBulkFormatSubData] = useState([])
  const [action, setAction] = useState(false)
  const [search, setSearch] = useState({
    submit: false,
    value: "",
  })
  const [drawerItemId, setDrawerItemId] = useState()
  const [accessItemId, setAccessItemId] = useState()
  const [requestAddOffers, setRequestAddOffers] = useState({
    token: props?.token,
    notice_id: "",
    offer_ids: "",
  })

  const data = props?.data

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const handleopenAssignNoticeDrawer = data => {
    setOpenNoticeDraftDrawer(true)
    setDrawerSubData(data)
  }

  const handleopenBulkFormatDrawer = data => {
    // setOpenNoticeBulkFormatDrawer(true)
    const request = {
      token: props?.token,
      nid: data,
    }
    ApiCall(request, "admin/bulkformat-documents-view", false, result => {
      if (result?.data?.response === "success") {
        // ShowNotification(result?.data?.message, "success")
        setBulkFormatSubData(result?.data?.responseData)
        setOpenNoticeBulkFormatDrawer(true)
      } else {
        ShowNotification(
          result?.data?.message
            ? result?.data?.message
            : "Something went wrong.",
          "danger"
        )
      }
    })
    // setDrawerSubData(data)
  }

  const handleDeleteNotice = value => {
    const request = {
      token: props?.token,
      notice_id: value,
    }
    ShowSwal(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, Delete it!",
      result => {
        if (result.isConfirmed) {
          // Swal.fire("Deleted!", "Your file has been deleted.", "success")
          ApiCall(request, "admin/delete-notice", false, result => {
            if (result?.data?.response === "success") {
              setAction(!action)
              ShowNotification(result?.data?.message, "success")
            } else {
              ShowNotification(result?.data?.message, "danger")
            }
          })
        }
      }
    )
  }

  const handleCopyWithAddNotice = value => {
    history.push({
      pathname: "/admin/add-user-notice",
      state: { draft_notice_html: value },
    })
  }
  const handleAssignOffers = () => {
    setDrawerLoad(true)
    // console.log(requestAddOffers)
    ApiCall(requestAddOffers, "admin/assign-offers", false, result => {
      if (result?.data?.response === "success") {
        setAction(!action)
        setOpenAssignOffersDrawer(false)
        ShowNotification(result?.data?.message, "success")
        // history.push("/admin/existingnotice")
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
      setDrawerLoad(false)
    })
  }

  const handleClose = () => {
    openNoticeDraftDrawer && setOpenNoticeDraftDrawer(false)
    openNoticeBulkFormatDrawer && setOpenNoticeBulkFormatDrawer(false)
    openAssignOffersDrawer && setOpenAssignOffersDrawer(false)
  }

  const handleopenAssignOffersDrawer = (itemId, access) => {
    setAccessItemId(access)
    // setDrawItemId(itemId)
    //  alert(itemId);
    setDrawerItemId(itemId)
    setRequestAddOffers({ ...requestAddOffers, notice_id: itemId })
    setOpenAssignOffersDrawer(true)
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      searchValue: search?.value,
    }
    dispatch(getExistingNotice(requestPayload))
  }, [page, limit, action, search?.submit])

  useEffect(() => {
    const requestPayload = {
      token: props?.token,
    }
    dispatch(getOffers(requestPayload))
  }, [])

  return (
    <div className="table-responsive">
      <CustomProvider theme="dark">
        {openNoticeDraftDrawer && (
          <DrwerBar
            open={openNoticeDraftDrawer}
            setOpen={setOpenNoticeDraftDrawer}
            title="Draft Notice"
            body="view"
            size="sm"
            view={true}
            subDataFromHtml={drawerSubData}
            onCancel={handleClose}
          />
        )}
        {openNoticeBulkFormatDrawer && (
          <DrwerBar
            open={openNoticeBulkFormatDrawer}
            setOpen={setOpenNoticeBulkFormatDrawer}
            title={`Draft Bulk Format - ${bulkFormatSubData?.admin_notice_name}`}
            body="viewBulkFormat"
            size="sm"
            view={true}
            subDataFromHtml={bulkFormatSubData?.csvColumns}
            onCancel={handleClose}
          />
        )}
        {openAssignOffersDrawer && (
          <DrwerBar
            open={openAssignOffersDrawer}
            setOpen={setOpenAssignOffersDrawer}
            title="Assign Offers"
            body="assignOffers"
            labelData="Offers"
            subData={props?.offerList}
            size="xs"
            loading={drawerLoad}
            // defaultValue={accessItemId}
            state={requestAddOffers}
            setState={setRequestAddOffers}
            onClick={handleAssignOffers}
            onCancel={handleClose}
          />
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SearchInput
            placeholder="Search"
            search={search}
            setSearch={setSearch}
          />
        </div>
        <Table
          height={height - 350}
          data={data}
          wordWrap="break-word"
          headerHeight={46}
          loading={props.tableLoading}
        >
          <Column width={80} align="center">
            <HeaderCell>Sr.No.</HeaderCell>
            <Cell>
              {(rowdata, key) => (
                <>
                  <span>
                    {page === 1 ? key + 1 : limit * (page - 1) + key + 1}
                  </span>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Notice ID</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column flexGrow={3}>
            <HeaderCell>Type</HeaderCell>
            <Cell dataKey="name" />
          </Column>
          <Column flexGrow={3}>
            <HeaderCell>Description</HeaderCell>
            <Cell dataKey="notice_desc" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Send As</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  {parseInt(rowdata?.send_as) === 1 && <p>Legal Notice</p>}
                  {parseInt(rowdata?.send_as) === 2 && <p>Communication</p>}
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Created At</HeaderCell>
            <Cell dataKey="created_on" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Draft</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  {rowdata.nature_of_notice === "USER" && (
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="View Draft PDF"
                    >
                      <IconButton
                        appearance="primary"
                        color="cyan"
                        size="xs"
                        icon={<VisibleIcon />}
                        onClick={() =>
                          handleopenAssignNoticeDrawer(
                            rowdata?.draft_notice_html
                          )
                        }
                      />
                    </WhisperTooltip>
                  )}
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={width < 500 ? 3 : 1} align="center">
            <HeaderCell>Bulk Format</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  {rowdata.nature_of_notice === "USER" && (
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="View Bulk Format"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="violet"
                        icon={<VisibleIcon />}
                        onClick={() => handleopenBulkFormatDrawer(rowdata?.id)}
                      />
                    </WhisperTooltip>
                  )}
                </>
              )}
            </Cell>
          </Column>
          <Column
            /*  flexGrow={width > 1300 ? 1 : width < 500 ? 7 : 2} */
            align="center"
            width={180}
          >
            <HeaderCell>Action</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="Edit Notice"
                  >
                    <Link
                      to={{
                        pathname: "/admin/notice/edit/" + btoa(rowdata.id),
                        state: { prevPath: location.pathname },
                      }}
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="blue"
                        icon={<EditIcon />}
                      />
                    </Link>
                  </WhisperTooltip>
                  &nbsp;
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="Delete Notice"
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="red"
                      icon={<TrashIcon />}
                      onClick={() => handleDeleteNotice(rowdata?.id)}
                    />
                  </WhisperTooltip>
                  &nbsp;
                  {rowdata.nature_of_notice === "USER" && (
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Copy Draft Notice"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="orange"
                        icon={<CopyIcon />}
                        onClick={() =>
                          handleCopyWithAddNotice(rowdata?.draft_notice_html)
                        }
                      />
                    </WhisperTooltip>
                  )}
                  &nbsp;
                  {rowdata.nature_of_notice === "USER" && (
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Assign Offers"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="blue"
                        onClick={() => handleopenAssignOffersDrawer(rowdata.id)}
                        icon={<TagAuthorizeIcon />}
                      />
                    </WhisperTooltip>
                  )}
                </>
              )}
            </Cell>
          </Column>
        </Table>
        <Pagination
          className="my-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={props.tableCount}
          limitOptions={[50, 100, 200]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </CustomProvider>
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.ExistingNotice.items,
  tableLoading: state.ExistingNotice.loading,
  tableCount: state.ExistingNotice.count,
  token: state.Login.token,
  offerList: state.OfferList.items,
})

export default connect(mapStateToProps)(ExistingNoticeTable)
