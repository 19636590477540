import ViewsAuthorizeIcon from "@rsuite/icons/ViewsAuthorize"
import ViewsUnauthorizeIcon from "@rsuite/icons/ViewsUnauthorize"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import SearchInput from "common/SearchInput"
import { ShowNotification } from "common/ShowNotification"
import { ShowSwal } from "common/ShowSwal"
import useWindowDimensions from "common/useWindowDimensions"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { IconButton, Pagination, Table } from "rsuite"
import { getCsvReport } from "store/actions"

const { Column, HeaderCell, Cell } = Table

function ReportTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState({
    submit: false,
    value: "",
  })
  const [action, setAction] = useState(false)
  const { height, width } = useWindowDimensions()
  const dispatch = useDispatch()
  const data = props?.data

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const handleChangeStatus = (id, status) => {
    const request = {
      token: props?.token,
      bulkCaseUploadId: id,
      new_status: status,
    }
    ShowSwal("Are you sure?", "", "warning", "Yes, Change it!", result => {
      if (result.isConfirmed) {
        ApiCall(request, "user/pdf-generate-mark-changecsv", false, result => {
          if (result?.data?.response === "success") {
            setAction(!action)
            ShowNotification(result?.data?.message, "success")
          } else {
            ShowNotification(result?.data?.message, "danger")
          }
        })

        // Swal.fire("Deleted!", "Your file has been deleted.", "success")
        // ShowNotification("Deleted! Your file has been deleted.", "success")
      }
    })
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props?.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      searchValue: search?.value,
    }
    dispatch(getCsvReport(requestPayload))

    let interval = setInterval(() => {
      // if (props?.items?.token) {
      //   dispatch(refreshToken(props?.items?.token, props?.items?.role))
      // }

      const requestPayload = {
        token: props?.token,
        page: page,
        iDisplayStart: displayStart,
        iDisplayLength: limit,
        searchValue: search?.value,
      }
      dispatch(getCsvReport(requestPayload))
    }, 50000)

    return () => {
      clearInterval(interval)
    }
  }, [page, limit, action, search?.submit])

  return (
    <div className="table-responsive" style={{ fontSize: "0.9em" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SearchInput
          placeholder="Search"
          search={search}
          setSearch={setSearch}
        />
      </div>
      <Table
        height={height - 350}
        data={data}
        wordWrap="break-word"
        headerHeight={50}
        loading={props?.dataloading}
      >
        <Column width={80} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          {/* <Cell dataKey="id" /> */}
          <Cell>
            {(rowdata, dataKey) => (
              <>
                <span>
                  {page === 1 ? dataKey + 1 : limit * (page - 1) + dataKey + 1}
                </span>
              </>
            )}
          </Cell>
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Notice ID</HeaderCell>
          <Cell dataKey="notice_id" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Notice</HeaderCell>
          <Cell dataKey="table_name" />
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Created On</HeaderCell>
          <Cell dataKey="created_on" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Created By</HeaderCell>
          <Cell dataKey="enterpriseEmail" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Batch Name</HeaderCell>
          <Cell dataKey="batch_name" />
        </Column>
        <Column width={150}>
          <HeaderCell>Row</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <span>Total: {rowdata?.total_row}</span>
                <br />
                <span style={{ color: "green" }}>
                  Inserted: {rowdata?.inserted_row}
                </span>
                <br />
                <span style={{ color: "red" }}>
                  Failed: {rowdata?.failed_row}
                </span>
                <br />
                <span style={{ color: "darkcyan" }}>
                  PDF Generated: {rowdata?.pdf_generated}
                </span>
              </>
            )}
          </Cell>
        </Column>
        <Column width={150}>
          <HeaderCell>Status</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                {rowdata.status == 0 ? (
                  <span style={{ color: "red" }}>Pending</span>
                ) : (
                  <span style={{ color: "green" }}>Completed</span>
                )}
              </>
            )}
          </Cell>
        </Column>
        <Column width={150}>
          <HeaderCell>Is Pdf Generated</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                {rowdata.pdf_uploaded == 0 ? (
                  <>
                    <span style={{ color: "red" }}>Pending</span>
                    <br />
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Mark As Complete"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<ViewsAuthorizeIcon />}
                        onClick={() => {
                          handleChangeStatus(rowdata.id, 1)
                        }}
                      />
                    </WhisperTooltip>
                  </>
                ) : (
                  <>
                    <span style={{ color: "green" }}>Completed</span>
                    <br />
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Mark As Pending"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="red"
                        icon={<ViewsUnauthorizeIcon />}
                        onClick={() => {
                          handleChangeStatus(rowdata.id, 0)
                        }}
                      />
                    </WhisperTooltip>
                  </>
                )}
              </>
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.tableCount}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.CsvReport.items,
  dataloading: state.CsvReport.loading,
  tableCount: state.CsvReport.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(ReportTable)
