import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { useParams, useLocation } from "react-router-dom"

import { setBreadcrumbItems } from "../../../store/actions"
import Details from "./Details"

const UserPartyDetails = props => {
  const location = useLocation()

  const fragment = location?.state?.prevPath.split("/").slice(-2, -1)[0]

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Notice List", link: "/user/noticelist" },
    { title: "Notice Details", link: location?.state?.prevPath },
    { title: "Party Details", link: "#" },
  ]

  const params = useParams()

  const result = props?.data?.resultData?.filter(
    value => parseInt(value.Id) === parseInt(atob(params.id))
  )

  useEffect(() => {
    props.setBreadcrumbItems("Party Details", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Party Details - User | Incase360</title>
      </MetaTags>

      {result ? (
        <Details
          data={result[0]}
          token={props?.token}
          id={atob(params.id)}
          fragment={fragment}
        />
      ) : (
        <h6
          className="mt-5"
          style={{ display: "flex", justifyContent: "center" }}
        >
          No Direct Access
        </h6>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(
  UserPartyDetails
)
