import React, { useState, useEffect } from 'react';
import ReloadIcon from '@rsuite/icons/Reload';
import { Stack } from 'rsuite';
const  Captch = ({getValue}) => {
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  // const [operator, setOperator] = useState('+');
  const [result, setResult] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [src,setsrc] = useState("")
  
  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const operators = ['+'];
    // const randomOperator = operators[Math.floor(Math.random() * operators.length)];
    // setOperator(randomOperator);

    let firstNum =  Math.floor(Math.random() * (20 - 1 + 1)) + 1;
    let secondNum = Math.floor(Math.random() * (20 - 1 + 1)) + 1;

    setNum1(firstNum);
    setNum2(secondNum);
    setResult('');
    setCaptcha(`${firstNum} ${operators[0]} ${secondNum}`);
    const canvas = document.createElement('canvas');
     canvas.style.backgroundColor="#fff"
const ctx = canvas.getContext('2d');
ctx.font = '56px Arial';
ctx.fillText(`${firstNum} ${operators[0]} ${secondNum} = ?`,10, 85);
  const answer =  firstNum + secondNum 
 const dataUrl = canvas.toDataURL();
    setsrc(dataUrl)
    getValue(parseInt(answer),"")
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (eval(captcha) === parseInt(result)) {
  //     alert('Captcha passed!');
  //     generateCaptcha();
  //   } else {
  //     alert('Incorrect captcha, please try again.');
  //   }
  // };

  return (
    <div>
        <Stack spacing={6}>
        <img src={src} style={{backgroundColor:"#fff",height:"40px",width:"140px"}} alt='image_captcha'/>
        <ReloadIcon onClick={generateCaptcha} style={{fontSize:"1rem",cursor:"pointer",margin:" 0 1em"}} />
        <div style={{visibility:"hidden"}}>
          <span id="captchaanswer">{eval(captcha)}</span>
        </div>
        <input
          type="text"
          id="captcha"
          value={result}
          style={{width:"80px",color:"#000",height:"35px",fontSize:"1rem",textAlign:"center"}}
          onChange={(e) => {setResult(e.target.value) ;
             getValue(eval(captcha),parseInt(e.target.value))
          }}
        />
    </Stack>
    </div>
  );
};
export default Captch;
