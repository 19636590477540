const { default: axios } = require("axios")
 import { ApiRoute, RouteWithoutApi } from "common/ApiRoute"

    function bulkSendOfApiNotice (payload){
      const request = new FormData()
      request.append('pdfall_ids',payload.pdfall_ids)
      request.append('token',payload.token)
      request.append('mnid',payload.mnid)
      request.append('schedule_on',payload.schedule_on)
      request.append('rangeFrom',payload.rangeFrom)
      request.append('rangeTo',payload.rangeTo)
      request.append('rangeExcept',payload.rangeExcept)
      axios.post(`${ApiRoute}user/send-bulk-notice`,request)
      .then((res)=>{
          if(res.data.code === 200) {

          }
      })


    }

 const UserActionService = {
    getUserBySearch : function (data){
        return axios
        .post(ApiRoute+`onChangeUserSelect`,data)
    },
    getNoticeByUserId:function (id){
         const payload = new FormData()
         payload.append('userId',id)
      
       return axios
        .post(`${ApiRoute}getAssignedNoticeByUserId`,payload)
    },
    getLetterHeadByUserId:function (id){
        const payload = new FormData()
        payload.append('userId[]',id)
     
      return axios
       .post(`${ApiRoute}letterheadByUserId`,payload)
   },
   getbatchNameByNoticeId:function(data){
    const payload = new FormData()
    payload.append('token',data.token)
    payload.append('notice_id',data.notice_id)
 
  return axios
   .post(`${RouteWithoutApi}getBatchByNoticeId`,payload)
   },
   apiNoticeBulkSend:function async(payload){
     const request = new FormData()
      request.append('pdfall_ids',payload.pdfall_ids)
      request.append('token',payload.token)
      request.append('mnid',payload.mnid)
      request.append('schedule_on',payload.schedule_on)
      request.append('rangeFrom',payload.rangeFrom)
      request.append('rangeTo',payload.rangeTo)
      request.append('rangeExcept',payload.rangeExcept)
      axios.post(`${ApiRoute}user/send-bulk-notice`,request)
      .then((res)=>{
          if(res.data.code === 200) {
            const payload = new FormData()
            Object.entries(payload).forEach(([key, value]) => {
              payload.append( key, value );
            });
           axios.post(`${ApiRoute}bulk-send-apiNotice`,payload)
           .then((response)=>{
              if(response.status.code === 200) {
                   return {message:"Send Successfull!!",status:200}
              }
              else {
                 return {message:"Bulk Send ,But Data not Inserted!",status:500}
              }
           })
           .catch((err)=>{
            return {message:"Something went wrong!",status:500}
           })
          }
          else{
             return {message:res.data.message,status:500}
          }
      })
      .catch((err)=>{
           return {message:err,status:500}
      })
   }


}
export {UserActionService}