import "../bulkoption.scss"

import { ApiCall } from "common/ActionApiCall/ApiCall"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import { RouteForShow } from "common/ApiRoute"
import { ShowNotification } from "common/ShowNotification"
import { ShowSwal, ShowSwalForMultiAction } from "common/ShowSwal"
import DrwerBar from "components/Common/DrwerBar"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { CustomProvider } from "rsuite"

import { getUserLetterhead, setBreadcrumbItems } from "../../../store/actions"
import BulkMenu from "../BulkMenu"
import FilterMenu from "../FilterMenu"
import NewDraftTable from "./NewDraftTable"
import { getCommunication } from "utils/getCommunication"

const UserDraft = props => {
  const [checkedKeys, setCheckedKeys] = useState([])
  const [openSubMenu, setOpenSubMenu] = useState(false)
  const params = useParams()
  const [action, setAction] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [openFilterMenu, setOpenFilterMenu] = useState(false)
  const [openUploadSignedPdfDrawer, setOpenUploadSignedPdfDrawer] =
    useState(false)
  const [openUploadBulkDataDrawer, setOpenUploadBulkDataDrawer] =
    useState(false)
  const [openNoticeDraftDrawer, setOpenNoticeDraftDrawer] = useState(false)
  const [openSendNoticeDrawer, setOpenSendNoticeDrawer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [downloadAs, setDownloadAs] = useState("notice_id")
  const [fromTo, setFromTo] = useState({
    rangeFrom: "",
    rangeTo: "",
    rangeExcept: "",
  })
  const [filter, setFilter] = useState({
    filterFrom: "",
    filterTo: "",
    batch: "",
    case_ref_id: "",
    submit: false,
  })
  const [scheduleNotice, setScheduleNotice] = useState(new Date().toString())
  const [signedPDFs, setSignedPDFs] = useState(null)
  const [stateCsv, setStateCsv] = useState()
  const [bulkData, setBulkData] = useState({
    token: props?.token,
    noticeId: params?.id,
    batchName: null,
    user_bulk_data: null,
    lhead: null,
    is_sig: 1,
    ref_id_check: 0,
    ref_check_msg: null,
  })
  const [bulkDataError, setBulkDataError] = useState({
    batchName: null,
    user_bulk_data: null,
    lhead: null,
  })

  const breadcrumbItems = params?.userId
    ? [
        { title: "Incase360", link: "#" },
        { title: "Sub User", link: "/user/subuser" },
        {
          title: "Sub User Notice List",
          link: "/user/subuser/noticelist/" + params?.userId,
        },
        { title: "Sub User Draft Notice Details", link: "#" },
      ]
    : [
        { title: "Incase360", link: "#" },
        { title: "Notice List", link: "/user/noticelist" },
        { title: "Draft Notice Details", link: "#" },
      ]

  const handleUploadSignedPdfOpenDrawer = () => {
    setOpenUploadSignedPdfDrawer(true)
  }

  const handleUploadBulkDataOpenDrawer = () => {
    getCommunication(bulkData.noticeId)
    setOpenUploadBulkDataDrawer(true)
  }
  const handleBulkRejectNotice = () => {
    const request = {
      pdfs: checkedKeys,
      token: props?.token,
      mnid: params?.id,
      refrenceCsv: stateCsv,
      ...fromTo,
    }
    ShowSwal(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, Reject it!",
      result => {
        if (result.isConfirmed) {
          ApiCall(request, "user/reject-bulk-notice", false, result => {
            if (result?.data?.response === "success") {
              setAction(!action)
              ShowNotification(result?.data?.message, "success")
              history.push("/user/rejected-notice/" + params.id)
            } else {
              ShowNotification(result?.data?.message, "danger")
            }
          })

          // Swal.fire("Deleted!", "Your file has been deleted.", "success")
          // ShowNotification("Deleted! Your file has been deleted.", "success")
        }
      }
    )
    // console.log(request);
  }

  const handleOpenSendNoticeDrawer = () => {
    setOpenSendNoticeDrawer(true)
  }

  const handleBulkSendNotice = () => {
    const request = {
      pdfall_ids: checkedKeys,
      token: props?.token,
      mnid: params?.id,
      schedule_on: scheduleNotice,
      ...fromTo,
    }
    getCommunication(params?.id)
    ShowSwal(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes!",
      result => {
        if (result.isConfirmed) {
          ApiCall(request, "user/send-bulk-notice", false, result => {
            if (result?.data?.response === "success") {
              setAction(!action)
              ShowNotification(result?.data?.message, "success")
              setOpenSendNoticeDrawer(false)
              // history.push("/user/active-notice/" + params.id)
            } else {
              ShowNotification(result?.data?.message, "danger")
              setOpenSendNoticeDrawer(false)
            }
          })

          // Swal.fire("Deleted!", "Your file has been deleted.", "success")
          // ShowNotification("Deleted! Your file has been deleted.", "success")
        }
      }
    )
    // console.log(request);
  }

  const handleUploadSignedPDFsAction = () => {
    const request = {
      token: props?.token,
      noticeId: params?.id,
      user_bulk_sign_data: signedPDFs,
      // totalFiles: signedPDFs?.length,
    }
    signedPDFs &&
      ApiCall(request, "user/signed-pdf-upload", false, result => {
        if (result?.data?.response === "success") {
          setOpenUploadSignedPdfDrawer(false)
          // ShowNotification(result?.data?.message, "success")
          ShowSwal("", result?.data?.message, "success", "Ok", res => {})
        } else {
          // ShowNotification(result?.data?.message, "danger")
          ShowSwal("", result?.data?.message, "error", "Ok", res => {})
        }
      })
  }

  const handleClose = () => {
    openUploadBulkDataDrawer && [
      setOpenUploadBulkDataDrawer(false),
      setBulkData({
        ...bulkData,
        batchName: null,
        user_bulk_data: null,
        lhead: null,
        is_sig: 1,
      }),
    ]
    openUploadSignedPdfDrawer && [
      setOpenUploadSignedPdfDrawer(false),
      setSignedPDFs(null),
    ]
    openSendNoticeDrawer && [
      setOpenSendNoticeDrawer(false),
      setScheduleNotice(""),
    ]
    openNoticeDraftDrawer && setOpenNoticeDraftDrawer(false)
  }

  const handleUploadBulkDataAction = () => {
    // console.log(bulkData)
       
    const bulkDataToUpload = {
      ...bulkData,
      ...(params?.userId && { on_behalf_of: params?.userId && atob(params?.userId) }),
    };

    bulkData?.batchName &&
      bulkData?.lhead &&
      bulkData?.user_bulk_data &&
      bulkData?.is_sig && [
        setLoading(true),
        ApiCall(bulkDataToUpload, "user/upload-bulk-csv", false, result => {
          if (result?.data?.response === "success") {
            // setOpenUploadSignedPdfDrawer(false)
            setOpenUploadBulkDataDrawer(false)
            // ShowNotification(result?.data?.message, "success")
            ShowSwalForMultiAction(
              result?.data?.message,
              "Please wait for sometime while cases are being generate.",
              "success",
              "CSV Report",
              "Ok",
              result => {
                if (result.isConfirmed) {
                  history.push("/user/report")
                }
              }
            )
          } else {
            ShowNotification(result?.data?.message, "danger")
          }
          setLoading(false)
        }),
      ]
  }

  const handleReferenceIdCheck = () => {
    // console.log(bulkData)
    bulkData?.batchName &&
      bulkData?.user_bulk_data && [
        setLoading(true),
        ApiCall(bulkData, "user/checkReferenceId", false, result => {
          if (result?.data?.response === "success") {
            setBulkData({
              ...bulkData,
              ref_check_msg: result?.data?.responseData,
            })
          } else {
            setBulkData({ ...bulkData, ref_check_msg: " " })
          }
          setLoading(false)
        }),
      ]
  }

  useEffect(() => {
    setBulkDataError({
      ...bulkDataError,
      batchName: !bulkData?.batchName ? "Required Field" : null,
      lhead: !bulkData?.lhead ? "Required Field" : null,
      user_bulk_data: !bulkData?.user_bulk_data
        ? "Please Select at list one file"
        : null,
    })
  }, [bulkData])

  const handleBulkDownloadNotices = () => {
    // if()
    const request = {
      token: props?.token,
      all_ids: checkedKeys,
      mnid: params?.id,
      download_as: downloadAs,
      refrenceCsv: stateCsv,
      ...fromTo,
    }
    // ApiCall(request, "download/download-notice", false, result => {
    //   console.log(result);
    // })
    DownloadApiCall(request, "download/download-notice", "_self")
  }

  const handleopenNoticeDraftDrawer = () => {
    setOpenNoticeDraftDrawer(true)
  }

  const bulkButton = [
    {
      id: 1,
      size: "xs",
      color: "blue",
      label: "View Draft Notice",
      onClick: handleopenNoticeDraftDrawer,
    },
    {
      id: 2,
      size: "xs",
      color: "cyan",
      label: "Download CSV Format",
      // display: checkedKeys.length === 0 && "d-none",
      // onClick: handleApproveOpenDrawer,
      href: RouteForShow + "notice_bulk/" + props?.data?.notice_bulk,
    },
    {
      id: 3,
      size: "xs",
      color: "orange",
      label: "Bulk Upload Data",
      // display: checkedKeys.length === 0 && "d-none",
      onClick: handleUploadBulkDataOpenDrawer,
    },
    {
      id: 4,
      size: "xs",
      color: "violet",
      label: "Bulk Upload Signed PDFs",
      // display: checkedKeys.length === 0 && "d-none",
      onClick: handleUploadSignedPdfOpenDrawer,
    },
    {
      id: 5,
      size: "xs",
      color: "cyan",
      label: "Bulk Download Notices",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "") ||
        stateCsv
          ? "d-block"
          : "d-none",
      onClick: handleBulkDownloadNotices,
    },
    {
      id: 6,
      size: "xs",
      color: "green",
      label: "Bulk Send Notices",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "")
          ? "d-block"
          : "d-none",
      onClick: handleOpenSendNoticeDrawer,
    },
    {
      id: 7,
      size: "xs",
      color: "red",
      label: "Bulk Reject Notices",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "") ||
        stateCsv
          ? "d-block"
          : "d-none",
      onClick: handleBulkRejectNotice,
    },
    // {
    //   id: 8,
    //   size: "xs",
    //   color: "cyan",
    //   label: "Download Notice Links",
    //   display: checkedKeys.length === 0 && "d-none",
    //   // onClick: handleApproveOpenDrawer,
    // },
  ]

  const fromToInput = [
    {
      id: 1,
      name: "rangeFrom",
      placeholder: "From ex. IN12-1234",
      onChange: e => setFromTo({ ...fromTo, rangeFrom: e }),
    },
    {
      id: 2,
      name: "rangeTo",
      placeholder: "To ex. IN12-2345",
      onChange: e => setFromTo({ ...fromTo, rangeTo: e }),
    },
    {
      id: 3,
      name: "rangeExcept",
      placeholder: "Except ex. IN12-23,IN12-34,IN12-45",
      onChange: e => setFromTo({ ...fromTo, rangeExcept: e }),
    },
  ]

  const fromToDate = [
    {
      id: 1,
      name: "rangeDateFrom",
      placeholder: "From",
      onChange: e => setFilter({ ...filter, filterFrom: e }),
    },
    {
      id: 2,
      name: "rangeDateTo",
      placeholder: "To",
      min: filter?.filterFrom,
      onChange: e => setFilter({ ...filter, filterTo: e }),
    },
  ]

  useEffect(() => {
    props.setBreadcrumbItems(
      `${
        params?.userId ? "Sub User" : ""
      } Draft Notice Details - ${props?.data?.noticeName?.replace(
        "notice_",
        ""
      )}`,
      breadcrumbItems
    )
  })

  // console.log(props?.data?.notice_bulk)

  useEffect(() => {
    dispatch( 
      getUserLetterhead({

        token: props?.token,
        iDisplayLength: null,
        iDisplayStart: null,
        userId: params?.userId && atob(params?.userId),
      })

    )
  }, [])

  const approveLetter = props?.UserLetterhead?.filter(value => {
    return parseInt(value?.status) === 1
  })

  // console.log(props?.data);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Notice Details - User | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        {openNoticeDraftDrawer && (
          <DrwerBar
            open={openNoticeDraftDrawer}
            setOpen={setOpenNoticeDraftDrawer}
            title="Draft Notice"
            body="view"
            size="sm"
            view={true}
            subDataFromHtml={props?.data?.draft_notice_html}
            onCancel={handleClose}
          />
        )}
        {openSendNoticeDrawer && (
          <DrwerBar
            open={openSendNoticeDrawer}
            setOpen={setOpenSendNoticeDrawer}
            title="Send Notice"
            size="xs"
            setState={setScheduleNotice}
            body="SendNotice"
            onClick={handleBulkSendNotice}
            onCancel={handleClose}
          />
        )}
        {openUploadSignedPdfDrawer && (
          <DrwerBar
            open={openUploadSignedPdfDrawer}
            setOpen={setOpenUploadSignedPdfDrawer}
            title="Upload Digital Signed PDFs"
            body="UploadSigned"
            size="xs"
            onClick={() => handleUploadSignedPDFsAction()}
            state={signedPDFs}
            setState={setSignedPDFs}
            onCancel={handleClose}
          />
        )}
        {openUploadBulkDataDrawer && (
          <DrwerBar
            open={openUploadBulkDataDrawer}
            setOpen={setOpenUploadBulkDataDrawer}
            title="Upload Bulk Data"
            body="UploadBulkData"
            subData={approveLetter}
            size="xs"
            onClick={() => handleUploadBulkDataAction()}
            onClickCheck={() => handleReferenceIdCheck()}
            state={bulkData}
            setState={setBulkData}
            error={bulkDataError}
            setError={setBulkDataError}
            onCancel={handleClose}
            loading={loading}
          />
        )}
        <FilterMenu
          openFilterMenu={openFilterMenu}
          setOpenFilterMenu={setOpenFilterMenu}
          state={filter}
          setState={setFilter}
          option={true}
          mnid={params?.id}
          tab="draft"
          fromToDate={fromToDate}
        />

        <NewDraftTable
          checkedKeys={checkedKeys}
          setCheckedKeys={setCheckedKeys}
          setOpenSubMenu={setOpenSubMenu}
          action={action}
          filter={filter}
        />
        <BulkMenu
          openSubMenu={openSubMenu}
          setOpenSubMenu={setOpenSubMenu}
          bulkButton={bulkButton}
          state={downloadAs}
          setState={setDownloadAs}
          fromToInput={fromToInput}
          setStateCsv={setStateCsv}
        />
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
  token: state.Login.token,
  UserLetterhead: state.UserLetterhead.items,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(UserDraft)
