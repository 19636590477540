import { AvField, AvForm } from "availity-reactstrap-validation"
import { ApiRoute } from "common/ApiRoute"
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

const ForgetPasswordPage = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)

  const handleValidSubmit = async (event, values) => {
    event.preventDefault()
    setError(null)
    setSuccessMsg(null)
    console.log(ApiRoute)
    try {
      const response = await fetch(ApiRoute + "requestResetPassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: values.email }),
      })

      const data = await response.json()

      if (response.ok) {
        setSuccessMsg(
          data.message || "Please check your email for reset instructions."
        )
      } else {
        throw new Error(data.message || "Failed to send reset instructions.")
      }
    } catch (error) {
      setError(error.message || "An unexpected error occurred.")
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Forget Password | Lexa - Responsive Bootstrap 5 Admin Dashboard
        </title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <h3 className="text-center mt-4 mb-3">
                      <Link to="/" className="d-block auth-logo">
                        <img
                          src="https://api.incase360.com/assets/images/Incase360-logo-1-white.png"
                          alt=""
                          height="30"
                          className="auth-logo-dark"
                        />
                        <img
                          src="https://api.incase360.com/assets/images/Incase360-logo-1-white.png"
                          alt=""
                          height="30"
                          className="auth-logo-light"
                        />
                      </Link>
                    </h3>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-3 text-center">
                      Reset Password
                    </h4>
                    <div className="alert alert-info" role="alert">
                      Enter your Email and instructions will be sent to you!
                    </div>
                    {error && (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                    )}
                    {successMsg && (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {successMsg}
                      </Alert>
                    )}
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={handleValidSubmit}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                          onChange={e => setEmail(e.target.value)} // Make sure this updates the state
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It?{" "}
                  <Link to="login" className="text-primary">
                    Sign In Here
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()} Lexa{" "}
                  <span className="d-none d-sm-inline-block">
                    {" "}
                    - Crafted with <i className="mdi mdi-heart text-danger"></i>{" "}
                    by Themesbrand.
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ForgetPasswordPage)
