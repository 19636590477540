import React from "react"
import { Button, Col, Grid, Row } from "rsuite"
// import bannerImg from "../../../images/misc/image-2.png"

function ProcessFlow() {
  return (
    <>
      <div className="process">
        <div className="container">
          <p className="text-center process-text">How Does Incase360 Work?</p>
          <div className="process-card mt-5">
            <Grid fluid>
              <Row className="show-grid">
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="card">
                    <div style={{ padding: "3em" }}>
                      <i className="i-circle fa fa-pen"></i>
                      <br />
                      <h4 className="mt-4">Customized Template</h4>
                      <span>
                        Create a master template that matches your requirements
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="card">
                    <div style={{ padding: "3em" }}>
                      <i className="i-circle fa fa-file"></i>
                      <br />
                      <h4 className="mt-4">Generate & E-Sign</h4>
                      <span>
                        Upload bulk case data to autogenerate documents and
                        E-sign
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="card">
                    <div style={{ padding: "3em" }}>
                      <i className="i-circle fa fa-laptop"></i>
                      <br />
                      <h4 className="mt-4">Serve & Track</h4>
                      <span>
                        Serve notices in bulk and track delivery status
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4}></Col>

                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="card">
                    <div style={{ padding: "3em" }}>
                      <i className="i-circle fa fa-users"></i>
                      <br />
                      <h4 className="mt-4">Analyse Responses</h4>
                      <span>Record party responses under one roof</span>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="card">
                    <div style={{ padding: "3em" }}>
                      <i className="i-circle fa fa-random"></i>
                      <br />
                      <h4 className="mt-4">Settle & Escalate</h4>
                      <span>
                        Achieve outright settlements, or escalate matters for
                        the next steps
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4}></Col>
                {/* <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="card">
                    <div style={{ padding: "3em" }}>
                      <i className="i-circle fa fa-hourglass-start"></i>
                      <br />
                      <h4 className="mt-4">Timer</h4>
                      <span>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem
                      </span>
                    </div>
                  </div>
                </Col> */}
              </Row>
            </Grid>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProcessFlow
