import React, { useEffect, useState } from 'react'
import { Button, Col, Grid, Input, Row } from "rsuite"
import { Card, CardBody, Label} from "reactstrap";
import { BarChart, Bar,XAxis, YAxis,Tooltip} from 'recharts';
import { UserServerice } from './UserService';
import { useSelector } from 'react-redux'
import FormGroup from "components/Common/FormGroup"
import Loader from 'common/Loader';
import { UserActionService } from 'Service/userActionService';

// const data = [{name: 'Jan', uv: 300},{name: 'Feb', uv: 200},{name: 'Mar', uv: 100},{name: 'April', uv: 200},{name: 'May', uv: 250},{name: 'June', uv: 160},{name: 'July', uv: 400},{name: 'Aug', uv: 200},{name: 'Sept', uv: 400},{name: 'Oct', uv: 200},{name: 'Nov', uv: 400},{name: 'Dec', uv: 200}];



export default function YearwiseDistribution() {
   const [data,setdata] = useState([])
   const [loading,setloading] = useState(false)
   const [noticeList,setNoticelist]= useState([])
   const [noticeId,setNoticeId] = useState("")
    const date = new Date
      const year = date.getFullYear()
      const month = date.getMonth()
   const id =  useSelector((state)=> state.Login.id)
    const handelNoticeChange = (e) =>{
      console.log(e)
    setNoticeId(e)
    UserServerice.getNoticeListMonthWise({
      fromDate:`01-${month}-${year -1}`,
      toDate:`${date.getDate()}-${month+1}-${year}`,
      noticeId:e,
      userId:id
     })
     .then((res)=>{
       if (res.data.message === "Success") {
         setdata(res.data.responseData[0].noticeData)
             setloading(false)
       }
     })
    }
  useEffect(()=>{
    setloading(true)
      UserActionService.getNoticeByUserId(id)
        .then(res => {
          const data = res?.data?.response?.map(item => ({
            label: item?.notice_name,
            value: item?.notice_id,
          }))
          setNoticelist(data)
          UserServerice.getNoticeListMonthWise({
            fromDate:`01-${month}-${year -1}`,
            toDate:`${date.getDate()}-${month+1}-${year}`,
            userId:id,
            noticeId:data[0].value,
               
           })
           .then((res)=>{
             if (res.data.message === "Success") {
               setdata(res.data.responseData[0].noticeData)
                   setloading(false)
             }
           })
        
        })

},[])
  return (
    <React.Fragment>
                <Card>
                    <CardBody>
                      <div  style={{display:"flex",alignItems:"center",gap:"2em",justifyContent:"space-between"}}>
                      <h4 className="card-title">Notice Processed</h4>       
              <FormGroup
                select={true}
                selectData={noticeList}
                onChange={e => handelNoticeChange(e)}
              />
            <h4 className="card-title">Year {year-1}{2000-year}</h4>

    
                      </div>
                      {
                         loading  ? <Loader/> : <BarChart width={800} height={300} data={data}>
                         <XAxis dataKey="month" tick={{stroke: '#FFF',strokeWidth:'.5'}} color='#dee2e6' />
                         <Tooltip wrapperClassName='chartHoverBar'/>
                         <YAxis tick={{stroke: '#FFF'}} />
                         <Bar dataKey="noticeProcessed" barSize={35} fill="#055C9D"/>
                       </BarChart>
                      }
                         
                    </CardBody>
                </Card>
            </React.Fragment>
  )
}
