import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"

// Pages Components
// import MonthlyEarnings from "./montly-earnings";
// import EmailSent from "./email-sent";
// import MonthlyEarnings2 from "./montly-earnings2";
// import Inbox from "./inbox";
// import RecentActivity from "./recent-activity";
// import WidgetUser from "./widget-user";
// import YearlySales from "./yearly-sales";
// import LatestTransactions from "./latest-transactions";
// import LatestOrders from "./latest-orders";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions"
import NoticeTable from "./NoticeTable"

const AdminNoticeList = props => {
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Notice List", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Notice List", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Notice List - Admin | Incase360</title>
      </MetaTags>
      <p className="mb-3">
        <b>
          Note: The following notices are available for use. Click on the number
          appearing in the relevant column to view details and take appropriate
          action.
        </b>
      </p>
      <NoticeTable />
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AdminNoticeList)
