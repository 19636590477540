import { TabBar } from "common/TabBar/TabBar"
import React, { useState } from "react"
import { Button, Col, Grid, Row } from "rsuite"
import journyImg from "../../../images/misc/image-1.png"

function Journey() {
  const [active, setActive] = useState("Create")

  const tabbardata = [
    {
      eventkey: "Create",
      lable: "Easy to Create",
    },
    { eventkey: "Send", lable: "Simple To Send" },
    { eventkey: "Manage", lable: "Effective to Manage" },
  ]
  return (
    <>
      <div className="journey">
        <div className="container">
          <div className="journey-grid">
            <Grid fluid>
              <Row className="show-grid">
                <Col xs={24} sm={24} md={24} lg={14}>
                  <h1>Begin Your Automation Journey Today</h1>
                  <br />
                  <TabBar
                    style={{ textAlign: "center" }}
                    appearance="tabs"
                    active={active}
                    onSelect={setActive}
                    panel={tabbardata}
                  />
                  <br />
                  {active === "Create" && (
                    <>
                      <h5>
                        One-time setup and integration of template and workflow.
                      </h5>
                      <br />
                      {/* <h5 style={{ lineHeight: "1.7em" }}>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptatem.
                      </h5> */}
                    </>
                  )}
                  {active === "Send" && (
                    <>
                      <h5>One-click generation and service of e-notices.</h5>
                      <br />
                      {/* <h5 style={{ lineHeight: "1.7em" }}>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                      </h5> */}
                    </>
                  )}
                  {active === "Manage" && (
                    <>
                      <h5>One-roof analysis of responses and next steps.</h5>
                      <br />
                      {/* <h5 style={{ lineHeight: "1.7em" }}>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English.
                      </h5> */}
                    </>
                  )}
                </Col>
                <Col xs={24} sm={24} md={24} lg={2}></Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                  <img src={journyImg} width="100%" />
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </div>
    </>
  )
}

export default Journey
