import React, { useEffect, useState } from "react"
import "./details.scss"
import { CustomProvider, IconButton, Pagination, Table } from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import TrashIcon from "@rsuite/icons/Trash"
import CloseIcon from "@rsuite/icons/Close"
import { getUserLetterhead } from "store/actions"
import { connect, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { RouteForLetterHead } from "common/ApiRoute"
import useWindowDimensions from "common/useWindowDimensions"
import EditIcon from "@rsuite/icons/Edit"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import DrwerBar from "components/Common/DrwerBar"
import { ShowSwal } from "common/ShowSwal"
import { ShowNotification } from "common/ShowNotification"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import AddOutlineIcon from "@rsuite/icons/AddOutline"

const { Column, HeaderCell, Cell } = Table

function LetterheadTable(props) {
  const { height, width } = useWindowDimensions()

  //   console.log(Array.from(data));
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const params = useParams()
  const data = props?.data
  const [openEditLetterheadDrawer, setOpenEditLetterheadDrawer] =
    useState(false)
  const [request, setRequest] = useState({
    token: props?.token,
    latterhead2_path: null,
  })
  const [drawerItemId, setDrawerItemId] = useState()
  const [action, setAction] = useState(false)
  const [forEditData, setForEditData] = useState([])

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const handleopenEditLetterheadDrawer = itemId => {
    setOpenEditLetterheadDrawer(true)
    setDrawerItemId(itemId)
    const data_append = data?.filter(value => {
      return parseInt(value.id) === parseInt(itemId)
    })
    setForEditData(data_append[0])
    setTimeout(() => {
      setForEditData(prev => ({ ...prev, token: props?.token }))
    }, 2000)
  }

  const handleEditLetterheadAction = value => {
    // console.log(forEditData)
    ApiCall(forEditData, "user/edit-letterhead", false, result => {
      if (result?.data?.response === "success") {
        setAction(!action)
        setOpenEditLetterheadDrawer(false)
        ShowNotification(result?.data?.message, "success")
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  const handlePreview = value => {
    const passData = {
      token: props?.token,
      letterheadId: value,
    }
    ApiCall(passData, "previewLetterhead", false, result => {
      if (result?.data?.response === "success") {
        ShowNotification(result?.data?.message, "success")
        const link = document.createElement("a")
        // link.target = "_blank"
        link.target = "_blank"
        link.href = result?.data?.url
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  const handleDeleteNotice = value => {
    const request = {
      token: props?.token,
      letterheadid: value,
    }
    ShowSwal(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, Delete it!",
      result => {
        if (result.isConfirmed) {
          // Swal.fire("Deleted!", "Your file has been deleted.", "success")
          ApiCall(request, "user/delete-letterhead", false, result => {
            if (result?.data?.response === "success") {
              setAction(!action)
              ShowNotification(result?.data?.message, "success")
            } else {
              ShowNotification(result?.data?.message, "danger")
            }
          })
          // ShowNotification("Deleted! Your file has been deleted.", "success")
        }
      }
    )
  }

  const handleRemove = value => {
    const request = {
      token: props?.token,
      id: value,
    }
    ShowSwal(
      "Are you sure?",
      "Do you want to remove signature?",
      "warning",
      "Yes, Remove it!",
      result => {
        if (result.isConfirmed) {
          // Swal.fire("Deleted!", "Your file has been deleted.", "success")
          ApiCall(request, "removeSignature", false, result => {
            if (result?.data?.response === "success") {
              setAction(!action)
              ShowNotification(result?.data?.message, "success")
            } else {
              ShowNotification(result?.data?.message, "danger")
            }
          })
          // ShowNotification("Deleted! Your file has been deleted.", "success")
        }
      }
    )
  }

  const handleClose = () => {
    openEditLetterheadDrawer && setOpenEditLetterheadDrawer(false)
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      // userId: atob(params.id),
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
    }
    dispatch(getUserLetterhead(requestPayload))
  }, [page, limit, action])

  return (
    <React.Fragment>
      <div className="table-responsive">
        {openEditLetterheadDrawer && (
          <DrwerBar
            open={openEditLetterheadDrawer}
            setOpen={setOpenEditLetterheadDrawer}
            title="Edit Letterhead"
            body="editLetterhead"
            size="sm"
            onClick={() => handleEditLetterheadAction(drawerItemId)}
            itemId={drawerItemId}
            forEditData={forEditData}
            state={forEditData}
            setState={setForEditData}
            onCancel={handleClose}
          />
        )}
        <div className="addButtonLetter">
          <WhisperTooltip
            placement="top"
            trigger="hover"
            tooltipMsg="Add New Letterhead"
          >
            <Link to={"/user/add-letterhead"}>
              <IconButton
                size="xs"
                appearance="primary"
                color="blue"
                icon={<AddOutlineIcon />}
                // onClick={() => handleopenEditLetterheadDrawer(rowdata.id)}
              >
                Add New
              </IconButton>
            </Link>
          </WhisperTooltip>
        </div>
        <Table
          height={height - 300}
          data={data}
          wordWrap="break-word"
          headerHeight={50}
          loading={props.tableLoading}
        >
          <Column width={80} align="center">
            <HeaderCell>Sr.No.</HeaderCell>
            {/* <Cell dataKey="id" /> */}
            <Cell>
              {(rowdata, dataKey) => (
                <>
                  <span>
                    {page === 1
                      ? dataKey + 1
                      : limit * (page - 1) + dataKey + 1}
                  </span>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Status</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  {rowdata.status == 0 ? (
                    <span style={{ color: "orange" }}>Under Review</span>
                  ) : rowdata.status == 1 ? (
                    <span style={{ color: "green" }}>Approved</span>
                  ) : (
                    <span style={{ color: "red" }}>Rejected</span>
                  )}
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={3}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="head_name" />
          </Column>
          <Column flexGrow={2} align="center">
            <HeaderCell>Header Margin</HeaderCell>
            <Cell dataKey="head_margin" />
          </Column>
          <Column flexGrow={2} align="center">
            <HeaderCell>Footer Margin</HeaderCell>
            <Cell dataKey="foot_margin" />
          </Column>
          <Column flexGrow={2} align="center">
            <HeaderCell>Letterhead</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  {rowdata.letterhead && (
                    <a
                      target="_blank"
                      href={
                        RouteForLetterHead +
                        rowdata.user_id +
                        "/" +
                        rowdata.letterhead
                      }
                    >
                      <img
                        width={40}
                        src={
                          RouteForLetterHead +
                          rowdata.user_id +
                          "/" +
                          rowdata.letterhead
                        }
                      />
                    </a>
                  )}
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={2} align="center">
            <HeaderCell>Specimen Signature</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  {rowdata.signature && (
                    <a
                      target="_blank"
                      href={
                        RouteForLetterHead +
                        rowdata.user_id +
                        "/" +
                        rowdata.signature
                      }
                    >
                      <img
                        width={40}
                        src={
                          RouteForLetterHead +
                          rowdata.user_id +
                          "/" +
                          rowdata.signature
                        }
                      />
                    </a>
                  )}
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Preview</HeaderCell>
            <Cell>
              {rowdata => (
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="Letterhead Preview"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="violet"
                    icon={<VisibleIcon />}
                    onClick={() => handlePreview(rowdata?.id)}
                  />
                </WhisperTooltip>
              )}
            </Cell>
          </Column>
          <Column width={140} align="center">
            <HeaderCell>Action</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="Edit Letterhead"
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="blue"
                      icon={<EditIcon />}
                      onClick={() => handleopenEditLetterheadDrawer(rowdata.id)}
                    />
                  </WhisperTooltip>
                  &nbsp;
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="Delete Letterhead"
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="red"
                      icon={<CloseIcon />}
                      onClick={() => handleDeleteNotice(rowdata.id)}
                    />
                  </WhisperTooltip>
                  &nbsp;
                  {rowdata.signature && (
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Remove Signature"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="orange"
                        icon={<TrashIcon />}
                        onClick={() => handleRemove(rowdata?.id)}
                      />
                    </WhisperTooltip>
                  )}
                </>
              )}
            </Cell>
          </Column>
        </Table>
        <Pagination
          className="my-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={props.tableCount}
          limitOptions={[50, 100, 200]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.UserLetterhead.items,
  tableLoading: state.UserLetterhead.loading,
  tableCount: state.UserLetterhead.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(LetterheadTable)
