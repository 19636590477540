import React, { useState } from "react"
import { Button, Col, Grid, Input, InputGroup, List, Row } from "rsuite"
import SendIcon from "@rsuite/icons/Send"
import { Link } from "react-router-dom"

function HomeFooter() {
  return (
    <>
      <div className="main-footer">
        <div className="container">
          <div className="footer-grid">
            <Grid fluid>
              <Row className="show-grid">
                <Col xs={24} sm={24} md={24} lg={16}>
                  {/* <a>
                    <img
                      alt=""
                      style={{ width: "12em" }}
                      class="logo"
                      src="https://api.incase360.com/assets/images/Incase360-logo-1-white.png"
                    />
                  </a>
                  <br />
                  <br />
                  <br /> */}
                  <h3>Disclaimer</h3>
                  <br />
                  <h5 style={{ lineHeight: "1.5em" }}>
                    Incase360 is a SaaS platform and not a law firm. It does not
                    provide legal advice. The use of any materials or services
                    is not a substitute for legal advice. Only a legal
                    practitioner can provide legal advice. A legal practitioner
                    should be consulted for any legal advice or matter. No
                    Attorney-Client relationship is created by the use of these
                    materials or services.
                  </h5>
                  <br />
                </Col>
                <Col xs={24} sm={24} md={24} lg={2}></Col>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <h3>Important Links</h3>
                  <div className="links">
                    <Link to="/terms-of-use">Terms of Use</Link>
                    <br />
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <br />
                    <Link to="/cookie-policy">Cookie Policy</Link>
                    <br />
                  </div>
                  <br />
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
        <hr />
        <div className="container">
          <span className="manage-social">
            <h6> &copy; Copyright {new Date().getFullYear()} - Incase360</h6>
            <div className="social-icons">
              {/* <a href="#">
                <span className="mdi mdi-facebook"></span>
              </a>
              <a href="#">
                <span className="mdi mdi-whatsapp"></span>
              </a>
              <a href="#">
                <span className="mdi mdi-twitter"></span>
              </a>
              <a href="#">
                <span className="mdi mdi-linkedin"></span>
              </a> */}
            </div>
          </span>
        </div>
      </div>
    </>
  )
}

export default HomeFooter
