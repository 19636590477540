import React, { useEffect, useState } from "react"
import { CustomProvider, IconButton, Pagination, Table, Input } from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import CheckRoundIcon from "@rsuite/icons/CheckRound"
import ViewsUnauthorizeIcon from "@rsuite/icons/ViewsUnauthorize"
import ViewsAuthorizeIcon from "@rsuite/icons/ViewsAuthorize"
import useWindowDimensions from "common/useWindowDimensions"
import { connect, useDispatch } from "react-redux"
import { getRefReport } from "store/actions"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import { ShowSwal } from "common/ShowSwal"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"
import SearchInput from "common/SearchInput"
import EventDetailIcon from "@rsuite/icons/EventDetail"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import { Link, useHistory, useParams } from "react-router-dom"

const { Column, HeaderCell, Cell } = Table

function ReportRefTable(props) {
  // const [limit, setLimit] = useState(50)
  // const [page, setPage] = useState(1)
  const [search, setSearch] = useState({
    submit: false,
    value: "",
  })
  const [action, setAction] = useState(false)
  const { height, width } = useWindowDimensions()
  const dispatch = useDispatch()
  const data = props?.data

  const downloadClickNoticePdf = file => {
    const request = {
      token: props?.token,
      folder: "userGnrtdNotice",
      filename: file,
    }
    DownloadApiCall(request, "download/single-download", "_blank")
  }

  // const handleChangeLimit = dataKey => {
  //   setPage(1)
  //   setLimit(dataKey)
  // }

  // const handleChangeStatus = (id, status) => {
  //   const request = {
  //     token: props?.token,
  //     bulkCaseUploadId: id,
  //     new_status: status,
  //   }
  //   ShowSwal("Are you sure?", "", "warning", "Yes, Change it!", result => {
  //     if (result.isConfirmed) {
  //       ApiCall(request, "user/pdf-generate-mark-changecsv", false, result => {
  //         if (result?.data?.response === "success") {
  //           setAction(!action)
  //           ShowNotification(result?.data?.message, "success")
  //         } else {
  //           ShowNotification(result?.data?.message, "danger")
  //         }
  //       })

  //     }
  //   })
  // }

  useEffect(() => {
    // const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props?.token,
      searchValue: search?.value,
    }
    dispatch(getRefReport(requestPayload))

  }, [action, search?.submit])

  return (
    <div className="table-responsive" style={{ fontSize: "0.9em" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SearchInput
          placeholder="Reference/Notice ID"
          search={search}
          setSearch={setSearch}
        />
      </div>
      <Table
        height={height - 350}
        data={data}
        wordWrap="break-word"
        loading={props?.dataloading}
      >
        <Column width={40} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          {/* <Cell dataKey="id" /> */}
          <Cell>
            {(rowdata, dataKey) => (
              <>
                <span>
                  { dataKey + 1 }
                </span>
              </>
            )}
          </Cell>
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Notice ID</HeaderCell>
          <Cell dataKey="notice_id" />
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Reference ID</HeaderCell>
          <Cell dataKey="Reference_ID" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Notice</HeaderCell>
          <Cell dataKey="table_name" />
        </Column>

        <Column width={150}>
          <HeaderCell>Date of notice</HeaderCell>
          <Cell dataKey="date_of_notice" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Batch Name</HeaderCell>
          <Cell dataKey="batchName" />
        </Column>
        
       
        <Column width={150}>
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                    <WhisperTooltip
                            placement="top"
                            trigger="hover"
                            tooltipMsg="View Track"
                          >
                            <Link
                              target="_blank"
                              to={{
                                pathname:
                                  "/user/track/" +
                                  btoa(rowdata.noticeId),
                                state: { prevPath: location.pathname },
                              }}
                            >
                              <IconButton
                                size="xs"
                                appearance="primary"
                                color="blue"
                                icon={<EventDetailIcon />}
                              />
                            </Link>
                          </WhisperTooltip>
                          &nbsp;
                          <WhisperTooltip
                            placement="top"
                            trigger="hover"
                            tooltipMsg="View Notice PDF"
                          >
                            <IconButton
                              size="xs"
                              appearance="primary"
                              color="orange"
                              icon={<VisibleIcon />}
                              onClick={() =>
                                downloadClickNoticePdf(
                                  rowdata?.user_notice_path
                                )
                              }
                            />
                          </WhisperTooltip>
                
              </>
            )}
          </Cell>
        </Column>
      </Table>
      {/* <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.tableCount}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      /> */}
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.RefReport.items,
  dataloading: state.RefReport.loading,
  token: state.Login.token,
})

export default connect(mapStateToProps)(ReportRefTable)
