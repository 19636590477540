import React, { useEffect, useState } from "react"
import { Stack } from 'rsuite';
import {
  Form,
  FormGroup,
  FormText,
  Col,
  Row,
  CustomInput,
  FormFeedback,
  Label,
} from "reactstrap"
import { FileUploader } from "react-drag-drop-files"
import {
  CheckPicker,
  CustomProvider,
  SelectPicker,
  Input,
  Button,
} from "rsuite"
import Editor from "common/Editor"
import { connect, useDispatch } from "react-redux"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { useHistory } from "react-router-dom"
import { ShowNotification } from "common/ShowNotification"
import { useLocation } from "react-router-dom"
import {
  getAllApproveUser,
  getAllExistingNoticeIdName,
  getBatchByNoticeId,
  getChangeUserSelect,
} from "store/actions"
import { getBatchByUserId, saveInoviceAnnexure } from "./InvoiceService";

function AutoReportComponents(props) {
  const requestData = {
    userName :null,
    userId: null,
    batchNames: [],
    reportOn: new Date().toLocaleString('sv-SE').replace(' ', 'T').slice(0, 16),
    token: props?.token,
  }
  const [batchNameList,setbatchNameList] = useState([])
  const [request, setRequest] = useState(requestData)
  const dispatch = useDispatch()
  const selectdatauser = props?.userdata?.map(item => ({
    label: item?.organization_name,
    value: item?.id,
  }))
  
  const [selectedbatch,setSelectedBatch] = useState([])

  const handleSubmit = e => {
    e.preventDefault()
    request?.userId &&
      request?.batchNames.length !== 0 &&
      [
        props?.setApiAction({ loading: true }),
        saveInoviceAnnexure(request)
        .then((result) => {
          if (result?.data?.response === "success") {
            ShowNotification(result?.data?.message, "success")
            setRequest({
              userName :null,
              userId: null,
              batchNames: [],
              reportOn: new Date().toLocaleString('sv-SE').replace(' ', 'T').slice(0, 16),
              token: props?.token,
            })
            setSelectedBatch([])
            setbatchNameList([])

          } else {
            ShowNotification(result?.data?.message, "danger")
          }
          props?.setApiAction({ ...props?.apiAction, loading: false })
        }
          
          )
        
      ]
  }

  const AllFetchUserBySearch = (e) => {
    const requestPayload = {
      token: props?.token,
      search_text: e,
    }
    if(e.length >= 3) {
      dispatch(getChangeUserSelect(requestPayload))
    }
  }
  





  return (
    <div className="card">
      <div className="card-Body m-2">
        <Form method="post" onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <Label>Select User:</Label>
              <SelectPicker
                onSearch={(e) => AllFetchUserBySearch(e)}
                value={request?.userId}
                block
                className="mb-3"
                data={selectdatauser}
                onSelect={(e,value)=>{
                   setRequest({ ...request, userId:e,userName:value.label})
                }}
                onClean={()=>{setRequest({ ...request, userId:null,userName:null})
                setbatchNameList([])
              }}
                onChange={(e,lable) => {
                  let formData = new FormData()
                  formData.append("token", props?.token)
                  formData.append("userId", e)
                   getBatchByUserId(formData)
                   .then((res)=>{
                    if(res.status === 200){
                      setbatchNameList(res.data?.message.map(item => ({ label: item, value: item })))
                    }
                    else {
                      console.log(res.data)
                    }
                 })
                 .catch((err)=>{
                     console.log(err)
                 })
              }}
              />
            </Col>
            <Col md={4}>
            <Stack  direction="column" alignItems="flex-start">
            <Label>Select Batch:</Label>
    <CheckPicker data={batchNameList}  style={{maxWidth:'300px',minWidth:'250px'}} value={selectedbatch} onChange={(e)=>{setSelectedBatch()
      setRequest({...request,batchNames:e})
    }} />
  </Stack>
            </Col>
  
            <Col md={4}>
              <Label> Schedule On Date & Time:</Label>
              <Input
                type="datetime-local"
                name="reportOn"
                id="reportOn"
                value={request?.reportOn}
                onChange={e => setRequest({ ...request, reportOn: e })}
                block
              />
            </Col>
            <Col md={4}>
              <Button
                type="submit"
                className="my-4"
                // color="green"
                color="primary"
                size="md"
                block
              >
                {props?.apiAction?.loading ? "Loading..." : "Submit"}
              </Button>{" "}
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
  noticeData: state.ExistingNoticeIdName.items,
  batchData: state.BatchByNoticeId.items,
  userdata: state.AllOnChangeUser.items,
})

export default connect(mapStateToProps)(AutoReportComponents)
