import { combineReducers } from "redux"

// Front

import Breadcrumb from "./Breadcrumb/reducer"

// Authentication
import { Login } from "./auth/login/reducer"
import { Register } from "./auth/register/reducer"
import AssignNotice from "./AssignNotice/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import DraftNotice from "./DataNotice/reducer"
import { Profile } from "./auth/profile/reducer"
import {
  ExistingNotice,
  ExistingNoticeDetailsById,
  ExistingNoticeIdName,
  BatchByNoticeId
} from "./Admin/ExistingNotice/reducer"
import { CountData, DashboardCountData } from "./Admin/CountData/reducer"
import { FileSharing } from "./FileSharing/reducer"
import { CsvReport } from "./User/CsvReport/reducer"
import { RefReport } from "./User/RefReport/reducer"
import { PartyReply } from "./User/PartyReply/reducer"
import { EmailLog, WhatsappLog, SMSLog, CourierLog } from "./User/Log/reducer"
import { SubUser, SubUserNoticeList } from "./User/SubUser/reducer"
import OfferList from "./Admin/OfferList/reducer"
import AutoReport from "./Admin/AutoReport/reducer"
import AutoDownload from "./Admin/AutoDownload/reducer"
import { BotMisTrack } from "./User/BotMisTrack/reducer"


import {
  Users,
  UserDetails,
  UserLetterhead,
  AllLetterhead,
  AllApproveUser,
  AllOnChangeUser
} from "./Admin/Users/reducer"

//Calendar

const appReducer = combineReducers({
  // public
  //Breadcrumb items
  Breadcrumb,
  AssignNotice,
  DraftNotice,
  Login,
  ForgetPassword,
  Profile,
  Users,
  UserDetails,
  UserLetterhead,
  ExistingNotice,
  AllLetterhead,
  CountData,
  DashboardCountData,
  AllApproveUser,
  FileSharing,
  ExistingNoticeDetailsById,
  ExistingNoticeIdName,
  BatchByNoticeId,
  CsvReport,
  RefReport,
  PartyReply,
  EmailLog,
  WhatsappLog,
  SMSLog,
  CourierLog,
  SubUser,
  SubUserNoticeList,
  Register,
  OfferList,
  AutoReport,
  AutoDownload,
  AllOnChangeUser,
  BotMisTrack,
})

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
