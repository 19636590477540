import React, { useEffect, useState, useRef } from "react"
import {
  Button,
  Drawer,
  Modal,
  SelectPicker,
  Input,
  DatePicker,
  Grid,
  Row,
  Col,
  Form,
  CheckPicker,
  Uploader,
  useToaster,
  Message,
  Loader,
} from "rsuite"
import { FileUploader } from "react-drag-drop-files"
import FormGroup from "./FormGroup"
import useWindowDimensions from "common/useWindowDimensions"
import AvatarIcon from "@rsuite/icons/legacy/Avatar"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine"
import { Label } from "reactstrap"
import moment from "moment"
import { formatDate } from "utils/formatDate"

function DrwerBar({
  open,
  setOpen,
  itemId,
  title,
  body,
  subData = null,
  view = false,
  labelData = null,
  subDataFromHtml = null,
  defaultValue = "",
  size = "sm",
  forEditData,
  onClick = null,
  setState,
  state,
  error,
  setError,
  onCancel,
  loading = false,
  onClickCheck = null
}) {
  // forEditData =
  //   body === "editLetterhead" && itemId
  //     ? forEditData?.filter(value => {
  //         return parseInt(value.id) === parseInt(itemId)
  //       })
  //     : forEditData
  const [uploadfile, setUploadFile] = useState(null)
  const toaster = useToaster()
  const [uploading, setUploading] = React.useState(false)
  const [fileInfo, setFileInfo] = React.useState(null)

  const { height, width } = useWindowDimensions()

  const selectData = subData?.map(item => ({
    label: item?.name
      ? item?.name
      : item?.head_name
      ? item?.head_name
      : item?.organization_name
      ? item?.organization_name + " - " + item?.email
      : item.first_name + " " + item.last_name,
    value: item.id,
  }))

  const CheckPickerDefault = []
  body === "assignNotice" &&
    defaultValue
      ?.replace(/['"]+/g, "")
      ?.split(",")
      ?.map(value => {
        CheckPickerDefault.push(value?.replace("[", "")?.replace("]", ""))
      })

  const downloadClick = value => {
    const request = {
      token: state?.token,
      folder: state?.folder,
      filename: value,
    }
    DownloadApiCall(request, "download/single-download", "_blank")
  }

  // console.log(state)
  useEffect( () => {console.log("reached")}, [state?.ref_id_check,state?.ref_check_msg ]  )

  return (
    <div>
      <Modal size={size} open={open} onClose={onCancel}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: height - 160 }}>
          {body === "assignNotice" && (
            <CheckPicker
              data={selectData}
              placeholder="Select Notice"
              defaultValue={CheckPickerDefault}
              block
              onChange={e => setState(e)}
            />
          )}{" "}
          {body === "SubUserAdd" && (
            <>
              <CheckPicker
                data={selectData}
                placeholder="Select User"
                defaultValue={state?.subuser}
                block
                onChange={e => setState({ ...state, subuser: e })}
              />
              <br />
              <Input
                type="text"
                placeholder="Enter Relation Name"
                defaultValue={state?.relation_name}
                onChange={e => setState({ ...state, relation_name: e })}
              />
            </>
          )}{" "}
          {body === "assignOffers" && (
            <>
              <CheckPicker
                data={selectData}
                placeholder="Select Offers"
                defaultValue={state?.offer_ids}
                block
                onChange={e => setState({ ...state, offer_ids: e })}
              />
            </>
          )}
          {body === "editProfilePic" && (
            <>
              <FileUploader
                name="file"
                types={["jpeg", "png", "jpg"]}
                handleChange={e => setState({ ...state, profileImg: e })}
                onTypeError={e =>
                  e &&
                  setState({
                    ...state,
                    profileImg: null,
                  })
                }
              />
            </>
          )}
          {body === "UploadSigned" && (
            <>
              <FileUploader
                name="file"
                types={["zip"]}
                onTypeError={e => e && setState(null)}
                handleChange={e => setState(e)}
              />
              <span style={{ color: "#ff0000cc" }}>
                1. Create a folder containing all files, zip that folder and
                upload.
                <br />
                <br /> NOTE: Ensure that the PDFs are named as: IN85-
                {"{Notice ID}"}
                .pdf. please name them as IN62-101, IN62-102, IN62-103 and so
                on, OR as per refrence ID ( As Downloaded )
              </span>
            </>
          )}
          {body === "CourierCsv" && (
            <>
              <Form fluid className="mt-4">
                <Grid fluid>
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        name="type"
                        datalabel="Courier Type"
                        onChange={e => setState({ ...state, courier_type: e })}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <FileUploader
                        name="file"
                        types={["csv"]}
                        onTypeError={e =>
                          e &&
                          setState({
                            ...state,
                            user_bulk_data: null,
                          })
                        }
                        handleChange={e =>
                          setState({ ...state, user_bulk_data: e })
                        }
                      />
                    </Col>
                  </Row>
                </Grid>
              </Form>
              <span style={{ color: "#ff0000cc" }}>
                NOTE: In the Notice_Number column, if there are multiple
                couriers for the same notice ID, please add the notice numbers
                in the following order: 62-101, 62-101_1, 62-101_2 and so on
              </span>
            </>
          )}
          {body === "CourierPdf" && (
            <>
              <Form fluid className="mt-4">
                <Grid fluid>
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        name="type"
                        datalabel="Courier Type"
                        onChange={e => setState({ ...state, courier_type: e })}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <FileUploader
                        name="file"
                        types={["zip"]}
                        onTypeError={e =>
                          e &&
                          setState({
                            ...state,
                            user_bulk_data: null,
                          })
                        }
                        handleChange={e =>
                          setState({ ...state, user_bulk_data: e })
                        }
                      />
                    </Col>
                  </Row>
                </Grid>
              </Form>
              <span style={{ color: "#ff0000cc" }}>
                NOTE: <br />
                <ol>
                  <li>Create a folder of any name without space.</li>
                  <li>
                    Add all the PDF to above folder and Ensure that the PDFs are
                    named as: IN85-{"{Notice ID}"}.pdf. In case of multiple
                    couriers for the same notice ID, please name them as
                    IN62-101, IN62-101_1, IN62-101_2 and so on.{" "}
                  </li>
                  <li>Now zip the above folder and upload.</li>
                </ol>
              </span>
            </>
          )}
          {body === "UploadBulkData" && (
            <>
              <Form fluid className="mt-4">
                <Grid fluid>
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <FileUploader
                        name="file"
                        types={["csv"]}
                        onTypeError={e =>
                          e && setState({ ...state, user_bulk_data: null })
                        }
                        handleChange={e =>
                          setState({ ...state, user_bulk_data: e })
                        }
                      />
                      {/* {error?.user_bulk_data && (
                        <span style={{ color: "red" }}>
                          {error?.user_bulk_data}
                        </span>
                      )} */}
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        name="batchname"
                        datalabel="Batch Name"
                        onChange={e => setState({ ...state, batchName: e })}
                        // error={error?.batchName ? error?.batchName : null}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        name="letterhead"
                        datalabel="Select Letter Head"
                        select={true}
                        selectData={selectData}
                        onChange={e => setState({ ...state, lhead: e })}
                        // error={error?.lhead ? error?.lhead : null}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        name="use"
                        datalabel="Want To Use Signature Of Above Letter Head ?"
                        select={true}
                        datavalue={state?.is_sig}
                        selectData={[
                          { label: "Yes", value: 1 },
                          { label: "No", value: 2 },
                        ]}
                        onChange={e => setState({ ...state, is_sig: e })}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        name="use_check"
                        datalabel="Want To Check For Refrence Id ?"
                        select={true}
                        datavalue={state?.ref_id_check}
                        selectData={[
                          { label: "Yes", value: 1 },
                          { label: "No", value: 0 },
                        ]}
                        onChange={e => setState({ ...state, ref_id_check: e })}
                      />
                    </Col>
                    {state?.ref_check_msg !==null && <Col xs={24} sm={24} md={24}>
                      <p style={{ color: 'red' }}>{state?.ref_check_msg}</p>
                    </Col>}
                  </Row>
                </Grid>
              </Form>
            </>
          )}
          {body === "editProfile" && (
            <>
              <Form fluid className="mt-4">
                <Grid fluid>
                  <Row>
                    <Col xs={24} sm={12} md={12}>
                      <FormGroup
                        name="username"
                        datalabel="Username"
                        datavalue={forEditData?.username}
                        onChange={e => setState({ ...state, username: e })}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <FormGroup
                        name="designation"
                        datalabel="Designation"
                        datavalue={forEditData?.designation}
                        onChange={e => setState({ ...state, designation: e })}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        name="email"
                        datalabel="Email"
                        type="email"
                        datavalue={forEditData?.email}
                        onChange={e => setState({ ...state, email: e })}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <FormGroup
                        name="organizationName"
                        datalabel="Organization Name"
                        datavalue={forEditData?.organization_name}
                        onChange={e =>
                          setState({ ...state, organization_name: e })
                        }
                      />
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <FormGroup
                        name="number"
                        datalabel="Mobile Number"
                        datavalue={forEditData?.contact}
                        onChange={e => setState({ ...state, contact: e })}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <FormGroup
                        name="address1"
                        datalabel="Address Line 1"
                        datavalue={forEditData?.address1}
                        onChange={e => setState({ ...state, address1: e })}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <FormGroup
                        name="address2"
                        datalabel="Address Line 2"
                        datavalue={forEditData?.address2}
                        onChange={e => setState({ ...state, address2: e })}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <FormGroup
                        name="city"
                        datalabel="City"
                        datavalue={forEditData?.city}
                        onChange={e => setState({ ...state, city: e })}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <FormGroup
                        name="pinCode"
                        datalabel="Pin Code"
                        datavalue={forEditData?.pin_Code}
                        onChange={e => setState({ ...state, pin_Code: e })}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <FormGroup
                        name="state"
                        datalabel="State"
                        datavalue={forEditData?.state}
                        onChange={e => setState({ ...state, state: e })}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <FormGroup
                        name="country"
                        datalabel="Country"
                        datavalue={forEditData?.country}
                        onChange={e => setState({ ...state, country: e })}
                      />
                    </Col>
                    {/* <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        upload={true}
                        datalabel="Profile"
                        types={["jpeg", "jpg", "png"]}
                      />
                    </Col> */}
                  </Row>
                </Grid>
              </Form>
            </>
          )}
          {body === "editOffer" && (
            <>
              <Form fluid className="mt-4">
                <Grid fluid>
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        name="offer_name"
                        datalabel="Offer Name"
                        datavalue={state?.name}
                        onChange={e => setState({ ...state, name: e })}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        textarea={true}
                        name="description"
                        datalabel="Description"
                        datavalue={state?.description}
                        onChange={e => setState({ ...state, description: e })}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        name="offer_value"
                        datalabel="Offer Value"
                        type="offer_value"
                        datavalue={state?.offer_value}
                        onChange={e => setState({ ...state, offer_value: e })}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <Label className="mt-2" for="valid_from">
                        Valid Date From :
                      </Label>
                      <Input
                        type="date"
                        name="valid_from"
                        id="valid_from"
                        defaultValue={moment(state?.valid_from).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={e => setState({ ...state, valid_from: e })}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <Label className="mt-2" for="valid_to">
                        Valid Date To :
                      </Label>
                      <Input
                        type="date"
                        name="valid_to"
                        id="valid_to"
                        defaultValue={moment(state?.valid_to).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={e => setState({ ...state, valid_to: e })}
                      />
                    </Col>
                  </Row>
                </Grid>
              </Form>
            </>
          )}
          {body === "AddNotes" && (
            <>
              <Form fluid className="mt-4">
                <Grid fluid>
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        name="notes"
                        textarea={true}
                        datalabel="Notes"
                        datavalue={
                          state?.user_notes && state?.user_notes != 0
                            ? state?.user_notes
                            : ""
                        }
                        onChange={e => setState({ ...state, user_notes: e })}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <FileUploader
                        name="file"
                        multiple
                        onTypeError={e =>
                          e &&
                          setState({
                            ...state,
                            notesfile: null,
                            totalFiles: null,
                          })
                        }
                        handleChange={e =>
                          setState({
                            ...state,
                            notesfile: e,
                            totalFiles: e.length,
                          })
                        }
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      {/* {Object.entries(forEditData).map(([key, value]) => <>{value}<br /></>)} */}
                      {/* {forEditData?.map((value) => <>{value}</>)} */}
                      {/* <span>File</span> */}
                      {/* <FileUploader name="file" multiple handleChange={e => setState({ ...state, notesfile: e, totalFiles: e.length })} /> */}
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <label>Attachment Date</label>
                      <Input
                        type="date"
                        onChange={e => setState({ ...state, attach_date: e })}
                      />
                      {/* <FormGroup
                        datepicker={true}
                        datalabel="Attachment Date"
                        onChange={e => setState({ ...state, attach_date: e })}
                      /> */}
                    </Col>
                  </Row>
                </Grid>
              </Form>
            </>
          )}
          {body === "editLetterhead" && (
            <>
              <Form fluid className="mt-4">
                <Grid fluid>
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        name="name"
                        datalabel="Name"
                        datavalue={forEditData?.head_name}
                        onChange={e => setState({ ...state, head_name: e })}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <FormGroup
                        name="headerMargin"
                        datalabel="Letterhead Header Margin (in mm) :"
                        datavalue={forEditData?.head_margin}
                        onChange={e => setState({ ...state, head_margin: e })}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <FormGroup
                        name="footerMargin"
                        datalabel="Letterhead Footer Margin (in mm) :"
                        datavalue={forEditData?.foot_margin}
                        onChange={e => setState({ ...state, foot_margin: e })}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        upload={true}
                        datalabel="Letterhead"
                        types={["jpeg", "jpg", "png"]}
                        onChange={e => setState({ ...state, letterhead: e })}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        upload={true}
                        datalabel="Specimen Signature"
                        types={["jpeg", "jpg", "png"]}
                        onChange={e => setState({ ...state, signature: e })}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <FormGroup
                        name="signature_width"
                        datalabel="Letterhead Signature Width (in px) :"
                        datavalue={forEditData?.signature_width}
                        onChange={e =>
                          setState({ ...state, signature_width: e })
                        }
                      />
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <FormGroup
                        name="signature_height"
                        datalabel="Letterhead Signature Height (in px) :"
                        datavalue={forEditData?.signature_height}
                        onChange={e =>
                          setState({ ...state, signature_height: e })
                        }
                      />
                    </Col>
                  </Row>
                </Grid>
              </Form>
            </>
          )}
          {body === "view" && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: subDataFromHtml,
                }}
              />
            </>
          )}
          {body === "viewMsg" && (
            <>
              <div>{subDataFromHtml}</div>
            </>
          )}
          {body === "viewBulkFormat" && (
            <>
              {subDataFromHtml?.map((item, index) => {
                return (
                  <p key={index}>
                    <ArrowRightLineIcon
                      style={{ fontSize: "1.5em", color: "green" }}
                    />
                    {item}
                  </p>
                )
              })}
            </>
          )}
          {body === "addRemarks" && (
            <>
              <Form fluid className="mt-4">
                <Grid fluid>
                  <Row>
                    <Col xs={23} sm={23} md={23}>
                      <FormGroup
                        name="remarks"
                        textarea={true}
                        datalabel="Remarks"
                        datavalue={state?.remark ? state?.remark : ""}
                        onChange={e => setState({ ...state, remark: e })}
                      />
                    </Col>
                  </Row>
                </Grid>
              </Form>
            </>
          )}
          {body === "shareFile" && (
            <>
              <Form fluid className="mt-4">
                <Grid fluid>
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        upload={true}
                        datalabel="Upload File"
                        types={["zip", "rar", "pdf", "csv"]}
                        onChange={e => setState({ ...state, file_name: e })}
                      />
                    </Col>
                    {subData && (
                      <Col xs={24} sm={24} md={24}>
                        <FormGroup
                          name="user"
                          select={true}
                          datalabel="Share With"
                          selectData={subData}
                          onChange={e => setState({ ...state, shared_with: e })}
                        />
                      </Col>
                    )}
                    <Col xs={24} sm={24} md={24}>
                      <FormGroup
                        name="remarks"
                        datalabel="Remarks"
                        onChange={e => setState({ ...state, remarks: e })}
                      />
                    </Col>
                  </Row>
                </Grid>
              </Form>
            </>
          )}
          {body === "SendNotice" && (
            <>
              <label>Schedule on ( Not Mandatory )</label>
              <DatePicker
               defaultValue={new Date().toString()}
                onChangeCalendarDate={e => setState(e)}
                format="dd-MM-yyyy HH:mm"
                block
              />
              {/* <Input type="datetime-local" onChange={e => console.log(e)} /> */}
            </>
          )}
          {body === "ViewAttachment" && (
            <>
              {subDataFromHtml?.map((value, index) => {
                return (
                  <>
                    <div key={index}>
                      <Button
                        appearance="ghost"
                        color="green"
                        block
                        onClick={() => downloadClick(value[0])}
                      >
                        {value[0]}
                      </Button>
                      <br />
                    </div>
                  </>
                )
              })}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onCancel} appearance="subtle">
            Cancel
          </Button>
          {body === "UploadBulkData" ? state?.ref_id_check && state?.ref_check_msg == null ?  <Button onClick={onClickCheck} color="red" appearance="primary" disabled={loading}>
              {loading ? "Loading..." : "Check"}
            </Button>:<Button onClick={onClick} appearance="primary" disabled={loading}>
              {loading ? "Loading..." : "Submit"}
            </Button> : !view && (
            <Button onClick={onClick} appearance="primary" disabled={loading}>
              {loading ? "Loading..." : "Submit"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DrwerBar
