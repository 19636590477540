import React , {useEffect} from "react"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import {
  Row,
  Col,
} from "reactstrap"

import { setBreadcrumbItems } from "../../../store/actions";
import ExistingNoticeTable from "./ExistingNoticeTable";

const ExistingNotice = (props) => {

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Existing Notice", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Existing Notice' , breadcrumbItems)
  },)

  return (
    <React.Fragment>

      <MetaTags>
        <title>Existing Notice - Admin | Incase360</title>
      </MetaTags>

      <ExistingNoticeTable />

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(ExistingNotice);