import { TabBar } from "common/TabBar/TabBar"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import { CustomProvider } from "rsuite"
import { setBreadcrumbItems } from "../../../store/actions"
import ApprovedUsers from "./ApprovedUsers"

const UserApproved = props => {
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Approved Users", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Users - Approved", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Approved Users - Admin | Incase360</title>
      </MetaTags>

      <CustomProvider theme="dark">
        <ApprovedUsers />
      </CustomProvider>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(UserApproved)
