import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import { CustomProvider, IconButton } from "rsuite"
import ShareRoundIcon from "@rsuite/icons/ShareRound"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions"
import UserShareFileTable from "./UserShareFileTable"
import DrwerBar from "components/Common/DrwerBar"
import { TabBar } from "common/TabBar/TabBar"
import { ShowNotification } from "common/ShowNotification"
import { ApiCall } from "common/ActionApiCall/ApiCall"

const UserFileSharing = props => {
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Shared File List", link: "#" },
  ]

  const [openShareFileDrawer, setOpenShareFileDrawer] = useState(false)
  const [action, setAction] = useState(false)

  const [request, setRequest] = useState({
    token: props?.token,
    file_name: null,
    remarks: null,
    user_type: 2,
  })

  const [active, setActive] = useState("userShare")

  const tabbardata = [
    {
      eventkey: "userShare",
      lable: "Share With Admin",
    },
    { eventkey: "shareByAdmin", lable: "Share By Admin" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Shared File List", breadcrumbItems)
  })

  const handleopenShareFileDrawer = () => {
    setOpenShareFileDrawer(true)
  }

  const handleShareFileAction = () => {
      console.log("Function calling to File Share...")
    request.file_name &&
      ApiCall(request, "admin/addfile-sharing", false, result => {
           console.log(`Result from Server ${JSON.stringify(result)}`)
        if (result?.data?.response === "success") {
          ShowNotification(result?.data?.message, "success")
          // history.push("/admin/existingnotice")
          setAction(!action)
          setOpenShareFileDrawer(false)
        } else {
          ShowNotification(result?.data?.message, "danger")
           console.log(JSON.stringify(result?.data?.message))
        }
      })
  }

  const handleClose = () => {
    setOpenShareFileDrawer(false)
    setRequest({
      ...request,
      file_name: null,
      remarks: null,
    })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Shared File List - User | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        {openShareFileDrawer && (
          <DrwerBar
            open={openShareFileDrawer}
            setOpen={setOpenShareFileDrawer}
            title="Share File With Admin"
            body="shareFile"
            size="xs"
            onClick={() => handleShareFileAction()}
            state={request}
            setState={setRequest}
            onCancel={handleClose}
          />
        )}
        <div className="addButtonLetter">
          <WhisperTooltip
            placement="top"
            trigger="hover"
            tooltipMsg="Share File With User"
          >
            <IconButton
              size="xs"
              appearance="primary"
              color="violet"
              icon={<ShareRoundIcon />}
              onClick={() => handleopenShareFileDrawer()}
            >
              Share File
            </IconButton>
          </WhisperTooltip>
        </div>
        <TabBar
          style={{ textAlign: "center" }}
          appearance="tabs"
          active={active}
          onSelect={setActive}
          panel={tabbardata}
        />
        {active === "userShare" && (
          <>
            <UserShareFileTable />
          </>
        )}
        {active === "shareByAdmin" && (
          <>
            <UserShareFileTable user_type="2" />
          </>
        )}
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(UserFileSharing)
