import Spinner from "react-bootstrap/Spinner"
import Button from "react-bootstrap/Button"

function Loader() {
  return (
    <>
      {/* <div> */}
      {/* <Button variant="primary" disabled> */}
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      &nbsp; &nbsp; &nbsp;
      <span className="ml-4">Loading...</span>
      {/* </Button>{" "} */}
      {/* <Button variant="primary" disabled> */}
      {/* <Spinner
        className="tex"
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      /> */}
      {/* Loading... */}
      {/* </Button> */}
      {/* </div> */}
    </>
  )
}

export default Loader
