import { TabBar } from "common/TabBar/TabBar"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import { CustomProvider } from "rsuite"

import { setBreadcrumbItems } from "../../../store/actions"
import NewRequest from "./NewRequest"

const UserNewRequest = props => {
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "New Request Users", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Users - New Request", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>New Request Users - Admin | Incase360</title>
      </MetaTags>

      <CustomProvider theme="dark">
        <NewRequest />
      </CustomProvider>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(UserNewRequest)
