import React from "react"
import "./notidiv.scss"
import useWindowDimensions from "common/useWindowDimensions"
import { Message } from "rsuite"
import { Link } from "react-router-dom"

function NotificationDiv(props) {
  const { height, width } = useWindowDimensions()

  return (
    <div style={{ height: height - 290 }} className="notification-show">
      {props?.count > 0 ? (
        props?.list?.map((value, index) => {
          return (
            <Link to={`/${value?.url}`} key={index}>
              <Message type="info" className="mb-3">
                {value?.title}
              </Message>
            </Link>
          )
        })
      ) : (
        <h3 className="text-center mt-5 ">Notification is Empty</h3>
      )}
    </div>
  )
}

export default NotificationDiv
