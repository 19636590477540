import {
  ViewsAuthorize as ViewsAuthorizeIcon,
  ViewsUnauthorize as ViewsUnauthorizeIcon,
} from "@rsuite/icons"
import SearchIcon from "@rsuite/icons/Search"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ApiRoute } from "common/ApiRoute"
import { ShowNotification } from "common/ShowNotification"
import { ShowSwal } from "common/ShowSwal"
import useWindowDimensions from "common/useWindowDimensions"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { IconButton, Input, InputGroup, Pagination, Table } from "rsuite"

const api_url = ApiRoute
const { Column, HeaderCell, Cell } = Table

const ReportTable = () => {
  let token = JSON.parse(localStorage.getItem("data")).token
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const [tableCount, setTableCount] = useState(0)
  const [search, setSearch] = useState({
    submit: false,
    value: "",
  })

  const [action, setAction] = useState(false)
  const { height } = useWindowDimensions()

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const handleChangeStatus = (id, status) => {
    const request = {
      token,
      bulkCaseUploadId: id,
      new_status: status,
    }

    ShowSwal("Are you sure?", "", "warning", "Yes, Change it!", result => {
      if (result.isConfirmed) {
        ApiCall(request, "user/pdf-generate-mark-changecsv", false, result => {
          if (result?.data?.response === "success") {
            setAction(prev => !prev)
            ShowNotification(result?.data?.message, "success")
          } else {
            ShowNotification(result?.data?.message, "danger")
          }
        })
      }
    })
  }

  const fetchData = async () => {
    setLoading(true)
    const displayStart = limit * (page - 1)
    const requestPayload = {
      token,
      page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      searchValue: search.value,
    }
    try {
      const response = await fetch(api_url + "proxyCsvReport", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestPayload),
      })

      const result = await response.json()

      if (result?.message === "success") {
        setData(result?.responseData)
        setTableCount(result?.responseData.length)
        // ShowNotification(result?.message, "success")
      } else {
        ShowNotification(result?.message, "danger")
      }
    } catch (error) {
      ShowNotification("Failed to fetch data", "danger")
    } finally {
      setLoading(false)
    }
  }
  // const fetchData = useCallback(async () => {

  // }, [token, limit, page])

  useEffect(() => {
    fetchData()
  }, [])

  const renderStatusCell = rowdata => (
    <span style={{ color: rowdata.status === 0 ? "red" : "green" }}>
      {rowdata.status === 0 ? "Pending" : "Completed"}
    </span>
  )

  const renderPdfGeneratedCell = rowdata => (
    <>
      <span style={{ color: rowdata.pdf_uploaded === 0 ? "red" : "green" }}>
        {rowdata.pdf_uploaded === 0 ? "Pending" : "Completed"}
      </span>
      <br />
      <WhisperTooltip
        placement="top"
        trigger="hover"
        tooltipMsg={
          rowdata.pdf_uploaded === 0 ? "Mark As Complete" : "Mark As Pending"
        }
      >
        <IconButton
          size="xs"
          appearance="primary"
          color={rowdata.pdf_uploaded === 0 ? "green" : "red"}
          icon={
            rowdata.pdf_uploaded === 0 ? (
              <ViewsAuthorizeIcon />
            ) : (
              <ViewsUnauthorizeIcon />
            )
          }
          onClick={() =>
            handleChangeStatus(rowdata.id, rowdata.pdf_uploaded === 0 ? 1 : 0)
          }
        />
      </WhisperTooltip>
    </>
  )
  return (
    <div className="table-responsive" style={{ fontSize: "0.9em" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <InputGroup className="mb-2" style={{ width: 200 }}>
          <Input
            placeholder="Search"
            // search={search}
            value={search.value}
            onChange={e => setSearch({ ...search, value: e })}
            type="text"
          />
          <InputGroup.Button onClick={() => fetchData()}>
            <SearchIcon />
          </InputGroup.Button>
        </InputGroup>
      </div>
      <Table
        height={height - 350}
        data={data}
        wordWrap="break-word"
        headerHeight={50}
        loading={loading}
      >
        <Column width={80} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell>
            {(rowdata, dataKey) => (
              <span>
                {page === 1 ? dataKey + 1 : limit * (page - 1) + dataKey + 1}
              </span>
            )}
          </Cell>
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Notice ID</HeaderCell>
          <Cell dataKey="notice_id" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Notice</HeaderCell>
          <Cell dataKey="table_name" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Created On</HeaderCell>
          <Cell dataKey="created_on" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Created By</HeaderCell>
          <Cell dataKey="enterpriseEmail" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Batch Name</HeaderCell>
          <Cell dataKey="batch_name" />
        </Column>
        <Column width={150}>
          <HeaderCell>Row</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <span>Total: {rowdata?.total_row}</span>
                <br />
                <span style={{ color: "green" }}>
                  Inserted: {rowdata?.inserted_row}
                </span>
                <br />
                <span style={{ color: "red" }}>
                  Failed: {rowdata?.failed_row}
                </span>
                <br />
                <span style={{ color: "darkcyan" }}>
                  PDF Generated: {rowdata?.pdf_generated}
                </span>
              </>
            )}
          </Cell>
        </Column>
        <Column width={150}>
          <HeaderCell>Status</HeaderCell>
          <Cell>{renderStatusCell}</Cell>
        </Column>
        <Column width={150}>
          <HeaderCell>Is Pdf Generated</HeaderCell>
          <Cell>{renderPdfGeneratedCell}</Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={tableCount}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

ReportTable.propTypes = {
  token: PropTypes.string.isRequired,
}

export default ReportTable
