import "rsuite/dist/rsuite.css"

import Loader from "common/Loader"
import SearchInput from "common/SearchInput"
import useWindowDimensions from "common/useWindowDimensions"
import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import { connect, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Col, CustomProvider, Grid, Pagination, Row, Table } from "rsuite"
import { getSubUserAssignNotice, removeNoticeList } from "store/actions"
import { getAssignNotice } from "store/AssignNotice/actions"

const { Column, HeaderCell, Cell } = Table

const NoticeTable = props => {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState({
    submit: false,
    value: "",
  })
  const dispatch = useDispatch()
  const params = useParams()
  const { height, width } = useWindowDimensions()

  let data = props?.data
  let filteredData = []
  for (let i = 0; i < data.length; i++) {
    if (data[i].nature_of_notice === "USER") {
      filteredData.push(data[i])
    }
  }
  data = filteredData
  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }
  const displayStart = limit * (page - 1)
  const requestPayload = {
    token: props.token,
    page: page,
    iDisplayStart: displayStart,
    iDisplayLength: limit,
    user_id: params?.id ? atob(params?.id) : "",
    searchValue: search?.value,
  }
  useEffect(() => {
    dispatch(removeNoticeList())
    if (search?.value) {
      params?.sub
        ? dispatch(getSubUserAssignNotice(requestPayload))
        : dispatch(getAssignNotice(requestPayload))
    }
  }, [page, limit, search?.submit])

  return (
    <React.Fragment>
      <div className="table-responsive" style={{ fontSize: "0.9em" }}>
        <CustomProvider theme="dark">
          <Grid fluid>
            <Row className="show-grid">
              <Col lg={18} md={18}>
                <p className="mb-3">
                  <b>
                    Note: The following notices are available for use. Click on
                    the number appearing in the relevant column to view details
                    and take appropriate action.
                  </b>
                </p>
                <Button
                  onClick={() => {
                    const newpayload = { ...requestPayload, searchValue: "" }
                    params?.sub
                      ? dispatch(getSubUserAssignNotice(newpayload))
                      : dispatch(getAssignNotice(newpayload))
                  }}
                  variant="outline-primary"
                >
                  View All Notice
                </Button>
              </Col>
              <Col lg={6} md={6}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <SearchInput
                    placeholder="Search"
                    search={search}
                    setSearch={setSearch}
                  />
                </div>
              </Col>
            </Row>
          </Grid>
          {data.length != 0 ? (
            <Table
              height={height - 300}
              data={data}
              wordWrap="break-word"
              rowHeight={46}
              loading={props.dataloading}
            >
              <Column width={100} align="center">
                <HeaderCell>Sr.No.</HeaderCell>
                {/* <Cell dataKey="noticeid" /> */}
                <Cell>
                  {(rowdata, dataKey) => (
                    <>
                      <span>
                        {page === 1
                          ? dataKey + 1
                          : limit * (page - 1) + dataKey + 1}
                      </span>
                    </>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Notice ID</HeaderCell>
                <Cell>{rowdata => <>{rowdata?.noticeid}</>}</Cell>
              </Column>
              <Column flexGrow={3}>
                <HeaderCell>Notice</HeaderCell>
                <Cell>
                  {rowdata => (
                    <>{rowdata?.noticeName?.replace("notice_", "")}</>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Draft</HeaderCell>
                <Cell>
                  {rowdata => (
                    <>
                      <Link
                        to={
                          params?.id
                            ? "/user/draft-notice/" +
                              rowdata.noticeid +
                              "/" +
                              params?.id
                            : "/user/draft-notice/" + rowdata.noticeid
                        }
                      >
                        {rowdata.draftCount}
                      </Link>
                    </>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Active</HeaderCell>
                <Cell>
                  {rowdata => (
                    <>
                      <Link
                        to={
                          params?.id
                            ? "/user/active-notice/" +
                              rowdata.noticeid +
                              "/" +
                              params?.id
                            : "/user/active-notice/" + rowdata.noticeid
                        }
                      >
                        {rowdata.activeCount}
                      </Link>
                    </>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Overdue</HeaderCell>
                <Cell>
                  {rowdata => (
                    <>
                      <Link
                        to={
                          params?.id
                            ? "/user/overdue-notice/" +
                              rowdata.noticeid +
                              "/" +
                              params?.id
                            : "/user/overdue-notice/" + rowdata.noticeid
                        }
                      >
                        {rowdata.overdueCount}
                      </Link>
                    </>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Escalated</HeaderCell>
                <Cell>
                  {rowdata => (
                    <>
                      <Link
                        to={
                          params?.id
                            ? "/user/escalated-notice/" +
                              rowdata.noticeid +
                              "/" +
                              params?.id
                            : "/user/escalated-notice/" + rowdata.noticeid
                        }
                      >
                        {rowdata.escalateCount}
                      </Link>
                    </>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Resolved</HeaderCell>
                <Cell>
                  {rowdata => (
                    <>
                      <Link
                        to={
                          params?.id
                            ? "/user/resolved-notice/" +
                              rowdata.noticeid +
                              "/" +
                              params?.id
                            : "/user/resolved-notice/" + rowdata.noticeid
                        }
                      >
                        {rowdata.resolveCount}
                      </Link>
                    </>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Rejected</HeaderCell>
                <Cell>
                  {rowdata => (
                    <>
                      <Link
                        to={
                          params?.id
                            ? "/user/rejected-notice/" +
                              rowdata.noticeid +
                              "/" +
                              params?.id
                            : "/user/rejected-notice/" + rowdata.noticeid
                        }
                      >
                        {rowdata.rejectedCount}
                      </Link>
                    </>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Scheduled</HeaderCell>
                <Cell>
                  {rowdata => (
                    <>
                      <Link
                        to={
                          params?.id
                            ? "/user/scheduled-notice/" +
                              rowdata.noticeid +
                              "/" +
                              params?.id
                            : "/user/scheduled-notice/" + rowdata.noticeid
                        }
                      >
                        {rowdata.draftScheduledCount}
                      </Link>
                    </>
                  )}
                </Cell>
              </Column>
            </Table>
          ) : (
            <div
              style={{
                height: "calc(100vh - 300px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              {props.dataloading ? <Loader /> : <p>Search to see notice</p>}
            </div>
          )}

          <Pagination
            className="my-4"
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={props?.data?.length}
            limitOptions={[50, 100, 200]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </CustomProvider>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.AssignNotice.items,
  dataloading: state.AssignNotice.loading,
  token: state.Login.token,
})

export default connect(mapStateToProps)(NoticeTable)
