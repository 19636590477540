import { AvField, AvForm } from 'availity-reactstrap-validation';
import axios from 'axios';
import { ApiRoute } from 'common/ApiRoute';
import { ShowNotification } from 'common/ShowNotification';
import React from 'react';
import { Link, useParams, withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const PasswordReset = ({ history, match }) => {
  const { token } = useParams()
  const handleValidSubmit = (event, values) => {
    console.log(values) // Log to see what values are being sent
    if (values.newPassword !== values.confirmPassword) {
      ShowNotification("Passwords do not match", "danger")
      return
    }

    axios
      .post(ApiRoute + "resetPassword", {
        token: token,
        newPassword: values.newPassword,
      })
      .then(response => {
        if (response.data.code === 200) {
          ShowNotification("Password has been successfully reset.", "success")
          history.push("/login")
        } else {
          ShowNotification(response.data.message, "danger")
        }
      })
      .catch(error => {
        if (error.response) {
          ShowNotification(error.response.data.message, "danger")
        } else {
          ShowNotification(
            "Failed to reset password. Please try again.",
            "danger"
          )
        }
      })
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5}>
          <Card>
            <CardBody>
              <h3 className="text-center mt-4">Reset Your Password</h3>
              <AvForm onValidSubmit={handleValidSubmit}>
                <AvField
                  name="newPassword"
                  label="New Password"
                  type="password"
                  placeholder="Enter new password"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Password is required",
                    },
                    pattern: {
                      value:
                        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})",
                      errorMessage: "Password must be stronger",
                    },
                  }}
                  style={{ marginBottom: "10px" }}
                />
                <AvField
                  name="confirmPassword"
                  label="Confirm New Password"
                  type="password"
                  placeholder="Confirm new password"
                  style={{ marginBottom: "10px" }}
                  required
                />
                <button className="btn btn-primary w-100" type="submit">
                  Reset Password
                </button>
              </AvForm>
              <p className=" text-center mt-3">
                Password must be at least 8 characters long and include an
                uppercase letter, a lowercase letter, a number, and a special
                character.
              </p>
              <div className="mt-3 text-center">
                <Link to="/login" className="">
                  Back to Login
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default withRouter(PasswordReset)
