import React from 'react'

function ManuallyBadge(props) {
  return (
    <>
      <span style={{backgroundColor:props.color, fontSize:"0.8em", borderRadius:"30%", color:"white"}} className="px-1 m-1">{props.show}</span>
    </>
  )
}

export default ManuallyBadge
