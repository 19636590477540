import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
// Redux
import { connect, useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import avatar from "../../../assets/images/users/Pic_profile.png"
import { logOut } from "store/actions"
import { RouteForShow } from "common/ApiRoute"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const dispatch = useDispatch()

  const [username, setusername] = useState("Admin")

  const handleLogout = () => {
    dispatch(logOut())
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={
              props?.user?.profileImg
                ? RouteForShow + "profileImg/" + props?.user?.profileImg
                : avatar
            }
            alt="Header Avatar"
          />
          <br />
          <p>{props?.user?.username}</p>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link
            to={`${parseInt(props?.role) === 1 ? "/admin" : "/user"}/profile`}
          >
            <DropdownItem>
              {" "}
              <i className="mdi mdi-account-circle font-size-17 text-muted align-middle me-1" />
              Profile{" "}
            </DropdownItem>
          </Link>

          {/* <DropdownItem tag="a" href="#">
            <i className="mdi mdi-wallet font-size-17 text-muted align-middle me-1"/>
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="mdi mdi-cog font-size-17 text-muted align-middle me-1"/>
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="mdi mdi-lock-open-outline font-size-17 text-muted align-middle me-1"/>
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <span
            style={{ cursor: "pointer" }}
            className="dropdown-item text-danger"
            onClick={handleLogout}
          >
            <i className="mdi mdi-power font-size-17 text-muted align-middle me-1 text-danger" />
            <span>Logout</span>
          </span>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

// ProfileMenu.propTypes = {
//   success: PropTypes.any,
//   t: PropTypes.any,
// }

const mapStatetoProps = state => ({
  role: state.Login.role,
  user: state.Profile.items,
})

export default withRouter(connect(mapStatetoProps)(ProfileMenu))
