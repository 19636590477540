import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import '../../index.css'
import { Modal } from 'rsuite'

export default function LogonbannerModle({isOpen,handleClose,handleAccept}) {
  return (
    <Modal open={isOpen} onClose={handleClose} className="centered-modal">
    <Modal.Body >
     <p style={{fontWeight:'bold'}}>Warning: Unauthorized Access Prohibited</p>  
     <p style={{fontWeight:'bold'}}>Notice:
     You are attempting to access a secured system. Access to this system is restricted to authorized personnel only. Unauthorized use of this system is strictly prohibited and may result in disciplinary action, as well as civil and criminal penalties.</p>
    
     <span style={{fontWeight:'bold'}}>
     I accept to abide by Incase360’s  <Link to='/terms-of-use'>Terms & Conditions</Link> and <Link to='/privacy-policy'>Privacy Policy</Link>.
     </span>
    </Modal.Body>
    <Modal.Footer style={{display:'flex',justifyContent:'space-between'}}>
      <button onClick={()=>{handleAccept()}}   className="btn btn-primary w-md waves-effect waves-light">
        Accept
      </button>
      <button onClick={handleClose}  className="btn btn-primary w-md waves-effect waves-light">
        Cancel
      </button>
    </Modal.Footer>
  </Modal>
  )
}
