import React, {useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, CardHeader, Container, Table ,   Button,
  Form,
  Label,
  Input,
  FormText,
} from "reactstrap"
import { Col, Grid, Row } from "rsuite"
import "./dispute.scss"
import { connect, useDispatch } from "react-redux";
// Redux
import {
  withRouter,
  Link,
  useHistory,
  Redirect,
  useParams,
} from "react-router-dom"
import Swalalert from 'sweetalert2';
import { ApiRoute } from "common/ApiRoute"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowSwal } from "common/ShowSwal"
import { ShowNotification } from "common/ShowNotification"
import moment from 'moment';
import axios from "axios";
import { FileUploader } from "react-drag-drop-files"
import FormGroup from "components/Common/FormGroup"


function DisputeAmount() {

    const dispatch = useDispatch();
    const api_url = ApiRoute
    const history = useHistory();
    const params = useParams();
    const [disputeReply, setDisputeReply] = useState('');
    const [reply, setReply] = useState('');
    const [partyName, setpartyName] = useState('');
    const [noticeId, setnoticeId] = useState('');
    const [notice_unq_id, set_notice_unq_id] = useState([]);
    const [replyData, setReplyData] = useState('');
    const [request, setRequest] = useState({
      token: null,
      notice_id: null,
      your_reply: null, 
      party_name: null,
      files: null,
    })
    const [replyFile, setReplyFile] = useState('');
    const [action, setAction] = useState(false)
  
    useEffect(() => {
  
        var values = {
          notice_id: params.notice_id,
          token: params.token,
          api_from: 'Reply',
        }
        axios.post(api_url + "smcb-notice-details", values).then(response => {
          set_notice_unq_id(response?.data?.responseData?.notice_unq_id), 
          setReplyData(response?.data?.responseData?.noticeData),
          setpartyName(response?.data?.responseData?.party_name),
            setRequest({
              ...request,
              party_name : response?.data?.responseData?.party_name,
              notice_id : params.notice_id,
              token : params.token,
            })
        })
    }, []);
  

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
            request?.token &&
            request?.notice_id &&
            request?.party_name &&
            request?.your_reply &&
            //request?.files &&
            ApiCall(request, "smcb-add-dispute-reply", false, result => {
                if (result?.data?.response === "success" ) {
                    setRequest({
                      ...request,
                      files: null,
                      party_name: null,
                      your_reply: null,
                    });
                    setAction(!action)
                    Swalalert.fire(
                        "Success",
                        "Your reply has been submitted",
                        "info",
                        "Ok"
                      ).then(result => {
                       window.location.href ="https://incase360.com";
                      })
        
                  } else {
                    ShowNotification(result?.data?.message, "danger")
                  }
              })
        } catch (error) {
          console.error(error);
          ShowNotification("An error occurred during the upload", "danger");
        }
      };



    return (

        <div>
            <React.Fragment>
            <MetaTags>
                <title>Restructure | Incase360</title>
            </MetaTags>
            <div className="journey">
                <div className="container">
                <div className="">
                   <div className="container-fluid">
                        <Grid fluid>
                            <CardBody className="p-4 body">
                                <div className="row mb-3">
                                    <div className="col-md-12 text-center">
                                    <h5> We're here to help! If you have any concerns or questions, we're just a message away.</h5>
                                    <p>Simply write down your concern in the message box below, and our dedicated support team will get back to you as soon as possible. We value your feedback and strive to provide you with the best possible assistance.</p>
                                    </div>
                                </div>
                            </CardBody>
                        </Grid>
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="card-box">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row mb-3">
                                                <div className="col-md-6 mb-3">
                                                    <Input
                                                        name="noticeId"
                                                        type="text"
                                                        value={notice_unq_id}
                                                        onChange={(e) =>  (e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Input
                                                        name="partyName"
                                                        type="text"
                                                        value={request?.party_name || "" }

                                                        placeholder="Enter Party Name"
                                                        onChange={e =>
                                                        setRequest({ ...request, party_name: e.target.value })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                <Input
                                                type="textarea"
                                                name="reply"
                                                id="reply"
                                                placeholder=" Reply"
                                                value={request?.your_reply || ""  }
                                                onChange={e =>
                                                    setRequest({ ...request, your_reply: e.target.value })
                                                }
                                                rows={5}
                                                />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className=" d-flex justify-content-center">
                                                <FileUploader
                                                    name="file"
                                                    onTypeError={e =>
                                                    e && setRequest({ ...request, files: null })
                                                    }
                                                    handleChange={e =>
                                                    setRequest({ ...request, files: e })
                                                    }
                                                />

                                                </div>
                                            </div>
                                            <Row className="show-grid text-center">
                                                <Button
                                                    appearance="primary"
                                                    onClick={handleSubmit}
                                                    color="warning"
                                                                >
                                                    Submit
                                                </Button>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="contactsection">
                <div className="container">
                <div className="journey-grid">
                    <Grid fluid>
                    <Row className="show-grid">
                
                    </Row>
                    </Grid>
                </div>
                </div>
            </div>
            </React.Fragment>
        </div>
      )
}

export default DisputeAmount
