import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch, useSelector } from "react-redux"
import { Button, Col, Input, Label, Row } from "reactstrap"
import { SelectPicker } from "rsuite"
import { getAllApproveUser,setBreadcrumbItems,getUserLetterhead } from "store/actions"
import "pages/User/bulkoption.scss"
import { ApiRoute} from "common/ApiRoute"
import {useParams } from "react-router-dom"
import { CustomProvider } from "rsuite"
import ApiNoticeTable from "./ApiNoticeTable"
import axios from "axios"
import BulkSendModal from "./BulkSendModal"



const ApiNotice = props => {
  const dispatch = useDispatch()
  const [selectedNotice, setselectedNotice] = useState("")
  const [NoticeList, setNoticeList] = useState([])
   const [noticeDetails,setNoticeDetails] = useState([])
   const [option,setOption] = useState(false)
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "API Notice", link: "#" },
  ]
  const [checkedKeys, setCheckedKeys] = useState([])
  const params = useParams()
  const [filterData,setFilterData] = useState(useSelector((state)=>state?.DraftNotice?.items.resultData))
  const [isFilter, setIsFilter] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({
    filterFrom: "",
    filterTo: "",
     filterExcept: "",
  })
  const [bulkData, setBulkData] = useState({
    token: props?.token,
    noticeId: params?.id,
    batchName: null,
    user_bulk_data: null,
    lhead: null,
    is_sig: 1,
    ref_id_check: 0,
    ref_check_msg: null,
  })
  const [bulkDataError, setBulkDataError] = useState({
    batchName: null,
    user_bulk_data: null,
    lhead: null,
  })
  let data = useSelector((state)=>state?.DraftNotice?.items.resultData)

  useEffect(() => {
    setBulkDataError({
      ...bulkDataError,
      batchName: !bulkData?.batchName ? "Required Field" : null,
      lhead: !bulkData?.lhead ? "Required Field" : null,
      user_bulk_data: !bulkData?.user_bulk_data
        ? "Please Select at list one file"
        : null,
    })
  }, [bulkData])

  useEffect(() => {
    props.setBreadcrumbItems(
      `${
        params?.userId ? "Sub User" : ""
      } Draft Notice Details - ${props?.data?.noticeName?.replace(
        "notice_",
        ""
      )}`,
      breadcrumbItems
    )
  },[NoticeList])

  const getNoticeApiList = e =>{
    const requestPayload = {
      searchValue: e,
    }
    if (e.length >= 3) {
      axios.post(`${ApiRoute}ApiAllNotice`,requestPayload)
        .then(res => {
          
          if (res.data?.message === "success") {
            console.log(res.data.response)
            const newArray = res.data.response.map(obj => ({
              label: obj.notice_type,
              value: obj.notice_id,
            }))
            setNoticeList(newArray)
             setNoticeDetails(res.data.response)
          } 
        })
        .catch(e => {
          setNoticeList([])
        })
    }
  }
  const handleFilter = () => {
       if (isFilter === false){
        console.log(filter)
         setCheckedKeys([])
    let newdata = data.filter(item => {
          const inRange = item.notice_unq_id >= filter.filterFrom && item.notice_unq_id <= filter.filterTo;
          const notExceptID = !filter.filterExcept || !filter.filterExcept.includes(item.notice_unq_id);
          return inRange && notExceptID;
        });
       setFilterData(newdata)
        setIsFilter(true)
       }
       else {
          setIsFilter(false)
          setFilterData(data)
          setFilter({
            filterFrom: "",
            filterTo: "",
             filterExcept: "",
       })
       }
}
 const reset = () => {
  setIsFilter(false)
  setFilter({
    filterFrom: "",
    filterTo: "",
     filterExcept: "",
})
setselectedNotice("")
setNoticeList([])
setCheckedKeys([])
   setOption(false)
 }

const handlebulkSend = (state) => {
     setOption(state)
}

  useEffect(() => {
    props.setBreadcrumbItems("API Notice", breadcrumbItems)
  })


  return (
    <React.Fragment>
      <MetaTags>
        <title>API Notice - Admin | Incase360</title>
      </MetaTags>
      <Row>
        <Col md={3}>
          <Label>Select Notice Template</Label>
          <SelectPicker
           onSearch={e => getNoticeApiList(e)}
            value={selectedNotice}
            block
            className="mb-3"
            data={NoticeList}
            onChange={e => {
              setselectedNotice(e)
               let onb = noticeDetails.filter((ele)=>{
                if(ele.notice_id === e){
                  return ele
                }
               })
               setNoticeDetails(onb)
                console.log(e)
            }}       />
        </Col>
        <Col md={2}>
          <Label for="rangeFrom">
            From Notice Id :*
          </Label>
          <Input
            type="text"
            // disabled={bulkData?.batchName}
            placeholder="From ex. IN12-1234"
            value={filter.filterFrom}
            onChange={e => {
              setFilter({ ...filter, filterFrom: e.target.value.replace(/\s/g, '') })
            }}
          />
        </Col>
        <Col md={2}>
          <Label  for="">
            To Notice Id :*
          </Label>
          <Input
            type="text"
            // disabled={bulkData?.batchName}
            placeholder="To ex. IN12-2345"
            value={filter.filterTo}
            onChange={e => {
              setFilter({ ...filter, filterTo: e.target.value.replace(/\s/g, '') })
            }}
          />
        </Col>
        <Col md={2}>
          <Label for="">
            Except Notice Id :
          </Label>
          <Input
            type="text"
            // disabled={bulkData?.batchName}
            placeholder="Except ex. IN12-23,IN12-34,IN12-45"
            value={filter.filterExcept}
            onChange={e => {
              setFilter({ ...filter, filterExcept: e.target.value.replace(/\s/g, '') })
            }}
          />
        </Col>
        <Col md={2} >
             <Button onClick={handleFilter} color="primary" style={{marginTop:"2em"}}> {isFilter ? "Show All Notice":"Apply Filter"}</Button>
        </Col>
        
        </Row>
        {
            checkedKeys.length !== 0 &&  <Button style={{"position":"absolute",zIndex:"9",right:"15px",top:"32%"}} color="success" onClick={()=>{setOption(true)}} >Bulk Action</Button>
        }
        <BulkSendModal open={option} setOpen={handlebulkSend} bulksendIds={checkedKeys} noticeDetails={noticeDetails} filter={filter} reset={reset}  />
       
         {
          selectedNotice && <CustomProvider theme="dark">
              
           <ApiNoticeTable 
           apiNoticeID={selectedNotice}
            NoticeList={isFilter === true ? filterData : data} 
             checkedKeys={checkedKeys}
             setCheckedKeys={setCheckedKeys}
           />
         </CustomProvider>
         }
        
  {/* <CsvUpload/> */}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.AllApproveUser.items,
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(ApiNotice)

