import React from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, CardHeader, Container } from "reactstrap"
import { Col, Grid, Row } from "rsuite"
import "./privacy.scss"

function Privacy() {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Privacy Policy | Incase360</title>
      </MetaTags>
      <div className="banner">
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none container"
          aria-hidden="true"
        >
          <Grid fluid>
            <Row className="show-grid text-center">
              <Col xs={24} sm={24} md={24}>
                <h2>
                  <b>Privacy Policy</b>
                </h2>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
      <Container className="mt-3 terms">
        <Row className="justify-content-center">
          <Col md={24} lg={24} xl={24}>
            <Card className="overflow-hidden">
              <CardBody className="p-4 body">
                <p>
                  This privacy policy governs the Services provided by
                  “Incase360” and applies to all the websites, products and
                  services provided by Incase360. This privacy policy (“Policy”)
                  is an electronic record in the form of an electronic contract
                  formed under the Information Technology Act, 2000, and the
                  rules made thereunder.
                </p>
                <p>
                  The terms “we / us / our / platform / Incase360”, individually
                  and collectively, refer to M/S. INCASE SOLUTIONS LLP and the
                  “Portal” or “platform” refers to the website{" "}
                  <a href="https://www.incase360.com">
                    https://www.incase360.com
                  </a>{" "}
                  and the terms “you / your / yourself / user” refer to any
                  legal person or entity accessing the platform who is competent
                  to enter into binding contracts, as per the provisions of the
                  Indian Contract Act, 1872.
                </p>
                <p>
                  This document is published and shall be construed in
                  accordance with the provisions of the Information Technology
                  Act, 2000 read with the Information Technology (Reasonable
                  Security Practices and Procedures and Sensitive Personal Data
                  or Information) Rules, 2011, that require publishing of the
                  privacy policy for collection, use, storage and transfer of
                  sensitive personal data or information.
                </p>
                <p>
                  Please read this Policy carefully. By using the platform, you
                  indicate that you understand, agree and consent to this
                  Policy. If you do not agree with the terms of this Policy,
                  please do not use the platform. The user expressly agrees that
                  we retain the sole and exclusive right to amend or modify the
                  Policy and the{" "}
                  <a href="https://www.incase360.com/terms-of-use">
                    Terms of Use{" "}
                  </a>
                  (“Terms”) without any prior permission or intimation to the
                  user, and the user expressly agrees that any such amendments
                  or modifications shall come into effect immediately. We
                  include the date when this Policy was last updated and
                  encourage you to review this Policy periodically.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Introduction</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  Incase360 is a software platform that enables creation of
                  customized templates, automation of communication workflow,
                  serving and tracking of communications digitally, recording
                  and analysing responses and negotiating terms for resolution.
                </p>
                <p>
                  We care about your privacy and protect the information that we
                  collect from you when you use our platform. We handle the
                  information collected from you in accordance with this Policy.
                  By using our platform, you consent to the data collection
                  practices described in this Policy. This Policy is only
                  applicable to our platform, and not to any other platforms
                  that you may be able to access from our platform or any of our
                  partner website, each of which will be governed by separate
                  terms and may have data collection, storage, and use practices
                  and policies that differ materially from this Policy, for
                  which Incase360 shall not be responsible. Your use of the
                  platform is governed by this Policy and the Terms. Each time
                  you use the platform, the current version of this Policy will
                  govern your use. Accordingly, each time you use our platform,
                  you should check the date at the bottom of this Policy’s page
                  to be sure that you’ve read the most recent version.
                </p>
                <p>
                  You unequivocally agree that this Policy and the Terms
                  constitute a legally binding agreement between you and us, and
                  that you shall be subject to the terms applicable to any
                  service that is provided by the platform, and that the same
                  shall be deemed to be incorporated into the Terms and shall be
                  treated as part and parcel of the same. You acknowledge and
                  agree that no physical or electronic signature or express act
                  is required to make this Policy and the Terms binding on you,
                  and that your act of visiting any part of the platform
                  constitutes your full and final acceptance of this Policy and
                  the Terms.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Collection, Purpose and Usage of Information</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  We limit the collection and use of your information to the
                  minimum, to deliver superior service to our users. We do not
                  reveal your information to external organizations unless we
                  have previously informed you in disclosures or agreements,
                  have been authorized by you or are required by law. Below
                  information may be collected from you:
                </p>
                <p>
                  ● Personal Information
                  <br /> For the purpose of availing any services through our
                  platform, you may have to provide us with basic personal
                  information, including but not limited to first and last name,
                  e-mail address, office or residential address, nationality,
                  contact number, login credentials and passwords for the
                  purpose of using the platform (individually and collectively
                  hereinafter referred to as “personal information”). You are
                  required to provide information about yourself that is true,
                  accurate, current and complete in all respects.
                </p>
                <p>
                  ● Communication
                  <br /> Communications you send to us include but not limited
                  to requests for customer support or technical questions or any
                  other communication in relation to the services provided
                  through our platform.
                </p>
                <p>
                  ● Platform Usage Information
                  <br /> You are aware that our platform may automatically track
                  certain information about the user based upon your IP address
                  and behaviour on the platform, and you expressly consent to
                  the same. The user is aware that this information is used to
                  do internal research on user demographics, interests, and
                  behaviour, to enable the platform to better understand, and
                  cater to the interests of its users. The user is expressly
                  made aware that such information may include the URL that the
                  user visited prior to accessing the platform, the URL which
                  the user subsequently visits (whether or not these URLs form a
                  part of the platform), the user’s device and web browser
                  information, the user’s IP address, etc.
                </p>
                {/* <p>
                  ● Communication Communications
                  <br /> you send to us include but not limited to requests for
                  customer support or technical questions or any other
                  communication in relation to the services provided through our
                  platform.
                </p> */}
                {/* <p>
                  ● Device Information
                  <br /> We may collect information about your mobile device /
                  laptop / desktop / tablet, including but not limited to, the
                  hardware model, operating system and version, software and
                  file names and versions, preferred language, serial number,
                  device motion information, mobile network information, etc.
                  This shall be important to maintain the user experience and
                  future performance of the platform.
                </p> */}
                <p>
                  ● Device Information
                  <br /> We may collect information about your mobile device /
                  laptop / desktop / tablet, including but not limited to, the
                  hardware model, operating system and version, software and
                  file names and versions, preferred language, serial number,
                  device motion information, mobile network information, etc.
                  This shall be important to maintain the user experience and
                  future performance of the platform.
                </p>
                <p>
                  ● User Account
                  <br /> The user is aware that while you can browse some
                  sections of the platform without being a registered user,
                  certain activities require the user to provide valid personal
                  information to the platform for the purpose of registration.
                </p>
                <p>
                  ● Cookies
                  <br /> Cookies are text information files that are placed on
                  the visitor’s computer/ device when the visitor visits the
                  platform. Our platform, as well as most sites on the Internet
                  and mobile applications, use cookies to enhance user
                  experience. Our cookie notices available here:{" "}
                  <a href="https://incase360.com/cookie-policy">
                    https://incase360.com/cookie-policy
                  </a>{" "}
                  explains how Incase360 uses cookies and similar technologies
                  in the course of our Services.
                </p>
                <p>
                  ● Platform Analytics
                  <br /> We may use third party analytics services in connection
                  with our platform. These analytical services do not collect
                  personal information that you do not voluntarily enter on the
                  platform. These services neither track your browsing habits
                  across websites nor your usage of other mobile applications,
                  which do not use their services. We are using the information
                  collected from these services to find usability problems to
                  make the platform easier to use.
                </p>
                <p>
                  ● Sensitive Personal Data or Information <br /> For the
                  purpose of providing services through the platform, we may
                  have the requirement to collect Sensitive Personal Data or
                  Information (“SPDI”) from you while offering our services due
                  to the nature of the services being offered. SPDI shall have
                  the meaning in accordance with the Information Technology
                  (Reasonable Security Practices and Procedures and Sensitive
                  Personal Data or Information) Rules, 2011.
                </p>

                <p>
                  User information on the platform is collected and retained by
                  Incase360.
                </p>
                <p>
                  The user may review the information provided and ensure that
                  any information found to be inaccurate or deficient is
                  corrected or amended as feasible. However, we shall not be
                  responsible for the authenticity of the information provided
                  by the user.
                </p>
                <p>
                  The user shall, at any time, have an option to withdraw its
                  consent for the collection of information contemplated herein.
                  In such a case, the user shall write to us withdrawing consent
                  by way of an email addressed to{" "}
                  <a href="mailto:info@incase360.com">info@incase360.com</a>{" "}
                  Incase360 shall, upon a request made to that effect by the
                  user, erase and destroy all the data, record and
                  correspondence collected, preserved or retained for the
                  purpose of providing services through the platform, provided
                  Incase360 may retain data, record and correspondence in its
                  archive for a period of 03 (three) years or if required as per
                  the applicable law requirements.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Disclosure of Information</b>
                </h3>
              </CardHeader>

              <CardBody className="p-4 body">
                <h5>
                  <b>We may share your information</b>
                </h5>
                <p>
                  ● in response to a request for information by a competent
                  authority if we believe disclosure is in accordance with or is
                  otherwise required by, any applicable law, regulation, or
                  legal process.
                </p>
                <p>
                  ● with law enforcement officials, government authorities, if
                  we believe your actions are inconsistent with our Terms, other
                  terms and conditions and this Policy, or to protect our
                  rights, property, or safety or that of others.
                </p>
                <p>
                  ● if disclosure is intended to help investigate or prevent
                  unauthorized transactions or other illegal activities.
                </p>
                <p>
                  ● if necessary or appropriate to protect our rights or
                  property, or the rights or property of any person or entity.
                </p>
                <p>
                  ● if we otherwise notify you and you consent to the sharing.
                </p>
                <p>
                  ● we may share your de-identified personal information with
                  third parties to enable them to run statistical research on
                  individual or aggregate trends.
                </p>
                <p>
                  ● we share personal information and traffic data with our
                  business partners who assist us by performing core services
                  (such as consultations, maintenance of site, hosting, billing,
                  fulfilment, or data storage and security) related to our
                  operation of the platform and/or by making certain interactive
                  tools available to our users. Those business partners shall be
                  bound to uphold the same standards of security and
                  confidentiality that we have promised to you in this Policy,
                  and they will only use your personal information to carry out
                  their specific business obligations to Incase360.
                </p>
                <p>
                  We do not sell, exchange, transfer or give your information or
                  SDPI to third parties for any reason whatsoever except to the
                  extent required for fulfilment of our services.{" "}
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Rights under the GDPR</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  You have certain rights if you are within the European Union.
                  These include:
                </p>
                <p>
                  ● Right to access: This right allows you to obtain a copy of
                  your personal data as well as other supplementary information.
                </p>
                <p>
                  ● Right to restrict processing: You have the right to restrict
                  the processing of your personal data in certain circumstances.
                </p>
                <p>
                  ● Right to rectification: You have the right to have any
                  incomplete or inaccurate information, we hold about you,
                  corrected.
                </p>
                <p>
                  ● Right to object to processing: The right to object allows
                  you to stop or prevent us from processing your personal data.
                  This right exists where we are relying on a legitimate
                  interest as the legal basis for processing your personal data.
                  You also have the right to object where we are processing your
                  personal data for direct marketing purposes.
                </p>
                <p>
                  ● Right to erasure: You have the right to ask us to delete or
                  remove personal data when the personal data is no longer
                  necessary for the purpose for which it was originally
                  collected or processed.
                </p>
                <p>
                  To exercise your rights, you can contact us at{" "}
                  <a href="mailto:info@incase360.com">info@incase360.com</a>
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Rights under the CCPA</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  Users who are California residents have certain rights under
                  the California Consumer Privacy Act (“CCPA”). These rights
                  include:
                </p>
                <p>
                  ● <b>Right to know:</b> You have the right to request to know
                  more about the categories and specific pieces of personal
                  information that we have collected about you and access a copy
                  of your personal information.
                </p>
                <p>
                  ● <b>Right to deletion:</b> You have the right to request
                  deletion of personal information that we have collected about
                  you.
                </p>
                <p>
                  ● <b>Right to non-discrimination:</b> If you choose to
                  exercise any of your rights under CCPA, we will treat you like
                  all other users. In other words, there is no penalty for
                  exercising your rights under CCPA.
                </p>
                <p>
                  ● <b>Right to opt-out:</b> We do not sell personal
                  information.
                </p>
                <p>
                  To exercise any of these rights under CCPA, please email{" "}
                  <a href="mailto:info@incase360.com">info@incase360.com</a>
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Security Practices and Procedures</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  We have implemented appropriate managerial, technical,
                  operational and physical procedures to safeguard the
                  information of the user and to maintain data security. These
                  safeguards take into account the sensitivity of the
                  information that Incase360 collects, processes and stores and
                  the current state of technology.
                </p>
                <p>
                  Our hosting services maintains its systems in accordance with
                  reasonable industry standards to reasonably secure the
                  information of its customers, such as using SSL encryption in
                  certain places to prevent eavesdropping, and employing
                  up-to-date software on the server. Incase360 utilizes services
                  of a third-party payments processor which employs appropriate
                  safeguards for the purpose of payments made on the platform.
                </p>
                <p>
                  Incase360 follows generally accepted industry standards to
                  protect the information submitted to it. However, no method of
                  transmission over the internet, or method of electronic
                  storage, is 100% secure. Therefore, while we strive to use
                  commercially acceptable means to protect the information of
                  the users, we cannot guarantee its absolute security.
                </p>
                <p>
                  Incase360 assumes no liability or responsibility for
                  disclosure of the user’s information due to errors in
                  transmission, unauthorized third-party access, or other causes
                  beyond our control.
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Data Protection Officer/ Grievance Officer</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  The name and contact details of the Grievance Officer who can
                  be contacted with respect to any complaints or concerns
                  including those pertaining to breach of these terms contained
                  in the Policy, and other polices or questions are published as
                  under:
                </p>
                <p>
                  Data Protection/ Grievance Officer Name: Mr. Bhaven Shah
                  <br />
                  E-mail address:{" "}
                  <a href="mailto:bhaven@incase360.com">
                    bhaven@incase360.com
                  </a>{" "}
                </p>
              </CardBody>
              <CardHeader className="text-center">
                <h3>
                  <b>Miscellaneous</b>
                </h3>
              </CardHeader>
              <CardBody className="p-4 body">
                <p>
                  While we may retain any correspondence sent by you, we do not
                  intend to collect or save any information from children 18
                  years of age and younger. The platform is also not intended
                  for users who are not competent to contract under the
                  provisions of Indian Contract Act, 1872.
                </p>
                <p>
                  We reserve the right to remove or edit content and refuse
                  access to the platform at our sole discretion.
                </p>
                <br />
                <br />
                <p>Last updated 1st June 2023.</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Privacy
