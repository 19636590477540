const initialState = {
  items: [],
  loading: false,
  error: null,
}

const initialStateDashboard = {
  items: [],
  loading: false,
  error: null,
}

export const CountData = (state = initialState, action) => {
  switch (action.type) {
    case "CountDataBegin":
      return {
        ...state,
        loading: true,
        error: null,
      }
    case "CountDataSuccess":
      return {
        ...state,
        items: action.data,
        loading: false,
      }
    case "CountDataFailure":
      return {
        ...state,
        items: [],
        loading: false,
        error: action.data,
      }
    default:
      return state
  }
}

export const DashboardCountData = (state = initialStateDashboard, action) => {
  switch (action.type) {
    case "DashboardCountDataBegin":
      return {
        ...state,
        loading: true,
        error: null,
      }
    case "DashboardCountDataSuccess":
      return {
        ...state,
        items: action.data,
        loading: false,
      }
    case "DashboardCountDataFailure":
      return {
        ...state,
        items: [],
        loading: false,
        error: action.data,
      }
    default:
      return state
  }
}

// export default Users;
