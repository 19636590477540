import React, { useRef, useEffect } from "react"
import {
  Button,
  Col,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Row,
  Input,
} from "rsuite"
import MoreIcon from "@rsuite/icons/More"
import CloseOutlineIcon from "@rsuite/icons/CloseOutline"
import "../bulkoption.scss"
import { Link } from "react-router-dom"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import { FileUploader } from "react-drag-drop-files"

function BulkMenu({
  openSubMenu,
  bulkButton,
  fromToInput,
  setOpenSubMenu,
  state,
  setState,
  stateDelivery = null,
  setStateDelivery = null,
  setStateCsv = null,
}) {
  const ref = useRef()
  useEffect(() => {
    const closeDiv = e => {
      if (ref && !ref.current.contains(e.target)) {
        setOpenSubMenu(false)
        setStateCsv(null)
      }
    }
    document.body.addEventListener("click", closeDiv)
    return () => document.body.removeEventListener("click", closeDiv)
  }, [])

  return (
    <>
      <div ref={ref}>
        {openSubMenu && (
          <div
            className="subMenu"
            style={{
              // display: `${openSubMenu ? "block" : "none"}`
              opacity: `${!openSubMenu ? 0 : 1}`,
              transition: "all 1s",
              visibility: `${!openSubMenu ? "hidden" : "visible"}`,
            }}
          >
            <Grid fluid>
              <Row className="show-grid">
                <Col
                  lg={24}
                  md={24}
                  // sm={22}
                  // xs={22}
                >
                  <RadioGroup
                    name="radioList"
                    value={state}
                    onChange={setState}
                    inline
                  >
                    <Radio value="notice_id">Notice ID</Radio>
                    <Radio value="ref_id">Reference ID</Radio>
                  </RadioGroup>
                  {stateDelivery !== null && (
                    <>
                      <RadioGroup
                        name="radioList"
                        value={stateDelivery}
                        onChange={setStateDelivery}
                        inline
                      >
                        <Radio value="0">Without delivery tracking</Radio>
                        <Radio value="1">With delivery tracking</Radio>
                      </RadioGroup>
                    </>
                  )}
                  <FileUploader
                    name="file"
                    types={["csv"]}
                    onTypeError={e => e && setStateCsv(null)}
                    handleChange={e => setStateCsv(e)}
                  />
                </Col>
                {fromToInput &&
                  fromToInput?.map((value, index) => {
                    return (
                      <div key={index}>
                        <Col
                          lg={value?.id < 3 ? 12 : 24}
                          md={value?.id < 3 ? 12 : 24}
                          sm={24}
                          xs={24}
                          className="mt-3"
                        >
                          <Input
                            type="text"
                            name={value.name}
                            id={value.name}
                            placeholder={value.placeholder}
                            onChange={value?.onChange}
                            block
                          />
                        </Col>
                      </div>
                    )
                  })}

                {bulkButton &&
                  bulkButton?.map((value, index) => {
                    return (
                      <div key={index}>
                        {/* <Col
                      className={`${value.display} mt-3`}
                      md={2}
                      sm={2}
                      xs={2}
                    >
                      <span>{index + 1}</span>
                    </Col> */}
                        <Col
                          className={`${value.display} mt-3`}
                          lg={12}
                          md={12}
                          sm={24}
                          xs={24}
                        >
                          {value?.href ? (
                            <a href={value?.href}>
                              <Button
                                size={value.size}
                                appearance="primary"
                                color={value.color}
                                onClick={value?.onClick}
                                block
                              >
                                {value.label}
                              </Button>
                            </a>
                          ) : (
                            <Button
                              size={value.size}
                              appearance="primary"
                              color={value.color}
                              onClick={value?.onClick}
                              block
                            >
                              {value.label}
                            </Button>
                          )}
                        </Col>
                      </div>
                    )
                  })}
              </Row>
            </Grid>
          </div>
        )}

        <div className="moreOption">
          <WhisperTooltip
            placement="left"
            trigger="hover"
            tooltipMsg="Bulk Action"
          >
            {/* <IconButton
            
            icon={openSubMenu ? <CloseOutlineIcon /> : <MoreIcon />}
            circle
          />{" "} */}
            <Button
              onClick={() => {
                setOpenSubMenu(!openSubMenu)
                setStateCsv(null)
              }}
              size="sm"
              appearance="primary"
              color={openSubMenu ? "red" : "violet"}
            >
              {openSubMenu ? "Close" : "Bulk Action"}
            </Button>
          </WhisperTooltip>
        </div>
      </div>
    </>
  )
}

export default BulkMenu
