import React from "react"
import { FileUploader } from "react-drag-drop-files"
import { CheckPicker, DatePicker, Form, Input, SelectPicker } from "rsuite"

function FormGroup({
  name,
  datavalue = "",
  datalabel,
  disabled = false,
  textarea = false,
  select = false,
  selectData = [],
  upload = false,
  types,
  multipleSelect = false,
  datepicker = false,
  type,
  onChange = null,
  error = null,
  placeholder = null,
}) {
  return (
    <Form.Group controlId={name} className="mb-2">
      <Form.ControlLabel>{datalabel}</Form.ControlLabel>
      {textarea ? (
        <Input
          as="textarea"
          rows={3}
          defaultValue={datavalue}
          onChange={onChange}
          placeholder={placeholder}
        />
      ) : select ? (
        <SelectPicker
          block
          data={selectData}
          defaultValue={datavalue}
          onChange={onChange}
        />
      ) : upload ? (
        <FileUploader name="file" types={types} handleChange={onChange} />
      ) : multipleSelect ? (
        <CheckPicker
          block
          data={selectData}
          defaultValue={datavalue}
          onChange={onChange}
        />
      ) : datepicker ? (
        <DatePicker onChange={onChange} block format="dd-MM-yyyy" size="sm" />
      ) : (
        <Form.Control
          size="sm"
          name={name}
          defaultValue={datavalue || ""}
          disabled={disabled}
          type={type ? type : ""}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
      {error && <span style={{ color: "red" }}>{error}</span>}
    </Form.Group>
  )
}

export default FormGroup
