import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"

import { getAllApproveUser, setBreadcrumbItems } from "../../../store/actions"
import OfferForm from "./OfferForm"

const AddNewOffers = props => {
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Add New Offer", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Add New Offer", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Add Offer - Admin | Incase360</title>
      </MetaTags>

      <OfferForm />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.AllApproveUser.items,
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(AddNewOffers)
