import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

const CheckAccess = props => {
  return (
    <>
      {parseInt(props.role) === 1 ? (
        <Redirect to="/admin/dashboard" />
      ) : parseInt(props.role) === 0 ? (
        <Redirect to="/user/dashboard" />   
      ) : (
        <Redirect to="/login" />
      )}
    </>
  )
}

const mapStateToProps = state => ({
  role: state.Login.role,
})

export default connect(mapStateToProps)(CheckAccess)
