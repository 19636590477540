import 'react-notifications-component/dist/theme.css';

import ScrollToTop from 'common/ScrollToTop';
import useActivityBasedTokenRefresh from 'common/useActivityBasedTokenRefresh';
import UserVerticalLayout from 'components/UserVerticalLayout';
import React, { useEffect } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Pagemiddleware from 'routes/middleware/Pagemiddleware';

import AdminVerticalLayout from './components/AdminVerticalLayout';
import HomePageLayout from './components/HomePageLayout';
import MainPage from './pages/Home/MainPage';
import Reply from './pages/Home/Reply';
import { adminRoutes, authRoutes, homeRoutes, userRoutes } from './routes/allRoutes';
import Authmiddleware from './routes/middleware/Authmiddleware';
import OldReply from 'pages/Home/Reply/oldReply';

const App = () => {
  useActivityBasedTokenRefresh()

  const token = useSelector(state => state.Login.token)
  return (
    <React.Fragment>
      <ReactNotifications />

      <Router>
        <ScrollToTop>
          <Switch>
            <Route
              exact
              path="/"
              render={props => {
                return (
                  <HomePageLayout>
                    <MainPage {...props} />
                  </HomePageLayout>
                )
              }}
            />
            <Route
              path="/reply/:id"
              render={props => {
                return (
                  <HomePageLayout>
                    <OldReply {...props} />
                  </HomePageLayout>
                )
              }}
            />
            <Route
              path="/r"
              render={props => {
                return (
                  <HomePageLayout>
                    <Reply {...props} />
                  </HomePageLayout>
                )
              }}
            />
            {homeRoutes.map((route, idx) => (
              <Pagemiddleware
                path={route.path}
                layout={HomePageLayout}
                component={route.component}
                key={idx}
              />
            ))}
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={HomePageLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={UserVerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                token={token}
              />
            ))}
            {adminRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={AdminVerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                token={token}
              />
            ))}
          </Switch>
        </ScrollToTop>
      </Router>
    </React.Fragment>
  )
}

export default App