import React from "react"
import { Table } from "reactstrap"
import "./details.scss"

function Details({ data }) {
  return (
    <React.Fragment>
      <div className="table-responsive">
        <Table bordered className="detailsView">
          <tbody>
            {Object.keys(data).map(key => {
              return (
                <>
                  {key != "userNotes" &&
                    key != "active_On" &&
                    key != "esc_rsv_status" &&
                    key != "Id" &&
                    key != "overdue_On" &&
                    key != "resolved_On" &&
                    key != "escalated_On" &&
                    key != "user_notice_path" &&
                    key != "UserId" &&
                    key != "notice_status" && (
                      <tr>
                        <th>
                          {key.replace(/_/g, " ") == "notice unq id"
                            ? "Notice ID"
                            : key.replace(/_/g, " ") == "notesAttachment"
                            ? "Notes"
                            : key.replace(/_/g, " ") == "batchName"
                            ? "Batch Name"
                            : key.replace(/_/g, " ")}
                        </th>
                        <td>{data[key]}</td>
                      </tr>
                    )}
                </>
              )
            })}
            {/* <tr>
            <th>Notice ID :</th>
            <td></td>
          </tr>
          <tr>
            <th>Date of Legal Notification :</th>
            <td>Data testing</td>
          </tr>
          <tr>
            <th>Name of Service Recipient :</th>
            <td>Data testing Data testing</td>
          </tr>
          <tr>
            <th>Claimant Organisation Name :</th>
            <td>Data testing Data testing</td>
          </tr>
          <tr>
            <th>Full Name of Primary Respondent :</th>
            <td>
              Data testing Data testing Data testing Data testing Data testing
              Data testing
            </td>
          </tr>
          <tr>
            <th>Status of Primary Respondent :</th>
            <td></td>
          </tr>
          <tr>
            <th>Full Address of Primary Respondent :</th>
            <td></td>
          </tr>
          <tr>
            <th>Email ID of Primary Respondent :</th>
            <td></td>
          </tr>
          <tr>
            <th>WhatsApp of Primary Respondent :</th>
            <td></td>
          </tr>
          <tr>
            <th>Details of Other Respondent :</th>
            <td></td>
          </tr>
          <tr>
            <th>Email ID of Other Respondent :</th>
            <td></td>
          </tr>
          <tr>
            <th>WhatsApp of Other Respondent :</th>
            <td></td>
          </tr>
          <tr>
            <th>Customer ID :</th>
            <td></td>
          </tr>
          <tr>
            <th>Total Outstanding Amount :</th>
            <td></td>
          </tr>
          <tr>
            <th>Payment Link :</th>
            <td></td>
          </tr>
          <tr>
            <th>Name of Advocate :</th>
            <td></td>
          </tr>
          <tr>
            <th>Designation of Advocate :</th>
            <td></td>
          </tr>
          <tr>
            <th>Due Days for Response :</th>
            <td></td>
          </tr>
          <tr>
            <th>Reference ID :</th>
            <td></td>
          </tr>
          <tr>
            <th>Date of Generation :</th>
            <td></td>
          </tr>
          <tr>
            <th>User Notice Path :</th>
            <td></td>
          </tr>
          <tr>
            <th>Notice Status :</th>
            <td></td>
          </tr>
          <tr>
            <th>Activated On :</th>
            <td></td>
          </tr>
          <tr>
            <th>User Notes :</th>
            <td></td>
          </tr>
          <tr>
            <th>Notes Attachment :</th>
            <td></td>
          </tr>
          <tr>
            <th>Batch Name :</th>
            <td></td>
          </tr>
          <tr>
            <th>Head Margin :</th>
            <td></td>
          </tr>
          <tr>
            <th>Foot Margin :</th>
            <td></td>
          </tr>
          <tr>
            <th>Letterhead :</th>
            <td></td>
          </tr>
          <tr>
            <th>Signature :</th>
            <td></td>
          </tr>
          <tr>
            <th>Uploded CSV ID :</th>
            <td></td>
          </tr>
          <tr>
            <th>PDF Created :</th>
            <td></td>
          </tr> */}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  )
}

export default Details
