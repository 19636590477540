const initialState = {
    items: [],
    loading: false,
    count: 0,
    error: null,
};

const deatilsState = {
    items: [],
    loading: false,
    error: null
}

const letterheadState = {
    items: [],
    loading: false,
    count: 0,
    error: null
}

const approverUserState = {
    items: [],
    loading: false,
    error: null
}

const changeUserSelectState = {
    items: [],
    loading: false,
    error: null
}

export const UserDetails = (state = deatilsState, action) => {
    switch (action.type) {
        case "UserDetailsBegin":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "UserDetailsSuccess":
            return {
                ...state,
                items: action.data,
                loading: false,
            };
        case "UserDetailsFailure":
            return {
                ...state,
                items: [],
                loading: false,
                error: action.data,
            };
        default:
            return state;
    }
};

export const UserLetterhead = (state = letterheadState, action) => {
    switch (action.type) {
        case "UserLetterheadBegin":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "UserLetterheadSuccess":
            return {
                ...state,
                items: action.data,
                count: action.count,
                loading: false,
            };
        case "UserLetterheadFailure":
            return {
                ...state,
                items: [],
                loading: false,
                error: action.data,
            };
        default:
            return state;
    }
};

export const Users = (state = initialState, action) => {
    switch (action.type) {
        case "UsersListBegin":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "UsersListSuccess":
            return {
                ...state,
                items: action.data,
                count: action.count,
                loading: false,
            };
        case "UsersListFailure":
            return {
                ...state,
                items: [],
                loading: false,
                error: action.data,
            };
        default:
            return state;
    }
};

export const AllLetterhead = (state = letterheadState, action) => {
    switch (action.type) {
        case "AllLetterheadBegin":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "AllLetterheadSuccess":
            return {
                ...state,
                items: action.data,
                count: action.count,
                loading: false,
            };
        case "AllLetterheadFailure":
            return {
                ...state,
                items: [],
                loading: false,
                error: action.data,
            };
        default:
            return state;
    }
};

export const AllApproveUser = (state = approverUserState, action) => {
    switch (action.type) {
        case "AllApproveUserBegin":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "AllApproveUserSuccess":
            return {
                ...state,
                items: action.data,
                loading: false,
            };
        case "AllApproveUserFailure":
            return {
                ...state,
                items: [],
                loading: false,
                error: action.data,
            };
        default:
            return state;
    }
};

export const AllOnChangeUser = (state = changeUserSelectState, action) => {
    switch (action.type) {
        case "AllChangeUserSelectBegin":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "AllChangeUserSelectSuccess":
            return {
                ...state,
                items: action.data,
                loading: false,
            };
        case "AllChangeUserSelectFailure":
            return {
                ...state,
                items: [],
                loading: false,
                error: action.data,
            };
        default:
            return state;
    }
};

// export default Users;
