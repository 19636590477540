import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap"

// Redux
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"

import avatar from "../../../assets/images/users/Pic_profile.png"
// actions
// import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { getUserMe, logOut, setBreadcrumbItems } from "../../../store/actions"
import { RouteForShow } from "common/ApiRoute"
import DrwerBar from "components/Common/DrwerBar"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"
import { CustomProvider } from "rsuite"
import PasswordReset from "./PasswordReset"

const AdminProfile = props => {
  const dispatch = useDispatch()
  const [openEditProfileDrawer, setOpenEditProfileDrawer] = useState(false)
  const [updateRequest, setUpdateRequest] = useState([])
  const [profileDrawer, setProfileDrawer] = useState(false)
  const [profilePicRequest, setProfilePicRequest] = useState({
    token: props?.token,
    profileImg: null,
  })
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Profile", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Profile", breadcrumbItems)
  })

  // function handleValidSubmit(event, values) {
  //   props.editProfile(values)
  // }

  const handleOpenChangeProfile = () => {
    setProfileDrawer(true)
  }

  const handleopenEditProfileDrawer = () => {
    setOpenEditProfileDrawer(true)
    setUpdateRequest(props?.user)
    setTimeout(() => {
      setUpdateRequest(prev => ({ ...prev, token: props?.token }))
    }, 2000)
  }

  const handleClose = () => {
    openEditProfileDrawer && setOpenEditProfileDrawer(false)
    profileDrawer && [
      setProfileDrawer(false),
      setProfilePicRequest({
        ...profilePicRequest,
        profileImg: null,
      }),
    ]
  }

  const handleUpdateProfile = () => {
    ApiCall(updateRequest, "user/update-profile", false, result => {
      if (result?.data?.response === "success") {
        setOpenEditProfileDrawer(false)
        ShowNotification(result?.data?.message, "success")
        dispatch(
          getUserMe({ token: props?.token }, result => {
            result?.data?.code !== 200 && dispatch(logOut())
          })
        )
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  const handleChangeProfilePic = () => {
    profilePicRequest.profileImg &&
      ApiCall(profilePicRequest, "user/change-profile", false, result => {
        if (result?.data?.response === "success") {
          setProfileDrawer(false)

          ShowNotification(result?.data?.message, "success")
          dispatch(
            getUserMe({ token: props?.token }, result => {
              result?.data?.code !== 200 && dispatch(logOut())
            })
          )
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
  }

  useEffect(() => {
    const requestPayload = {
      token: props?.token,
    }
    dispatch(
      getUserMe(requestPayload, result => {
        result?.data?.code !== 200 && dispatch(logOut())
      })
    )
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Profile - Admin | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        {openEditProfileDrawer && (
          <DrwerBar
            open={openEditProfileDrawer}
            setOpen={setOpenEditProfileDrawer}
            title="Edit Profile"
            body="editProfile"
            forEditData={props?.user}
            size="sm"
            state={updateRequest}
            setState={setUpdateRequest}
            onClick={() => handleUpdateProfile()}
            onCancel={handleClose}
          />
        )}
        {profileDrawer && (
          <DrwerBar
            open={profileDrawer}
            setOpen={setProfileDrawer}
            title="Edit Profile Pic"
            body="editProfilePic"
            forEditData={props?.user}
            size="xs"
            state={profilePicRequest}
            setState={setProfilePicRequest}
            onClick={() => handleChangeProfilePic()}
            onCancel={handleClose}
          />
        )}
        <Row>
          <Col lg="4">
            <Card>
              <CardBody>
                <Media>
                  <div className="">
                    <img
                      src={
                        props?.user?.profileImg
                          ? RouteForShow +
                            "profileImg/" +
                            props?.user?.profileImg
                          : avatar
                      }
                      alt=""
                      className="avatar-md rounded-circle img-thumbnail"
                    />
                    <br />
                    <span
                      style={{
                        cursor: "pointer",
                        marginLeft: "0.5em",
                        color: "#3ab5dd",
                      }}
                      onClick={() => handleOpenChangeProfile()}
                    >
                      Change
                    </span>
                  </div>
                  <Media body className="align-self-center">
                    <div className="text-muted ms-2">
                      <h5>{props.user.authorised_r}</h5>
                      <p className="mb-1">{props.user.email}</p>
                      {/* <p className="mb-0">Id no: #{idx}</p> */}
                    </div>
                  </Media>
                </Media>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <PasswordReset />
              </CardBody>
            </Card>
          </Col>
          <Col lg="8">
            <Card>
              <CardBody className="mb-5">
                <h5 className="text-center mb-4">Admin Details</h5>
                <FormGroup>
                  <Label for="noticeType">Full Name :</Label>
                  <Input disabled type="text" value={props.user.authorised_r} />
                </FormGroup>
                <FormGroup>
                  <Label className="mt-3" for="noticeType">
                    Mobile Number :
                  </Label>
                  <Input disabled type="text" value={props.user.contact} />
                </FormGroup>
                <FormGroup>
                  <Label className="mt-3" for="noticeType">
                    Email :
                  </Label>
                  <Input disabled type="text" value={props.user.email} />
                </FormGroup>
                <Button
                  className="my-4"
                  color="primary"
                  size="sm"
                  onClick={() => handleopenEditProfileDrawer()}
                >
                  Edit Profile
                </Button>{" "}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStatetoProps = state => ({
  user: state.Profile.items,
  token: state.Login.token,
})

export default withRouter(
  connect(mapStatetoProps, {
    setBreadcrumbItems,
  })(AdminProfile)
)
