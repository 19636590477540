import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { CustomProvider } from "rsuite"
import { setBreadcrumbItems } from "../../../store/actions"
import { useLocation } from "react-router-dom"
import ContentEditor from "./ContentEditor"

const EditNotice = props => {
  const location = useLocation()

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Notice List", link: "/user/noticelist" },
    { title: "Notice Details", link: location?.state?.prevPath },
    { title: "Edit Notice Content", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems(`Edit Notice Content`, breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Notice Content - User | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        <ContentEditor />
        {/* <BulkMenu openSubMenu={openSubMenu} setOpenSubMenu={setOpenSubMenu} bulkButton={bulkButton} />  */}
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(EditNotice)
