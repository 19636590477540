import useWindowDimensions from "common/useWindowDimensions"
import React, { useState, useRef, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import moment from "moment"
import {
  CustomProvider,
  Pagination,
  Table,
  Input,
  InputGroup,
  IconButton,
} from "rsuite"
import "rsuite/dist/rsuite.css"
import { getOffers, getAllApproveUser } from "store/actions"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import EditIcon from "@rsuite/icons/Edit"
import DrwerBar from "components/Common/DrwerBar"
import TagAuthorizeIcon from "@rsuite/icons/TagAuthorize"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"

const { Column, HeaderCell, Cell } = Table

const OfferList = props => {
  const { height, width } = useWindowDimensions()
  const [updateRequest, setUpdateRequest] = useState([])
  const [updateAccessItemId, setUpdateAccessItemId] = useState()
  // const { width, height } = useContainerDimensions(contentRef)
  const [drawItemId, setDrawItemId] = useState()
  const [openEditOfferDrawer, setOpenEditOfferDrawer] = useState(false)
  const [openAssignUsersDrawer, setOpenAssignUsersDrawer] = useState(false)
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const [action, setAction] = useState(false)
  const dispatch = useDispatch()

  const data = props?.data

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  var filterData = data?.filter(value => {
    return parseInt(value.id) === parseInt(drawItemId)
  })

  const handleopenAssignUsersDrawer = itemId => {
    // setAccessItemId(access)
    setDrawItemId(itemId)
    setOpenAssignUsersDrawer(true)
  }

  const handleopenEditOfferDrawer = value => {
    setDrawItemId(value)
    setTimeout(() => {
      setOpenEditOfferDrawer(true)
      setUpdateRequest(prev => ({ ...prev, token: props?.token }))
    }, 500)
    // setUpdateRequest(filterData[0])
  }

  const handleClose = () => {
    openEditOfferDrawer && setOpenEditOfferDrawer(false)
    openAssignUsersDrawer && setOpenAssignUsersDrawer(false)
  }

  const handleEditOffer = () => {
    ApiCall(updateRequest, "admin/update-offer", false, result => {
      if (result?.data?.response === "success") {
        setOpenEditOfferDrawer(false)
        setAction(!action)
        ShowNotification(result?.data?.message, "success")
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props?.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
    }
    dispatch(getOffers(requestPayload))
  }, [page, limit, action])

  useEffect(() => {
    setUpdateRequest(filterData[0])
  }, [drawItemId])

  useEffect(() => {
    const requestPayload = {
      token: props.token,
    }
    dispatch(getAllApproveUser(requestPayload))
  }, [])

  return (
    <React.Fragment>
      <div className="table-responsive">
        <CustomProvider theme="dark">
          {openEditOfferDrawer && (
            <DrwerBar
              open={openEditOfferDrawer}
              setOpen={setOpenEditOfferDrawer}
              title="Edit Offer"
              body="editOffer"
              size="xs"
              state={updateRequest}
              setState={setUpdateRequest}
              onClick={() => handleEditOffer()}
              onCancel={handleClose}
            />
          )}
          {openAssignUsersDrawer && (
            <DrwerBar
              open={openAssignUsersDrawer}
              setOpen={setOpenAssignUsersDrawer}
              itemId={drawItemId}
              title="Assign Users"
              body="assignUsers"
              labelData="Users"
              subData={props?.AllApproveUser}
              size="xs"
              // defaultValue={accessItemId}
              setState={setUpdateAccessItemId}
              // onClick={handleAssignNotice}
              onCancel={handleClose}
            />
          )}
          <Table
            height={height - 380}
            data={data}
            wordWrap="break-word"
            loading={props.dataloading}
          >
            <Column flexGrow={1} align="center">
              <HeaderCell>Sr.No.</HeaderCell>
              <Cell>
                {(rowdata, dataKey) => (
                  <>
                    <span>
                      {page === 1
                        ? dataKey + 1
                        : limit * (page - 1) + dataKey + 1}
                    </span>
                  </>
                )}
              </Cell>
            </Column>
            <Column flexGrow={2}>
              <HeaderCell>Name</HeaderCell>
              <Cell>
                {rowdata => (
                  <>
                    {/* <Link to={"/admin/noticedetails/" + rowdata.noticeid}> */}
                    {rowdata?.name}
                    {/* </Link> */}
                  </>
                )}
              </Cell>
            </Column>
            <Column flexGrow={2}>
              <HeaderCell>Description</HeaderCell>
              {/* <Cell dataKey="draftCount" /> */}
              <Cell>{rowdata => <>{rowdata?.description}</>}</Cell>
            </Column>
            <Column flexGrow={1} align="center">
              <HeaderCell>Value</HeaderCell>
              {/* <Cell dataKey="activeCount" /> */}
              <Cell>{rowdata => <>{rowdata?.offer_value}</>}</Cell>
            </Column>
            <Column flexGrow={1} align="center">
              <HeaderCell>Valid Date From</HeaderCell>
              {/* <Cell dataKey="overdueCount" /> */}
              <Cell>
                {rowdata => (
                  <>{moment(rowdata?.valid_from).format("DD-MM-YYYY")}</>
                )}
              </Cell>
            </Column>
            <Column flexGrow={1} align="center">
              <HeaderCell>Valid Date To</HeaderCell>
              {/* <Cell dataKey="escalateCount" /> */}
              <Cell>
                {rowdata => (
                  <>{moment(rowdata?.valid_to).format("DD-MM-YYYY")}</>
                )}
              </Cell>
            </Column>
            <Column flexGrow={1} align="center">
              <HeaderCell>Action</HeaderCell>
              {/* <Cell dataKey="resolveCount" /> */}
              <Cell>
                {rowdata => (
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Edit Offer"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="blue"
                        icon={<EditIcon />}
                        onClick={() => handleopenEditOfferDrawer(rowdata?.id)}
                        // onClick={() =>
                        //   handleChangeUserStatus(rowdata?.id, 2)
                        // }
                      />
                    </WhisperTooltip>
                    {/* &nbsp;
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Assign Users"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="blue"
                        onClick={() => handleopenAssignUsersDrawer(rowdata.id)}
                        icon={<TagAuthorizeIcon />}
                      />
                    </WhisperTooltip> */}
                  </>
                )}
              </Cell>
            </Column>
          </Table>
          {/* <Pagination
            className="my-4"
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={props?.data?.length}
            limitOptions={[50, 100, 200]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          /> */}
        </CustomProvider>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.OfferList.items,
  dataloading: state.OfferList.loading,
  token: state.Login.token,
  AllApproveUser: state.AllApproveUser.items,
})

export default connect(mapStateToProps)(OfferList)
