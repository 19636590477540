import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
  InputGroup,
} from "reactstrap"

import { IconButton } from "rsuite"

import EyeIcon from "@rsuite/icons/legacy/Eye"
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash"
import StrengthMeter from "common/StrengthMeter"
import { connect } from "react-redux"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"

function PasswordReset(props) {
  const [visible, setVisible] = useState({
    old: false,
    new: false,
    confirm: false,
  })
  const [poorPassword, setPoorPassword] = useState(false)
  const [weakPassword, setWeakPassword] = useState(false)
  const [strongPassword, setStrongPassword] = useState(false)
  const [passwordError, setPasswordErr] = useState("")
  const [oldPasswordError, setOldPasswordErr] = useState("")
  const [confirmPasswordError, setConfirmPasswordErr] = useState("")
  const [passwordErrorCheck, setPasswordErrCheck] = useState(true)
  const [request, setRequest] = useState({
    token: props?.token,
    newPassword: null,
    oldPassword: null,
    confirmPassword: null,
  })

  const OldPasswordCheck = event => {
    const passwordValue = event.target.value
    if (passwordValue === "") {
      setOldPasswordErr("Old Password is Empty")
      setPasswordErrCheck(true)
    } else {
      setOldPasswordErr("")
      setPasswordErrCheck(false)
    }
  }

  const passwordStrength = evnt => {
    const passwordValue = evnt.target.value
    const passwordLength = passwordValue.length
    const poorRegExp = /[A-Za-z]/
    const weakRegExp = /(?=.*?[0-9])/
    // const strongRegExp = /(?=.*?[#?!@$%^&*-])/
    const strongRegExp = /[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/
    const whitespaceRegExp = /^$|\s+/
    const poorPassword = poorRegExp.test(passwordValue)
    const weakPassword = weakRegExp.test(passwordValue)
    const strongPassword = strongRegExp.test(passwordValue)
    const whiteSpace = whitespaceRegExp.test(passwordValue)

    if (passwordValue === "") {
      setPasswordErr("Password is Empty")
      setPasswordErrCheck(true)
    } else {
      // to check whitespace
      if (whiteSpace) {
        setPasswordErr("Whitespaces are not allowed")
        setPasswordErrCheck(true)
      }
      // to check poor password
      if (
        passwordLength <= 3 &&
        (poorPassword || weakPassword || strongPassword)
      ) {
        setPoorPassword(true)
        setPasswordErr("Password is Poor")
        setPasswordErrCheck(true)
      }
      // to check weak password
      if (
        (passwordLength >= 4 && poorPassword && weakPassword) ||
        (strongPassword && weakPassword) ||
        (strongPassword && poorPassword)
      ) {
        setWeakPassword(true)
        setPasswordErr("Password is Weak")
        setPasswordErrCheck(true)
      } else {
        setWeakPassword(false)
      }
      // to check strong Password
      if (
        passwordLength >= 6 &&
        poorPassword &&
        weakPassword &&
        strongPassword
      ) {
        setStrongPassword(true)
        setPasswordErr("Password is Strong")
        setPasswordErrCheck(false)
      } else {
        setStrongPassword(false)
      }

      if (request?.confirmPassword !== "") {
        if (passwordValue !== request?.confirmPassword) {
          setConfirmPasswordErr("Password did not match")
          setPasswordErrCheck(true)
        } else {
          setConfirmPasswordErr("")
          setPasswordErrCheck(false)
        }
      }
    }
  }

  const confirmPasswordCheck = evnt => {
    const passwordValue = evnt.target.value
    passwordValue === "" && [
      setConfirmPasswordErr("Confirm Password is empty"),
      setPasswordErrCheck(true),
    ]
    passwordValue !== request.newPassword
      ? [
          setConfirmPasswordErr("Password did not match"),
          setPasswordErrCheck(true),
        ]
      : [setConfirmPasswordErr(""), setPasswordErrCheck(false)]
  }

  const handlePasswordReset = () => {
    !request?.oldPassword
      ? [setOldPasswordErr("Old Password is Empty"), setPasswordErrCheck(true)]
      : [setOldPasswordErr(""), setPasswordErrCheck(false)]
    !request?.confirmPassword
      ? [
          setConfirmPasswordErr("Confirm Password is Empty"),
          setPasswordErrCheck(true),
        ]
      : request?.confirmPassword !== request.newPassword
      ? [
          setConfirmPasswordErr("Password did not match"),
          setPasswordErrCheck(true),
        ]
      : [setConfirmPasswordErr(""), setPasswordErrCheck(false)]
    !passwordErrorCheck &&
      strongPassword &&
      request?.confirmPassword &&
      request?.newPassword &&
      request?.oldPassword &&
      confirmPasswordError === "" &&
      ApiCall(request, "user/checkLast5Password", false, result => {
        if (result?.data?.response === "success") {
          ApiCall(request, "user/update-password", false, result => {
            if (result?.data?.response === "success") {
              ShowNotification(result?.data?.message, "success")
              Array.from(
                document.querySelectorAll("input[id='password']")
              ).forEach(input => (input.value = ""))
              setStrongPassword(false)
              setWeakPassword(false)
              setPoorPassword(false)
              setPasswordErr("")
            } else {
              ShowNotification(result?.data?.message, "danger")
            }
          })
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
  }

  //   console.log(request)

  return (
    <>
      <h5 className="text-center">Password Reset</h5>
      <FormGroup>
        <Label className="mt-2" for="oldP">
          Old Password
        </Label>
        <InputGroup>
          <Input
            id="oldPassword"
            type={visible?.old ? "text" : "password"}
            onChange={e => {
              setRequest({ ...request, oldPassword: e.target.value })
            }}
            onInput={OldPasswordCheck}
          />
          <IconButton
            size="sm"
            onClick={() => setVisible({ ...visible, old: !visible?.old })}
            icon={visible?.old ? <EyeIcon /> : <EyeSlashIcon />}
          />
        </InputGroup>
        <span> {oldPasswordError}</span>
      </FormGroup>
      <FormGroup>
        <Label className="mt-2" for="newP">
          New Password
        </Label>
        <InputGroup>
          <Input
            id="newPassword"
            type={visible?.new ? "text" : "password"}
            onChange={e => {
              setRequest({ ...request, newPassword: e.target.value })
            }}
            onInput={passwordStrength}
            // value={passwordValue}
          />
          <IconButton
            size="sm"
            onClick={() => setVisible({ ...visible, new: !visible?.new })}
            icon={visible?.new ? <EyeIcon /> : <EyeSlashIcon />}
          />
        </InputGroup>
        <StrengthMeter
          poorPassword={poorPassword}
          weakPassword={weakPassword}
          strongPassword={strongPassword}
          passwordError={passwordError}
        />
      </FormGroup>
      <FormGroup>
        <Label className="mt-2" for="cnewP">
          Confirm New Password
        </Label>
        <InputGroup>
          <Input
            id="confirmPassword"
            type={visible?.confirm ? "text" : "password"}
            onInput={confirmPasswordCheck}
            onChange={e =>
              setRequest({ ...request, confirmPassword: e.target.value })
            }
          />
          <IconButton
            size="sm"
            onClick={() =>
              setVisible({ ...visible, confirm: !visible?.confirm })
            }
            icon={visible?.confirm ? <EyeIcon /> : <EyeSlashIcon />}
          />
        </InputGroup>
        <span> {confirmPasswordError}</span>
      </FormGroup>
      <Button
        onClick={() => handlePasswordReset()}
        className="my-4"
        color="primary"
        size="sm"
      >
        Submit
      </Button>{" "}
    </>
  )
}

const mapStatetoProps = state => ({
  token: state.Login.token,
})

export default connect(mapStatetoProps)(PasswordReset)
