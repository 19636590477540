import React, { useState, useEffect } from "react"
import { CustomProvider, IconButton, Pagination, Table } from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import CheckRoundIcon from "@rsuite/icons/CheckRound"
import DetailIcon from "@rsuite/icons/Detail"
import { getDataNotice } from "store/actions"
import { connect, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import useWindowDimensions from "common/useWindowDimensions"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"

const { Column, HeaderCell, Cell } = Table

function EscalatedTable(props) {
  const { height, width } = useWindowDimensions()

  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)

  const dispatch = useDispatch()
  const params = useParams()
  const data = props?.data?.resultData

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const downloadClickNoticePdf = file => {
    const request = {
      token: props?.token,
      folder: "userGnrtdNotice",
      filename: file,
    }
    DownloadApiCall(request, "download/single-download", "_blank")
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      data_type: "escalate",
      notice_id: params.id,
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
    }
    dispatch(getDataNotice(requestPayload))
  }, [page, limit])

  return (
    <div className="table-responsive">
      <Table
        height={height - 300}
        data={data}
        wordWrap="break-word"
        rowHeight={46}
        loading={props.tableLoading}
      >
        <Column width={70} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell>
            {(rowdata, dataKey) => (
              <>
                <span>
                  {page === 1 ? dataKey + 1 : limit * (page - 1) + dataKey + 1}
                </span>
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Notice ID</HeaderCell>
          <Cell dataKey="notice_unq_id" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Date of Generation</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: rowdata.date_of_generation,
                  }}
                />
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Name of User</HeaderCell>
          <Cell dataKey="useremail" />
        </Column>
        <Column width={80} align="center">
          <HeaderCell>Due Days</HeaderCell>
          <Cell dataKey="due_days_for_response" />
        </Column>
        <Column width={120} align="center">
          <HeaderCell>Notes</HeaderCell>
          <Cell>
            {rowdata => (
              <>{rowdata.userNotes == 0 ? "-NA-" : rowdata.userNotes}</>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1} align="center">
          <HeaderCell>Escalated On</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: rowdata.escalated_On,
                  }}
                />
              </>
            )}
          </Cell>
        </Column>
        <Column width={140} align="center">
          <HeaderCell>Party Details</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View Party Details"
                >
                  <Link
                    to={{
                      pathname: "/admin/party-details/" + btoa(rowdata.Id),
                      state: { prevPath: location.pathname },
                    }}
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="violet"
                      icon={<DetailIcon />}
                    />
                  </Link>
                </WhisperTooltip>
              </>
            )}
          </Cell>
        </Column>
        <Column width={140} align="center">
          <HeaderCell>View Notice</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View Notice PDF"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="orange"
                    icon={<VisibleIcon />}
                    onClick={() =>
                      downloadClickNoticePdf(rowdata?.user_notice_path)
                    }
                  />
                </WhisperTooltip>
              </>
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.data?.escalateCount}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
  tableLoading: state.DraftNotice.loading,
  token: state.Login.token,
})

export default connect(mapStateToProps)(EscalatedTable)
