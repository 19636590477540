import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"

import { getAllApproveUser, setBreadcrumbItems } from "../../../store/actions"
import AutoReportComponents from "./AutoReportComponents"
import { CustomProvider } from "rsuite"
import AutoReportTable from "./AutoReportTable"


const InvoiceAnnexure = props => {
  const [apiAction, setApiAction] = useState({ loading: false, action: false })

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Invoice Annexure", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Invoice Annexure", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Invoice Annexure - Admin | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        <AutoReportComponents
          apiAction={apiAction}
          setApiAction={setApiAction}
        />
        <AutoReportTable apiAction={apiAction} />
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.AllApproveUser.items,
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(InvoiceAnnexure)
