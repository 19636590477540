const initialState = {
  items: [],
  loading: false,
  count: 0,
  error: null,
}

const initialStateSubUser = {
  items: [],
  loading: false,
  count: 0,
  error: null,
}

export const SubUser = (state = initialState, action) => {
  switch (action.type) {
    case "SubUserBegin":
      return {
        ...state,
        loading: true,
        error: null,
      }
    case "SubUserSuccess":
      return {
        ...state,
        items: action.data,
        count: action.count,
        loading: false,
      }
    case "SubUserFailure":
      return {
        ...state,
        items: [],
        loading: false,
        error: action.data,
      }
    default:
      return state
  }
}

export const SubUserNoticeList = (state = initialStateSubUser, action) => {
  switch (action.type) {
    case "SubUserNoticeListBegin":
      return {
        ...state,
        loading: true,
        error: null,
      }
    case "SubUserNoticeListSuccess":
      return {
        ...state,
        items: action.data,
        count: action.count,
        loading: false,
      }
    case "SubUserNoticeListFailure":
      return {
        ...state,
        items: [],
        loading: false,
        error: action.data,
      }
    default:
      return state
  }
}
// export default Users;
