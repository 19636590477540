import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { MetaTags } from "react-meta-tags"
import { connect } from "react-redux"
import { CustomProvider } from "rsuite"
import { setBreadcrumbItems } from "../../../store/actions"
import Chart from "./Chart"
import axios from "axios"
import { ApiRoute, RouteWithoutApi } from "common/ApiRoute"

const CanvaReport = props => {
  const params = useParams()
  const api_url = RouteWithoutApi

  const [data, setData] = useState()

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Canva Report", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Canva Report", breadcrumbItems)
  })

  useEffect(() => {
    // Define the URL you want to fetch data from
    const apiUrl = `${api_url}autoCanvaReport/${params.id}`
    // Make a GET request using Axios
    axios
      .get(apiUrl)
      .then(response => {
        // Handle the successful response
        console.log("in Call", response)
        setData(response.data)
      })
      .catch(error => {
        // Handle any errors that occurred during the request
        console.error("Error fetching data:", error)
      })
  }, [])

  console.log("in data", data)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Canva Report - Admin | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        <Chart />
      </CustomProvider>
    </React.Fragment>
  )
}

// export default CanvaReport
export default connect(null, { setBreadcrumbItems })(CanvaReport)
