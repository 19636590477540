import React, { useEffect, useState } from "react"
import "./preview.scss"

function LetterheadPreview(props) {
  useEffect(() => {}, [props])

  return (
    <div
      className="page"
      style={{
        paddingTop: props?.headMargin + "mm",
        paddingBottom: props?.footMargin + "mm",
        backgroundImage: `url(${props?.letterPicUrl})`,
      }}
    >
      {/* {props?.letterPicUrl && (
        <img className="watermark" src={props?.letterPicUrl} />
      )} */}
      <span>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </span>
      <br />
      {props?.signUrl && (
        <img
          height={`${props?.signature_height}px`}
          width={`${props?.signature_width}px`}
          className="sign"
          src={props?.signUrl}
        />
      )}
    </div>
  )
}

export default LetterheadPreview
