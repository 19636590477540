import React, { useState,useEffect } from "react"
import { CustomProvider, IconButton, Pagination, Table } from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import DetailIcon from "@rsuite/icons/Detail"
import { getDataNotice } from "store/actions";
import { connect, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

const { Column, HeaderCell, Cell } = Table

function ActiveTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const params = useParams()
  const data = props?.data?.resultData

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      data_type: "active",
      notice_id: params.id,
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
    }
    dispatch(getDataNotice(requestPayload))
  }, [page, limit])

  return (
    <div className="table-responsive">
      <Table height={280} data={data} wordWrap="break-word" rowHeight={46} loading={props.tableLoading}>
        <Column width={95} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell>
            {(rowdata, dataKey) => (
              <>
                <span>{page === 1 ? dataKey + 1 : (limit * (page - 1)) + dataKey + 1}</span>
              </>
            )}
          </Cell>
        </Column>
        <Column width={100}>
          <HeaderCell>Notice ID</HeaderCell>
          <Cell dataKey="notice_unq_id" />
        </Column>
        <Column width={170}>
          <HeaderCell>Date of Generation</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: rowdata.date_of_generation,
                  }}
                />
              </>
            )}
          </Cell>
        </Column>
        <Column width={160}>
          <HeaderCell>Name of User</HeaderCell>
          <Cell dataKey="username" />
        </Column>
        <Column width={140}>
          <HeaderCell>Active On</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: rowdata.active_On,
                  }}
                />
              </>
            )}
          </Cell>
        </Column>
        <Column width={100} align="center">
          <HeaderCell>No. of Days</HeaderCell>
          <Cell dataKey="due_days_for_response" />
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Party Details</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <Link to={"/admin/party-details/" + btoa(rowdata.Id)}>
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="violet"
                    icon={<DetailIcon />}
                  />
                </Link>
              </>
            )}
          </Cell>
        </Column>
        <Column width={100} align="center">
          <HeaderCell>View Notice</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <IconButton
                  size="xs"
                  appearance="primary"
                  color="orange"
                  icon={<VisibleIcon />}
                />
              </>
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.data?.activeCount}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
  tableLoading: state.DraftNotice.loading,
  token: state.Login.token,
})

export default connect(mapStateToProps)(ActiveTable)
