import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import { CustomProvider } from "rsuite"

// Pages Components
// import MonthlyEarnings from "./montly-earnings";
// import EmailSent from "./email-sent";
// import MonthlyEarnings2 from "./montly-earnings2";
// import Inbox from "./inbox";
// import RecentActivity from "./recent-activity";
// import WidgetUser from "./widget-user";
// import YearlySales from "./yearly-sales";
// import LatestTransactions from "./latest-transactions";
// import LatestOrders from "./latest-orders";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions"
import ReportRefTable from "./ReportRefTable"

const UserRefIdSearch = props => {
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Ref. Id Report", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Ref. Id Report", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Ref. Id - User | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        <ReportRefTable />
      </CustomProvider>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(UserRefIdSearch)
