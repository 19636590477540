const initialStateEmail = {
  items: [],
  loading: false,
  count: 0,
  error: null,
}

const initialStateWhatsapp = {
  items: [],
  loading: false,
  count: 0,
  error: null,
}

const initialStateSMS = {
  items: [],
  loading: false,
  count: 0,
  error: null,
}

const initialStateCourier = {
  items: [],
  loading: false,
  count: 0,
  error: null,
}

export const EmailLog = (state = initialStateEmail, action) => {
  switch (action.type) {
    case "EmailLogBegin":
      return {
        ...state,
        loading: true,
        error: null,
      }
    case "EmailLogSuccess":
      return {
        ...state,
        items: action.data,
        count: action.count,
        loading: false,
      }
    case "EmailLogFailure":
      return {
        ...state,
        items: [],
        loading: false,
        error: action.data,
      }
    default:
      return state
  }
}

export const WhatsappLog = (state = initialStateWhatsapp, action) => {
  switch (action.type) {
    case "WhatsappLogBegin":
      return {
        ...state,
        loading: true,
        error: null,
      }
    case "WhatsappLogSuccess":
      return {
        ...state,
        items: action.data,
        count: action.count,
        loading: false,
      }
    case "WhatsappLogFailure":
      return {
        ...state,
        items: [],
        loading: false,
        error: action.data,
      }
    default:
      return state
  }
}

export const SMSLog = (state = initialStateSMS, action) => {
  switch (action.type) {
    case "SMSLogBegin":
      return {
        ...state,
        loading: true,
        error: null,
      }
    case "SMSLogSuccess":
      return {
        ...state,
        items: action.data,
        count: action.count,
        loading: false,
      }
    case "SMSLogFailure":
      return {
        ...state,
        items: [],
        loading: false,
        error: action.data,
      }
    default:
      return state
  }
}

export const CourierLog = (state = initialStateCourier, action) => {
  switch (action.type) {
    case "CourierLogBegin":
      return {
        ...state,
        loading: true,
        error: null,
      }
    case "CourierLogSuccess":
      return {
        ...state,
        items: action.data,
        count: action.count,
        loading: false,
      }
    case "CourierLogFailure":
      return {
        ...state,
        items: [],
        loading: false,
        error: action.data,
      }
    default:
      return state
  }
}

// export default Users;
