import React, { useEffect, useState } from "react"
import { Button, Col, Grid, Input, Row, SelectPicker } from "rsuite"
import { connect } from "react-redux"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { FileUploader } from "react-drag-drop-files"
import { ShowNotification } from "common/ShowNotification"

function UploadCSV(props) {
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState(false)
  const [request, setRequest] = useState({
    token: props?.token,
    file_csv: null,
    reportType: "Upload Bar Code",
    barcodeType: null,
  })

  const handleSubmit = async () => {
    request?.file_csv &&
      request?.reportType &&
      request?.barcodeType && [
        setLoading(true),
        await ApiCall(request, "barcodeUpload", false, result => {
          console.log(result?.status)
          if (result?.status === 200) {
            //   setActiveCountData(result?.data?.responseData)
            // ShowNotification(result?.data?.message, "success")
            // const link = document.createElement("a")
            // link.target = "_blank"
            // link.href = result?.message
            // document.body.appendChild(link)
            // link.click()
            // link.parentNode.removeChild(link)
            setRequest({
              ...request,
              file_csv: null,
              barcodeType: null,
            })
            Array.from(document.querySelectorAll(["input", "select"])).forEach(
              input => (input.value = "")
            )
            ShowNotification("Successfully Upload!", "success")
          } else {
            //   setRequest({ ...request, file_csv: null })
            ShowNotification(
              result?.data?.message ? result?.data?.message : "Somthing Wrong",
              "danger"
            )
          }
          setAction(!action)
          setLoading(false)
        }),
      ]
  }

  const selectData = [
    {
      label: "Barcode",
      value: "barcode",
    },
    {
      label: "Status / Reason",
      value: "status_reason",
    },
  ]

  useEffect(() => {
    setRequest({ ...request, file_csv: null })
  }, [action])

  return (
    <div className="card">
      <div className="card-header">
        <h5>Upload Bar Code</h5>
      </div>
      <div className="card-Body m-2">
        <Grid fluid>
          <Row className="show-grid">
            <Col lg={9} md={9} sm={24} xs={24} className="mt-3">
              <SelectPicker
                label={"Select Type"}
                value={request?.barcodeType}
                block
                data={selectData}
                onChange={e => setRequest({ ...request, barcodeType: e })}
              />
            </Col>
            <Col lg={9} md={9} sm={24} xs={24} className="mt-2">
              <FileUploader
                name="file_csv"
                types={["csv", "xls", "xlsx"]}
                handleChange={e => setRequest({ ...request, file_csv: e })}
                onTypeError={e =>
                  e &&
                  setRequest({
                    ...request,
                    file_csv: null,
                  })
                }
              />
            </Col>
            <Col lg={6} md={6} sm={24} xs={24} className="mt-0">
              <Button
                appearance="primary"
                color="blue"
                disabled={loading}
                style={{ marginTop: "1.2em" }}
                onClick={() => handleSubmit()}
                block
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps)(UploadCSV)
