import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"

import { setBreadcrumbItems } from "../../../store/actions"
import Details from "./Details"
import { CustomProvider } from "rsuite"
import { useLocation } from "react-router-dom"

const UserDetails = props => {
  const location = useLocation();

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Manage Users", link: location?.state?.prevPath },
    { title: "User Proforma Letterhead", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("User Proforma Letterhead", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>User Proforma Letterhead - Admin | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        <Details />
      </CustomProvider>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(UserDetails)
