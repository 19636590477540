import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"

import { getAllApproveUser, setBreadcrumbItems } from "../../../store/actions"
import NoticeForm from "./NoticeForm"

const AddNewProxyNotice = props => {
  const dispatch = useDispatch()

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Add New Proxy Notice", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Add New Proxy Notice", breadcrumbItems)
  })

  useEffect(() => {
    const requestPayload = {
      token: props?.token,
    }
    dispatch(getAllApproveUser(requestPayload))
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Add Notice - Admin | Incase360</title>
      </MetaTags>

      <NoticeForm userdata={props.data} />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.AllApproveUser.items,
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(
  AddNewProxyNotice
)
