const initialState = {
    items: [],
    loading: false,
    count: 0,
    error: null,
};
const detailsStateIdName = {
    items: [],
    loading: false,
    error: null,
};
const batchStateIdName = {
    items: [],
    loading: false,
    error: null,
};
const detailsStateById = {
    items: [],
    loading: false,
    error: null,
    rowCount: null
};

export const ExistingNotice = (state = initialState, action) => {
    switch (action.type) {
        case "ExistingNoticeBegin":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "ExistingNoticeSuccess":
            return {
                ...state,
                items: action.data,
                count: action.count,
                loading: false,
            };
        case "ExistingNoticeFailure":
            return {
                ...state,
                items: [],
                loading: false,
                error: action.data,
            };
        default:
            return state;
    }
};

export const ExistingNoticeIdName = (state = detailsStateIdName, action) => {
    switch (action.type) {
        case "ExistingNoticeIdNameBegin":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "ExistingNoticeIdNameSuccess":
            return {
                ...state,
                items: action.data,
                loading: false,
            };
        case "ExistingNoticeIdNameFailure":
            return {
                ...state,
                items: [],
                loading: false,
                error: action.data,
            };
        default:
            return state;
    }
};

export const BatchByNoticeId = (state = batchStateIdName, action) => {
    switch (action.type) {
        case "BatchByNoticeIdBegin":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "BatchByNoticeIdSuccess":
            return {
                ...state,
                items: action.data,
                loading: false,
            };
        case "BatchByNoticeIdFailure":
            return {
                ...state,
                items: [],
                loading: false,
                error: action.data,
            };
        default:
            return state;
    }
};

export const ExistingNoticeDetailsById = (state = detailsStateById, action) => {
    switch (action.type) {
        case "ExistingNoticDetailsByIdBegin":
            return {
                ...state,
                loading: true,
                items: [],
                error: null,
            };
        case "ExistingNoticDetailsByIdSuccess":
            return {
                ...state,
                items: action.data,
                loading: false,
                rowCount: action.rowCount,
            };
        case "ExistingNoticDetailsByIdFailure":
            return {
                ...state,
                items: [],
                loading: false,
                error: action.data,
            };
        default:
            return state;
    }
};

// export default Users;
