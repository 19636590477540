import { TabBar } from "common/TabBar/TabBar"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { CustomProvider } from "rsuite"
import { setBreadcrumbItems } from "../../../../store/actions"
import ResolvedTable from "./ResolvedTable"

const AdminResolved = props => {
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Notice List", link: "/admin/noticelist" },
    { title: "Resolved Notice Details", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems(
      `Resolved Notice Details - ${props?.data?.noticeName?.replace(
        "notice_",
        ""
      )}`,
      breadcrumbItems
    )
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Resolved Notice Details - Admin | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        <ResolvedTable />
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(
  AdminResolved
)
