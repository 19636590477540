import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { Row, Col } from "reactstrap"

import { setBreadcrumbItems } from "../../../store/actions"
import NoticeTable from "./NoticeTable"

const UserNoticeList = props => {
  const params = useParams()

  const breadcrumbItems = params?.id
    ? [
        { title: "Incase360", link: "#" },
        { title: "Sub User", link: "/user/subuser" },
        { title: "Sub User Notice List", link: "#" },
      ]
    : params?.sub
    ? [
        { title: "Incase360", link: "#" },
        { title: "Sub User Notice List", link: "#" },
      ]
    : [
        { title: "Incase360", link: "#" },
        { title: "Notice List", link: "#" },
      ]

  useEffect(() => {
    props.setBreadcrumbItems(
      params?.id ? `Sub User Notice List - ${props?.user_name}` : "Notice List",
      breadcrumbItems
    )
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Notice List - User | Incase360</title>
      </MetaTags>

      <NoticeTable />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  user_name: state.AssignNotice.user_name,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(UserNoticeList)
