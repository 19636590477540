const initialState = {
  items: [],
  loading: false,
  error: null,
  user_name: null,
}

const AssignNotice = (state = initialState, action) => {
  switch (action.type) {
    case "NoticeListBegin":
      return {
        ...state,
        loading: true,
        error: null,
      }
    case "NoticeListSuccess":
      return {
        ...state,
        items: action.data,
        user_name: action.user_name,
        loading: false,
      }
      case "NoticeListDelete" : 
         return {
           ...state,
           items: action.data,
           user_name: action.user_name,
           loading: false,
         }  
    case "NoticeListFailure":
      return {
        ...state,
        items: [],
        loading: false,
        error: action.data,
      }
    default:
      return state
  }
}

export default AssignNotice
