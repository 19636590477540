import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { CustomProvider } from "rsuite"
import { setBreadcrumbItems } from "../../../store/actions"
import LetterheadTable from "./LetterheadTable"


const Letterhead = props => {

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Proforma Letterhead", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Proforma Letterhead', breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Proforma Letterhead - User | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        <LetterheadTable />
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(Letterhead)
