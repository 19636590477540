import axios from "axios"
import { ApiRoute, RouteWithoutApi } from "common/ApiRoute"

const api_url = ApiRoute

export const getExistingNotice = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "ExistingNoticeBegin" })
    axios
      .post(api_url + "existing-notice", requestPayload)
      .then(result => {
        dispatch({
          type: "ExistingNoticeSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "ExistingNoticeFailure",
          data: error,
        })
      })
  }
}

export const getAllExistingNoticeIdName = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "ExistingNoticeIdNameBegin" })
    axios
      .post(RouteWithoutApi + "getAllNoticeIdName", requestPayload)
      .then(result => {
        dispatch({
          type: "ExistingNoticeIdNameSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "ExistingNoticeIdNameFailure",
          data: error,
        })
      })
  }
}

export const getBatchByNoticeId = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "BatchByNoticeIdBegin" })
    axios
      .post(RouteWithoutApi + "getActiveBatchByNoticeId", requestPayload)
      .then(result => {
        dispatch({
          type: "BatchByNoticeIdSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "BatchByNoticeIdFailure",
          data: error,
        })
      })
  }
}

export const getExistingNoticDetailsById = (requestPayload, callback) => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "ExistingNoticDetailsByIdBegin" })
    axios
      .post(api_url + "existing-notice-details", requestPayload)
      .then(result => {
        dispatch({
          type: "ExistingNoticDetailsByIdSuccess",
          data: result.data.responseData,
          rowCount: result.data.rowCount,
        })
        callback(result)
      })
      .catch(error => {
        dispatch({
          type: "ExistingNoticDetailsByIdFailure",
          data: error,
        })
        callback(error)
      })
  }
}