import axios from "axios"
import { ApiRoute } from "common/ApiRoute"

const api_url = ApiRoute

export const getOffers = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"
  let formData = new FormData()
  Object.entries(requestPayload).map(([key, value]) => {
    formData.append(key, value)
  })
  return dispatch => {
    dispatch({ type: "OffersListBegin" })
    axios
      .post(api_url + "admin/offerlist", formData)
      .then(result => {
        dispatch({
          type: "OffersListSuccess",
          data: result.data.responseData,
          user_name: result.data.user_name,
        })
      })
      .catch(error => {
        dispatch({
          type: "OffersListFailure",
          data: error,
        })
      })
  }
}
