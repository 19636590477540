import React, { useState } from "react"
import { Header, Navbar, Nav } from "rsuite"
import { Link } from "react-router-dom"

function MobileHomeHeader() {
  const [avtiveMobile, setActiveMobile] = useState(false)
  return (
    <div className="main-header">
      <div className="container">
        <Header>
          <Navbar appearance="subtle">
            <div className="mobile-header">
              <Navbar.Brand>
                <Link to="/">
                  <img
                    alt=""
                    // style={{ width: "9em" }}
                    className="logo"
                    src="https://api.incase360.com/assets/images/Incase360-logo-1-white.png"
                  />
                </Link>
              </Navbar.Brand>
              <div className="mobile-btn-manage">
                <Link to="/login" style={{ paddingRight: "1em" }}>
                  <button className="custom-btn mt-2">SIGN IN</button>
                </Link>
                <span
                  className="mdi mdi-format-list-bulleted nav-open-btn"
                  onClick={() => setActiveMobile(!avtiveMobile)}
                ></span>
              </div>
            </div>
          </Navbar>
          {avtiveMobile && (
            <div className="mobile-nav-link">
              <Link to="/">Home</Link> <br />
              <Link>
                <Link to="/register">Create Account</Link>
              </Link>
              {/* <Link to="/">Process</Link>
              <br />
              <Link to="/">Features</Link>
              <br />
              <Link to="/">Reviews</Link>
              <br />
              <Link to="/">FAQs</Link> */}
            </div>
          )}
        </Header>
      </div>
    </div>
  )
}

export default MobileHomeHeader
