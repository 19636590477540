import React from "react"
import HomeIcon from "@rsuite/icons/legacy/Home"
import { Header, Navbar, Nav } from "rsuite"
import { Link } from "react-router-dom"
// import {width, he}

function HomeHeader() {
  return (
    <div className="main-header">
      <div className="container">
        <Header>
          <Navbar appearance="subtle">
            <Navbar.Brand>
              <Link to="/">
                <img
                  alt=""
                  style={{ width: "12em" }}
                  className="logo"
                  src="https://api.incase360.com/assets/images/Incase360-logo-1-white.png"
                />
              </Link>
            </Navbar.Brand>

            <Nav pullRight>
              <Nav.Item icon={<HomeIcon />}>
                <Link to="/">Home</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/register">Create Account</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/login">
                  <button className="custom-btn">SIGN IN</button>
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar>
        </Header>
      </div>
    </div>
  )
}

export default HomeHeader
