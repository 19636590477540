import axios from "axios"
import { ApiRoute } from "common/ApiRoute"

const api_url = ApiRoute

export const getBotMisTrack = requestPayload => {
  let formData = new FormData()
  Object.entries(requestPayload).map(([key, value]) => {
    formData.append(key, value)
  })
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "BotMisTrackBegin" })
    axios
      .post(api_url + "smcb-botmistrack", formData)
      .then(result => {
        //console.log(result.data.responseData);
        dispatch({
          type: "BotMisTrackSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "BotMisTrackFailure",
          data: error,
        })
      })
  }
}
