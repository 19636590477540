import React, { useState, useEffect } from "react"
import {
  Button,
  Checkbox,
  CustomProvider,
  IconButton,
  Pagination,
  Table,
} from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import CheckRoundIcon from "@rsuite/icons/CheckRound"
import CloseIcon from "@rsuite/icons/Close"
import ReviewPassIcon from "@rsuite/icons/ReviewPass"
import MoveUpIcon from "@rsuite/icons/MoveUp"
import AddOutlineIcon from "@rsuite/icons/AddOutline"
import EventDetailIcon from "@rsuite/icons/EventDetail"
import DetailIcon from "@rsuite/icons/Detail"
import { Link, useHistory, useParams } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import { getDataNotice } from "store/actions"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import useWindowDimensions from "common/useWindowDimensions"
import AddNotes from "../AddNotes"
import { ShowSwal } from "common/ShowSwal"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"
import NotesAttachment from "../NotesAttachment"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"

const { Column, HeaderCell, Cell } = Table

function ActiveTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const [action, setAction] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  let checked = false
  let indeterminate = false
  const { height, width } = useWindowDimensions()

  const data = props?.data?.resultData

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  if (data?.length !== 0) {
    if (props.checkedKeys?.length === data?.length) {
      checked = true
      props?.setOpenSubMenu(true)
    } else if (props?.checkedKeys?.length === 0) {
      checked = false
      props?.setOpenSubMenu(false)
    } else if (
      props?.checkedKeys?.length > 0 &&
      props?.checkedKeys?.length < data?.length
    ) {
      indeterminate = true
      props?.setOpenSubMenu(true)
    }
  }

  const handleCheckAll = (value, checked) => {
    const keys = checked ? data?.map(item => item.Id) : []
    props?.setCheckedKeys(keys)
    props?.setOpenSubMenu(checked)
  }

  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...props?.checkedKeys, value]
      : props?.checkedKeys?.filter(item => item !== value)
    props?.setCheckedKeys(keys)
    checked && props?.setOpenSubMenu(checked)
  }

  const downloadClickNoticePdf = file => {
    const request = {
      token: props?.token,
      folder: "userGnrtdNotice",
      filename: file,
    }
    DownloadApiCall(request, "download/single-download", "_blank")
  }

  const handleEscaltedAction = value => {
    const request = {
      token: props?.token,
      mnid: params?.id,
      userRowNoticeId: value,
    }
    ShowSwal(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, Escalate it!",
      result => {
        if (result.isConfirmed) {
          ApiCall(request, "user/notice-escalate", false, result => {
            if (result?.data?.response === "success") {
              setAction(!action)
              ShowNotification(result?.data?.message, "success")
              history.push("/user/escalated-notice/" + params.id)
            } else {
              ShowNotification(result?.data?.message, "danger")
            }
          })

          // Swal.fire("Deleted!", "Your file has been deleted.", "success")
          // ShowNotification("Deleted! Your file has been deleted.", "success")
        }
      }
    )
  }

  const handleResolvedAction = value => {
    const request = {
      token: props?.token,
      mnid: params?.id,
      userRowNoticeId: value,
    }
    ShowSwal(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, It's Resolved",
      result => {
        if (result.isConfirmed) {
          ApiCall(request, "user/notice-resolve", false, result => {
            if (result?.data?.response === "success") {
              setAction(!action)
              ShowNotification(result?.data?.message, "success")
              history.push("/user/resolved-notice/" + params.id)
            } else {
              ShowNotification(result?.data?.message, "danger")
            }
          })

          // Swal.fire("Deleted!", "Your file has been deleted.", "success")
          // ShowNotification("Deleted! Your file has been deleted.", "success")
        }
      }
    )
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      data_type: "active",
      notice_id: params.id,
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      notesAdded: props?.filter?.notesAdded,
      replyAdded: props?.filter?.replyAdded,
      batch: props?.filter?.batch,
      case_ref_id: props?.filter?.case_ref_id,
      undeliveredWhatsapp: props?.filter?.undeliveredWhatsapp,
      undeliveredEmail: props?.filter?.undeliveredEmail,
      undeliveredSms: props?.filter?.undeliveredSms,
    }
    dispatch(getDataNotice(requestPayload))
  }, [page, limit, action, props?.filter])

  function addDays(theDate, days) {
    var date = new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000)
    console.log(date)
    var day = date.getDate()
    var month = date.getMonth()
    var year = date.getFullYear()
    return day + "-" + month + "-" + year
  }

  return (
    <div className="table-responsive" style={{ fontSize: "0.9em" }}>
      <Table
        height={height - 300}
        data={data}
        wordWrap="break-word"
        loading={props.tableLoading}
        headerHeight={50}
      >
        <Column width={50} align="center">
          <HeaderCell style={{ fontSize: "1.2em" }}>
            <div>
              <Checkbox
                inline
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleCheckAll}
              />
            </div>
          </HeaderCell>
          <Cell>
            {(rowdata, dataKey) => (
              <>
                <span>
                  {page === 1 ? dataKey + 1 : limit * (page - 1) + dataKey + 1}
                </span>
                <Checkbox
                  value={rowdata.Id}
                  inline
                  onChange={handleCheck}
                  checked={props?.checkedKeys?.some(
                    item => item === rowdata.Id
                  )}
                />
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Reference ID</HeaderCell>
          <Cell dataKey="Reference_ID" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Notice ID</HeaderCell>
          <Cell dataKey="notice_unq_id" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Date of Generation</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: rowdata.date_of_generation,
                  }}
                />
              </>
            )}
          </Cell>
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Due Date</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                {rowdata?.activatedOn}
                {/* {addDays(
                  new Date(rowdata?.activatedOn),
                  parseInt(rowdata.due_days_for_response)
                )} */}
              </>
            )}
          </Cell>
        </Column>
        <Column width={200} align="center">
          <HeaderCell>Batch</HeaderCell>
          <Cell dataKey="batchName" />
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Notes</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <AddNotes
                  id={rowdata.Id}
                  token={props?.token}
                  notice={params?.id}
                  userNotes={rowdata?.userNotes}
                  action={action}
                  setAction={setAction}
                />
                {rowdata?.notesAttachment && (
                  <>
                    <NotesAttachment
                      attachment={rowdata?.notesAttachment}
                      id={rowdata.Id}
                      token={props?.token}
                    />
                  </>
                )}
              </>
            )}
          </Cell>
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Details</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View Party Details"
                >
                  <Link
                    to={{
                      pathname: "/user/party-details/" + btoa(rowdata.Id),
                      state: { prevPath: location.pathname },
                    }}
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="violet"
                      icon={<DetailIcon />}
                    />
                  </Link>
                </WhisperTooltip>
              </>
            )}
          </Cell>
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View Track"
                >
                  <Link
                    to={{
                      pathname: "/user/track/" + btoa(rowdata.Id),
                      state: { prevPath: location.pathname },
                    }}
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="blue"
                      icon={<EventDetailIcon />}
                    />
                  </Link>
                </WhisperTooltip>
                &nbsp;
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View Notice PDF"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="orange"
                    icon={<VisibleIcon />}
                    onClick={() =>
                      downloadClickNoticePdf(rowdata?.user_notice_path)
                    }
                  />
                </WhisperTooltip>
              </>
            )}
          </Cell>
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Mark As</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="Resolved"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="green"
                    icon={<ReviewPassIcon />}
                    onClick={() => handleResolvedAction(rowdata.Id)}
                  />
                </WhisperTooltip>
                &nbsp;
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="Escalated"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="red"
                    icon={<MoveUpIcon />}
                    onClick={() => handleEscaltedAction(rowdata.Id)}
                  />
                </WhisperTooltip>
              </>
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.data?.activeCount}
        limitOptions={[50, 100, 500, 1000, 5000]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
  tableLoading: state.DraftNotice.loading,
  token: state.Login.token,
})

export default connect(mapStateToProps)(ActiveTable)
