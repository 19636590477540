import { Store } from "react-notifications-component"
import Swal from 'sweetalert2';


export const ShowNotification = (title, type,time) => {
  Store.addNotification({
    title: title,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: time ? time : 5000,
      onScreen: true,
    },
  })
//   const Toast = Swal.mixin({
//     toast: true,
//     position: "top-end",
//     showConfirmButton: false,
//     timer: 3000,
//     timerProgressBar: true,
//     didOpen: toast => {
//       toast.addEventListener("mouseenter", Swal.stopTimer)
//       toast.addEventListener("mouseleave", Swal.resumeTimer)
//     },
//   })

//   Toast.fire({
//     icon: type,
//     title: title,
//   })
}
