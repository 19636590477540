import React, { useState, useEffect } from "react"
import { Button, CustomProvider, IconButton, Pagination } from "rsuite"
import { getSMSLog } from "store/actions"
import { connect, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import useWindowDimensions from "common/useWindowDimensions"
import { Table } from "reactstrap"
import Loader from "common/Loader"
import DrwerBar from "components/Common/DrwerBar"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import VisibleIcon from "@rsuite/icons/Visible"

function SMSLogTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const [msgShow, setMsgShow] = useState("")
  const [openMsgDrawer, setOpenMsgDrawer] = useState(false)
  const params = useParams()
  const data = props?.data
  const { height, width } = useWindowDimensions()

  //   var whevent = ""
  var dpmsg = ""
  var dpmsgcheck = false

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const handleOpenMsgView = data => {
    setMsgShow(data)
    setOpenMsgDrawer(true)
  }

  const handleClose = () => {
    openMsgDrawer && setOpenMsgDrawer(false)
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      case_id: atob(params.id),
      token: props.token,
      page: page,
      //   iDisplayStart: displayStart,
      //   iDisplayLength: limit,
    }
    dispatch(getSMSLog(requestPayload))
  }, [page, limit])

  return (
    <div className="table-responsive">
      {openMsgDrawer && (
        <DrwerBar
          open={openMsgDrawer}
          setOpen={setOpenMsgDrawer}
          title="View Message"
          body="viewMsg"
          subDataFromHtml={msgShow}
          view={true}
          size="xs"
          onCancel={handleClose}
        />
      )}
      <div style={{ height: height - 300, overflow: "scroll" }}>
        <Table size="sm">
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Event</th>
              <th>Event Date</th>
              <th>Message</th>
              <th>Mobile No.</th>
              <th>Status</th>
              <th>Delivery Date</th>
            </tr>
          </thead>
          <tbody>
            {props?.tableLoading ? (
              <tr>
                <td colSpan={7} className="text-center">
                  <div className="mt-4">
                    <Loader />
                  </div>
                </td>
              </tr>
            ) : data?.length !== 0 ? (
              data?.map((value, index) => {
                dpmsg != value.request_id
                  ? (dpmsgcheck = true)
                  : (dpmsgcheck = false)
                dpmsg = value.request_id

                return (
                  <>
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {value.smslstatus == "DELIVERED"
                          ? "SMS DELIVERED to " + value.contact
                          : ""}
                      </td>
                      <td>
                        {value.smslstatus == "DELIVERED"
                          ? value.created_at
                          : ""}
                      </td>
                      <td>
                        {value.content != "" && dpmsgcheck ? (
                          <WhisperTooltip
                            placement="top"
                            trigger="hover"
                            tooltipMsg="View Message"
                          >
                            <IconButton
                              size="xs"
                              appearance="primary"
                              color="violet"
                              icon={<VisibleIcon />}
                              onClick={() => handleOpenMsgView(value.content)}
                            />
                          </WhisperTooltip>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{value.contact}</td>
                      <td>
                        {value.smslstatus ? value.smslstatus : "Transmitted"}
                      </td>
                      <td>{value.smsldate}</td>
                    </tr>
                  </>
                )
              })
            ) : (
              <tr>
                <td colSpan={7} className="text-center">
                  <div className="mt-4">No Data Found</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {/* <Table
        height={height - 300}
        data={data}
        wordWrap="break-word"
        rowHeight={46}
        loading={props.tableLoading}
      >
        <Column width={95} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Event</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Event Date</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Message</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Mobile No.</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Status</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Date</HeaderCell>
          <Cell></Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.count}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      /> */}
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.SMSLog.items,
  tableLoading: state.SMSLog.loading,
  //   count: state.SMSLog.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(SMSLogTable)
