import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';
import { CustomProvider } from 'rsuite';

import { setBreadcrumbItems } from '../../../store/actions';
import BlockedUsers from './BlockedUsers';

const UserBlocked = props => {
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "UserBlocked Users", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Users - UserBlocked", breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>UserBlocked Users - Admin | Incase360</title>
      </MetaTags>

      <CustomProvider theme="dark">
        <BlockedUsers />
      </CustomProvider>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(UserBlocked)
