import DOMPurify from "dompurify";
export const SanitizeHtml = (html) => {
  let sanitizeContent = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'strong', 'u', 'em', 'table', 'td', 'colgroup', 'col', 'tbody', 'tr', 'li', 'ul', 'ol', 'div', 'a', 'em', 'i', 'br', 'thead', 'tfoot', 'th', 'center','b','small','mark','sup','sub','pre','dl','dt','dd','hr','img'],
    ALLOWED_ATTR: ['style', 'start', 'border', 'name','lang','dir','tabindex','href','type','value','src','alt','width','height','cellpadding','cellspacing','colspan','dir','lang','rowspan'],
    KEEP_CONTENT: true
  })
  return sanitizeContent
}

