import axios from "axios"
import { ApiRoute } from "common/ApiRoute"

const api_url = ApiRoute

export const getCountData = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "CountDataBegin" })
    axios
      .post(api_url + "allDataCount", requestPayload)
      .then(result => {
        dispatch({
          type: "CountDataSuccess",
          data: result.data.responseData,
        })
      })
      .catch(error => {
        dispatch({
          type: "CountDataFailure",
          data: error,
        })
      })
  }
}

export const getDashboardCountData = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "DashboardCountDataBegin" })
    axios
      .post(api_url + "getPendingNotification", requestPayload)
      .then(result => {
        dispatch({
          type: "DashboardCountDataSuccess",
          data: result.data.responseData,
        })
      })
      .catch(error => {
        dispatch({
          type: "DashboardCountDataFailure",
          data: error,
        })
      })
  }
}
