import axios from "axios"
import { ApiRoute } from "common/ApiRoute"

const api_url = ApiRoute

export const getAssignNotice = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "NoticeListBegin" })
    axios
      .post(api_url + "assignedNotice", requestPayload)
      .then(result => {
        dispatch({
          type: "NoticeListSuccess",
          data: result.data.responseData,
          user_name: result.data.user_name,
        })
      })
      .catch(error => {
        dispatch({
          type: "NoticeListFailure",
          data: error,
        })
      })
  }
}
