import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import axios from "axios"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { connect } from "react-redux"
import { ApiRoute } from "common/ApiRoute"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [notificationData, setNotificationData] = useState([])
  const api_url = ApiRoute
  const history = useHistory()

  const handleNotificationClick = (id, url) => {
    ApiCall(
      { id: id, token: props?.token },
      "seenNotification",
      false,
      result => {
        history.push("/" + url)
      }
    )
  }

  useEffect(() => {
    const request = {
      token: props?.token,
      notification_for: "top",
    }
    axios.post(api_url + "getUserNotification", request).then(result => {
      // console.log(result)
      setNotificationData(result?.data?.responseData)
    })
    // ApiCall(request, "getUserNotification", false, result =>
    // )
  }, [])

  // console.log(notificationData)

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block ms-1"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="ti-bell"></i>
          <span className="badge bg-danger rounded-pill">
            {notificationData?.notification_count}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0">
                  {" "}
                  Notifications ({notificationData?.notification_count}){" "}
                </h5>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notificationData?.notification_count === 0 ? (
              <div className="media text-center">
                <div className="media-body">
                  <h5 className="mt-4">Notification is Empty</h5>
                </div>
              </div>
            ) : (
              notificationData?.notification_list?.map((value, index) => {
                return (
                  <div
                    // to={`/${value?.url}`}
                    onClick={() =>
                      handleNotificationClick(value?.id, value?.url)
                    }
                    className="text-reset notification-item"
                    key={index}
                  >
                    <div className="media">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title border-success rounded-circle">
                          <i className="ti-bell"></i>
                        </span>
                      </div>
                      <div className="media-body">
                        <h6 className="mt-2 mb-1">{value?.title}</h6>
                        {/* <div className="text-muted">
                        <p>If several languages coalesce the grammar</p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                )
              })
            )}
            {/* <Link to="#" className="text-reset notification-item">
              <div className="media">
                <div className="avatar-xs me-3">
                  <span className="avatar-title border-success rounded-circle ">
                    <i className="mdi mdi-cart-outline"></i>
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mt-0 mb-1">Your order is placed</h6>
                  <div className="text-muted">
                    <p className="mb-1">
                      If several languages coalesce the grammar
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="#" className="text-reset notification-item">
              <div className="media">
                 <div className="avatar-xs me-3">
                  <span className="avatar-title border-warning rounded-circle ">
                    <i className="mdi mdi-message"></i>
                  </span>
                </div> 
                <div className="media-body">
                  <div>
                    <h6 className="mt-0 mb-1">New Message received</h6>
                  </div>
                  <div className="text-muted">
                    <p className="mb-1">You have 87 unread messages</p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="#" className="text-reset notification-item">
              <div className="media">
                <div className="avatar-xs me-3">
                  <span className="avatar-title border-info rounded-circle ">
                    <i className="mdi mdi-glass-cocktail"></i>
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mt-0 mb-1">Your item is shipped</h6>
                  <div className="text-muted">
                    <p className="mb-1">
                      It is a long established fact that a reader will
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="#" className="text-reset notification-item">
              <div className="media">
                <div className="avatar-xs me-3">
                  <span className="avatar-title border-primary rounded-circle ">
                    <i className="mdi mdi-cart-outline"></i>
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mt-0 mb-1">Your order is placed</h6>
                  <div className="text-muted">
                    <p className="mb-1">
                      Dummy text of the printing and typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="#" className="text-reset notification-item">
              <div className="media">
                <div className="avatar-xs me-3">
                  <span className="avatar-title border-warning rounded-circle ">
                    <i className="mdi mdi-message"></i>
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mt-0 mb-1">New Message received</h6>
                  <div className="text-muted">
                    <p className="mb-1">You have 87 unread messages</p>
                  </div>
                </div>
              </div>
            </Link> */}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-md font-size-16 btn-block text-center"
              to={`${
                parseInt(props?.role) === 1 ? "/admin" : "/user"
              }/notification`}
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i> View all
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
  role: state.Login.role,
})

export default connect(mapStateToProps)(NotificationDropdown)
