import axios from "axios"
import { ApiRoute } from "common/ApiRoute"

const api_url = ApiRoute

export const 






ApiCall = (requestPayload, url, bulkFile = false, callback) => {
  const headers = {
    "Content-Type": "application/json",
  }

  let formData = new FormData()
  Object.entries(requestPayload).map(([key, value]) => {
    if (bulkFile) {
      if (value && typeof value === "object") {
        Object.entries(value).map(([index, item]) => {
          formData.append(key + index, item)
        })
      } else {
        formData.append(key, value)
      }
    } else {
      formData.append(key, value)
    }
  })
  console.log("Request Send to Server...")
  axios
    .post(api_url + url, formData, {
      headers: headers,
    })
    .then(result => {
      callback(result)
    })
    .catch(error => {
      callback(error.response)
       console.log("Getting error from Api")
    })
}
