import React from "react"
import { Button, Col, Grid, Row } from "rsuite"
import bannerImg from "../../../images/misc/image-2.png"
import ProcessFlow from "./ProcessFlow"
import "./index.scss"
import Journey from "./Journey"
import Count from "./Count"
import Disclaimer from "./Disclaimer"
import Faqs from "./Faqs"
import { Link } from "react-router-dom"

function MainPage() {
  return (
    <>
      <div className="banner">
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none container"
          aria-hidden="true"
        >
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={24} sm={24} md={12}>
                <img src={bannerImg} className="img-fluid" width="100%" />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="banner-text">
                  <h2>
                    <b>
                      Incase360 – An Intuitive Platform for Legal Automation
                    </b>
                  </h2>
                  <br />
                  <p>
                    <b>Combining automation and analytics</b>
                    <br />
                    <br />
                    <b>
                      Incase360 is a platform for sending legal communications
                      digitally. Think of it as a courier partner but for
                      digital mediums like email/WhatsApp/SMS.
                    </b>
                  </p>
                  <p>
                    <b>
                      <span style={{ color: "red" }}>
                        <u>Note:</u>
                      </span>{" "}
                      Incase360 does not represent the sender or provide any
                      legal advice. It is not responsible for any contents of
                      the communication.{" "}
                    </b>
                  </p>
                  <br />
                  <Link to="/register">
                    <button className="btn custom-btn">GET STARTED</button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
      <ProcessFlow />
      <Journey />
      {/* <Disclaimer /> */}
      <Faqs />
    </>
  )
}

export default MainPage
