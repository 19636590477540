import Swal from "sweetalert2"

export const ShowSwal = (
  title,
  text,
  icon,
  buttonText = "Submit",
  callback
) => {
  Swal.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    // confirmButtonText: "Yes, delete it!",
    confirmButtonText: buttonText,
  }).then(result => {
    callback(result)
  })
}

export const ShowSwalForMultiAction = (
  title,
  text,
  icon,
  buttonText = "Submit",
  denyButtonText = "Ok",
  callback
) => {
  Swal.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: false,
    showDenyButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: buttonText,
    denyButtonText: denyButtonText,
  }).then(result => {
    callback(result)
  })
}
