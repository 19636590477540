import axios from "axios"
import { ApiRoute, RouteWithoutApi } from "common/ApiRoute"

const api_url = RouteWithoutApi

export const getAutoReport = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"
  let formData = new FormData()
  Object.entries(requestPayload).map(([key, value]) => {
    formData.append(key, value)
  })
  return dispatch => {
    dispatch({ type: "AutoReportListBegin" })
    axios
      .post(api_url + "listAutoReport", formData)
      .then(result => {
        dispatch({
          type: "AutoReportListSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "AutoReportListFailure",
          data: error,
        })
      })
  }
}
