import axios from "axios"
import { ApiRoute } from "common/ApiRoute"

const api_url = ApiRoute

export const getSubUser = requestPayload => {
  let formData = new FormData()
  Object.entries(requestPayload).map(([key, value]) => {
    formData.append(key, value)
  })
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"

  return dispatch => {
    dispatch({ type: "SubUserBegin" })
    axios
      .post(api_url + "user/subusers", formData)
      .then(result => {
        dispatch({
          type: "SubUserSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "SubUserFailure",
          data: error,
        })
      })
  }
}

export const getSubUserAssignNotice = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"
  let formData = new FormData()
  Object.entries(requestPayload).map(([key, value]) => {
    formData.append(key, value)
  })
  return dispatch => {
    dispatch({ type: "SubUserNoticeListBegin" })
    axios
      .post(api_url + "user/subuser-notice-list", formData)
      .then(result => {
        dispatch({
          type: "SubUserNoticeListSuccess",
          data: result.data.responseData,
          user_name: result.data.user_name,
        })
      })
      .catch(error => {
        dispatch({
          type: "SubUserNoticeListFailure",
          data: error,
        })
      })
  }
}
  export const removeNoticeList = () => {
    return dispatch => {
       dispatch({
          type: "NoticeListDelete",
          data: "",
          user_name:"",
        })
    }
  }
