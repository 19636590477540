import axios from "axios"
import { ApiRoute, RouteWithoutApi } from "common/ApiRoute"

 export const checkReport = (data) => {
     console.log(data)
           const payload = new FormData ()
           payload.append('batch_name',data?.batch_name)  
           payload.append('user_id',data.user_id)
           payload.append('notice_id',data.notice_id)
           payload.append('report_type',data.report_type)
           return axios.post(`${RouteWithoutApi}checkReport`,payload)
}