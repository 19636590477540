import React from "react"
import { Table } from "reactstrap"
import "./details.scss"

function Details({ result }) {
  //   console.log(Array.from(data));

  return (
    <React.Fragment>
      <div className="table-responsive">
        <Table bordered className="detailsView">
          <tbody>
            <tr>
              <th>User Name</th>
              <td>{result.username}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{result.email}</td>
            </tr>
            <tr>
              <th>Mobile / Whatsapp</th>
              <td>{result.contact}</td>
            </tr>
            <tr>
              <th>Recovery Email</th>
              <td>{result.recovery_email}</td>
            </tr>
            <tr>
              <th>Primary Address</th>
              <td>{result.address1}</td>
            </tr>
            <tr>
              <th>Secondary Address</th>
              <td>{result.address2}</td>
            </tr>
            <tr>
              <th>City</th>
              <td>{result.city}</td>
            </tr>
            <tr>
              <th>State</th>
              <td>{result.state}</td>
            </tr>
            <tr>
              <th>Country</th>
              <td>{result.country}</td>
            </tr>
            <tr>
              <th>Pin Code</th>
              <td>{result.pin_Code}</td>
            </tr>
            <tr>
              <th colSpan={2} style={{ textAlign: "center", fontSize: 17 }}>
                Business Details
              </th>
              {/* <td></td> */}
            </tr>
            <tr>
              <th>Organization Name</th>
              <td>{result.organization_name}</td>
            </tr>
            <tr>
              <th>Authorised Representative</th>
              <td>{result.authorised_r}</td>
            </tr>
            <tr>
              <th>Designation</th>
              <td>{result.designation}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  )
}

export default Details
