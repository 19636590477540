import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { Button, Col, Grid, List, Row } from "rsuite"
import FaqList from "../Common/FaqList"
// import DisclaimerImg from "../../../images/misc/2.png"

function Faqs() {
  return (
    <>
      <div className="faqs">
        <div className="container">
          <div className="faqs-grid">
            <Grid fluid>
              <Row className="show-grid">
                <Col xs={24} sm={24} md={24} lg={14}>
                  <FaqList />
                </Col>
                <Col xs={24} sm={24} md={24} lg={2}></Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                  {/* <img src={FaqsImg} /> */}
                  <br />
                  <br />
                  <br />
                  <h1>Schedule a Demo</h1>
                  <br />
                  <AvForm className="form-horizontal mt-4">
                    <div className="mb-3">
                      <AvField
                        name="name"
                        // label="Email"
                        className="form-control"
                        placeholder="Enter Your Name"
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="email"
                        type="email"
                        required
                        placeholder="Enter Your Email"
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="phone"
                        type="text"
                        required
                        placeholder="Enter Your Mobile No."
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="company"
                        type="text"
                        required
                        placeholder="Enter Your Company"
                      />
                    </div>
                    <div className="row mt-2">
                      <div className="col-12 text-end">
                        <button className="custom-btn" type="submit">
                          SUBMIT FORM
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </div>
    </>
  )
}

export default Faqs
