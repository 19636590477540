import React, { useState, useEffect } from "react"
import {
  Button,
  Checkbox,
  CustomProvider,
  IconButton,
  Pagination,
  Table,
} from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import CheckRoundIcon from "@rsuite/icons/CheckRound"
import SendIcon from "@rsuite/icons/Send"
import AddOutlineIcon from "@rsuite/icons/AddOutline"
import EventDetailIcon from "@rsuite/icons/EventDetail"
import DetailIcon from "@rsuite/icons/Detail"
import { Link, useParams } from "react-router-dom"
import { getDataNotice, getDraftNotice } from "store/actions"
import { connect, useDispatch } from "react-redux"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import useWindowDimensions from "common/useWindowDimensions"
import AddNotes from "../AddNotes"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import NotesAttachment from "../NotesAttachment"

const { Column, HeaderCell, Cell } = Table

function RejectedTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const { height, width } = useWindowDimensions()
  const [action, setAction] = useState(false)

  const dispatch = useDispatch()
  const params = useParams()
  const data = props?.data?.resultData

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  const downloadClickNoticePdf = file => {
    const request = {
      token: props?.token,
      folder: "userGnrtdNotice",
      filename: file,
    }
    DownloadApiCall(request, "download/single-download", "_blank")
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      data_type: "rejected",
      notice_id: params.id,
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
    }
    dispatch(getDataNotice(requestPayload))
  }, [page, limit, action])

  return (
    <div className="table-responsive" style={{ fontSize: "0.9em" }}>
      <Table
        height={height - 300}
        data={data}
        wordWrap="break-word"
        loading={props.tableLoading}
      >
        <Column width={70} align="center">
          <HeaderCell style={{ fontSize: "1.2em" }}>Sr.No.</HeaderCell>
          <Cell>
            {(rowdata, dataKey) => (
              <>
                <span>
                  {page === 1 ? dataKey + 1 : limit * (page - 1) + dataKey + 1}
                </span>
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Reference ID</HeaderCell>
          <Cell dataKey="Reference_ID" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Notice ID</HeaderCell>
          <Cell dataKey="notice_unq_id" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Date of Generation</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: rowdata.date_of_generation,
                  }}
                />
              </>
            )}
          </Cell>
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Due Date for Response</HeaderCell>
          <Cell dataKey="activatedOn" />
        </Column>
        <Column width={200} align="center">
          <HeaderCell>Batch</HeaderCell>
          <Cell dataKey="batchName" />
        </Column>
        <Column width={110} align="center">
          <HeaderCell>Notes</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <AddNotes
                  id={rowdata.Id}
                  token={props?.token}
                  notice={params?.id}
                  userNotes={rowdata?.userNotes}
                  action={action}
                  setAction={setAction}
                />
                {rowdata?.notesAttachment && (
                  <>
                    <NotesAttachment
                      attachment={rowdata?.notesAttachment}
                      id={rowdata.Id}
                      token={props?.token}
                    />
                  </>
                )}
              </>
            )}
          </Cell>
        </Column>
        <Column width={110} align="center">
          <HeaderCell>Details</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View Party Details"
                >
                  <Link
                    to={{
                      pathname: "/user/party-details/" + btoa(rowdata.Id),
                      state: { prevPath: location.pathname },
                    }}
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="violet"
                      icon={<DetailIcon />}
                    />
                  </Link>
                </WhisperTooltip>
              </>
            )}
          </Cell>
        </Column>
        <Column width={110} align="center">
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View Track"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="blue"
                    icon={<EventDetailIcon />}
                  />
                </WhisperTooltip>
                &nbsp;
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View Notice PDF"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="orange"
                    icon={<VisibleIcon />}
                    onClick={() =>
                      downloadClickNoticePdf(rowdata?.user_notice_path)
                    }
                  />
                </WhisperTooltip>
              </>
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.data?.rejectedCount}
        limitOptions={[50, 100, 500, 1000, 5000]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
  tableLoading: state.DraftNotice.loading,
  token: state.Login.token,
})

export default connect(mapStateToProps)(RejectedTable)
