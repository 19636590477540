import axios from "axios"
import { ApiRoute } from "common/ApiRoute"
import { ShowNotification } from "common/ShowNotification"
import { ShowSwal } from "common/ShowSwal"

const api_url = ApiRoute

export const DownloadApiCall = (requestPayload, url, target) => {
  const headers = {
    "Content-Type": "application/json",
  }

  let formData = new FormData()
  Object.entries(requestPayload).map(([key, value]) => {
    formData.append(key, value)
  })
  ShowSwal("Download", "Please wait file is getting download...", "info", "Ok")
  axios({
    url: api_url + url,
    method: "POST",
    data: formData,
  }).then(function (res) {
    if (res?.data?.response == "success") {
      const link = document.createElement("a")
      // link.target = "_blank"
      link.target = target
      link.href = res?.data?.url
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      ShowSwal("", "Successfully Download!", "success", "Ok")
    } else {
      ShowSwal("", "File Not Found!", "error", "Ok")
      ShowNotification(
        res?.data?.message ? res?.data?.message : "File Not Found!",
        "danger"
      )
    }
  })
}
