import React, { useState, useEffect } from "react"
import { CustomProvider, IconButton, Pagination, Table, Input } from "rsuite"
import VisibleIcon from "@rsuite/icons/Visible"
import CheckRoundIcon from "@rsuite/icons/CheckRound"
import CloseIcon from "@rsuite/icons/Close"
import PageIcon from "@rsuite/icons/Page"
import { getUsers } from "store/actions"
import { connect, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import moment from "moment"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import useWindowDimensions from "common/useWindowDimensions"
import { ShowSwal } from "common/ShowSwal"
import { ShowNotification } from "common/ShowNotification"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import EditIcon from "@rsuite/icons/Edit"
import DrwerBar from "components/Common/DrwerBar"
import SearchInput from "common/SearchInput"

const { Column, HeaderCell, Cell } = Table

function NewRequest(props) {
  const { height, width } = useWindowDimensions()

  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const [action, setAction] = useState(false)
  const [openEditProfileDrawer, setOpenEditProfileDrawer] = useState(false)
  const [openEditRemarksDrawer, setOpenEditRemarksDrawer] = useState(false)
  const [openViewRemarksDrawer, setOpenViewRemarksDrawer] = useState({
    view: false,
    msg: "",
  })
  const [drawItemId, setDrawItemId] = useState()
  const [updateRequest, setUpdateRequest] = useState([])
  const [remarksRequest, setRemarksRequest] = useState({
    token: props?.token,
    rowid: "",
    remark: "",
  })
  const [search, setSearch] = useState({
    submit: false,
    value: "",
  })
  const dispatch = useDispatch()
  const data = props?.data

  // console.log(data1);

  // const data = [
  //   { id: 1, name: "Test_RUPIFI_Level1", description: "Test_RUPIFI_Level1", email: "xyzabc123@gmail.com", contact: "9876543212", created_at: "2022-02-14 21:58:53" },
  //   { id: 2, name: "Testing", description: "Test_RUPIFI_Level1", email: "xyzabc123@gmail.com", contact: "9876543212", created_at: "2022-02-14 21:58:53" },
  // ]

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  var filterData = data?.filter(value => {
    return parseInt(value.id) === parseInt(drawItemId)
  })

  const handleChangeUserStatus = (dataId, status) => {
    // console.log(dataId, status);
    const request = {
      token: props?.token,
      userid: dataId,
      action: status,
    }
    const buttonText = status == 1 ? "Yes, Approve it!" : "Yes, Unapprove it!"
    ShowSwal("Are you sure?", "", "warning", buttonText, result => {
      if (result.isConfirmed) {
        ApiCall(request, "admin/change-userstatus", false, result => {
          if (result?.data?.response === "success") {
            setAction(!action)
            ShowNotification(result?.data?.message, "success")
            // history.push("/admin/existingnotice")
          } else {
            ShowNotification(result?.data?.message, "danger")
          }
        })
      }
    })
  }

  const handleopenEditProfileDrawer = value => {
    setDrawItemId(value)
    setOpenEditProfileDrawer(true)
    // setUpdateRequest(filterData[0])
    setTimeout(() => {
      setUpdateRequest(prev => ({ ...prev, token: props?.token }))
    }, 2000)
  }

  const handleopenViewRemarksDrawer = value => {
    // console.log(value)
    setOpenViewRemarksDrawer({
      ...openViewRemarksDrawer,
      view: true,
      msg: value,
    })
  }

  const handleopenEditRemarksDrawer = (value, msg = "") => {
    setRemarksRequest({
      ...remarksRequest,
      rowid: value,
      remark: msg ? msg : "",
    })
    setOpenEditRemarksDrawer(true)
  }

  const handleUpdateRemarks = () => {
    // console.log(remarksRequest)
    ApiCall(remarksRequest, "addUserRemark", false, result => {
      if (result?.data?.response === "success") {
        setOpenEditRemarksDrawer(false)
        setAction(!action)
        ShowNotification(result?.data?.message, "success")
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  const handleUpdateProfile = () => {
    ApiCall(updateRequest, "user/update-profile", false, result => {
      if (result?.data?.response === "success") {
        setOpenEditProfileDrawer(false)
        setAction(!action)
        ShowNotification(result?.data?.message, "success")
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }

  useEffect(() => {
    setUpdateRequest(filterData[0])
  }, [drawItemId])

  const handleClose = () => {
    openEditProfileDrawer && setOpenEditProfileDrawer(false)
    openViewRemarksDrawer &&
      setOpenViewRemarksDrawer({ ...openViewRemarksDrawer, view: false })
    openEditRemarksDrawer && setOpenEditRemarksDrawer(false)
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      status: 0,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      searchValue: search?.value,
    }
    dispatch(getUsers(requestPayload))
  }, [page, limit, action, search?.submit])

  return (
    <div className="table-responsive">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SearchInput
          placeholder="Search"
          search={search}
          setSearch={setSearch}
        />
      </div>
      {openEditProfileDrawer && (
        <DrwerBar
          open={openEditProfileDrawer}
          setOpen={setOpenEditProfileDrawer}
          title="Edit Profile"
          body="editProfile"
          forEditData={filterData[0]}
          size="sm"
          state={updateRequest}
          setState={setUpdateRequest}
          onClick={() => handleUpdateProfile()}
          onCancel={handleClose}
        />
      )}
      {openViewRemarksDrawer?.view && (
        <DrwerBar
          view={true}
          open={openViewRemarksDrawer?.view}
          // setOpen={setOpenViewRemarksDrawer}
          title="View Remarks"
          body="viewMsg"
          size="xs"
          // state={remarksRequest}
          subDataFromHtml={openViewRemarksDrawer?.msg}
          // setState={setRemarksRequest}
          // onClick={() => handleUpdateProfile()}
          onCancel={handleClose}
        />
      )}
      {openEditRemarksDrawer && (
        <DrwerBar
          open={openEditRemarksDrawer}
          // setOpen={setOpenViewRemarksDrawer}
          title="Add / Edit Remarks"
          body="addRemarks"
          size="xs"
          state={remarksRequest}
          setState={setRemarksRequest}
          onClick={() => handleUpdateRemarks()}
          onCancel={handleClose}
        />
      )}
      <Table
        height={height - 350}
        data={data}
        wordWrap="break-word"
        rowHeight={46}
        loading={props.tableLoading}
      >
        <Column width={80} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell>{(rowdata, key) => <>{key + 1}</>}</Cell>
        </Column>
        <Column flexGrow={2}>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="username" />
        </Column>
        <Column flexGrow={2}>
          <HeaderCell>Organization</HeaderCell>
          <Cell dataKey="organization_name" />
        </Column>
        <Column flexGrow={2}>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="email" />
        </Column>
        <Column flexGrow={2}>
          <HeaderCell>Contact</HeaderCell>
          <Cell dataKey="contact" />
        </Column>
        <Column flexGrow={2}>
          <HeaderCell>Created At</HeaderCell>
          <Cell>
            {rowdata => <>{moment(rowdata.created_at).format("DD-MM-YYYY")}</>}
          </Cell>
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Remarks</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="Add / Edit Remarks"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="blue"
                    icon={<EditIcon />}
                    onClick={() =>
                      handleopenEditRemarksDrawer(rowdata?.id, rowdata?.remarks)
                    }
                  />
                </WhisperTooltip>
                &nbsp;
                {rowdata?.remarks && (
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="View Remarks"
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="green"
                      icon={<VisibleIcon />}
                      onClick={() =>
                        handleopenViewRemarksDrawer(rowdata?.remarks)
                      }
                    />
                  </WhisperTooltip>
                )}
              </>
            )}
          </Cell>
        </Column>
        <Column width={180} align="center">
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {rowdata => (
              <>
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="Set Approve User"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="green"
                    icon={<CheckRoundIcon />}
                    onClick={() => handleChangeUserStatus(rowdata?.id, 1)}
                  />
                </WhisperTooltip>
                &nbsp;
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="Set Unapprove User"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="red"
                    icon={<CloseIcon />}
                    onClick={() => handleChangeUserStatus(rowdata?.id, 2)}
                  />
                </WhisperTooltip>
                &nbsp;
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="Edit User"
                >
                  <IconButton
                    size="xs"
                    appearance="primary"
                    color="blue"
                    icon={<EditIcon />}
                    onClick={() => handleopenEditProfileDrawer(rowdata?.id)}
                    // onClick={() =>
                    //   handleChangeUserStatus(rowdata?.id, 2)
                    // }
                  />
                </WhisperTooltip>
                &nbsp;
                {/* {{pathname: '/nextpath', state: { prevPath: location.pathname }}} */}
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View User Personal Details"
                >
                  <Link
                    to={{
                      pathname: "/admin/user-details/" + rowdata.id,
                      state: { prevPath: location?.pathname },
                    }}
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="orange"
                      icon={<VisibleIcon />}
                    />
                  </Link>
                </WhisperTooltip>
                &nbsp;
                <WhisperTooltip
                  placement="top"
                  trigger="hover"
                  tooltipMsg="View User Letterhead"
                >
                  <Link
                    to={{
                      pathname: "/admin/user-letterhead/" + btoa(rowdata.id),
                      state: { prevPath: location.pathname },
                    }}
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="violet"
                      icon={<PageIcon />}
                    />
                  </Link>
                </WhisperTooltip>
              </>
            )}
          </Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props.tableCount}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.Users.items,
  tableLoading: state.Users.loading,
  tableCount: state.Users.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(NewRequest)
