import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"
import { CustomProvider, IconButton } from "rsuite"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems, getAllApproveUser } from "../../../store/actions"
import AdminShareFileTable from "./AdminShareFileTable"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import ShareRoundIcon from "@rsuite/icons/ShareRound"
import DrwerBar from "components/Common/DrwerBar"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"
import { TabBar } from "common/TabBar/TabBar"

const AdminFileSharing = props => {
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Shared File List", link: "#" },
  ]
  const dispatch = useDispatch()

  const [openShareFileDrawer, setOpenShareFileDrawer] = useState(false)
  const [action, setAction] = useState(false)

  const [request, setRequest] = useState({
    token: props?.token,
    file_name: null,
    shared_with: null,
    remarks: null,
    user_type: 1,
  })

  const [active, setActive] = useState("shareWithUser")

  const tabbardata = [
    {
      eventkey: "shareWithUser",
      lable: "Share With User",
    },
    { eventkey: "shareByUser", lable: "Share By User" },
  ]

  const handleopenShareFileDrawer = () => {
    setOpenShareFileDrawer(true)
  }

  const handleClose = () => {
    setOpenShareFileDrawer(false)
    setRequest({
      ...request,
      file_name: null,
      shared_with: null,
      remarks: null,
    })
  }

  const handleShareFileAction = () => {
    request.file_name &&
      request.shared_with &&
      ApiCall(request, "admin/addfile-sharing", false, result => {
        if (result?.data?.response === "success") {
          ShowNotification(result?.data?.message, "success")
          // history.push("/admin/existingnotice")
          setAction(!action)
          setOpenShareFileDrawer(false)
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
  }

  useEffect(() => {
    props.setBreadcrumbItems("Shared File List", breadcrumbItems)
  })

  useEffect(() => {
    const requestPayload = {
      token: props?.token,
    }
    dispatch(getAllApproveUser(requestPayload))
  }, [])

  const userdata = props?.data?.map(item => ({
    label: item?.email + " - " + item?.organization_name,
    value: item?.id,
  }))

  return (
    <React.Fragment>
      <MetaTags>
        <title>Shared File List - Admin | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        {openShareFileDrawer && (
          <DrwerBar
            open={openShareFileDrawer}
            setOpen={setOpenShareFileDrawer}
            title="Share File With User"
            body="shareFile"
            size="sm"
            onClick={() => handleShareFileAction()}
            subData={userdata}
            state={request}
            setState={setRequest}
            onCancel={handleClose}
          />
        )}
        <div className="addButtonLetter">
          <WhisperTooltip
            placement="top"
            trigger="hover"
            tooltipMsg="Share File With User"
          >
            <IconButton
              size="xs"
              appearance="primary"
              color="violet"
              icon={<ShareRoundIcon />}
              onClick={() => handleopenShareFileDrawer()}
            >
              Share File
            </IconButton>
          </WhisperTooltip>
        </div>
        <TabBar
          style={{ textAlign: "center" }}
          appearance="tabs"
          active={active}
          onSelect={setActive}
          panel={tabbardata}
        />
        {active === "shareWithUser" && (
          <>
            <AdminShareFileTable userdata={props?.data} action={action} />
          </>
        )}
        {active === "shareByUser" && (
          <>
            <AdminShareFileTable
              userdata={props?.data}
              action={action}
              user_type="1"
            />
          </>
        )}
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
  data: state.AllApproveUser.items,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(
  AdminFileSharing
)
