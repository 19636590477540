const data = JSON.parse(localStorage.getItem("data"))

const initialState = {
  token: data?.token,
  items: [],
  role: data?.role,
  // name: null,
  id: data?.id,
}

export const Login = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...initialState,
        token: action?.token,
        items: action?.data?.responseData,
        role: action?.data?.user_type,
        // name: action.data.username,
        id: action?.data?.id,
      }
    case "REFRESHTOKEN":
      return {
        ...initialState,
        token: action?.token,
        role: action?.data?.user_type,
        items: action?.data?.responseData,
        id: action?.data?.id,
      }
    case "LOGOUT":
      return {
        ...initialState,
        token: null,
        role: null,
        items: [],
        id: null,
      }
    default:
      return state
  }
}
