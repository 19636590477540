import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"
import LetterheadForm from './LetterheadForm'

import { getAllApproveUser, setBreadcrumbItems } from "../../../store/actions"

const AdminAddLetterHead = props => {

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Add New Letterhead", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Add New Letterhead", breadcrumbItems)
  })

//   useEffect(() => {
//     const requestPayload = {
//       token: props?.token,
//     }
//     dispatch(getAllApproveUser(requestPayload))
//   }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Add Letterhead - Admin | Incase360</title>
      </MetaTags>
      <LetterheadForm token={props?.token} />
      {/* <NoticeForm userdata={props.data} /> */}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
//   data: state.AllApproveUser.items,
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(AdminAddLetterHead)
