import axios from "axios"
import { ApiRoute } from "common/ApiRoute"
import NotificationDiv from "components/Common/NotificationDiv"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { CustomProvider, Divider, Pagination } from "rsuite"

import { setBreadcrumbItems } from "../../../store/actions"
const UserNotification = props => {
  const [notificationData, setNotificationData] = useState([])
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const api_url = ApiRoute
  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Notification", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Notification", breadcrumbItems)
  })

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const request = {
      token: props?.token,
      notification_for: "list",
      iDisplayStart: displayStart,
      iDisplayLength: limit,
    }
    axios.post(api_url + "getUserNotification", request).then(result => {
      setNotificationData(result?.data?.responseData)
    })
  }, [page, limit])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Notification - User | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        <NotificationDiv
          list={notificationData?.notification_list}
          count={notificationData?.notification_count}
        />
        <Pagination
          className="my-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={notificationData?.notification_count}
          limitOptions={[50, 100, 200]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(
  UserNotification
)
