import { RouteWithoutApi } from "common/ApiRoute";

const { default: axios } = require("axios")
const UserServerice = {
       getNoticeListMonthWise : function (data) {
           let playload = new FormData()
           playload.append('fromDate', data?.fromDate);
           playload.append('toDate', data?.toDate);
        data.userId &&  playload.append('userId', data?.userId);
        data.noticeId && playload.append('noticeId', data?.noticeId);
           return axios
              .post(`${RouteWithoutApi}getNoticeBetweenRange`,playload)
       },
       getNotificationBetweenRange:function(data){
         let playload = new FormData()
         playload.append('fromDate', data?.fromDate);
         playload.append('toDate', data?.toDate);
      data.userId &&  playload.append('userId', data?.userId);
      data.noticeId && playload.append('noticeId', data?.noticeId);
         return axios
            .post(`${RouteWithoutApi}getNotificationBetweenRange`,playload)
       }
}

 export {UserServerice}