import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import {
  withRouter,
  Link,
  useHistory,
  Redirect,
  useParams,
  useLocation  
} from "react-router-dom"
import "./index.scss"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect, useDispatch } from "react-redux"
import axios from "axios"
import { Store } from "react-notifications-component"
import { logIn } from "store/actions"
import { ApiRoute } from "common/ApiRoute"
import FormGroup from "components/Common/FormGroup"
import { Button, Input } from "rsuite"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"
import { FileUploader } from "react-drag-drop-files"
import FaqList from "../Common/FaqList"
import queryString from 'query-string';
// actions

// import images
// import logoLightPng from "../../assets/images/logo-light.png";
// import logoDark from "../../assets/images/logo-dark.png";

const Reply = props => {
  const dispatch = useDispatch()
  const location = useLocation();
  const api_url = ApiRoute
  const history = useHistory()
  const params = useParams()

  const [noticeData, setNoticeData] = useState({})
  const [action, setAction] = useState(false)
  const [request, setRequest] = useState({
    reply_id: null,
    files: null,
    party_name: null,
    your_reply: null,
  })

  const getCurrentDate = () => {
    var currentDate = new Date()
    // currentDate =
    var year = currentDate.getFullYear()
    var month = currentDate.getMonth() + 1
    var date = currentDate.getDate()

    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${
      date < 10 ? `0${date}` : `${date}`
    }`
  }

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const iValue = queryParams.i;
     console.log(iValue)
    var values = {
      reply_id: iValue,
    }
    axios.post(api_url + "reply", values).then(response => {
      setNoticeData(response?.data?.responseData),
        setRequest({
          ...request,
          reply_id: response?.data?.responseData?.data?.id,
        })
    })
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    )
    Array.from(document.querySelectorAll("textarea")).forEach(
      input => (input.value = "")
    )
    // axios
    //   .post(ApiRoute + "reply", values)
    //   .then(result => {
    //     callback(result)
    //     setNoticeData(response?.data?.responseData)
    //   })
    //   .catch(error => {
    //     console.log(error.response)
    //   })
  }, [action])

  //   console.log(request)
  const handleSubmit = () => {
    request?.reply_id &&
      request?.party_name &&
      request?.your_reply &&
      ApiCall(request, "updateReply", false, result => {
        if (result?.data?.message === "success") {
          setRequest({
            ...request,
            files: null,
            party_name: null,
            your_reply: null,
          })
          setAction(!action)
          ShowNotification(result?.data?.responseData, "success")

          // history.push("/admin/existingnotice")
        } else {
          ShowNotification(result?.data?.responseData, "danger")
        }
      })
  }

  return (
    <React.Fragment>
      <>
        <MetaTags>
          <title>Reply | Incase360</title>
        </MetaTags>
        <div className="my-2 pt-sm-4 reply">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                    <div className="p-3">
                      {!noticeData?.data ? (
                        <h4>Notice ID Invaild</h4>
                      ) : noticeData?.till_date < getCurrentDate() &&
                        noticeData?.willLinkExpire == 1 ? (
                        <h4>The due date to respond has expried</h4>
                      ) : (
                        <>
                          <h4 className="text-muted font-size-24 text-center">
                            Reply
                          </h4>
                          <a href={noticeData?.notice_file} target="_blank">
                            <Button className="mt-4" appearance="primary" block>
                              View Notice / Communication
                            </Button>
                          </a>

                          <div className="mt-3 mb-3">
                            <Input
                              name="noticeId"
                              // defaultValue="123"
                              value={`IN${noticeData?.data?.notice_id}-${noticeData?.data?.notice_number}`}
                              type="text"
                              // disabled
                            />
                          </div>
                          <div className="mb-3">
                            <Input
                              name="partyName"
                              type="text"
                              datavalue={request?.party_name || ""}
                              placeholder="Enter Party Name"
                              onChange={e =>
                                setRequest({ ...request, party_name: e })
                              }
                            />
                          </div>
                          <div className="">
                            <FormGroup
                              textarea={true}
                              name="partyReply"
                              type="text"
                              datavalue={request?.your_reply || ""}
                              placeholder="Enter Your Reply"
                              onChange={e =>
                                setRequest({ ...request, your_reply: e })
                              }
                            />
                          </div>
                          <div className="mb-3">
                            {/* <FormGroup
                            name="partyFile"
                            upload={true}
                            onChange={e => setRequest({ ...request, files: e })}
                          /> */}
                            <FileUploader
                              name="file"
                              onTypeError={e =>
                                e && setRequest({ ...request, files: null })
                              }
                              handleChange={e =>
                                setRequest({ ...request, files: e })
                              }
                            />
                          </div>

                          <div className="mb-3 row mt-2">
                            <div className="col-12 text-end">
                              <Button
                                appearance="primary"
                                onClick={handleSubmit}
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="my-5 faqs-grid">
              <FaqList />
            </div>
          </Container>
        </div>
      </>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.Login,
})

export default withRouter(connect(mapStateToProps)(Reply))
