import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { CustomProvider } from "rsuite"
import { setBreadcrumbItems } from "../../../../store/actions"
import { useLocation } from "react-router-dom"
import UserListTable from "./UserListTable"

const UserList = props => {
  const location = useLocation()

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Sub User List", link: "#" },
    // { title: "Notice Details", link: location?.state?.prevPath },
    // { title: "Party Reply", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems(`Sub User List`, breadcrumbItems)
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Sub User List - User | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        <UserListTable />
        {/* <BulkMenu openSubMenu={openSubMenu} setOpenSubMenu={setOpenSubMenu} bulkButton={bulkButton} />  */}
      </CustomProvider>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(UserList)
