import "rsuite/dist/rsuite.css"

import Loader from "common/Loader"
import SearchInput from "common/SearchInput"
import useWindowDimensions from "common/useWindowDimensions"
import React, { useEffect, useRef, useState } from "react"
import Button from "react-bootstrap/Button"
import { connect, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { CustomProvider, Pagination, Table } from "rsuite"
import { getAssignProxyNotice, removeNoticeList } from "store/actions"

const { Column, HeaderCell, Cell } = Table

const NoticeTable = props => {
  const contentRef = useRef()
  const { height, width } = useWindowDimensions()
  const [search, setSearch] = useState({
    submit: false,
    value: "",
  })

  // const { width, height } = useContainerDimensions(contentRef)

  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  let data = props?.data

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }
  const displayStart = limit * (page - 1)

  const requestPayload = {
    token: props?.token,
    page: page,
    iDisplayStart: displayStart,
    iDisplayLength: limit,
    searchValue: search?.value,
  }
  useEffect(() => {
    dispatch(removeNoticeList())
    if (search?.value) {
      dispatch(getAssignProxyNotice(requestPayload))
    }
  }, [page, limit, search?.submit])

  return (
    <React.Fragment>
      <div className="table-responsive">
        <CustomProvider theme="dark">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SearchInput
              placeholder="Search"
              search={search}
              setSearch={setSearch}
            />
          </div>
          <Button
            onClick={() => {
              const newpayload = { ...requestPayload, searchValue: "" }
              dispatch(getAssignProxyNotice(newpayload))
            }}
            variant="outline-primary"
          >
            View All Notice
          </Button>

          {data.length != 0 ? (
            <Table
              height={height - 380}
              data={data}
              wordWrap="break-word"
              loading={props.dataloading}
            >
              <Column flexGrow={1} align="center">
                <HeaderCell>Sr.No.</HeaderCell>
                <Cell>
                  {(rowdata, dataKey) => (
                    <>
                      <span>
                        {page === 1
                          ? dataKey + 1
                          : limit * (page - 1) + dataKey + 1}
                      </span>
                    </>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Notice ID</HeaderCell>
                <Cell>{rowdata => <>{rowdata?.noticeid}</>}</Cell>
              </Column>
              <Column flexGrow={3}>
                <HeaderCell>Notice</HeaderCell>
                <Cell>
                  {rowdata => (
                    <>
                      {/* <Link to={"/admin/noticedetails/" + rowdata.noticeid}> */}
                      {rowdata?.noticeName?.replace("notice_", "")}
                      {/* </Link> */}
                    </>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Draft</HeaderCell>
                {/* <Cell dataKey="draftCount" /> */}
                <Cell>
                  {rowdata => (
                    <>
                      <Link to={"/admin/draft-notice/" + rowdata.noticeid}>
                        {rowdata.draftCount}
                      </Link>
                    </>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Active</HeaderCell>
                {/* <Cell dataKey="activeCount" /> */}
                <Cell>
                  {rowdata => (
                    <>
                      <Link to={"/admin/active-notice/" + rowdata.noticeid}>
                        {rowdata.activeCount}
                      </Link>
                    </>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Overdue</HeaderCell>
                {/* <Cell dataKey="overdueCount" /> */}
                <Cell>
                  {rowdata => (
                    <>
                      <Link to={"/admin/overdue-notice/" + rowdata.noticeid}>
                        {rowdata.overdueCount}
                      </Link>
                    </>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Escalated</HeaderCell>
                {/* <Cell dataKey="escalateCount" /> */}
                <Cell>
                  {rowdata => (
                    <>
                      <Link to={"/admin/escalated-notice/" + rowdata.noticeid}>
                        {rowdata.escalateCount}
                      </Link>
                    </>
                  )}
                </Cell>
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>Resolved</HeaderCell>
                {/* <Cell dataKey="resolveCount" /> */}
                <Cell>
                  {rowdata => (
                    <>
                      <Link to={"/admin/resolved-notice/" + rowdata.noticeid}>
                        {rowdata.resolveCount}
                      </Link>
                    </>
                  )}
                </Cell>
              </Column>
            </Table>
          ) : (
            <div
              style={{
                height: "calc(100vh - 300px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              {props.dataloading ? <Loader /> : <p>Search to see notice</p>}
            </div>
          )}

          <Pagination
            className="my-4"
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={props?.data?.length}
            limitOptions={[50, 100, 200]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </CustomProvider>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.AssignNotice.items,
  dataloading: state.AssignNotice.loading,
  token: state.Login.token,
})

export default connect(mapStateToProps)(NoticeTable)
