import axios from "axios"
import { ApiRoute } from "common/ApiRoute"

const api_url = ApiRoute

export const getUserMe = (requestPayload, callback) => {
  return dispatch => {
    axios
      .post(api_url + "userData", requestPayload)
      .then(result => {
        dispatch({
          type: "getUserDataSuccess",
          data: result.data.responseData,
        })
        callback(result)
      })
      .catch(error => {
        dispatch({
          type: "getUserDataFailure",
          data: error,
        })
        callback(error.response)
      })
  }
}
