import React, { useState } from "react"
import { Row, FormGroup, Col } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowSwal } from "common/ShowSwal"
import { ShowNotification } from "common/ShowNotification"

function Retrigger(props) {
  const [disabledBtn, setDisabledBtn] = useState(false)

  const handleRetrigger = (event, values) => {
    setDisabledBtn(true)

    // console.log(values)
    ShowSwal(
      "",
      `Do You Want to Resend From ${values?.from_case} To ${values?.to_case}?`,
      "warning",
      "Ok",
      result => {
        if (result.isConfirmed) {
          ApiCall(values, "resendWhatsappFromTo", false, result => {
            if (result?.data?.response === "success") {
              ShowNotification(result?.data?.message, "success")
            } else {
              ShowNotification(result?.data?.message, "danger")
            }
            setDisabledBtn(false)
          })
        } else {
          setDisabledBtn(false)
        }
      }
    )
  }

  return (
    <div>
      <div
        style={{
          backgroundColor: "#131826",
          padding: "1em 2em",
          borderRadius: "0.5em",
        }}
      >
        <h5>Retrigger WhatsApp</h5>
      </div>
      <AvForm
        className="mt-3 mb-3"
        onValidSubmit={(e, v) => {
          handleRetrigger(e, v)
        }}
      >
        <Row>
          <Col lg="5" md="5">
            <FormGroup className="mt-3">
              <AvField
                name="from_case"
                className="form-control"
                type="text"
                placeholder="From ex. IN12-1234"
                required
              />
            </FormGroup>
          </Col>
          <Col lg="5" md="5">
            <FormGroup className="mt-3">
              <AvField
                name="to_case"
                className="form-control"
                type="text"
                placeholder="To ex. IN12-2345"
                required
              />
              <AvField
                name="token"
                className="form-control"
                type="text"
                value={props?.token}
                hidden
              />
            </FormGroup>
          </Col>

          <Col lg="2" md="2">
            <button
              disabled={disabledBtn}
              className="btn btn-primary w-md waves-effect waves-light mt-3"
              type="submit"
            >
              Retrigger
            </button>
          </Col>
        </Row>
      </AvForm>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps)(Retrigger)
