const initialState = {
  items: [],
}

export const Register = (state = initialState, action) => {
  switch (action.type) {
    case "REGISTER":
      return {
        ...initialState,
        items: action?.data,
      }
    default:
      return state
  }
}
