import { CustomProvider, Nav } from "rsuite"

export const TabBar = ({ active, onSelect, panel, ...props }) => {
  return (
    <Nav justified {...props} activeKey={active} onSelect={onSelect}>
      {panel?.map((value, index) => {
        return (
          <Nav.Item key={index} eventKey={value.eventkey}>
            {value.lable}
          </Nav.Item>
        )
      })}
    </Nav>
  )
}
